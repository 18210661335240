import { Link, useLocation } from "react-router-dom";
import prof1_brown from '../../../assets/images/prof1-brown.png';
import prof2_brown from '../../../assets/images/prof2-brown.png';
import prof3_brown from '../../../assets/images/prof3-brown.png';
import prof4_brown from '../../../assets/images/prof4-brown.png';
import prof1_white from '../../../assets/images/prof1-white.png';
import prof2_white from '../../../assets/images/prof2-white.png';
import prof3_white from '../../../assets/images/prof3-white.png';
import prof4_white from '../../../assets/images/prof4-white.png';

import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProfileMenu = () => {
    var location = useLocation().pathname;
    return (
        <div className="col-sm-3">
            <ul className="profile_left flt">
                <li><Link to="/my-details" className={location === '/my-details' ? "active" : ''}><LazyLoadImage src={location === '/my-details' && prof1_white || location !== '/my-details' && prof1_brown} />My Details</Link></li>
                <li><Link to="/order-details" className={location === '/order-details' ? "active" : ''}><LazyLoadImage src={location === '/order-details' && prof2_white || location !== '/order-details' && prof2_brown} />Order Details</Link></li>
                <li><Link to="/wishlist" className={location === '/wishlist' ? "active" : ''}><LazyLoadImage src={location === '/wishlist' && prof3_white || location !== '/wishlist' && prof3_brown}/> Wishlist</Link></li>
                <li><Link to="/video-shopping" className={location === '/video-shopping' ? "active" : ''}><LazyLoadImage src={location === '/video-shopping' && prof4_white || location !== '/video-shopping' && prof4_brown}/>Video Shopping</Link></li>
            </ul>
        </div>
    )
}
export default ProfileMenu;