import useLoginHooks from "./useLoginHooks";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import "./Login.css";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Login = () => {
  const {
    google,
    home_banner2,
    mobile_no,
    password,
    submitted,
    loggedin,
    loginError,
    handleMobileNoChange,
    handlePasswordChange,
    handleSubmit,
    handleKeyPress,
    signIn,
    loginDescription,
  } = useLoginHooks();

  const logMain = () => {
    return (
      <div>
        <Header />

        <Helmet>
          <meta name="description" content={loginDescription?.info} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>

        <div class="login_container flt">
          <div class="login_box">
            <div class="login_banner flt">
              <LazyLoadImage src={home_banner2} />
            </div>
            <div class="login_cont flt">
              <div class="login_head flt">
                <h1 class="login_head flt">
                  Log In <span>or</span> Sign In
                </h1>
              </div>
              <div class="form-group flt">
                <div class="login_ip flt">
                  <input
                    type="number"
                    placeholder="Enter Mobile Number"
                    name="mobile_no"
                    value={mobile_no}
                    onChange={handleMobileNoChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    className={submitted && !mobile_no ? " is-invalid" : ""}
                    onWheel={(e) => e.currentTarget.blur()}
                  />

                  <span>+91</span>
                </div>
                {submitted && !mobile_no && (
                  <div className="invalid-feedback invalid-mob">
                    Mobile number is required
                  </div>
                )}
              </div>

              <div class="form-group flt">
                <input
                  type="password"
                  placeholder="Enter Password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  className={
                    submitted && !password ? " is-invalid" : "login_lbl"
                  }
                />
                {submitted && !password && (
                  <div className="invalid-feedback">Password is required</div>
                )}
                {loginError && loggedin == false && (
                  <div class="login-error">{loginError}</div>
                )}
                <div class="login_forgot flt">
                  <a href="/forgot-password">Forgot Your Password ?</a>
                </div>
              </div>
              {loggedin ? (
                <button class="login_btn flt" onClick={handleSubmit}>
                  Login Successful!!
                </button>
              ) : (
                <button class="login_btn flt" onClick={handleSubmit}>
                  Login now
                </button>
              )}
              <button class="google_login flt" onClick={signIn}>
                <LazyLoadImage src={google} />
                Or sign-in with google
              </button>
              <div class="signup_btn flt">
                Don’t have an account? Please{" "}
                <a href="/registration">Sign Up</a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
  return <div>{logMain()}</div>;
};
export default Login;
