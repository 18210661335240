import * as axios from "../network/Network";

const config = require("../network/api.json");

class FaqService {
  static getFaq() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.faq,
    });
    return Promise.resolve(result);
  }
}
export { FaqService };
