import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import { useState, useEffect } from "react";
import "./GoldRateToday.css";
import { HeaderService } from "../../../services/HeaderService";
import { Helmet } from "react-helmet";
import useCountHook from "../../../hooks/useCountHook";

const GoldRateToday = () => {
  const [data, setData] = useState();
  const { wishListCount, cartCount } = useCountHook();
  useEffect(() => {
    HeaderService.getDailyRate()
      .then((data) => {
        setData(data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, []);
  let goldRateData = {
    info: `Find Gold Prices Today in India at stores in Mangalore, Udupi, Kundapura, Goa, Shivamogga`,
  };

  if (window.location.href.endsWith("/gold-rate-today")) {
    document.title = `Gold Rate Today | Abharan Jewellers`;
  }
  const goldRate = () => {
    return (
      <div>
        <Helmet>
          <meta name="description" content={goldRateData?.info} />
        </Helmet>
        <div className="gold_rate_container">
          <table>
            <thead>
              <tr>
                <th>Metal Type</th>
                <th>Per Gram</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gold 22 KT</td>
                <td>
                  RS.
                  {data && data?.data?.gold22}
                </td>
              </tr>
              <tr>
                <td>Gold 18 KT</td>
                <td>
                  RS.
                  {data && data?.data?.gold18}
                </td>
              </tr>
              <tr>
                <td>Silver</td>
                <td>
                  RS.
                  {data && data?.data?.silver}
                </td>
              </tr>
              <tr>
                {/* <!-- <td colspan="2"><b>*Goa rates will be 0.5% more than rates shown here.</b></td> --> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  const renderHeader = () => {
    return <Header count={wishListCount} cart={cartCount} />;
  };
  const renderFooter = () => {
    return <Footer />;
  };
  return (
    <div>
      {renderHeader()}
      {goldRate()}
      {renderFooter()}
    </div>
  );
};
export default GoldRateToday;
