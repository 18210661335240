import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import order from "../../../assets/images/empty-order.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { alertActions, userLogout } from "../../../actions";
import { LazyLoadImage } from 'react-lazy-load-image-component';
const OrderDetailsEmptyCart = (tokenExpired) => {
  let history = useNavigate();
  const dispatch = useDispatch();
  const Order = () => {
    useEffect(() => {
      if (tokenExpired.tokenExpired) {
        setTimeout(() => {
          dispatch(alertActions.error("You are logged out. Please Login"));
        }, 50);

        dispatch(userLogout());
        history("/login");
      }
    }, [tokenExpired.tokenExpired]);
    return (
      <div class="profile_container flt">
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-9">
            <div class="empty_order flt">
              <div class="empty_order_image flt">
                <LazyLoadImage src={order} alt="image" />
              </div>
              <div class="empty_order_head flt">No orders found.</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header />
      {Order()}
      <Footer />
    </div>
  );
};
export default OrderDetailsEmptyCart;
