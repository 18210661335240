import prof1 from "../../../assets/images/prof1-white.png";
import prof2 from "../../../assets/images/prof2-brown.png";
import prof3 from "../../../assets/images/prof3-brown.png";
import prof4 from "../../../assets/images/prof4-brown.png";
import { ProductService } from "../../../services/ProductService";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertActions } from "../../../actions/alert.actions";
import { useNavigate } from "react-router-dom";
import { myDetails } from "../../../actions/UserAction";

const useMyDetails = () => {
  //get user data from redux
  const user_id = useSelector((state) => state?.userData?.user?._id);
  const names = useSelector((state) => state?.userData?.user?.first_name);
  const names1 = useSelector((state) => state?.userData?.user?.last_name);
  const emails = useSelector((state) => state?.userData?.user?.email);
  const mobile_no = useSelector((state) => state?.userData?.user?.mobile_no);
  const address1 = useSelector(
    (state) => state?.userData?.user?.billing_address
  );
  const address2 = useSelector(
    (state) => state?.userData?.user?.shipping_address
  );
  const userinfo = useSelector((state) => state?.userData?.user);
  const active = useSelector((state) => state?.userData?.user?.active);
  //usestate to store values
  const [name, setName] = useState(names ? names : "");
  const [lname, setlName] = useState(names1 ? names1 : "");
  const [email, setEmail] = useState(emails ? emails : "");
  const [mobile, setMobile] = useState(mobile_no ? mobile_no : "");
  const [address, setAddress] = useState(address1 ? address1 : "");
  const [password, setPassword] = useState(address2 ? address2 : "");
  const [locality, setLocality] = useState(
    userinfo?.locality ? userinfo?.locality : ""
  );
  const [c_password, setC_password] = useState();
  const [confirm_password, setConfirm_password] = useState();
  const [new_password, setNew_password] = useState();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [check, setCheck] = useState(false);
  const [valid, setValid] = useState(true);
  const [city, setCity] = useState(userinfo?.city ? userinfo?.city : "");
  const [state, setState] = useState(userinfo?.state ? userinfo?.state : "");
  const [storeState, setStoreState] = useState([]);
  const [storeStateShipping, setStoreStateShipping] = useState([]);
  const [id, setId] = useState("");
  const [storeDistrict, setStoreDistrict] = useState([]);
  const [storeDistrictShipping, setStoreDistrictShipping] = useState([]);
  const [district, setDistrict] = useState(
    userinfo?.district ? userinfo?.district : ""
  );
  const [landmark, setLandmark] = useState(
    userinfo?.landmark ? userinfo?.landmark : ""
  );
  const [pincode, setPincode] = useState(
    userinfo?.pincode ? userinfo?.pincode : ""
  );

  const [useDetails, setUserDetails] = useState(null);
  const [billingAddress, setBillingAddress] = useState();
  const [billingLandmark, setBillingLandmark] = useState();
  const [billingLocality, setBillingLocality] = useState();
  const [shippingAddress, setShippingAddress] = useState();
  const [shippingLocality, setShippingLocality] = useState();
  const [shippingCity, setShippingCity] = useState();
  const [shippingLandmark, setShippingLandmark] = useState();
  const [shippingPincode, setShippingPincode] = useState();
  const [shippingState, setShippingState] = useState(
    userinfo?.state ? userinfo?.state : ""
  );
  const [shippingDistrict, setShippingDistrict] = useState();
  const [billingCity, setBillingCity] = useState();
  // const [billingState, setBillingState] = useState();
  // const [billingDistrict, setBillingDistrict] = useState();
  const [billingPincode, setBillingPincode] = useState();
  const [alternateMobile, setAlternateMobile] = useState();
  let history = useNavigate();

  useEffect(() => {
    ProductService.getState()
      .then((storeState) => {
        setStoreState(storeState.data.data);
        setStoreStateShipping(storeState.data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, []);

  useEffect(() => {
    ProductService.getUserDetails()
      .then((data) => {
        setUserDetails(data);
        setBillingAddress(data.data.data.billing_address);
        setBillingLandmark(data.data.data.landmark);
        setBillingLocality(data.data.data.locality);
        setBillingCity(data.data.data.city);
        setState(data.data.data.state);
        setDistrict(data.data.data.district);
        setBillingPincode(data.data.data.pincode);
        setShippingAddress(data.data.data.shipping_address);
        setShippingLandmark(data.data.data.shipping_landmark);
        setShippingPincode(data.data.data.shipping_pincode);
        setShippingLocality(data.data.data.shipping_locality);
        setShippingCity(data.data.data.shipping_city);
        setShippingState(data.data.data.shipping_state);
        setShippingDistrict(data.data.data.shipping_district);
        setAlternateMobile(data.data.data.alternate_number);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, []);

  useEffect(() => {
    storeState &&
      storeState.map((value) => {
        if (state === value?.state) {
          ProductService.getDistrict(value?._id).then((storeDistrict) => {
            setStoreDistrict(storeDistrict.data.data);
          });
        }
      });
    storeStateShipping &&
      storeStateShipping.map((value) => {
        if (shippingState === value?.state) {
          ProductService.getDistrict(value?._id).then((storeDistrict) => {
            setStoreDistrictShipping(storeDistrict.data.data);
          });
        }
      });
  }, [id, storeState, storeStateShipping]);

  const changePincodeHandler = (e) => {
    if (e.target.value.length <= 6) {
      setBillingPincode(e.target.value);
    }
  };
  const changePincodeShippingHandler = (e) => {
    if (e.target.value.length <= 6) {
      setShippingPincode(e.target.value);
    }
  };

  //handlechange to edit values
  const HandleChange = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (e.target.name === "name") {
      setName(e.target.value);
    }
    if (e.target.name === "lname") {
      setlName(e.target.value);
    }
    if (e.target.name === "landmark") {
      setBillingLandmark(e.target.value);
    }
    if (e.target.name === "alternameMobile") {
      if (e.target.value.length <= 10) {
        setAlternateMobile(e.target.value);
      }
    }

    if (!mobile_no) {
      if (e.target.name === "mobile") {
        if (e.target.value.length <= 10) {
          setMobile(e.target.value);
        }
      }
    }

    if (e.target.name === "billing_address") {
      setBillingAddress(e.target.value);
    }
    if (e.target.name === "locality") {
      setBillingLocality(e.target.value);
    }
    if (e.target.name === "city") {
      setBillingCity(e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
    if (e.target.name === "c_password") {
      setC_password(e.target.value);
    }
    if (e.target.name === "new_password") {
      setNew_password(e.target.value);
    }
    if (e.target.name === "confirm_password") {
      setConfirm_password(e.target.value);
    }
    if (e.target.name === "shipping_address") {
      setShippingAddress(e.target.value);
    }
    if (e.target.name === "shipping_locality") {
      setShippingLocality(e.target.value);
    }
    if (e.target.name === "shipping_city") {
      setShippingCity(e.target.value);
    }
    if (e.target.name === "shipping_landmark") {
      setShippingLandmark(e.target.value);
    }
    if (e.target.name === "shipping_pincode") {
      setShippingPincode(e.target.value);
    }
  };

  const HandleChangeclick = () => {
    setCheck(!check);
  };

  const editDetails = () => {
    if (new_password !== confirm_password) {
      dispatch(alertActions.error("Both password must match"));
    } else {
      dispatch(
        myDetails(
          name,
          lname,
          email,
          billingAddress,
          password,
          c_password,
          confirm_password,
          new_password,
          user_id,
          mobile,
          billingLocality,
          billingCity,
          state,
          district,
          billingPincode,
          billingLandmark,
          shippingAddress,
          shippingLocality,
          shippingCity,
          shippingLandmark,
          shippingPincode,
          shippingState,
          shippingDistrict,
          alternateMobile
        )
      )
        .then((value) => {
          if (value.data.status.code === 0) {
            //dispatch(alertActions.success(value.data.status.message));
            history("/");
          } else {
            dispatch(alertActions.error(value.data.status.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  function changeStateHandler(e) {
    setState(e.target.value);
    storeState.map((value) => {
      if (e.target.value == value.state) {
        setId(value._id);
      }
    });
  }

  function changeDistrictHandlerShipping(e) {
    setShippingDistrict(e.target.value);
  }

  function changeStateHandlerShipping(e) {
    setShippingState(e.target.value);
    storeStateShipping.map((value) => {
      if (e.target.value == value.state) {
        setId(value._id);
      }
    });
  }

  function changeDistrictHandler(e) {
    setDistrict(e.target.value);
  }
  return {
    prof1,
    prof2,
    prof3,
    prof4,
    HandleChange,
    name,
    check,
    email,
    address,
    mobile,
    password,
    c_password,
    locality,
    editDetails,
    HandleChangeclick,
    submitted,
    lname,
    valid,
    active,
    city,
    storeState,
    storeStateShipping,
    state,
    storeDistrict,
    storeDistrictShipping,
    district,
    landmark,
    pincode,
    billingAddress,
    billingCity,
    // billingDistrict,
    billingLandmark,
    billingLocality,
    shippingAddress,
    shippingLandmark,
    shippingPincode,
    shippingLocality,
    shippingCity,
    billingPincode,
    shippingDistrict,
    //billingState,
    useDetails,
    shippingState,
    alternateMobile,
    changeStateHandler,
    changeDistrictHandler,
    changeDistrictHandlerShipping,
    changePincodeHandler,
    changePincodeShippingHandler,
    changeStateHandlerShipping,
  };
};
export default useMyDetails;
