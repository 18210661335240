import * as axios from "../network/Network";

const config = require("../network/api.json");

class ContactService {
  static add(add) {
    var bodyFormData = new FormData();
    bodyFormData.append("name", add.name);
    bodyFormData.append("email", add.email);
    bodyFormData.append("number", add.mobile_no);
    bodyFormData.append("message", add.reason);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.contact,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }
  static getBranch() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.home,
    });
    return Promise.resolve(result);
  }

  static getAllStates(state) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.stateget + `?search=${state}`,
    });
    return Promise.resolve(result);
  }

  static getBranches(state) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getBranches + `?search=${state}`,
    });
    return Promise.resolve(result);
  }
}
export { ContactService };
