import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { UPDATE_ALL_FILTER } from "../../../actions/types";
import { FaqService, ProductService } from "../../../services";

const Search = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchResponse, setSearchResponse] = useState();
  const onhandleChange = (e) => {
    setSearch(e.target.value.trimStart());
  };
  const submitSearch = () => {
    if (search !== "") {
      // localStorage.setItem("search", null);
      // localStorage.setItem("sub", null);
      // localStorage.setItem("main", null);
      // localStorage.setItem("gender", null);
      // localStorage.setItem("min_amount", null);
      // localStorage.setItem("max_amount", null);
      // localStorage.setItem("gen", null);
      // localStorage.setItem("pagination", 1);
      // FaqService.storeSearchData("search")
      //   .then((response) => {
      //     console.log("hfdkjdhgh");
      //     setSearchResponse(response);
      //   })
      //   .catch((error) => {
      //     console.log("Errorreg", error);
      //   });

      dispatch({
        type: UPDATE_ALL_FILTER,
        payload: {
          page_no: 1,
          search_key: search,
          main_cat: [],
          sub_cat: [],
          min_amount: 0,
          max_amount: 0,
          filter: [],
        },
      });
      navigate(`/product-list/${search.trim().replace(/ +/g, "-")}`);
      ProductService.storeSearchData(search)
        .then((response) => {
          setSearchResponse(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });

      document.body.classList.remove("modal-open");
      props.closeSearch();
    } else {
      dispatch(alertActions.error("Please type product name..!"));
    }
  };

  return (
    /* <!-- Search Modal --> */
    <div className="modalPop" id="searchModal">
      <div className="modal-dialog">
        <div className="modal-content">
          {/* <!-- Modal body --> */}
          <div className="modal-body">
            <span>
              <input
                type="text"
                placeholder="Search for items"
                name="serach"
                onChange={onhandleChange}
                value={search}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitSearch();
                  }
                }}
              />
            </span>
          </div>

          {/* <!-- Modal footer --> */}
          <div className="modal-footer">
            <button className="search_btn" onClick={submitSearch}>
              Search
            </button>
            <button className="close_btn" onClick={props.closeSearch}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Search;
