import { SAVE_PROMO_CODE_DATA } from "../actions/types";

export function PromocodeReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_PROMO_CODE_DATA:
      return {
        ...state,
        promocode: payload.promocode,
      };

    default:
      return state;
  }
}
