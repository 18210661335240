import google from "../../../assets/images/google-icon.png";
import home_banner2 from "../../../assets/images/home-banner2.jpg";
import { useEffect, useState } from "react";
import { alertActions } from "../../../actions/alert.actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registration, googleLogin } from "../../../actions/index";
import { useGoogleLogin } from "react-google-login";
import close_pop from "../../../assets/images/close-pop.png";
import crypto from "crypto";
import { UserService } from "../../../services";

const clientId =
  "415782717327-bchn8sfbgsca031mrsrq03utlbkdhiga.apps.googleusercontent.com";
const useRegistrationHooks = () => {
  let history = useNavigate();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const [name, setName] = useState("");
  const [mobile_no, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [re_password, setRePassword] = useState("");
  const [googleUserData, setGoogleUserData] = useState({});
  const [registered, setRegisterd] = useState(false);
  const [otp, setOtp] = useState("");
  const [registerError, setRegisterError] = useState("");

  const menuData = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [hashedData, setHashedData] = useState({});
  const [randomNumbersss, setRandomNumber] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [eventData, setEventData] = useState("");

  //Otp popup
  const [showOtp, setShowOtp] = useState(false);
  const openOtp = () => {
    setShowOtp(true);
  };
  const closeOtp = () => {
    setShowOtp(false);
  };

  const generateRandomNumber = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth() + 1; // Month is 0-indexed, so add 1
    // const date = currentDate.getDate();
    // const hours = currentDate.getHours();
    // const minutes = currentDate.getMinutes();
    // const seconds = currentDate.getSeconds();
    // const milliseconds = currentDate.getMilliseconds();

    // // Combine all parts into a single number
    // const randomNumber = parseInt(
    //   `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}`,
    //   10
    // );

    // return randomNumber;
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);
  }, []);

  useEffect(() => {
    if (registerData?.data?.status?.code === 0) {
      setTimeout(() => {
        history("/");
      }, 1500);
      setRegisterd(true);
      setSubmitted(false);
      //dispatch(alertActions.success(registerData?.data?.status?.message));
    } else {
      setRegisterd(false);
      setRegisterError(googleUserData?.data?.status?.message);
      //dispatch(alertActions.error(registerData?.data?.status?.message));
    }
  }, [registerData]);
  useEffect(() => {
    if (googleUserData?.data?.status?.code === 0) {
      setTimeout(() => {
        history("/");
      }, 1500);
      setSubmitted(false);
      setRegisterd(true);
      // dispatch(alertActions.success(googleUserData?.data?.status?.message));
    } else {
      setRegisterd(false);
      setRegisterError(googleUserData?.data?.status?.message);
      //dispatch(alertActions.error(googleUserData?.data?.status?.message));
    }
  }, [googleUserData]);

  useEffect(() => {
    // const hashObject = (obj) => {
    //   const hashedObj = {};
    //   for (const key in obj) {
    //     if (obj.hasOwnProperty(key)) {
    //       const value = obj[key];
    //       const hash = crypto
    //         .createHash("sha256")
    //         .update(String(value))
    //         .digest("hex");
    //       hashedObj[key] = hash;
    //     }
    //   }
    //   return hashedObj;
    // };
    // const hashedSingleObject = hashObject(menuData);
    // setHashedData(hashedSingleObject);

    const hashName = crypto.createHash("sha256").update(name).digest("hex");
    const hashEmail = crypto.createHash("sha256").update(email).digest("hex");
    const hashPhone = crypto
      .createHash("sha256")
      .update(mobile_no)
      .digest("hex");

    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashEmail],
      ph: [hashPhone],
      ln: [hashedData.last_name],
      fn: [hashName],
      ct: [hashedData.city, hashedData.shipping_city],
      st: [hashedData.state, hashedData.shipping_state],
      zp: [hashedData.pincode, hashedData.shipping_pincode],
    };
    setEventData({
      event_name: "CompleteRegistration",
      event_time: unixTimestamp,
      action_source: "website",
      event_source_url: currentUrl,
      event_id: randomNumbersss.toString(),
      user_data: user_data,
    });
  }, [
    menuData,
    ipAddress,
    userAgent,
    unixTimestamp,
    randomNumbersss,
    name,
    email,
    mobile_no,
  ]);

  useEffect(() => {
    if (updatedPixelData.length) {
      UserService.getUserData(updatedPixelData)
        .then((response) => {
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }, [updatedPixelData]);

  const onSuccess = (res) => {
    dispatch(
      googleLogin(
        res.profileObj.givenName,
        res.profileObj.googleId,
        res.profileObj.email
      )
    )
      .then((response) => {
        localStorage.setItem("expiresIn", response?.data?.data?.expires_in);
        var today = new Date();

        localStorage.setItem("loginTime", today);
        setGoogleUserData(response);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };
  const onFailure = (res) => {
    if (res.error === "popup_closed_by_user") {
    }
  };
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
  });
  function changeOtpHandler(e) {
    setOtp(e.target.value);
  }
  function changeMobileNoHandler(e) {
    setRegisterError(undefined);
    setMobileNo(e.target.value);
  }
  function changeNameHandler(e) {
    setRegisterError(undefined);
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setName(e.target.value);
    } else if (!e.target.value) {
      setName("");
    }
  }

  function changeEmailHandler(e) {
    setRegisterError(undefined);
    setEmail(e.target.value);
  }
  function changePasswordHandler(e) {
    setRegisterError(undefined);
    setPassword(e.target.value);
  }
  function changeRePasswordHandler(e) {
    setRegisterError(undefined);
    setRePassword(e.target.value);
  }
  function handleOtpSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (name && mobile_no && email && password) {
      if (email && !pattern.test(email)) {
        //dispatch(alertActions.error("Enter valid Email Address"));
        setRegisterError("Enter valid Email Address");
      } else if (mobile_no && !filter.test(mobile_no)) {
        setRegisterError("Enter valid  Mobile Number");
        //dispatch(alertActions.error("Enter valid Mobile Number"));
      } else if (password !== re_password) {
        setRegisterError("Both Passwords must match");
        //dispatch(alertActions.error("Both Passwords must match"));
      } else {
        UserService.verifyEmailMobile(mobile_no, email)
          .then((response) => {
            if (response?.data?.status?.code === 0) {
              UserService.userOtp(mobile_no, email)
                .then((response) => {
                  if (response?.data?.status?.code === 0) {
                    setShowOtp(true);
                  } else {
                    setRegisterError(response?.data?.status?.message);
                    // dispatch(
                    //   alertActions.error(response?.data?.status?.message)
                    // );
                  }
                })
                .catch((error) => {
                  console.log("Errorreg", error);
                });
            } else {
              setRegisterError(response?.data?.status?.message);
              // dispatch(alertActions.error(response?.data?.status?.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  }
  function verifyOtp(e) {
    // console.log("otp", e);
    e.preventDefault();
    setSubmitted(true);
    if (otp) {
      UserService.verifyOtp(otp, mobile_no)
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            handleSubmit(e);
            closeOtp();
            const currentTimestamp = Math.floor(Date.now() / 1000);
            setUnixTimestamp(currentTimestamp);

            const newRandomNumber = generateRandomNumber();
            setRandomNumber(newRandomNumber);

            setTimeout(() => {
              if (ipAddress && userAgent && unixTimestamp) {
                setUpdatedPixelData((prevData) => [...prevData, eventData]);
              }
            }, 100);
            //dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setShowOtp(false);
            setOtp("");
            openOtp();
            //setRegisterError(response?.data?.status?.message);
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
          //   setLoading(false);
        });
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (name && mobile_no && email && password) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error("Enter Valid Email Address"));
        // setTimeout(() => dispatch(alertActions.clear()), 3000);
      } else if (mobile_no && !filter.test(mobile_no)) {
        dispatch(alertActions.error("Enter Valid Mobile Number"));
        // setTimeout(() => dispatch(alertActions.clear()), 3000);
      } else if (password !== re_password) {
        dispatch(alertActions.error("Both password must match"));
        // setTimeout(() => dispatch(alertActions.clear()), 3000);
      } else {
        dispatch(registration(name, mobile_no, email, password))
          .then((response) => {
            localStorage.setItem("expiresIn", response?.data?.data?.expires_in);
            var today = new Date();

            localStorage.setItem("loginTime", today);
            setRegisterData(response);
            //  window.location.reload();
          })
          .catch((error) => {
            console.log("Errorreg", error);
            //   setLoading(false);
          });
      }
    }
  }

  return {
    google,
    home_banner2,
    history,
    dispatch,
    name,
    email,
    mobile_no,
    password,
    re_password,
    changeNameHandler,
    changeEmailHandler,
    changeMobileNoHandler,
    changePasswordHandler,
    changeRePasswordHandler,
    registered,
    registerError,
    submitted,
    signIn,
    openOtp,
    closeOtp,
    close_pop,
    showOtp,
    handleOtpSubmit,
    changeOtpHandler,
    otp,
    verifyOtp,
    setOtp,
  };
};
export default useRegistrationHooks;
