import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../actions/alert.actions";
import message from "../../_common/Message/message";
import { useSelector } from "react-redux";
import "./Header.css";
import { HeaderService } from "../../../services/HeaderService";
import { ProductService } from "../../../services/ProductService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import crypto from "crypto";
import { UserService } from "../../../services";

const Videorequest = (props) => {
  const menuData = useSelector((state) => state.abData.category);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobileNo] = useState("");
  const [language, setLanguage] = useState("");
  const [time, setTime] = useState("");
  const [category, setCategory] = useState("");
  const [sub_category, setSubCategory] = useState("");
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("");
  const [purpose_video, setPurposeVideo] = useState("");
  const [startDate, setStartDate] = useState();

  const [data, setData] = useState();
  const [drop, setDrop] = useState();

  const [disableDates, setDisableDates] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const menuDataee = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [randomNumbersss, setRandomNumber] = useState("");
  const [hashedData, setHashedData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [eventData, setEventData] = useState("");

  useEffect(() => {
    ProductService.blockDate()
      .then((val) => {
        setDisableDates(val?.data?.data);
        excludesOff(val?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
    const currentTimestamp = Math.floor(Date.now() / 1000); // Getting Unix timestamp in seconds
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);
  }, []);

  const generateRandomNumber = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth() + 1; // Month is 0-indexed, so add 1
    // const date = currentDate.getDate();
    // const hours = currentDate.getHours();
    // const minutes = currentDate.getMinutes();
    // const seconds = currentDate.getSeconds();
    // const milliseconds = currentDate.getMilliseconds();

    // // Combine all parts into a single number
    // const randomNumber = parseInt(
    //   `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}`,
    //   10
    // );

    // return randomNumber;
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  useEffect(() => {
    if (data?.data?.status?.code === 0) {
      setSubmitted(false);
      props.closeVideoCall();
      dispatch(alertActions.success(data?.data?.status?.message));
    } else {
      dispatch(alertActions.error(data?.data?.status?.message));
    }
  }, [data]);

  function handleDatePicker(e) {
    setStartDate(e);
  }

  function changeNameHandler(e) {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setName(e.target.value);
    } else if (!e.target.value) {
      setName("");
    }
  }

  function changeEmailHandler(e) {
    setEmail(e.target.value);
  }

  function changeMobileNoHandler(e) {
    setMobileNo(e.target.value);
  }

  function changeLanguageHandler(e) {
    setLanguage(e.target.value);
  }

  function changeTimeHandler(e) {
    setTime(e.target.value);
  }

  function changeCategoryHandler(e) {
    var id = JSON.parse(e.target.value);
    var title = JSON.parse(e.target.value);
    ProductService.subcategoryGet(id._id).then((val) => {
      setDrop(val);
    });

    setCategory(title.title);

    console.error(id._id);
  }
  function changeSubCategoryHandler(e) {
    setSubCategory(e.target.value);
  }
  function changeCityHandler(e) {
    setCity(e.target.value);
  }
  function changePurposeHandler(e) {
    setPurposeVideo(e.target.value);
  }
  function changeMessageHandler(e) {
    setMessage(e.target.value);
  }
  function excludesOff(disableDates) {
    const disabledArray = disableDates.map((e) => {
      return new Date(Date.parse(e));
    });

    setDisableDates(disabledArray);
    return disabledArray;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    setSubmitted(true);

    if (
      name &&
      email &&
      mobile_number &&
      language &&
      startDate &&
      time &&
      category &&
      sub_category &&
      city &&
      purpose_video &&
      message
    ) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error("Enter Valid Email Address"));
      } else if (mobile_number && !filter.test(mobile_number)) {
        dispatch(alertActions.error("Enter Valid Mobile Number"));
      } else {
        const dateObject = new Date(startDate);
        const formattedDate = `${dateObject
          .getDate()
          .toString()
          .padStart(2, "0")}/${(dateObject.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${dateObject.getFullYear()}`;

        HeaderService.videoRequest(
          name,
          email,
          mobile_number,
          language,
          formattedDate,
          time,
          category,
          sub_category,
          city,
          purpose_video,
          message
        )
          .then((response) => {
            setData(response);
            const hashObject = (obj) => {
              const hashedObj = {};
              for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                  const value = obj[key];
                  const hash = crypto
                    .createHash("sha256")
                    .update(String(value))
                    .digest("hex");
                  hashedObj[key] = hash;
                }
              }
              return hashedObj;
            };

            const hashedSingleObject = hashObject(menuDataee);
            setHashedData(hashedSingleObject);

            function getCookie(name) {
              const cookieValue = document.cookie.match(
                "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
              );
              return cookieValue ? cookieValue.pop() : "";
            }

            // Fetch the values of fbp and fbc cookies
            const fbpValue = getCookie("_fbp");
            const fbcValue = getCookie("_fbc");

            var currentUrl = window.location.href;

            console.log(hashedSingleObject, "shdafudhsf");

            const user_data = {
              client_ip_address: ipAddress,
              client_user_agent: userAgent,
              fbp: fbpValue,
              fbc: fbcValue,
              em: [hashedSingleObject.email],
              ph: [
                hashedSingleObject.mobile_no,
                hashedSingleObject.alternate_number,
              ],
              ln: [hashedSingleObject.last_name],
              fn: [hashedSingleObject.first_name],
              ct: [hashedSingleObject.city, hashedSingleObject.shipping_city],
              st: [hashedSingleObject.state, hashedSingleObject.shipping_state],
              zp: [
                hashedSingleObject.pincode,
                hashedSingleObject.shipping_pincode,
              ],
            };

            if (ipAddress && userAgent) {
              const updatedPixelDataUpdater = [
                {
                  event_name: "Lead",
                  event_time: unixTimestamp,
                  action_source: "website",
                  event_source_url: currentUrl,
                  event_id: randomNumbersss.toString(),
                  user_data: user_data,
                },
              ];
              // const updatedPixelDataUpdater = eventData;

              UserService.getUserData(updatedPixelDataUpdater)
                .then((response) => {
                  //setGetOtpData(response);
                })
                .catch((error) => {
                  console.log("Errorreg", error);
                });
            }

            //  window.location.reload();
          })
          .catch((error) => {
            console.log("Errorreg", error);
            //   setLoading(false);
          });
      }
    }
  };
  return (
    <div className="modalPop video_pop" id="videoModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="row">
            <div className="col-sm-12">
              <h4 className="modal-title">
                <span>Request for a video Call</span>
                <br />
                Hop On A Video Call & See Your Fave Designs Live
              </h4>
            </div>
          </div>
          <button
            type="button"
            className="close"
            onClick={props.closeVideoCall}
          >
            &times;
          </button>
          <form className="modal-body" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group flt">
                  <input
                    type="text"
                    name="name"
                    value={name}
                    placeholder="Name"
                    onChange={changeNameHandler}
                    className={submitted && !name ? " is-invalid" : ""}
                  />
                  {submitted && !name && (
                    <div className="invalid-feedback">Name is required</div>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group flt">
                  <input
                    type="text"
                    name="email"
                    value={email.trim()}
                    placeholder="Email"
                    onChange={changeEmailHandler}
                    className={submitted && !email.trim() ? " is-invalid" : ""}
                  />
                  {submitted && !email && (
                    <div className="invalid-feedback">Email is required</div>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group flt">
                  <input
                    type="number"
                    name="mobile_number"
                    value={mobile_number}
                    placeholder="Mobile Number"
                    onChange={changeMobileNoHandler}
                    className={submitted && !mobile_number ? " is-invalid" : ""}
                  />

                  {submitted && !mobile_number && (
                    <div className="invalid-feedback">
                      Mobile number is required
                    </div>
                  )}
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group flt">
                  <DatePicker
                    name="date"
                    selected={startDate}
                    onChange={handleDatePicker}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select Date"
                    minDate={new Date()}
                    autoComplete="off"
                    excludeDates={disableDates}
                  />
                  {submitted && !startDate && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Date is required
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group flt">
                  <select
                    name="language"
                    value={language}
                    onChange={changeLanguageHandler}
                    className={
                      submitted && !language.trim() ? " is-invalid" : ""
                    }
                  >
                    <option>Select Language</option>
                    <option>Kannada</option>
                    <option>Hindi</option>
                    <option>English</option>
                  </select>
                  {submitted && !language && (
                    <div className="invalid-feedback">Language is required</div>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group flt">
                  <select
                    name="time"
                    value={time}
                    onChange={changeTimeHandler}
                    className={submitted && !time ? " is-invalid" : ""}
                  >
                    <option>Select Time</option>
                    <option value="11:00 AM">11:00 AM</option>
                    <option value="12:00 PM">12:00 PM</option>
                    <option value="01:00 PM">01:00 PM</option>
                    <option value="02:00 PM">02:00 PM</option>
                    <option value="03:00 PM">03:00 PM</option>
                    <option value="04:00 PM">04:00 PM</option>
                    <option value="05:00 PM">05:00 PM</option>
                    <option value="06:00 PM">06:00 PM</option>
                  </select>
                  {submitted && !time && (
                    <div className="invalid-feedback">Time is required</div>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group flt">
                  <select
                    name="category"
                    onChange={changeCategoryHandler}
                    className={
                      submitted && !category.trim() ? " is-invalid" : ""
                    }
                  >
                    <option>Choose Category</option>
                    {menuData
                      ? menuData.map((main_cat) => (
                          <option
                            value={JSON.stringify(main_cat)}
                            key={main_cat._id}
                          >
                            {main_cat.title}
                          </option>
                        ))
                      : null}
                  </select>
                  {submitted && !category && (
                    <div className="invalid-feedback">Category is required</div>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group flt">
                  <select
                    name="sub_category"
                    value={sub_category}
                    onChange={changeSubCategoryHandler}
                    className={
                      submitted && !sub_category.trim() ? " is-invalid" : ""
                    }
                  >
                    {/* <option>Sub Category</option> */}
                    <option>Choose Sub-Category</option>

                    {drop &&
                    drop.data &&
                    drop.data.data &&
                    drop.data.data.sub_category
                      ? drop.data.data.sub_category.map((value) => (
                          <option>{value.title}</option>
                        ))
                      : ""}
                  </select>
                  {submitted && !sub_category && (
                    <div className="invalid-feedback">
                      Sub category is required
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group flt">
                  <input
                    type="text"
                    name="city"
                    value={city.trim()}
                    placeholder="City/Town"
                    onChange={changeCityHandler}
                    className={submitted && !city.trim() ? " is-invalid" : ""}
                  />
                  {submitted && !city && (
                    <div className="invalid-feedback">City is required</div>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group flt">
                  <select
                    name="purpose_video"
                    value={purpose_video}
                    onChange={changePurposeHandler}
                    className={
                      submitted && !purpose_video.trim() ? " is-invalid" : ""
                    }
                  >
                    <option>Select Purpose</option>
                    <option value="viewing designs">Viewing Designs</option>
                    <option value="to redeem voucher or scheme">
                      To redeem voucher or scheme
                    </option>
                  </select>
                  {submitted && !purpose_video && (
                    <div className="invalid-feedback">Purpose is required</div>
                  )}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group flt">
                  <input
                    type="text"
                    name="message"
                    value={message}
                    placeholder="Message"
                    onChange={changeMessageHandler}
                    className={
                      submitted && !message.trim() ? " is-invalid" : ""
                    }
                  />
                  {submitted && !message && (
                    <div className="invalid-feedback">Message is required</div>
                  )}
                </div>
              </div>
            </div>
            <div className="video_pop_btn">
              <button>Request</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Videorequest;
