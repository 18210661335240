import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import "./Forgot password.css";
import useForgotPasswordHook from "./ForgotPasswordHook";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ForgotPassword = () => {
  const {
    home_banner2,
    mobile,
    submitted,
    forgotPasswordError,
    handleChange,
    handleSubmit,
  } = useForgotPasswordHook();
  const forgot = () => {
    return (
      <div class="login_container flt">
        <div class="login_box">
          <div class="login_banner flt">
            <LazyLoadImage src={home_banner2} />
          </div>
          <div class="login_cont flt">
            <div class="login_head flt">Forgot Password</div>
            <div class="form-group flt">
              <div class="login_ip flt">
                <input
                  type="number"
                  placeholder="Enter Mobile Number"
                  name="mobile"
                  value={mobile}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  className={submitted && !mobile ? " is-invalid" : ""}
                  onWheel={(e) => e.currentTarget.blur()}
                />
                <span>+91</span>
                {submitted && !mobile && (
                  <div className="invalid-feedback">
                    Mobile number is required
                  </div>
                )}
              </div>
              <div class="forgot-password-error">{forgotPasswordError}</div>
            </div>
            <button class="login_btn flt" onClick={handleSubmit}>
              Get OTP
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header />
      {forgot()}
      <Footer />
    </div>
  );
};
export default ForgotPassword;
