import * as axios from "../network/Network";

const config = require("../network/api.json");

class PrivacyService {
  static getPrivacy() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.privacy_policy,
    });
    return Promise.resolve(result);
  }
  static getCertifiedJewellary() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.certified_jewellary,
    });
    return Promise.resolve(result);
  }
  //feedback question service
  static getFeedBackQuestion() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.feedbackQuestion,
    });
    return Promise.resolve(result);
  }
  //update feedback service
  static updateFeedback(fq1, fq2, fq3, fq4, fq5, orderId) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.createfeedback,
      data: { fq1, fq2, fq3, fq4, fq5, orderId },
    });
    return Promise.resolve(result);
  }
}
export { PrivacyService };
