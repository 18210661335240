import sadananda from "../../../assets/images/burde-sadananda-kamath.jpg";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import { Helmet } from "react-helmet";
import useCountHook from "../../../hooks/useCountHook";
import { LazyLoadImage } from "react-lazy-load-image-component";

var aboutData = {
  info: `About Abharan Jewellers`,
};
const AboutUs = () => {
  const { wishListCount, cartCount } = useCountHook();
  const about = () => {
    document.title = `About Us | Abharan Jewellers`;
    return (
      <div class="about_container flt">
        <Helmet>
          <meta name="description" content={aboutData?.info} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>

        <div class="about_head flt">
          <h1 class="about_head flt">About Us</h1>
        </div>
        <div class="about_tag flt">
          Founder : Late Sri Burde Sadananda Kamath
        </div>
        <div class="about_cont flt">
          <span>
            <LazyLoadImage src={sadananda} alt="image" />
            It all began in 1930. Late Sri Burde Sadananda Kamath, a general
            merchant, in his travels to Malnad, discovered the exquisite nature
            of jewellery made in Udupi. Soon he began purchasing jewellery from
            the local craftsmen and selling them to the customers in other
            places. In 1935, Kamath opened "Neo Jewellery Mart", the first
            jewellery shop in Udupi.
            <br />
            <br />
            A man of principles, Kamath only sold pure, quality gold to
            customers. The Gold Control Act of 1962 restricted jewellers from
            selling 22 karat gold. They were allowed to sell only 14 karat gold.
            Utterly dejected Kamath closed his shop.
            <br />
            <br />
            Years later the government withdrew its restrictions. And in 1979
            Sri Madhukar S. Kamath, the founder's son, started a new gold
            showroom 'Abharan Jewellers' in Udupi. From then on, Abharan grew
            into a retail chain with 19 showrooms across 16 locations in
            Karnataka & in Goa under the skillful management of Sri Subhas M.
            Kamath and Sri Mahesh M. Kamath, sons of Sri Madhukar S. Kamath, and
            the guiding force of their uncle Sri Dayanand Kamath. Abharan also
            has separate silver showroom and boutique concept diamond showroom
            in Udupi.
            <br />
            <br />
            Abharan is an ISO 9001: 2008 certified jeweller selling 'Hallmark'
            jewellery. In fact, Abharan was the first to introduce the
            Karatometer in the country. Abharan has an online purchase window
            enabling customers to choose and purchase jewellery through the
            internet. With uncompromising quality and customer satisfaction
            measures Abharan has won customers the world over.
          </span>
        </div>
      </div>
    );
  };
  const renderHeader = () => {
    return <Header count={wishListCount} cart={cartCount} />;
  };
  const renderFooter = () => {
    return <Footer />;
  };
  return (
    <div>
      {renderHeader()}
      {about()}
      {renderFooter()}
    </div>
  );
};
export default AboutUs;
