import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import thankyou from "../../../assets/images/thankyou.gif";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useThankYouHook from "./useThankYouHook";
import { userLogout } from "../../../actions/UserAction";
import { alertActions } from "../../../actions";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ThankYou = (tokenExpired) => {
  const { cart, order_id } = useThankYouHook();
  let history = useNavigate();
  const prodPage = () => {
    history("/");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("You are logged out. Please Login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);
  const email = useSelector((state) => state?.userData?.user?.email);
  return (
    <div>
      <Header count={cart} />
      <div class="thankyou_container flt">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div class="row">
          <div class="col-sm-12">
            <div class="thankyou_box flt">
              <div class="thankyou_image flt">
                <LazyLoadImage src={thankyou} alt="image" />
              </div>
              <div class="thankyou_desk flt">
                Thank you for placing the order your order ID is {order_id}.
                <br /> Your order has been confirmed, check the order details in
                "order details" section of "My account"
                <br /> A confirmation email has been sent to your mail ID.
                <br /> {email}
              </div>
              <div class="emptycart_btn flt" onClick={() => prodPage()}>
                <button>Continue Shopping</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ThankYou;
