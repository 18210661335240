import useRegistrationHooks from "./useRegistrationHooks";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import "./Registration.css";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Registration = () => {
  const {
    google,
    home_banner2,
    history,
    dispatch,
    name,
    email,
    mobile_no,
    password,
    re_password,
    registered,
    registerError,
    changeNameHandler,
    changeEmailHandler,
    changeMobileNoHandler,
    changePasswordHandler,
    changeRePasswordHandler,
    submitted,
    signIn,
    openOtp,
    closeOtp,
    close_pop,
    showOtp,
    handleOtpSubmit,
    changeOtpHandler,
    otp,
    verifyOtp,
    setOtp,
  } = useRegistrationHooks();

  var regDescription = {
    info: ` Registration or Sign Up on Abharan official site`,
  };
  document.title = `Abharan Jewellers | Registration/Sign Up`;
  const RegistrationMain = () => {
    return (
      <div class="login_container flt">
        <Helmet>
          <meta name="description" content={regDescription?.info} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div class="login_box">
          <div class="login_banner flt">
            <LazyLoadImage src={home_banner2} />
          </div>
          <div class="login_cont flt">
            <div class="login_head flt">
              <h1 class="login_head flt">
                Registration <span>or</span> Sign Up
              </h1>
            </div>
            <div class="form-group flt">
              <input
                type="text"
                placeholder="Enter Full Name"
                name="name"
                value={name}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleOtpSubmit(e);
                  }
                }}
                onChange={changeNameHandler}
                className={submitted && !name ? " is-invalid" : ""}
              />
              {submitted && !name && (
                <div className="invalid-feedback">Name is required</div>
              )}
            </div>
            <div class="form-group flt">
              <input
                type="text"
                placeholder="Enter Email ID"
                name="email"
                value={email.trim()}
                onChange={changeEmailHandler}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleOtpSubmit(e);
                  }
                }}
                className={submitted && !email ? " is-invalid" : ""}
              />
              {submitted && !email && (
                <div className="invalid-feedback">Email is required</div>
              )}
            </div>
            <div class="form-group flt">
              <div class="login_ip flt">
                <input
                  type="number"
                  placeholder="Enter Mobile Number"
                  name="mobile_no"
                  value={mobile_no}
                  onChange={changeMobileNoHandler}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleOtpSubmit(e);
                    }
                  }}
                  className={submitted && !mobile_no ? " is-invalid" : ""}
                  onWheel={(e) => e.currentTarget.blur()}
                />

                <span>+91</span>
              </div>
              {submitted && !mobile_no && (
                <div className="invalid-feedback invalid-mob">
                  Mobile number is required
                </div>
              )}
            </div>
            <div class="form-group flt">
              <input
                type="password"
                placeholder="Enter Password"
                name="password"
                value={password}
                onChange={changePasswordHandler}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleOtpSubmit(e);
                  }
                }}
                className={submitted && !password ? " is-invalid" : ""}
              />

              {submitted && !password && (
                <div className="invalid-feedback">Password is required</div>
              )}
            </div>
            <div class="form-group flt">
              <input
                type="password"
                placeholder="Confirm Password"
                name="re_password"
                value={re_password}
                onChange={changeRePasswordHandler}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleOtpSubmit(e);
                  }
                }}
                className={submitted && !re_password ? " is-invalid" : ""}
              />
              {!re_password && (
                <div className="invalid-feedback">
                  Confirm password is required
                </div>
              )}

              {registerError && registered == false && (
                <div class="login-error">{registerError}</div>
              )}
            </div>

            {registered ? (
              <button class="login_btn flt" onClick={handleOtpSubmit}>
                Account Created Successfully!!
              </button>
            ) : (
              <button class="login_btn flt" onClick={handleOtpSubmit}>
                Create an Account
              </button>
            )}

            <button class="google_login flt" onClick={signIn}>
              <LazyLoadImage src={google} />
              Or sign-in with google
            </button>
            <div class="signup_btn flt">
              Already have an account? Please <a href="/login">Login</a>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const OtpVerify = () => {
    return (
      <div class="modalPop" id="enquiryModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" onClick={closeOtp}>
                <LazyLoadImage src={close_pop} alt="image" />
              </button>
              <div class="enquiry_head flt">Enter One Time Password (OTP)</div>
              <div class="enquiry_cont flt">
                <div class="mode_box flt">
                  <div class="mode_tag flt">
                    OTP has been sent to your mobile
                    {`********${mobile_no % 100}`}
                  </div>
                  <div class="form-group flt">
                    <input
                      type="number"
                      placeholder="Enter OTP"
                      name="otp"
                      value={otp}
                      onChange={changeOtpHandler}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setOtp(e.target.value);
                          verifyOtp(e);
                        }
                      }}
                      className={submitted && !otp ? " is-invalid" : ""}
                    />
                    {submitted && !otp && (
                      <div className="invalid-feedback">OTP is required</div>
                    )}
                  </div>
                </div>
                <div class="mode_btn flt">
                  <button onClick={verifyOtp}>Verify</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header />
      {RegistrationMain()}
      {showOtp && OtpVerify()}
      <Footer />
    </div>
  );
};
export default Registration;
