import { useState, useRef } from "react";
import "./Faq.css";
const FaqAccordion = (props) => {
    const [activeState, setActiveState] = useState("");
    const [heightState, setHeightState] = useState("0px");
    const content = useRef(null);
    function toggleAccordion() {
        setActiveState(activeState === "" ? "active" : "");
        setHeightState(
            activeState === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
    }
    return (
        <div class="faq_box flt">
            <div class="faq_quest flt">
                <a className={`accordion ${activeState}`} onClick={toggleAccordion}>
                    {props.title}
                </a>
            </div>
            <div class="faq_answer flt" ref={content}
                style={{ maxHeight: `${heightState}` }}>
                <p dangerouslySetInnerHTML={{ __html: props.content }} />
            </div>
        </div>

    )
};
export default FaqAccordion;
