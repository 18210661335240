import UseOrderHooks from "./useOrderHooks";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import ProfileMenu from "../../_common/ProfileMenu/ProfileMenu";
import track from "../../../assets/images/track-order.png";
import { commaValidation } from "../../../utility";
import "./OrderDetails.css";
import upload_icon from "../../../assets/images/upload-icon.png";
import re_upload_icon from "../../../assets/images/reUpload.png";
import { Link, useNavigate } from "react-router-dom";
import { alertActions, userLogout } from "../../../actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import dateFormat, { masks } from "dateformat";
import cancel from "../../../assets/images/cancelorder.png";
import StarRatings from "react-star-ratings";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useCountHook from "../../../hooks/useCountHook";

const OrderDetails = (tokenExpired, { sendDataToOrderDetails }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    invoice,
    problem,
    return_order,
    order,
    sample,
    showPop,
    openEnquiry,
    openUpload,
    close_pop,
    showEnquiry,
    showTrack,
    showUpload,
    closeEnquiry,
    closeUpload,
    cancelOrder,
    ongoingOrder2,
    name,
    mobile,
    sku,
    product,
    order_id,
    message,
    cancelledOrders,
    changeHandler,
    submitProblem,
    submitted,
    empty_order,
    downloadInvoice,
    downloadInvoiceCopy,
    display,
    ongoingOrder,
    ongoingOrder1,
    displayProduct,
    orderP,
    onSelectFile,
    image,
    selectedFile,
    fileName,
    handleClose,
    showButton,
    changePancardHandler,
    pan_no,
    handleUpload,
    trackMyOrder,
    doccur_num,
    closeTrackOrder,
    orderid,
    statusData,
    newDataStatus,
    estimatedate,
    showCancel,
    showReturnOrder,
    cancelTrackOrder,
    cancelTracks,
    returnOrder,
    trackReturnOrder,
    cancelOrderTrack,
    returnedOrders,
    ongoingOrder3,
  } = UseOrderHooks();
  const { wishListCount, cartCount } = useCountHook();

  const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("You are logged out. Please Login"));
      }, 50);

      dispatch(userLogout());
      navigate("/login");
    }
  }, [tokenExpired.tokenExpired]);
  const renderSendEnquiry = (closeEnquiry) => {
    return (
      <div class="modalPop" id="enquiryModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" onClick={closeEnquiry}>
                <LazyLoadImage src={close_pop} alt="image" />
              </button>
              <div class="enquiry_head flt">Report Problem</div>
              <div class="enquiry_cont flt">
                <div class="form-group flt">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={name}
                  />
                </div>
                <div class="form-group flt">
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    name="mobile"
                    value={mobile}
                  />
                </div>
                <div class="form-group flt">
                  <input type="text" placeholder="SKU" name="sku" value={sku} />
                </div>
                <div class="form-group flt">
                  <input
                    type="text"
                    placeholder="Order Id"
                    name="order"
                    value={product}
                  />
                </div>
                <div class="form-group flt">
                  <input
                    type="text"
                    placeholder="Product name"
                    name="order_id"
                    value={order_id}
                  />
                </div>
                <div class="form-group flt">
                  <input
                    type="text"
                    placeholder="Comments"
                    name="message"
                    defaultValue={message}
                    onBlur={changeHandler}
                    className={
                      submitted && !message ? " is-invalid" : "login_lbl"
                    }
                  />
                </div>
                {submitted && !message && (
                  <div className="invalid-feedback">password is required</div>
                )}
                <div class="mode_btn flt">
                  <button onClick={() => submitProblem()}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TrackOrderDetails = () => {
    return (
      <div className="modalPop" id="trackModal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <button className="close" onClick={closeTrackOrder}>
              <LazyLoadImage src={close_pop} alt="" />
            </button>
            <div className="modal-body">
              <ul className="track_intro">
                <li>
                  Logistic Company :{" "}
                  <span>
                    {newDataStatus.data?.service
                      ? newDataStatus.data?.service
                      : "SQL Logistics"}
                  </span>
                </li>
                <li>
                  Estimated Delivery Date : <span>{estimatedate}</span>
                </li>
                {doccur_num && (
                  <li>
                    Tracking ID : <span>{doccur_num}</span>
                  </li>
                )}
                <li>
                  Order ID : <span>{orderid}</span>
                </li>
              </ul>
              <div className="track_details">
                <ul className="track_flow">
                  <li
                    className={
                      statusData === 1 ||
                      statusData === 2 ||
                      statusData === 3 ||
                      statusData === 4 ||
                      statusData === 5
                        ? "active"
                        : ""
                    }
                  >
                    <span className="track_txt">
                      Order Placed{sendDataToOrderDetails}
                    </span>
                    <br />
                    <span className="track_icon"></span>
                  </li>
                  <li
                    className={
                      statusData === 2 ||
                      statusData === 3 ||
                      statusData === 4 ||
                      statusData === 5
                        ? "active"
                        : ""
                    }
                  >
                    <span className="track_txt">Dispatched</span>
                    <br />
                    <span className="track_icon"></span>
                  </li>
                  <li
                    className={
                      statusData === 3 || statusData === 4 || statusData === 5
                        ? "active"
                        : ""
                    }
                  >
                    <span className="track_txt">In Transit</span>
                    <br />
                    <span className="track_icon"></span>
                  </li>
                  <li
                    className={
                      statusData === 4 || statusData === 5 ? "active" : ""
                    }
                  >
                    <span className="track_txt">Out for Delivery</span>
                    <br />
                    <span className="track_icon"></span>
                  </li>
                  <li className={statusData === 5 ? "active" : ""}>
                    <span className="track_txt">Delivered</span>
                    <br />
                    <span className="track_icon"></span>
                  </li>
                </ul>
                {newDataStatus?.DocketTrackList
                  ? newDataStatus?.DocketTrackList.map((data) => (
                      <div className="track_cont">
                        <div className="track_box">
                          <div className="track_date">
                            {dateFormat(data.Date, "dS / mmmm ")}
                          </div>
                          <ul className="track_list">
                            <li>
                              Location : <span>{data?.City}</span>
                            </li>
                            <li>
                              Time : <span>{data?.Time}</span>
                            </li>
                            <li>
                              Status : <span>{data?.DocketStatus}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                  : newDataStatus?.data?.tracking
                  ? newDataStatus?.data?.tracking.map((data) => (
                      <div className="track_cont">
                        <div className="track_box">
                          <div className="track_date">
                            {dateFormat(data.date_time, "dS / mmmm ")}
                          </div>
                          <ul className="track_list">
                            <li>
                              Location : <span>{data?.location}</span>
                            </li>
                            <li>
                              Status : <span>{data?.description}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const uploadImage = (closeUpload) => {
    return (
      <div className="modalPop" id="enquiryModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={closeUpload}>
                <LazyLoadImage src={close_pop} alt="image" />
              </button>
              <div className="enquiry_head flt">ReUpload</div>
              <div className="enquiry_cont flt">
                <div className="col-sm-12">
                  <div className="form-group flt">
                    <label>Pan Card Number</label>
                    <input
                      type="text"
                      placeholder="Enter Pancard Number"
                      name="pan_no"
                      value={pan_no}
                      onChange={changePancardHandler}
                      className={submitted && !pan_no ? " is-invalid" : ""}
                    />
                    {submitted && !pan_no && (
                      <div className="invalid-feedback">
                        Pancard Number is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group flt">
                    <label>Upload document front page</label>
                    <div className="upload-btn-wrapper flt">
                      <button>
                        {!selectedFile && <LazyLoadImage src={upload_icon} />}

                        {selectedFile && (
                          <LazyLoadImage
                            src={image}
                            width="100%"
                            height="270"
                          />
                        )}
                      </button>

                      <input
                        type="file"
                        onChange={onSelectFile}
                        name="myfile"
                        key={fileName}
                        accept="image/*"
                        className={submitted && !image ? " is-invalid" : ""}
                      />
                      {submitted && !image && (
                        <div className="invalid-feedback">
                          Please Upload the Pancard Image
                        </div>
                      )}
                    </div>
                    {showButton && image && (
                      <div className="upload_file_box flt">
                        <div className="upload_file_name flt"></div>
                        <div className="upload_file_desc flt">
                          <span>Successfully uploaded</span>

                          <button
                            onClick={handleClose}
                            className="uploadfile_close"
                          >
                            <i
                              className="fa fa-times-circle"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mode_btn flt">
                  <button onClick={handleUpload}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const mainDetails = () => {
    return (
      <div className="col-sm-9">
        <div className="order_main order_return_main flt">
          {display == 1 && (
            <ul className="nav nav-pills" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="pill"
                  onClick={ongoingOrder1}
                >
                  Ongoing Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-toggle="pill"
                  onClick={ongoingOrder}
                >
                  Past Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-toggle="pill"
                  onClick={ongoingOrder2}
                >
                  Cancelled Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="pill"
                  onClick={ongoingOrder3}
                >
                  Returned Orders
                </a>
              </li>
            </ul>
          )}
          {display == 2 && (
            <ul className="nav nav-pills" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="pill"
                  onClick={ongoingOrder1}
                >
                  Ongoing Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="pill"
                  onClick={ongoingOrder}
                >
                  Past Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-toggle="pill"
                  onClick={ongoingOrder2}
                >
                  Cancelled Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="pill"
                  onClick={ongoingOrder3}
                >
                  Returned Orders
                </a>
              </li>
            </ul>
          )}
          {display == 3 && (
            <ul className="nav nav-pills" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="pill"
                  onClick={ongoingOrder1}
                >
                  Ongoing Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="pill"
                  onClick={ongoingOrder}
                >
                  Past Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="pill"
                  onClick={ongoingOrder2}
                >
                  Cancelled Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="pill"
                  onClick={ongoingOrder3}
                >
                  Returned Orders
                </a>
              </li>
            </ul>
          )}
          {display == 4 && (
            <ul className="nav nav-pills" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="pill"
                  onClick={ongoingOrder1}
                >
                  Ongoing Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="pill"
                  onClick={ongoingOrder}
                >
                  Past Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-toggle="pill"
                  onClick={ongoingOrder2}
                >
                  Cancelled Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="pill"
                  onClick={ongoingOrder3}
                >
                  Returned Orders
                </a>
              </li>
            </ul>
          )}
          <div className="tab-content">
            {display === 2 && (
              <div className="tab-pane active">
                {order &&
                order.orders &&
                order.orders.length &&
                order.orders.length >= 0 ? (
                  order.orders.map((order_list) => (
                    <div className="order_box flt">
                      <ul className="order_box_head flt">
                        <li>
                          ORDER PLACED: <span>{order_list?.created_on}</span>
                        </li>
                        <li>
                          SHIP TO: <span>{order_list?.user?.first_name}</span>
                        </li>
                        <li
                          className="total_pop_btn"
                          onClick={() => showPop(order_list?._id)}
                        >
                          TOTAL:
                          {order_list?.amount &&
                          order_list?.amount !== "undefined" ? (
                            <span>
                              ₹
                              {commaValidation(
                                (
                                  parseFloat(order_list?.amount) +
                                  parseFloat(order_list?.res_total)
                                ).toFixed(2)
                              )}
                            </span>
                          ) : (
                            <span>
                              ₹{commaValidation(order_list?.res_total)}
                            </span>
                          )}
                        </li>
                        <li>
                          ORDER ID: <span>{order_list?.order_no}</span>
                        </li>
                      </ul>
                      <div className="order_cont return_order_holder  flt">
                        <div className="row">
                          <div className="col-sm-8">
                            <div className="order_flex flt">
                              <div
                                className="order_image flt"
                                onClick={() =>
                                  displayProduct(order_list ? order_list : "")
                                }
                              >
                                {order_list?.pro_images?.[0]?.image ? (
                                  <LazyLoadImage
                                    src={
                                      image_url +
                                      order_list?.pro_images?.[0]?.image
                                    }
                                  />
                                ) : (
                                  <LazyLoadImage
                                    src={sample}
                                    className="example"
                                  />
                                )}
                              </div>
                              <ul className="order_desc flt">
                                <li
                                  className="order_name"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  {order_list?.product.name}
                                </li>
                                <li
                                  className="order_code"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  Item Code : {order_list?.product?.SKU}
                                </li>
                                <li
                                  className="order_id"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  HUID :{" "}
                                  {order_list?.HUID &&
                                  order_list?.HUID !== "undefined"
                                    ? order_list?.HUID
                                    : order_list?.product?.pro_details?.HUID}
                                </li>
                                <li
                                  className="order_id"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  Net Weight :{" "}
                                  {order_list?.extra_weight &&
                                  order_list?.extra_weight !== "undefined"
                                    ? order_list?.final_net_weight
                                    : order_list?.product?.net_weight}{" "}
                                  GM
                                </li>
                                <li
                                  className="order_quantity"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  Order delivered on:{" "}
                                  <span>
                                    {dateFormat(
                                      order_list?.updated_at,
                                      "dS-mmmm-yyyy"
                                    )}
                                  </span>
                                </li>
                                <li>
                                  {order_list?.feedback?.average_rating ? (
                                    <StarRatings
                                      rating={
                                        order_list?.feedback?.average_rating
                                      }
                                      starRatedColor="gold"
                                      numberOfStars={5}
                                      name="product-rating"
                                      starDimension="25px"
                                      starSpacing="3px"
                                    />
                                  ) : (
                                    <Link
                                      to={`/feedback/${order_list?.order_no}`}
                                      style={{ textDecoration: "underline" }}
                                    >
                                      Product Ratings
                                    </Link>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="order_btn flt">
                              <button
                                className="download_invoice"
                                onClick={() => downloadInvoice(order_list?._id)}
                              >
                                <LazyLoadImage src={invoice} /> Download Order
                                Copy
                              </button>
                              {order_list?.final_invoice &&
                                order_list?.final_invoice != "undefined" && (
                                  <button
                                    className="download_invoice"
                                    onClick={() =>
                                      downloadInvoiceCopy(
                                        order_list?.final_invoice
                                      )
                                    }
                                  >
                                    <LazyLoadImage src={invoice} /> Download
                                    Invoice
                                  </button>
                                )}

                              <button
                                className="report_problem"
                                git
                                onClick={() => openEnquiry(order_list)}
                              >
                                <LazyLoadImage src={problem} />
                                Report Problem
                              </button>
                              <button
                                className="download_invoice"
                                onClick={() => trackReturnOrder(order_list)}
                              >
                                <LazyLoadImage src={return_order} />
                                Return Order
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div class="empty_order flt">
                    <div class="empty_order_image flt">
                      <LazyLoadImage src={empty_order} alt="image" />
                    </div>
                    <div class="empty_order_head flt">No orders found</div>
                  </div>
                )}
              </div>
            )}
            {display === 1 && (
              <div className="tab-pane active">
                {orderP && orderP.length > 0 ? (
                  orderP.map((order_list) => (
                    <div className="order_box return_order_holder flt">
                      <ul className="order_box_head flt">
                        <li>
                          ORDER PLACED: <span>{order_list?.updated_at}</span>
                        </li>

                        <li>
                          SHIP TO: <span>{order_list.user?.first_name}</span>
                        </li>
                        <li>
                          TOTAL:
                          {order_list?.amount &&
                          order_list?.amount !== "undefined" ? (
                            <span>
                              ₹
                              {commaValidation(
                                (
                                  parseFloat(order_list?.amount) +
                                  parseFloat(order_list?.res_total)
                                ).toFixed(2)
                              )}
                            </span>
                          ) : (
                            <span>
                              ₹{commaValidation(order_list?.res_total)}
                            </span>
                          )}
                          {/* <span>
                            ₹{commaValidation(order_list?.res_total)}</span> */}
                        </li>
                        <li>
                          ORDER ID: <span>{order_list?.order_no}</span>
                        </li>
                      </ul>
                      <div className="order_cont flt">
                        <div className="row">
                          <div className="col-sm-8">
                            <div
                              className="order_flex flt"
                              onClick={() =>
                                displayProduct(order_list ? order_list : "")
                              }
                            >
                              <div className="order_image flt">
                                {order_list?.pro_images?.[0]?.image ? (
                                  <LazyLoadImage
                                    src={
                                      image_url +
                                      order_list?.pro_images?.[0]?.image
                                    }
                                  />
                                ) : (
                                  <LazyLoadImage
                                    src={sample}
                                    className="example"
                                  />
                                )}
                              </div>

                              <ul className="order_descss flt">
                                <li className="order_name">
                                  {order_list.product.name}
                                </li>
                                <li className="order_code">
                                  Item Code : {order_list?.product?.SKU}
                                </li>
                                <li className="order_id">
                                  HUID :{" "}
                                  {order_list?.HUID &&
                                  order_list?.HUID !== "undefined"
                                    ? order_list?.HUID
                                    : order_list?.product?.pro_details?.HUID}
                                </li>

                                <li className="order_id">
                                  Net Weight : {order_list?.product?.net_weight}{" "}
                                  GM{" "}
                                </li>
                                <li className="order_rate">
                                  <b>₹</b>
                                  {commaValidation(order_list?.res_total)}{" "}
                                </li>
                                {order_list?.payment_link === "undefined" ||
                                  (order_list?.payment_link === " " && (
                                    <li className="order_id">
                                      {" "}
                                      Payment Link :{" "}
                                      <a
                                        href={order_list.payment_link}
                                        target="_blank"
                                      >
                                        {order_list.payment_link}
                                      </a>
                                    </li>
                                  ))}
                              </ul>

                              {order_list?.HUID != "undefined" &&
                                order_list?.extra_weight != "undefined" && (
                                  <ul className="order_desc_trial flt">
                                    <li className="order_id">
                                      {order_list?.extra_weight && (
                                        <span className="order_id">
                                          <b>Extra Weight :</b>
                                          {parseFloat(
                                            order_list?.extra_weight
                                          ).toFixed(2)}{" "}
                                          GM
                                        </span>
                                      )}
                                    </li>

                                    <li
                                      title={
                                        order_list?.reason
                                          ? `${order_list.reason}`
                                          : null
                                      }
                                    >
                                      <a className="link-style">
                                        {order_list?.reason && (
                                          <span className="order_id">
                                            <b>Extra Weight Reason</b>
                                          </span>
                                        )}
                                      </a>
                                    </li>

                                    <li className="order_rate">
                                      {order_list?.amount && (
                                        <span className="order_rate">
                                          Extra Amount : <b>₹</b>
                                          {commaValidation(order_list?.amount)}
                                        </span>
                                      )}
                                    </li>
                                  </ul>
                                )}
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="order_btn flt">
                              <button
                                className="download_invoice"
                                onClick={() => downloadInvoice(order_list?._id)}
                              >
                                <LazyLoadImage src={invoice} /> Download Order
                                Copy
                              </button>
                              {order_list?.final_invoice &&
                                order_list?.final_invoice != "undefined" && (
                                  <button
                                    className="download_invoice"
                                    onClick={() =>
                                      downloadInvoiceCopy(
                                        order_list?.final_invoice
                                      )
                                    }
                                  >
                                    <LazyLoadImage src={invoice} /> Download
                                    Invoice
                                  </button>
                                )}

                              <button
                                className="track_order"
                                onClick={() => {
                                  trackMyOrder(order_list);
                                }}
                              >
                                <LazyLoadImage src={track} />
                                Track Order
                              </button>
                              <button
                                className="track_order mt-3"
                                onClick={() => {
                                  cancelTrackOrder(order_list);
                                }}
                              >
                                <LazyLoadImage src={cancel} />
                                Cancel Order
                              </button>
                              {order_list?.order_status === -1 && (
                                <button
                                  onClick={() =>
                                    openUpload(order_list?.payments?.order_id)
                                  }
                                  className="track_order upload_pan_btn"
                                >
                                  <LazyLoadImage src={re_upload_icon} />
                                  Re-Upload PAN
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div class="empty_order flt">
                    <div class="empty_order_image flt">
                      <LazyLoadImage src={empty_order} alt="image" />
                    </div>
                    <div class="empty_order_head flt">No orders found</div>
                  </div>
                )}
              </div>
            )}
            {display === 3 && (
              <div className="tab-pane active">
                {cancelledOrders &&
                cancelledOrders.orders &&
                cancelledOrders.orders.length &&
                cancelledOrders.orders.length >= 0 ? (
                  cancelledOrders.orders.map((order_list) => (
                    <div className="order_box flt">
                      <ul className="order_box_head flt">
                        <li>
                          ORDER PLACED: <span>{order_list?.created_on}</span>
                        </li>
                        <li>
                          SHIP TO: <span>{order_list?.user?.first_name}</span>
                        </li>
                        <li
                          className="total_pop_btn"
                          onClick={() => showPop(order_list?._id)}
                        >
                          TOTAL:
                          {order_list?.amount &&
                          order_list?.amount !== "undefined" ? (
                            <span>
                              ₹
                              {commaValidation(
                                (
                                  parseFloat(order_list?.amount) +
                                  parseFloat(order_list?.res_total)
                                ).toFixed(2)
                              )}
                            </span>
                          ) : (
                            <span>
                              ₹{commaValidation(order_list?.res_total)}
                            </span>
                          )}
                        </li>
                        <li>
                          ORDER ID: <span>{order_list?.order_no}</span>
                        </li>
                      </ul>
                      <div className="order_cont flt">
                        <div className="row">
                          <div className="col-sm-8">
                            <div className="order_flex flt">
                              <div
                                className="order_image flt"
                                onClick={() =>
                                  displayProduct(order_list ? order_list : "")
                                }
                              >
                                {order_list?.pro_images?.[0]?.image ? (
                                  <LazyLoadImage
                                    src={
                                      image_url +
                                      order_list?.pro_images?.[0]?.image
                                    }
                                  />
                                ) : (
                                  <LazyLoadImage
                                    src={sample}
                                    className="example"
                                  />
                                )}
                              </div>
                              <ul className="order_desc order_desc_cancel flt">
                                <li
                                  className="order_name"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  {order_list?.product.name}
                                </li>
                                <li
                                  className="order_code"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  Item Code : {order_list?.product?.SKU}
                                </li>
                                <li
                                  className="order_id"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  HUID :{" "}
                                  {order_list?.HUID &&
                                  order_list?.HUID !== "undefined"
                                    ? order_list?.HUID
                                    : order_list?.product?.pro_details?.HUID}
                                </li>
                                <li
                                  className="order_id"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  Net Weight :{" "}
                                  {order_list?.extra_weight &&
                                  order_list?.extra_weight !== "undefined"
                                    ? order_list?.final_net_weight
                                    : order_list?.product?.net_weight}{" "}
                                  GM
                                </li>
                                <li
                                  className="order_quantity"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  Order cancelled on:{" "}
                                  <span>
                                    {dateFormat(
                                      order_list?.updated_at,
                                      "dS-mmmm-yyyy"
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div class="empty_order flt">
                    <div class="empty_order_image flt">
                      <LazyLoadImage src={empty_order} alt="image" />
                    </div>
                    <div class="empty_order_head flt">No orders found</div>
                  </div>
                )}
              </div>
            )}
            {display === 4 && (
              <div className="tab-pane active">
                {returnedOrders &&
                returnedOrders.orders &&
                returnedOrders.orders.length &&
                returnedOrders.orders.length >= 0 ? (
                  returnedOrders.orders.map((order_list) => (
                    <div className="order_box flt">
                      <ul className="order_box_head flt">
                        <li>
                          ORDER PLACED: <span>{order_list?.created_on}</span>
                        </li>
                        <li>
                          SHIP TO: <span>{order_list?.user?.first_name}</span>
                        </li>
                        <li
                          className="total_pop_btn"
                          onClick={() => showPop(order_list?._id)}
                        >
                          TOTAL:
                          {order_list?.amount &&
                          order_list?.amount !== "undefined" ? (
                            <span>
                              ₹
                              {commaValidation(
                                (
                                  parseFloat(order_list?.amount) +
                                  parseFloat(order_list?.res_total)
                                ).toFixed(2)
                              )}
                            </span>
                          ) : (
                            <span>
                              ₹{commaValidation(order_list?.res_total)}
                            </span>
                          )}
                        </li>
                        <li>
                          ORDER ID: <span>{order_list?.order_no}</span>
                        </li>
                      </ul>
                      <div className="order_cont flt">
                        <div className="row">
                          <div className="col-sm-8">
                            <div className="order_flex flt">
                              <div
                                className="order_image flt"
                                onClick={() =>
                                  displayProduct(order_list ? order_list : "")
                                }
                              >
                                {order_list?.pro_images?.[0]?.image ? (
                                  <LazyLoadImage
                                    src={
                                      image_url +
                                      order_list?.pro_images?.[0]?.image
                                    }
                                  />
                                ) : (
                                  <LazyLoadImage
                                    src={sample}
                                    className="example"
                                  />
                                )}
                              </div>
                              <ul className="order_desc order_desc_cancel flt">
                                <li
                                  className="order_name"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  {order_list?.product.name}
                                </li>
                                <li
                                  className="order_code"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  Item Code : {order_list?.product?.SKU}
                                </li>
                                <li
                                  className="order_id"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  HUID :{" "}
                                  {order_list?.HUID &&
                                  order_list?.HUID !== "undefined"
                                    ? order_list?.HUID
                                    : order_list?.product?.pro_details?.HUID}
                                </li>
                                <li
                                  className="order_id"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  Net Weight :{" "}
                                  {order_list?.extra_weight &&
                                  order_list?.extra_weight !== "undefined"
                                    ? order_list?.final_net_weight
                                    : order_list?.product?.net_weight}{" "}
                                  GM
                                </li>
                                <li
                                  className="order_quantity"
                                  onClick={() =>
                                    displayProduct(order_list ? order_list : "")
                                  }
                                >
                                  Order returned on:{" "}
                                  <span>
                                    {dateFormat(
                                      order_list?.updated_at,
                                      "dS-mmmm-yyyy"
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div class="empty_order flt">
                    <div class="empty_order_image flt">
                      <LazyLoadImage src={empty_order} alt="image" />
                    </div>
                    <div class="empty_order_head flt">No orders found</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderProfileMenu = () => {
    return <ProfileMenu />;
  };
  const renderHeader = () => {
    return <Header count={wishListCount} cart={cartCount} />;
  };
  const renderFooter = () => {
    return <Footer />;
  };
  const cancelOrderDetails = () => {
    return (
      <div class="modalPop return_order_popup" id="enquiryModal">
        <div class="modal-dialog">
          <div class="modal-content cancelorder">
            <div class="modal-body">
              <div class="return_order_cont">
                <button type="button" class="close" onClick={cancelTracks}>
                  <LazyLoadImage src={close_pop} alt="image" />
                </button>
                <div class="enquiry_head">Cancel Order</div>
                <div className="cancel_show">
                  Are you sure you want to Cancel your Order?
                </div>
                <div class="button-cont">
                  <button className="cancel_return_btn" onClick={cancelOrder}>
                    Yes
                  </button>
                  &nbsp;
                  <button className="cancel_return_btn" onClick={cancelTracks}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReturnOrder = () => {
    return (
      <div class="modalPop return_order_popup" id="enquiryModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="return_order_cont">
                <button type="button" class="close" onClick={cancelOrderTrack}>
                  <LazyLoadImage src={close_pop} alt="image" />
                </button>
                <div class="enquiry_head">Return Order</div>
                <div className="cancel_show">
                  Are you sure you want to Return your order?
                </div>
                <div class="button-cont">
                  <button className="cancel_return_btn" onClick={returnOrder}>
                    Yes
                  </button>
                  &nbsp;
                  <button
                    className="cancel_return_btn"
                    onClick={cancelOrderTrack}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {renderHeader()}
      {showEnquiry && renderSendEnquiry(closeEnquiry)}
      {showUpload && uploadImage(closeUpload)}
      {showReturnOrder && renderReturnOrder()}
      <div className="profile_container flt">
        <div className="row">
          {renderProfileMenu()}
          {mainDetails()}
          {showTrack && TrackOrderDetails()}
          {showCancel && cancelOrderDetails()}
        </div>
      </div>
      {renderFooter()}
    </div>
  );
};
export default OrderDetails;
