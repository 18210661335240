import useProductDetails from "./useProductDetails";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import { Link } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";
import "./ProductDetails.css";
import decide_pic from "../../../assets/images/decide-pic.png";
import { commaValidation } from "../../../utility";
import { useEffect } from "react";
import Slider from "react-slick";
import loader from "../../../assets/images/loader.png";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import InnerImageZoom from "react-inner-image-zoom";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProductDetails = (props) => {
  const {
    singleproductList,
    image_url,
    detail,
    detail2,
    detail3,
    detail4,
    chart,
    selectedSizeValue,
    deliverydate,
    useState,
    LikelyImageData,
    cartPage,
    sample,
    openEnquiry,
    showEnquiry,
    showShipping,
    closeEnquiry,
    showCertified,
    close_pop,
    imgChange,
    openShipping,
    tollfree,
    closeShipping,
    openCertified,
    closeCertified,
    showBackchain,
    openBackchain,
    closeBackchain,
    policyData,
    checkPincode,
    backChainStatus,
    name,
    email,
    mobile,
    comments,
    ctype,
    cbutton,
    changeEmailHandler,
    changeNameHandler,
    changeMobileNoHandler,
    changeCbuttonHandler,
    changeCommentsHandler,
    changeCtypeHandler,
    submitted,
    pincode,
    handleSubmit,
    sub,
    formattedDate,
    certifiedData,
    selectedImageIndex,
    showLargeImage,
    changeLargeImage,
    identity1,
    identity2,
    identity3,
    identity4,
    goldchaindetails,
    laceshow,
    laces,
    addChainStatus,
    isChain,
    chainorlace,
    showProduct,
    openProduct,
    closeProduct,
    loading,
    sendItemPrice,
    gst,
    makingCharges,
    metalPrice,
    deliveryMessage,
    singleProductName,
    itemSizeDetails,
    diamondPrice,
    itemPrice,
    weightValue,
    netWeight,
    quantity,
    wishListCount,
    cartCount,
    outOfStock,
  } = useProductDetails(props);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const gross =
    singleproductList && singleproductList.c_total
      ? singleproductList.c_total
      : "0";
  const saved =
    singleproductList && singleproductList.c_saved
      ? singleproductList.c_saved
      : "0";
  const total = (parseFloat(gross) + parseFloat(saved)).toFixed(2);

  const [showDecide, setShowDecide] = useState(false);
  const delay = 300;

  useEffect(() => {
    let timer1 = setTimeout(() => setShowDecide(true), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const closeDecide = () => {
    setShowDecide(false);
  };
  var productSlider = {
    dots: true,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var prodDetailData = {
    info: `Shop ${singleProductName}. 100% Certified Hallmark | Safe Shopping Guaranteed | Free Shipping`,
  };

  const ProductMobile = () => {
    return (
      <div className="modalPop" id="productModal">
        <div className="modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={closeProduct}>
                <LazyLoadImage src={close_pop} alt="image" />
              </button>
              <div className="mobile_product_slected">
                {singleproductList &&
                  singleproductList.pro_images &&
                  singleproductList.pro_images[selectedImageIndex].image && (
                    <InnerImageZoom
                      src={
                        image_url +
                        singleproductList?.pro_images?.[selectedImageIndex]
                          ?.image
                      }
                      zoomSrc={
                        image_url +
                        singleproductList?.pro_images?.[selectedImageIndex]
                          ?.image
                      }
                    />
                  )}
                {singleproductList &&
                  singleproductList.pro_images &&
                  singleproductList.pro_images[selectedImageIndex].video && (
                    <video width="100%" height="100%" controls>
                      <source
                        src={
                          image_url +
                          singleproductList.pro_images[selectedImageIndex].video
                        }
                      />
                    </video>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const detailContainer = () => {
    return (
      <div className="flt">
        <Helmet>
          <meta name="robots" content="noindex" />
          <meta name="description" content={prodDetailData?.info} />
          <link rel="canonical" href={window?.location?.href} />
        </Helmet>
        <div className="row product_expl">
          <div className="col-sm-6">
            <div
              className={
                selectedImageIndex === -1
                  ? "detail_left flt"
                  : "detail_left detail_left_more flt"
              }
            >
              <div
                className={
                  singleproductList &&
                  singleproductList.wish_status &&
                  singleproductList.wish_status === 1
                    ? "heart heart_red"
                    : "heart"
                }
                onClick={() =>
                  imgChange(
                    singleproductList &&
                      singleproductList.pro_details &&
                      singleproductList.pro_details.product_id
                      ? singleproductList.pro_details.product_id
                      : ""
                  )
                }
              >
                <i className="fa fa-heart" aria-hidden="true"></i>
              </div>

              {(singleproductList && singleproductList.pro_image === null) ||
              selectedImageIndex === -1 ? (
                <LazyLoadImage src={sample} className="example" />
              ) : showLargeImage() ? (
                <div className="flt">
                  <div className="zoom_area flt mobile_hide">
                    {singleproductList &&
                      singleproductList.pro_images &&
                      singleproductList.pro_images[selectedImageIndex]
                        .image && (
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              isFluidWidth: true,
                              src:
                                image_url +
                                singleproductList?.pro_images?.[
                                  selectedImageIndex
                                ]?.image,
                            },
                            largeImage: {
                              src:
                                image_url +
                                singleproductList?.pro_images?.[
                                  selectedImageIndex
                                ]?.image,
                              width: 1500,
                              height: 1500,
                            },
                          }}
                        />
                      )}
                    {singleproductList &&
                      singleproductList.pro_images &&
                      singleproductList.pro_images[selectedImageIndex]
                        .video && (
                        <video width="100%" height="100%" controls>
                          <source
                            src={
                              image_url +
                              singleproductList.pro_images[selectedImageIndex]
                                .video
                            }
                          />
                        </video>
                      )}
                  </div>
                  <div className="product_mobile flt hide_desktop">
                    {selectedImageIndex >= 0 && (
                      <Slider {...productSlider} className="flt">
                        {singleproductList &&
                        singleproductList.pro_images !== null
                          ? singleproductList.pro_images.map(
                              (image_list, index) => (
                                <div
                                  className="product_mob_slide flt"
                                  key={image_list._id}
                                  onClick={() => openProduct(index)}
                                >
                                  {image_list.image && (
                                    <LazyLoadImage
                                      src={image_url + image_list.image}
                                      alt="image"
                                    />
                                  )}
                                  {image_list.video && (
                                    <video width="100%" height="100%">
                                      <source
                                        src={image_url + image_list.video}
                                      />
                                    </video>
                                  )}
                                </div>
                              )
                            )
                          : null}
                      </Slider>
                    )}
                  </div>
                </div>
              ) : null}
              {selectedImageIndex >= 0 && (
                <ul className="detail_more flt mobile_hide">
                  {singleproductList && singleproductList.pro_images !== null
                    ? singleproductList.pro_images.map((image_list, index) => (
                        <li
                          key={image_list._id}
                          onClick={() => changeLargeImage(index)}
                        >
                          {image_list.image && (
                            <LazyLoadImage
                              src={image_url + image_list.image}
                              alt="image"
                            />
                          )}
                          {image_list.video && (
                            <video width="100%" height="100%">
                              <source src={image_url + image_list.video} />
                            </video>
                          )}
                        </li>
                      ))
                    : null}
                </ul>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="detail_right flt">
              <div className="detail_name flt">
                {singleproductList && singleproductList.name
                  ? singleproductList.name
                  : ""}
              </div>
              <div className="detail_code flt">
                Item Code :{" "}
                {singleproductList && singleproductList?.pro_details?.parentSKU
                  ? singleproductList?.pro_details?.parentSKU
                  : ""}
              </div>

              <div className="detail_rate flt">
                {singleproductList && singleproductList.c_saved !== "" && (
                  <strike>
                    {" "}
                    ₹ {commaValidation(total)} <br />{" "}
                  </strike>
                )}
                <span>
                  <b> ₹ </b>
                  {commaValidation(itemPrice ? itemPrice : "0")}
                </span>{" "}
                Tax inclusive
              </div>
              {singleproductList && singleproductList.c_saved !== "" && (
                <div className="detail_save flt">
                  You will save{" "}
                  <b>
                    {" "}
                    <span className="rs_font"> ₹ </span>
                  </b>{" "}
                  {commaValidation(
                    singleproductList && singleproductList.c_saved
                      ? singleproductList.c_saved
                      : "0"
                  )}
                </div>
              )}
              {singleproductList &&
                singleproductList.pro_details &&
                singleproductList.pro_details.is_chain === 1 &&
                singleproductList.pro_details.include_backchain === "NO" && (
                  <div class="back_chain flt"></div>
                )}

              {singleproductList?.pro_details?.is_chain === 0 &&
                singleproductList?.pro_details?.lace_status === 2 &&
                singleproductList?.pro_details?.lock_type !== 0 &&
                singleproductList?.pro_details?.lock_type !== null &&
                singleproductList?.pro_details?.lock_type !== "" &&
                singleproductList?.pro_details?.lock_type !== "NULL" && (
                  <div class=" detail_code flt">
                    Lock Type : &nbsp;
                    {singleproductList?.pro_details?.is_chain === 0 &&
                      singleproductList?.pro_details?.lace_status === 2 &&
                      singleproductList?.pro_details?.lock_type}
                  </div>
                )}

              <div className="detail_code flt">
                Select{" "}
                <a href={chart} target="_blank">
                  Size
                </a>
                :{" "}
                <b>
                  {singleproductList?.size &&
                  singleproductList?.size &&
                  singleproductList?.size?.length > 0 ? (
                    <select onChange={sendItemPrice} className="select_size">
                      {singleproductList?.size.map((value) => (
                        <option
                          value={value.product_id}
                          selected={
                            value.size == singleproductList.pro_details.size
                              ? true
                              : false
                          }
                        >
                          {value.size}
                        </option>
                      ))}
                    </select>
                  ) : (
                    "NA"
                  )}
                </b>
                {/* {outOfStock == 0 ? (
                  <b className="out_of_stock">
                    {" "}
                    (This item is out of stock)
                  </b>
                ) : (
                  ""
                )} */}
              </div>
              {/* Out of stock is disabled */}
              {/* <div className="detail_btn flt">
                {outOfStock == 0 ? (
                  <button className="display3">Add to Cart</button>
                ) : backChainStatus &&
                  backChainStatus?.pro_details?.include_backchain === "NO" ? (
                  <button onClick={openBackchain}>Add to Cart</button>
                ) : backChainStatus &&
                  backChainStatus?.pro_details?.include_backchain === "YES" ? (
                  <button
                    onClick={() =>
                      cartPage(itemSizeDetails ? itemSizeDetails : "")
                    }
                  >
                    Add to Cart
                  </button>
                ) : singleproductList?.pro_details?.include_backchain ===
                    "NO" && singleproductList?.chain?.is_chain === 0 ? (
                  <button onClick={openBackchain}>Add to Cart</button>
                ) : (
                  <button
                    onClick={() =>
                      cartPage(singleproductList ? singleproductList : "")
                    }
                  >
                    Add to Cart
                  </button>
                )}
                <button onClick={openEnquiry}>Send Enquiry</button>
              </div> */}

              {/* Out of stock is enabled */}
              <div className="detail_btn flt">
                {backChainStatus &&
                backChainStatus?.pro_details?.include_backchain === "NO" ? (
                  <button onClick={openBackchain}>Add to Cart</button>
                ) : backChainStatus &&
                  backChainStatus?.pro_details?.include_backchain === "YES" ? (
                  <button
                    onClick={() =>
                      cartPage(itemSizeDetails ? itemSizeDetails : "")
                    }
                  >
                    Add to Cart
                  </button>
                ) : singleproductList?.pro_details?.include_backchain ===
                  "NO" ? (
                  <button onClick={openBackchain}>Add to Cart</button>
                ) : singleproductList?.chain?.is_chain === 0 ? (
                  <button onClick={openBackchain}>Add to Cart</button>
                ) : (
                  <button
                    onClick={() =>
                      cartPage(singleproductList ? singleproductList : "")
                    }
                  >
                    Add to Cart
                  </button>
                )}
                <button onClick={openEnquiry}>Send Enquiry</button>
              </div>
              {outOfStock === 0 && (
                <div className="netweight_info flt">
                  Net weight of the product may vary post production
                  <span>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                    <div className="netweight" style={{ display: "none" }}>
                      Since the weight of handmade ornaments cannot be
                      mechanically standardised, the final weight may vary by
                      upto 2% and 4% for Kundan and Polki ornaments. Abharan
                      will promptly refund or ask to pay any difference in net
                      weight at the time of billing. You will be notified by sms
                      & e-mail to approve the same in your abharan.com account.
                    </div>
                  </span>
                </div>
              )}

              <ul className="detail_note flt">
                <li>
                  <a onClick={openCertified}>
                    <LazyLoadImage src={detail3} alt="image" />
                    CERTIFIED JEWELLERY
                  </a>
                </li>
                <li>
                  <a onClick={openShipping}>
                    <LazyLoadImage src={detail} alt="image" />
                    T&C / RETURNS / SHIPPING
                  </a>
                </li>
                <li>
                  <a href="tel:18005999777" target="_blank" onClick={tollfree}>
                    <LazyLoadImage src={detail2} alt="image" />
                    TOLL FREE: 1800 5999 777
                  </a>
                </li>
                <li>
                  <a href={chart} target="_blank">
                    <LazyLoadImage src={detail4} alt="image" />
                    SIZE CHART
                  </a>
                </li>
              </ul>
              <div className="detail_enq flt">
                FOR ANY QUERIES ABOUT THIS PRODUCT OR OTHERWISE, PLEASE CONTACT
                US ON OUR WHATSAPP NUMBER BY CLICKING ON THE WHATSAPP ICON
              </div>
              <ul className="detail_identity flt">
                <li>
                  <LazyLoadImage src={identity1} />
                  <br />
                  Easy Returns
                </li>
                <li>
                  <LazyLoadImage src={identity2} />
                  <br />
                  Easy Shipping
                </li>
                <li>
                  <LazyLoadImage src={identity3} />
                  <br />
                  Hallmark Details
                </li>
                <li>
                  <LazyLoadImage src={identity4} />
                  <br />
                  Certification info on HUID
                </li>
              </ul>
              <div className="detail_pin_box flt">
                <div className="detail_pin_label flt">
                  Enter your pin code to check delivery availability in your
                  area
                </div>
                <div className="detail_pin_cont flt">
                  <div className="pincode_left">
                    <input
                      type="text"
                      name="pincode"
                      value={pincode}
                      onChange={changeNameHandler}
                      maxLength={6}
                      placeholder="Enter your pin code"
                      className={sub && !pincode ? " is-invalid" : ""}
                    />
                    {sub && !pincode && (
                      <div className="invalid-feedback dispay-feedback flt">
                        Pincode is required
                      </div>
                    )}
                  </div>
                  <button onClick={checkPincode}>Check</button>
                </div>

                {sub && deliverydate && pincode ? (
                  <div className="detail_pin_note flt">
                    Estimated delivery date :{" "}
                    {deliverydate ? formattedDate : ""}
                    <br />
                    {deliveryMessage}
                  </div>
                ) : (!pincode && !sub) ||
                  (pincode && !sub) ||
                  (!pincode && sub) ||
                  (pincode.length < 6 && sub) ? (
                  ""
                ) : (
                  <div className="detail_pin_label detail_pin_label1  flt">
                    Delivery not available for this location
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const specification = () => {
    return (
      <div className="specification_main flt">
        <div className="speification_head flt">Product Specification</div>
        <div className="specification_desc flt">
          <div className="row">
            <div className="col-sm-4">
              <div id="accordion flt">
                <div className="specification_box flt">
                  <div className="specification_head flt">
                    <a
                      onClick={() => setShow((prev) => !prev)}
                      data-toggle="collapse"
                      aria-expanded={show ? "true" : "false"}
                    >
                      Product Details
                    </a>
                  </div>

                  <div
                    id="collapseOne"
                    className={"tab_collapse flt" + (show ? " show" : "")}
                  >
                    <ul className="specification_cont flt">
                      <li>
                        <span>Item Code</span>
                        <b>
                          {singleproductList &&
                          singleproductList?.pro_details?.parentSKU
                            ? singleproductList?.pro_details?.parentSKU
                            : "NA"}
                        </b>
                      </li>
                      <li>
                        <span>Gross Weight</span>
                        <b>
                          {weightValue ? (
                            <div>{weightValue} GM</div>
                          ) : (
                            <div>
                              {singleproductList &&
                                singleproductList.pro_details &&
                                singleproductList.pro_details.weight}{" "}
                              GM
                            </div>
                          )}
                        </b>
                      </li>

                      {singleproductList && (
                        <li>
                          <span>Selected Size</span>
                          {selectedSizeValue}
                        </li>
                      )}

                      <li>
                        <span>Quantity Available</span>
                        <b>
                          {quantity ? (
                            <div>{quantity}</div>
                          ) : (
                            <div>
                              {singleproductList &&
                                singleproductList?.pro_details?.pieces}
                            </div>
                          )}
                        </b>
                      </li>

                      <li>
                        <span>Gender</span>
                        <b>
                          {itemSizeDetails &&
                          itemSizeDetails.pro_details &&
                          itemSizeDetails.pro_details.men === 1 &&
                          itemSizeDetails?.pro_details?.women === 1 &&
                          itemSizeDetails.pro_details.kids === 1
                            ? `Men,Women,kids`
                            : itemSizeDetails?.pro_details?.men === 1
                            ? "Men"
                            : itemSizeDetails?.pro_details?.women === 1
                            ? "Women"
                            : itemSizeDetails?.pro_details?.kids === 1
                            ? "Kids"
                            : "NA" &&
                              singleproductList &&
                              singleproductList.pro_details &&
                              singleproductList.pro_details.men === 1 &&
                              singleproductList?.pro_details?.women === 1 &&
                              singleproductList.pro_details.kids === 1
                            ? `Men,Women,kids`
                            : singleproductList?.pro_details?.men === 1
                            ? "Men"
                            : singleproductList?.pro_details?.women === 1
                            ? "Women"
                            : singleproductList?.pro_details?.kids === 1
                            ? "Kids"
                            : "NA"}
                        </b>
                      </li>
                      {
                        <li>
                          <span>Lock Type</span>
                          <b>
                            {itemSizeDetails &&
                            itemSizeDetails.pro_details &&
                            itemSizeDetails.pro_details.lock_type &&
                            itemSizeDetails.pro_details.lock_type !== "NULL"
                              ? itemSizeDetails.pro_details.lock_type
                              : "NA" &&
                                singleproductList &&
                                singleproductList.pro_details &&
                                singleproductList.pro_details.lock_type !==
                                  "NULL" &&
                                singleproductList.pro_details.lock_type
                              ? singleproductList.pro_details.lock_type
                              : "NA"}
                          </b>
                        </li>
                      }
                    </ul>
                  </div>
                </div>
                <div className="specification_box flt">
                  <div className="specification_head flt">
                    <a
                      onClick={() => setShow1((prev) => !prev)}
                      data-toggle="collapse"
                      aria-expanded={show1 ? "true" : "false"}
                    >
                      Metal Details
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    className={"tab_collapse flt" + (show1 ? " show" : "")}
                  >
                    <ul className="specification_cont flt">
                      <li>
                        <span>Metal</span>
                        <b>
                          {itemSizeDetails?.pro_details?.metal
                            ? itemSizeDetails?.pro_details?.metal
                            : singleproductList &&
                              singleproductList.pro_details &&
                              singleproductList.pro_details.metal
                            ? singleproductList.pro_details.metal
                            : "NA"}
                        </b>
                      </li>
                      <li>
                        <span>Purity</span>
                        <b>
                          {itemSizeDetails?.pro_details?.purity
                            ? itemSizeDetails?.pro_details?.purity
                            : singleproductList &&
                              singleproductList.pro_details &&
                              singleproductList.pro_details.purity
                            ? singleproductList.pro_details.purity
                            : "NA"}
                        </b>
                      </li>
                      <li>
                        <span>
                          {" "}
                          {singleproductList &&
                          singleproductList.pro_details &&
                          singleproductList.pro_details.metal
                            ? singleproductList.pro_details.metal
                            : "NA"}{" "}
                          Weight
                        </span>
                        <b>
                          {netWeight ? (
                            <div>{netWeight} GM</div>
                          ) : singleproductList &&
                            singleproductList.net_weight ? (
                            <b>{singleproductList.net_weight} GM</b>
                          ) : (
                            "NA"
                          )}{" "}
                        </b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div id="accordion flt">
                <div className="specification_box flt">
                  <div className="specification_head flt">
                    <a
                      onClick={() => setShow2((prev) => !prev)}
                      data-toggle="collapse"
                      aria-expanded={show2 ? "true" : "false"}
                    >
                      Stone Details
                    </a>
                  </div>
                  <div
                    id="collapseThree"
                    className={"tab_collapse flt" + (show2 ? " show" : "")}
                  >
                    <ul className="specification_cont flt">
                      <li>
                        <span>Stone Weight</span>
                        <b>
                          {itemSizeDetails?.pro_details?.stone_weight
                            ? itemSizeDetails?.pro_details?.stone_weight
                            : singleproductList &&
                              singleproductList.pro_details &&
                              singleproductList.pro_details.stone_weight &&
                              singleproductList?.pro_details?.stone_weight !==
                                "0"
                            ? singleproductList.pro_details.stone_weight
                            : "NA"}
                        </b>
                      </li>
                      <li>
                        <span>Stone Value</span>
                        <b>
                          {itemSizeDetails?.pro_details?.stone_value
                            ? itemSizeDetails?.pro_details?.stone_value
                            : singleproductList &&
                              singleproductList.pro_details &&
                              singleproductList.pro_details.stone_value &&
                              singleproductList?.pro_details?.stone_value !==
                                "0"
                            ? singleproductList.pro_details.stone_value
                            : "NA"}
                        </b>
                      </li>
                      <li>
                        <span>Stone Details</span>
                        <b>
                          {itemSizeDetails?.pro_details?.stone_details
                            ? itemSizeDetails?.pro_details?.stone_details
                            : singleproductList &&
                              singleproductList.pro_details &&
                              singleproductList.pro_details.stone_details &&
                              singleproductList?.pro_details?.stone_details !==
                                "0"
                            ? singleproductList.pro_details.stone_details
                            : "NA"}
                        </b>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="specification_box flt">
                  <div className="specification_head flt">
                    <a
                      onClick={() => setShow3((prev) => !prev)}
                      data-toggle="collapse"
                      aria-expanded={show3 ? "true" : "false"}
                    >
                      Diamond Details
                    </a>
                  </div>
                  <div
                    id="collapseFive"
                    className={"tab_collapse flt" + (show3 ? " show" : "")}
                  >
                    <ul className="specification_cont flt">
                      <li>
                        <span>Clarity</span>
                        <b>
                          {itemSizeDetails?.pro_details?.clarity
                            ? itemSizeDetails?.pro_details?.clarity
                            : singleproductList &&
                              singleproductList.pro_details &&
                              singleproductList.pro_details.clarity &&
                              singleproductList?.pro_details?.clarity !== "0"
                            ? singleproductList.pro_details.clarity
                            : "NA"}
                        </b>
                      </li>
                      <li>
                        <span>Color</span>
                        <b>
                          {itemSizeDetails?.pro_details?.colour
                            ? itemSizeDetails?.pro_details?.colour
                            : singleproductList &&
                              singleproductList.pro_details &&
                              singleproductList.pro_details.colour &&
                              singleproductList?.pro_details?.colour !== "0"
                            ? singleproductList.pro_details.colour
                            : "NA"}
                        </b>
                      </li>
                      <li>
                        <span>Carat</span>
                        <b>
                          {itemSizeDetails?.pro_details?.cents
                            ? itemSizeDetails?.pro_details?.cents
                            : singleproductList &&
                              singleproductList.pro_details &&
                              singleproductList.pro_details.cents &&
                              singleproductList?.pro_details?.cents !== "0"
                            ? singleproductList.pro_details.cents
                            : "NA"}
                        </b>
                      </li>
                      <li>
                        <span>Cut</span>
                        <b>
                          {itemSizeDetails?.pro_details?.cut
                            ? itemSizeDetails?.pro_details?.cut
                            : singleproductList &&
                              singleproductList.pro_details &&
                              singleproductList.pro_details.cut &&
                              singleproductList?.pro_details?.cut !== "0"
                            ? singleproductList.pro_details.cut
                            : "NA"}
                        </b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div id="accordion flt">
                <div className="specification_box flt">
                  <div className="specification_head flt">
                    <a
                      onClick={() => setShow4((prev) => !prev)}
                      data-toggle="collapse"
                      aria-expanded={show4 ? "true" : "false"}
                    >
                      Price Breakup
                    </a>
                  </div>
                  <div
                    id="collapseSix"
                    className={"tab_collapse flt" + (show4 ? " show" : "")}
                  >
                    <ul className="specification_cont flt">
                      <li>
                        <span>Metal Price</span>
                        <b>
                          <span className="rs_font"> ₹ </span>
                          {metalPrice ? (
                            <>{commaValidation(metalPrice)}</>
                          ) : (
                            commaValidation(
                              singleproductList &&
                                singleproductList.c_metal_value
                            )
                          )}
                        </b>
                      </li>
                      {singleproductList &&
                        singleproductList.pro_details &&
                        singleproductList.pro_details.daimond != 0 && (
                          <li>
                            <span>Diamond Price</span>
                            <b>
                              <span className="rs_font"> ₹ </span>
                              {diamondPrice ? (
                                <>{commaValidation(diamondPrice)}</>
                              ) : (
                                commaValidation(
                                  singleproductList &&
                                    singleproductList.pro_details &&
                                    singleproductList.pro_details.daimond !=
                                      0 &&
                                    singleproductList?.pro_details?.daimond
                                )
                              )}
                            </b>
                          </li>
                        )}
                      <li>
                        <span>Making Charges</span>
                        <b>
                          <span className="rs_font"> ₹ </span>
                          {makingCharges ? (
                            <>{commaValidation(makingCharges)}</>
                          ) : (
                            commaValidation(
                              singleproductList &&
                                singleproductList.c_making_amount
                            )
                          )}
                        </b>
                      </li>
                      <li>
                        <span>GST</span>
                        <b>
                          <span className="rs_font"> ₹ </span>
                          {gst ? (
                            <>{commaValidation(gst)}</>
                          ) : (
                            commaValidation(
                              singleproductList &&
                                singleproductList.c_gst_amount
                            )
                          )}
                        </b>
                      </li>
                      <li>
                        <span>Total</span>
                        <b>
                          <span className="rs_font"> ₹ </span>

                          {itemPrice ? (
                            <>{commaValidation(itemPrice)}</>
                          ) : (
                            commaValidation(
                              singleproductList &&
                                singleproductList.c_gross_value
                            )
                          )}
                        </b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="detail_pin_label_desc flt">
              <b>Product Description </b>: &nbsp;
              {singleproductList?.name} for{" "}
              {singleproductList &&
              singleproductList.pro_details &&
              singleproductList.pro_details.men === 1 &&
              singleproductList.pro_details.men === 1
                ? "Men"
                : singleproductList?.pro_details?.women === 1
                ? "women"
                : "kids"}
              -
              {itemSizeDetails?.pro_details?.purity
                ? itemSizeDetails?.pro_details?.purity
                : singleproductList &&
                  singleproductList.pro_details &&
                  singleproductList.pro_details.purity
                ? singleproductList.pro_details.purity
                : "NA"}
              k&nbsp;
              {singleproductList &&
              singleproductList.pro_details &&
              singleproductList.pro_details.purity
                ? singleproductList.pro_details.metal
                : "NA"}{" "}
              -
              {weightValue ? (
                <>{weightValue} GM</>
              ) : (
                <>
                  {singleproductList &&
                    singleproductList.pro_details &&
                    singleproductList.pro_details.weight}{" "}
                  GM
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const devider = () => {
    return (
      <div className="divider_main flt">
        <div className="divider flt"></div>
      </div>
    );
  };
  const devidermain = () => {
    return (
      <div className="related_main flt">
        <div className="related_head flt">Products you may like</div>
        <div className="related_cont flt">
          <div className="row items" id="style-3">
            {singleproductList &&
              singleproductList.likely &&
              singleproductList.likely?.data?.map((prodLikely) => (
                <div className="col-sm-3">
                  <div className="related_box flt">
                    <div className="related_image flt">
                      {prodLikely?.pro_images?.[0]?.image ? (
                        <LazyLoadImage
                          onClick={() => LikelyImageData(prodLikely)}
                          src={`${image_url}${
                            prodLikely &&
                            prodLikely.pro_images[0] &&
                            prodLikely.pro_images[0].image &&
                            prodLikely.pro_images[0].image
                              ? prodLikely.pro_images[0].image
                              : ""
                          }`}
                          alt="image"
                        />
                      ) : (
                        <LazyLoadImage
                          onClick={() => LikelyImageData(prodLikely)}
                          src={sample}
                          className="example"
                        />
                      )}
                      <div
                        className={
                          prodLikely &&
                          prodLikely.wish_status &&
                          prodLikely.wish_status === 1
                            ? "heart heart_red"
                            : "heart"
                        }
                        onClick={() =>
                          imgChange(
                            prodLikely &&
                              prodLikely.pro_details &&
                              prodLikely.pro_details.product_id
                              ? prodLikely.pro_details.product_id
                              : ""
                          )
                        }
                      >
                        <i className="fa fa-heart" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="related_txt flt">
                      <div className="related_desc flt">
                        <div className="related_name">
                          {prodLikely && prodLikely.name ? prodLikely.name : ""}
                        </div>
                        <div className="related_rate">
                          <span className="rs_font"> ₹ </span>{" "}
                          <span>
                            {commaValidation(
                              prodLikely && prodLikely.total_price
                                ? prodLikely.total_price
                                : "0"
                            )}
                          </span>
                        </div>
                      </div>
                      {prodLikely?.net_weight &&
                        prodLikely?.net_weight !== "0" && (
                          <div className="related_weight flt">
                            {prodLikely && prodLikely.net_weight
                              ? prodLikely.net_weight
                              : "0"}
                            GM
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const SendEnquiry = () => {
    return (
      <div className="modalPop" id="enquiryModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={closeEnquiry}>
                <LazyLoadImage src={close_pop} alt="image" />
              </button>
              <div className="enquiry_head flt">Enquiry Details</div>
              <div className="enquiry_cont flt">
                <div className="form-group flt">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={name}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    onChange={changeNameHandler}
                    className={submitted && !name ? " is-invalid" : ""}
                  />
                  {submitted && !name && (
                    <div className="invalid-feedback">Name is required</div>
                  )}
                </div>

                <div className="form-group flt">
                  <input
                    type="text"
                    placeholder="Email ID"
                    value={email.trim()}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    onChange={changeEmailHandler}
                    className={submitted && !email ? " is-invalid" : ""}
                  />
                  {submitted && !email && (
                    <div className="invalid-feedback">Email is required</div>
                  )}
                </div>
                <div className="form-group flt">
                  <input
                    type="number"
                    placeholder="Phone Number"
                    value={mobile}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    onChange={changeMobileNoHandler}
                    className={submitted && !mobile ? " is-invalid" : ""}
                  />
                  {submitted && !email && (
                    <div className="invalid-feedback">
                      Mobile Number is required
                    </div>
                  )}
                </div>
                <div className="form-group flt">
                  <input
                    type="text"
                    placeholder="Comments"
                    value={comments}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    onChange={changeCommentsHandler}
                    className={submitted && !comments ? " is-invalid" : ""}
                  />
                  {submitted && !comments && (
                    <div className="invalid-feedback">
                      Comments are required
                    </div>
                  )}
                </div>
                <div class="mode_box flt">
                  <div class="mode_tag flt">
                    What is your mode of communication ?
                  </div>
                  <ul className="mode_inp flt">
                    <li>
                      <input
                        type="radio"
                        name="radio-group1"
                        id="video"
                        checked={ctype === "video" ? true : false}
                        value="video"
                        onChange={changeCtypeHandler}
                      />
                      <label for="video">Video call</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="radio-group2"
                        value="phone"
                        checked={ctype === "phone" ? true : false}
                        onChange={changeCtypeHandler}
                        id="phone"
                      />
                      <label for="phone">Phone call</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="radio-group3"
                        value="whatsapp"
                        checked={ctype === "whatsapp" ? true : false}
                        onChange={changeCtypeHandler}
                        id="whatsapp"
                      />
                      <label for="whatsapp">Whatsapp</label>
                    </li>
                  </ul>
                </div>

                <div class="mode_check flt">
                  <input
                    type="checkbox"
                    id="agree"
                    checked={cbutton}
                    value={cbutton}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    onChange={changeCbuttonHandler}
                    className={submitted && !cbutton ? " is-invalid" : ""}
                  />

                  <label for="agree">
                    <span>
                      I agree to get a call from one of the representatives from
                      Abharan to discuss more about jewellery and its purchase
                    </span>
                  </label>
                  {submitted && cbutton === false && (
                    <div className="invalid-feedback">
                      Please accept the terms and conditions
                    </div>
                  )}
                </div>
                <div className="mode_btn flt">
                  <button data-dismiss="modal" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Shipping = () => {
    return (
      <div className="modalPop" id="shippingModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="faq_head flt">T & C / Returns / Shipping</div>
              <button type="button" className="close" onClick={closeShipping}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="policy_cont flt">
                <div className="policy_box flt">
                  <div className="policy_desc flt">
                    {policyData &&
                      policyData?.data &&
                      policyData?.data?.privacy && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: policyData?.data?.shipping?.description,
                          }}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Certified = () => {
    return (
      <div className="modalPop" id="certifiedModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="faq_head flt">Certified Jewellery</div>
              <button type="button" className="close" onClick={closeCertified}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="policy_desc flt">
                {certifiedData && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: certifiedData?.description,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Backchain = () => {
    return (
      <div class="modalPop" id="backChain">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" onClick={closeBackchain}>
                &times;
              </button>
              <div className="backchain_box">
                <div className="backchain_title">
                  Do you want to include Back Chain ?
                </div>
                <div className="backchain_btn">
                  <button
                    className={
                      chainorlace === 0 ? "button_back" : "button_back1"
                    }
                    onClick={() => goldchaindetails(1)}
                  >
                    Gold Backchain
                  </button>
                  <button
                    className={
                      chainorlace === 0 ? "button_back1" : "button_back"
                    }
                    onClick={() => laceshow(1)}
                  >
                    Lace
                  </button>
                </div>
                {chainorlace === 0 && (
                  <div class="backchain_tag">
                    Weight :{" "}
                    {itemSizeDetails?.chain_net_weight
                      ? itemSizeDetails?.chain_net_weight
                      : singleproductList.pro_details.chain_weight}
                    g Price : <span className="rs_font"> ₹ </span>
                    {itemSizeDetails?.chain_c_gross_value
                      ? commaValidation(itemSizeDetails?.chain_c_gross_value)
                      : commaValidation(singleproductList?.chain_c_gross_value)}
                  </div>
                )}
                {chainorlace === 0 && (
                  <div class="backchain_image">
                    {laces &&
                    laces === 1 &&
                    singleproductList?.backchain?.image ? (
                      <LazyLoadImage
                        src={`${image_url}${
                          singleproductList &&
                          singleproductList.backchain &&
                          singleproductList.backchain.image
                            ? singleproductList.backchain.image
                            : ""
                        }`}
                      />
                    ) : (
                      <LazyLoadImage src={sample} />
                    )}
                  </div>
                )}
                {chainorlace === 1 && (
                  <div class="backchain_tag">
                    {" "}
                    Price : <span className="rs_font"> ₹ </span>
                    {singleproductList?.pro_details?.lace_cost &&
                    singleproductList?.pro_details?.lace_cost !== "null"
                      ? commaValidation(
                          singleproductList?.pro_details?.lace_cost
                        )
                      : 0}
                  </div>
                )}
                {chainorlace === 1 && (
                  <div class="backchain_image">
                    {singleproductList &&
                    singleproductList.backchain &&
                    singleproductList.backchain.laceimage ? (
                      <LazyLoadImage
                        src={`${image_url}${singleproductList.backchain.laceimage}`}
                      />
                    ) : (
                      <LazyLoadImage src={sample} />
                    )}
                  </div>
                )}

                <div className="backchain_click">
                  <button
                    onClick={() => {
                      const success = addChainStatus(
                        singleproductList &&
                          singleproductList.pro_details &&
                          singleproductList.pro_details.product_id
                          ? singleproductList.pro_details.product_id
                          : "",
                        isChain
                      );
                      if (success) {
                        cartPage(singleproductList ? singleproductList : "");
                      }
                    }}
                    data-dismiss="modal"
                  >
                    Select and Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const emptypg = () => {
    return (
      <div className="cartpg flt">
        <LazyLoadImage src={loader} alt="abharan" />
      </div>
    );
  };
  const Decide = () => {
    return (
      <div className="modalPop" id="decideModal">
        <div className="modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" onClick={closeDecide}>
                <LazyLoadImage src={close_pop} alt="image" />
              </button>
              <div className="decide_box">
                <div className="decide_image">
                  <LazyLoadImage src={decide_pic} />
                </div>
                <div className="decide_txt">
                  “Still deciding ?” No need to worry because we have a free
                  returns policy with doorstep pickup.Order Now!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderHeader = () => {
    return <Header count={wishListCount} cart={cartCount} />;
  };
  const renderFooter = () => {
    return <Footer />;
  };

  return (
    <div>
      {renderHeader()}
      <div className="detail_container flt">
        <div className="breadcrumbs flt">
          <Link to="/">HOME</Link> /
          <span>
            {" "}
            {singleproductList && singleproductList.name
              ? singleproductList.name
              : "Gold"}
          </span>
        </div>
        {loading === "notempty" && detailContainer()}
        {loading === "loading" && emptypg()}

        {specification()}
      </div>
      {devider()}
      {devidermain()}
      {showEnquiry && SendEnquiry()}
      {showShipping && <Shipping closeShipping={() => closeShipping()} />}
      {showCertified && <Certified closeShipping={() => closeShipping()} />}
      {showBackchain && Backchain()}
      {showDecide && <Decide closeDecide={() => closeDecide()} />}
      {showProduct && <ProductMobile />}
      {renderFooter()}
    </div>
  );
};
export default ProductDetails;
