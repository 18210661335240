export const SAVE_USER_DATA = "SAVE_USER_DATA";
export const SAVE_HEADER_DATA = "SAVE_HEADER_DATA";
export const USER_LOGOUT = "USER_LOGOUT";
export const SAVE_PROMO_CODE_DATA = "SAVE_PROMO_CODE_DATA";
export const EDIT_USER_DATA = "EDIT_USER_DATA";
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const ADD_BANNER_DATA = "ADD_BANNER_DATA";

export const MIN_AMOUNT = "MIN_AMOUNT";
export const MAX_AMOUNT = "MAX_AMOUNT";
export const FILTER_DATA = "FILTER_DATA";
export const MAIN_CAT = "MAIN_CAT";
export const SUB_CAT = "SUB_CAT";
export const PAGE_NO = "PAGE_NO";
export const SEARCH_KEY = "SEARCH_KEY";
export const UPDATE_ALL_FILTER = "UPDATE_ALL_FILTER";
