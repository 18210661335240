import { useState, useEffect } from "react";
import Header from "../../_common/Header/Header";
import FaqAccordion from "./FaqAccordion";
import { FaqService } from "../../../services/FaqService";
import Footer from "../../_common/Footer/Footer";
import { Helmet } from "react-helmet";
import useCountHook from "../../../hooks/useCountHook";

const Faq = () => {
  const [data, setData] = useState();
  const { wishListCount, cartCount } = useCountHook();
  useEffect(() => {
    FaqService.getFaq()
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, []);

  var faqData = {
    info: `FAQs and other details of Abharan Jewellers Official site`,
  };

  document.title = `FAQs | Abharan Jewellers`;
  return (
    <div className="flt">
      <Header count={wishListCount} cart={cartCount} />
      <Helmet>
        <meta name="description" content={faqData?.info} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div class="faq_container flt">
        <div class="faq_head flt">
          <h1 class="faq_head flt">FAQs</h1>
        </div>
        <div class="faq_cont flt" id="accordion">
          {data && data.data && data.data.faq !== null
            ? data.data.faq.map((list, index) => (
                <FaqAccordion title={list.question} content={list.answer} />
              ))
            : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
