import { useState } from "react";
import { PrivacyService } from "../../../services/PrivacyService";
import { ProductService } from "../../../services/ProductService";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { userLogout } from "../../../actions";
import detail from "../../../assets/images/detail-note1.svg";
import detail2 from "../../../assets/images/detail-note2.svg";
import detail3 from "../../../assets/images/detail-note3.svg";
import detail4 from "../../../assets/images/detail-note4.svg";
import { alertActions } from "../../../actions/alert.actions";
import sample from "../../../assets/images/default.png";
import identity1 from "../../../assets/images/identity1.png";
import identity2 from "../../../assets/images/identity2.png";
import identity3 from "../../../assets/images/identity3.png";
import identity4 from "../../../assets/images/identity4.png";
import { useDispatch, useSelector } from "react-redux";
import chart from "../../../assets/file/size-chart.pdf";
import backchain from "../../../assets/images/backchain.png";
import close_pop from "../../../assets/images/close-pop.png";
import { useNavigate } from "react-router-dom";
import useCountHook from "../../../hooks/useCountHook";
import { UserService } from "../../../services";
import crypto from "crypto";
import TagManager from "react-gtm-module";

const useProductDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let history = useNavigate();
  const dispatch = useDispatch();
  const { wishListCount, cartCount, getWishListCount } = useCountHook();
  const [showBackchain, setShowBackchain] = useState(false);
  const skuId =
    location && location?.state && location?.state?.id
      ? location?.state?.id
      : "";
  const [current, setcurrent] = useState(localStorage.getItem("pagination"));
  const [singleproductList, setsingleProductList] = useState(null);
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN; // Image url
  const usr_id = useSelector((state) => state?.userData?.user?._id);
  const [showEnquiry, setShowEnquiry] = useState(false);
  const [showCertified, setShowCertified] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [favClicked, setFavClicked] = useState(false);
  const [estimateDelivery, setEstimateDelivery] = useState();
  const [pincode, setPincode] = useState();
  const [policyData, setPolicyData] = useState();
  const [certifiedData, setCertifiedData] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [comments, setComments] = useState("");
  const [sku, setSku] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [ctype, setCtype] = useState("video");
  const [cbutton, setCbutton] = useState(false);
  const [enquiryData, setEnquiryData] = useState({});
  const [sub, setSub] = useState(false);
  const [deliverydate, setDeliveryDate] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [laces, setLace] = useState(1);
  const [isChain, setIsChain] = useState(1);
  const [chaincost, setChaincost] = useState(false);
  const [chainorlace, setChainorlace] = useState(null);
  const [showProduct, setShowProduct] = useState(false);
  const [loading, setLoading] = useState("loading");
  let [itemSize, setItemSize] = useState("");
  let getSku = window.location.href.split("/")[6].toUpperCase();
  const [selectedSizeValue, setSelectedSizeValue] = useState(null);
  const [itemSizeDetails, setItemSizeDetails] = useState();
  const [itemPrice, setItemPrice] = useState();
  const [backChainStatus, setBackChainStatus] = useState();
  const [weightValue, setWeightValue] = useState();
  const [metalPrice, setMetalPrice] = useState();
  const [makingCharges, setMakingCharges] = useState();
  const [diamondPrice, setDiamondPrice] = useState();
  const [gst, setGst] = useState();
  const [quantity, setQuantity] = useState();
  const [gender, setGender] = useState();
  const [netWeight, setNetWeight] = useState();
  const [prodId, setProdId] = useState();
  const [deliveryMessage, setDeliveryMessage] = useState("");
  const [sizeArray, setSizeArray] = useState([]);
  const [outOfStock, setOutOfStock] = useState();
  const [formattedDate, setFormattedDate] = useState(
    new Date().toLocaleDateString("en-GB")
  );

  const menuData = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [randomNumbersss, setRandomNumber] = useState("");
  const [hashedData, setHashedData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [eventData, setEventData] = useState("");

  var singleProductName = singleproductList?.name;

  useEffect(() => {
    if (enquiryData?.data?.status?.code === 0) {
      setName("");
      setEmail("");
      setMobile("");
      setComments("");
      setCbutton(false);
      setSubmitted(false);
      setShowEnquiry(false);
      dispatch(alertActions.success(enquiryData?.data?.status?.message));
    } else {
      dispatch(alertActions.error(enquiryData?.data?.status?.message));
    }
  }, [enquiryData]);

  useEffect(() => {
    const slider = document.querySelector(".items");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });

    const currentTimestamp = Math.floor(Date.now() / 1000); // Getting Unix timestamp in seconds
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);
  }, []);

  useEffect(() => {
    ProductService.getSingleProduct(skuId && skuId !== "" ? skuId : getSku)
      .then((singleproductList) => {
        setOutOfStock(singleproductList?.data?.data?.in_stock);
        // setItemSizeDetails(singleproductList?.data?.data);
        setSelectedSizeValue(
          singleproductList?.data?.data?.pro_details.size !== null
            ? singleproductList?.data?.data?.pro_details.size
            : "NA"
        );
        if (
          singleproductList?.data?.data?.pro_details?.include_backchain ===
          "YES"
        ) {
          setItemPrice(singleproductList?.data?.data?.c_gross_value);
        } else {
          setItemPrice(singleproductList?.data?.data?.c_gross_value);
        }

        setEstimateDelivery(singleproductList?.data?.data);
        setsingleProductList(singleproductList?.data?.data);
        setSku(singleproductList?.data?.data?.SKU);
        if (singleproductList?.data?.data?.pro_images[0]?.image !== "") {
          setSelectedImageIndex(0);
        } else {
          setSelectedImageIndex(-1);
        }
        setLoading("notempty");
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoading("empty");
        //   setLoading(false);
      });

    ProductService.getSizeValue(prodId)
      .then((response) => {
        setItemSizeDetails(response?.data?.data);
        setSelectedSizeValue(response.data.data.pro_details.size);
        if (response?.data?.data?.pro_details?.include_backchain === "YES") {
          setItemPrice(response?.data?.data?.c_gross_value);
        } else {
          setItemPrice(response?.data?.data?.c_gross_value);
        }
        setMetalPrice(response?.data?.data?.c_metal_value);
        setMakingCharges(response?.data?.data?.c_making_amount);
        setDiamondPrice(response?.data?.data?.pro_details?.daimond);
        setGst(response?.data?.data?.c_gst_amount);
        setNetWeight(response?.data?.data?.net_weight);
        setQuantity(response?.data?.data?.pro_details?.pieces);
        setEstimateDelivery(response?.data?.data);
        setOutOfStock(response?.data?.data?.in_stock);
        setGender(
          itemSizeDetails &&
            itemSizeDetails.pro_details &&
            itemSizeDetails.pro_details.men === 1 &&
            itemSizeDetails.pro_details.men === 1
            ? "Men"
            : itemSizeDetails?.pro_details?.women === 1
            ? "women"
            : "kids"
        );
        setBackChainStatus(response?.data?.data);
        setWeightValue(response.data.data.pro_details.weight);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [skuId, wishListCount, chaincost, location]);

  const generateRandomNumber = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth() + 1; // Month is 0-indexed, so add 1
    // const date = currentDate.getDate();
    // const hours = currentDate.getHours();
    // const minutes = currentDate.getMinutes();
    // const seconds = currentDate.getSeconds();
    // const milliseconds = currentDate.getMilliseconds();

    // // Combine all parts into a single number
    // const randomNumber = parseInt(
    //   `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}`,
    //   10
    // );

    // return randomNumber;
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  useEffect(() => {
    const hashObject = (obj) => {
      const hashedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const hash = crypto
            .createHash("sha256")
            .update(String(value))
            .digest("hex");
          hashedObj[key] = hash;
        }
      }
      return hashedObj;
    };
    const hashedSingleObject = hashObject(menuData);
    setHashedData(hashedSingleObject);

    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashedData.email],
      ph: [hashedData.mobile_no, hashedData.alternate_number],
      ln: [hashedData.last_name],
      fn: [hashedData.first_name],
      ct: [hashedData.city, hashedData.shipping_city],
      st: [hashedData.state, hashedData.shipping_state],
      zp: [hashedData.pincode, hashedData.shipping_pincode],
    };

    const custom_data = {
      currency: "INR",
      value: singleproductList?.c_total.toString(),
      content_category: singleproductList?.pro_details?.description,
      content_ids: singleproductList?.SKU,
    };

    const eventData2 = {
      event_name: "ViewContent",
      event_time: unixTimestamp,
      action_source: "website",
      event_source_url: currentUrl,
      event_id: randomNumbersss.toString(),
      user_data: user_data,
      custom_data: custom_data,
    };

    if (singleproductList !== null) {
      const productData = {
        id: singleproductList?.SKU,
        name: singleproductList?.name,
        price: singleproductList?.c_total.toString(),
        quantity: 1,
        category: singleproductList?.pro_details?.description,
        currency: "INR",
      };

      TagManager.dataLayer({
        dataLayer: {
          event: "contentview",
          ecommerce: productData,
          pagePath: location.pathname,
        },
      });
      console.log(window.dataLayer, "DataLayer");
    }

    if (ipAddress && userAgent) {
      setUpdatedPixelData((prevData) => [...prevData, eventData2]);
    }
  }, [menuData, ipAddress, userAgent, randomNumbersss]);

  useEffect(() => {
    if (updatedPixelData.length) {
      UserService.getUserData(updatedPixelData)
        .then((response) => {
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }, [updatedPixelData]);

  useEffect(() => {
    PrivacyService.getPrivacy()
      .then((data) => {
        setPolicyData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });

    PrivacyService.getCertifiedJewellary()
      .then((data) => {
        setCertifiedData(data?.data?.data[2]);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, []);

  function changeNameHandler(e) {
    if (e.target.name === "pincode") {
      if (e.target.value.length < 6) {
        setDeliveryMessage("");
        setSub(false);
      }
      setPincode(e.target.value);
    } else if (e.target.name === "name") {
      if (/^[a-zA-Z ]*$/.test(e.target.value)) {
        setName(e.target.value);
      } else if (!e.target.value) {
        setName("");
      }
    }
  }
  function changeEmailHandler(e) {
    setEmail(e.target.value);
  }
  function changeMobileNoHandler(e) {
    setMobile(e.target.value);
  }
  function changeCommentsHandler(e) {
    setComments(e.target.value);
  }
  function changeCtypeHandler(e) {
    setCtype(e.target.value);
  }
  function changeCbuttonHandler(e) {
    setCbutton(!cbutton);
  }
  const openProduct = (index) => {
    setSelectedImageIndex(index);
    setShowProduct(true);
  };
  const closeProduct = () => {
    setShowProduct(false);
  };

  const changeCurrentPage = (page) => {
    setcurrent(page.selected + 1);
    localStorage.setItem("pagination", page.selected + 1);
  };

  const LikelyImageData = (prodsku) => {
    var prodNameData = prodsku?.name.toLowerCase().replace(/\s+/g, "-");
    var baseProductName = prodsku?.main_cat?.title.toLowerCase();
    ProductService.productClickCount(prodsku?.pro_details?.product_id);
    window.scrollTo(0, 0);
    navigate(
      `/product-details/${baseProductName}/${prodNameData}/${prodsku.SKU}`,
      { state: { id: prodsku.SKU, userid: usr_id } }
    );

    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: `/product-details/${baseProductName}/${prodNameData}/${prodsku.SKU}`,
      },
    });
    // if (ipAddress && userAgent) {
    //   setUpdatedPixelData((prevData) => [...prevData, eventData2]);
    // }
    console.log(window.dataLayer, "DataLayer");
  };

  let metaData1 = {
    info: `Shop ${singleProductName}. 100% Certified Hallmark | Safe Shopping Guaranteed | Free Shipping`,
  };

  const homeREdirect = () => {
    history("/");
  };

  const cartPage = (product) => {
    if (location.pathname !== "/login") {
      localStorage.setItem("pathname", location.pathname);
    }
    let chainprice;
    let chainweight;
    if (product.chain) {
      if (singleproductList?.chain?.is_chain === 1 && chainorlace === 1) {
        chainprice = "null";
        chainweight = 0;
      } else if (
        singleproductList?.chain?.is_chain === 1 &&
        chainorlace === 0
      ) {
        if (itemSizeDetails) {
          chainprice = itemSizeDetails.chain_c_gross_value;
          chainweight = itemSizeDetails.chain_net_weight;
        } else {
          chainprice = product?.chain_c_gross_value;
          chainweight = product?.pro_details?.chain_weight;
        }
      }
    } else if (chainorlace === 0) {
      chainprice = product?.chain_c_gross_value;
      chainweight = product?.pro_details?.chain_weight;
    } else {
      chainprice = 0;
      chainweight = 0;
    }
    if (isChain === 1) {
      ProductService.addToCarts(
        prodId ? prodId : product?.pro_details?.product_id,
        isChain,
        chainprice ? chainprice : 0,
        chainweight ? chainweight : 0
      )
        .then((product_info) => {
          if (product_info.data.status.code === 0) {
            dispatch(alertActions.success(product_info.data.status.message));
            history("/cart");
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log("Errorreg cart", error);
          if (error?.data?.status?.message === "Your not authorized.") {
            dispatch(userLogout());
            dispatch(alertActions.error("Please login"));
            history("/login");
          }
        });
    } else if (isChain === 0) {
      ProductService.addToCarts(
        prodId ? prodId : product?.pro_details?.product_id,
        isChain,
        chainprice ? chainprice : 0,
        chainweight ? chainweight : 0
      )
        .then((product_info) => {
          if (product_info.data.status.code === 0) {
            dispatch(alertActions.success(product_info.data.status.message));
            history("/cart");
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log("Errorreg carts", error);
        });
    } else {
      ProductService.addToCart(
        prodId ? prodId : product?.pro_details?.product_id,
        isChain,
        chainprice ? chainprice : 0,
        chainweight ? chainweight : 0
      )
        .then((product_info) => {
          if (product_info.data.status.code === 0) {
            dispatch(alertActions.success(product_info.data.status.message));
            history("/cart");
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log("Errorreg cartss", error);
        });
    }
    if (!usr_id) {
      history("/login");
    }
  };

  const imgChange = (prod) => {
    setFavClicked(true);
    if (usr_id) {
      ProductService.getProdDetails(prod, usr_id)
        .then((prodList) => {
          getWishListCount();
          const newRandomNumber = generateRandomNumber();
          setRandomNumber(newRandomNumber);
          function getCookie(name) {
            const cookieValue = document.cookie.match(
              "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
            );
            return cookieValue ? cookieValue.pop() : "";
          }

          // Fetch the values of fbp and fbc cookies
          const fbpValue = getCookie("_fbp");
          const fbcValue = getCookie("_fbc");

          var currentUrl = window.location.href;

          const user_data = {
            client_ip_address: ipAddress,
            client_user_agent: userAgent,
            fbp: fbpValue,
            fbc: fbcValue,
            em: [hashedData.email],
            ph: [hashedData.mobile_no, hashedData.alternate_number],
            ln: [hashedData.last_name],
            fn: [hashedData.first_name],
            ct: [hashedData.city, hashedData.shipping_city],
            st: [hashedData.state, hashedData.shipping_state],
            zp: [hashedData.pincode, hashedData.shipping_pincode],
          };

          const custom_data = {
            currency: "INR",
            value: singleproductList?.c_total.toString(),
            content_category: singleproductList?.pro_details?.description,
            content_ids: singleproductList?.SKU,
          };
          if (singleproductList.wish_status !== 1) {
            if (ipAddress && userAgent) {
              const updatedPixelDataUpdater = [
                {
                  event_name: "AddToWishlist",
                  event_time: unixTimestamp,
                  action_source: "website",
                  event_source_url: currentUrl,
                  event_id: randomNumbersss.toString(),
                  user_data: user_data,
                  custom_data: custom_data,
                },
              ];

              if (singleproductList !== undefined) {
                const productData = {
                  id: singleproductList?.SKU,
                  name: singleproductList?.name,
                  price: singleproductList?.c_total.toString(),
                  quantity: 1,
                  category: singleproductList?.pro_details?.description,
                  currency: "INR",
                };

                TagManager.dataLayer({
                  dataLayer: {
                    event: "addtowishlist",
                    // ecommerce: {
                    //   currencyCode: productData.currency,
                    //   add: {
                    //     products: [productData],
                    //   },
                    // },
                    ecommerce: productData,
                    pagePath: location.pathname,
                  },
                });
                console.log(window.dataLayer, "DataLayer");
              }

              UserService.getUserData(updatedPixelDataUpdater)
                .then((response) => {
                  //setGetOtpData(response);
                })
                .catch((error) => {
                  console.log("Errorreg", error);
                });
            }
          }
          if (prodList.status.code === 4) {
            dispatch(alertActions.error(prodList.status.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
          history("/login");
        });
    } else {
      history("/login");
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (name && mobile && email && comments && sku && cbutton && ctype) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error("Enter Valid Email Address"));
      } else if (mobile && !filter.test(mobile)) {
        dispatch(alertActions.error("Enter Valid Mobile Number"));
      } else if (cbutton === "false") {
        dispatch(alertActions.error("Please accept the terms and conditions"));
      } else {
        ProductService.productEnquiry(name, email, mobile, comments, ctype, sku)
          .then((response) => {
            setEnquiryData(response);
            function getCookie(name) {
              const cookieValue = document.cookie.match(
                "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
              );
              return cookieValue ? cookieValue.pop() : "";
            }

            // Fetch the values of fbp and fbc cookies
            const fbpValue = getCookie("_fbp");
            const fbcValue = getCookie("_fbc");

            var currentUrl = window.location.href;

            const user_data = {
              client_ip_address: ipAddress,
              client_user_agent: userAgent,
              fbp: fbpValue,
              fbc: fbcValue,
              em: [hashedData.email],
              ph: [hashedData.mobile_no, hashedData.alternate_number],
              ln: [hashedData.last_name],
              fn: [hashedData.first_name],
              ct: [hashedData.city, hashedData.shipping_city],
              st: [hashedData.state, hashedData.shipping_state],
              zp: [hashedData.pincode, hashedData.shipping_pincode],
            };

            const custom_data = {
              currency: "INR",
              value: singleproductList?.c_total.toString(),
              content_category: singleproductList?.pro_details?.description,
              content_ids: singleproductList?.SKU,
            };

            if (ipAddress && userAgent) {
              const updatedPixelDataUpdater = [
                {
                  event_name: "Lead",
                  event_time: unixTimestamp,
                  action_source: "website",
                  event_source_url: currentUrl,
                  event_id: randomNumbersss.toString(),
                  user_data: user_data,
                  custom_data: custom_data,
                },
              ];
              // const updatedPixelDataUpdater = eventData;

              UserService.getUserData(updatedPixelDataUpdater)
                .then((response) => {
                  //setGetOtpData(response);
                })
                .catch((error) => {
                  console.log("Errorreg", error);
                });
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
        document.body.classList.remove("modal-open");
      }
    }
  }

  const checkPincode = () => {
    setSub(true);
    if (!pincode) {
      return false;
    } else {
      estimateDelivery && estimateDelivery?.in_stock === 0
        ? ProductService.estimated_delivery_outstock(
            pincode,
            estimateDelivery?.pro_details?.product_id
          ).then((deliverydate) => {
            if (deliverydate?.data?.status?.code === 1) {
              setDeliveryMessage(deliverydate?.data?.status?.message);
              setDeliveryDate("");
            } else {
              if (deliverydate?.data?.data?.message !== undefined) {
                setDeliveryDate(deliverydate?.data?.data?.estimated_delivery);
                setDeliveryMessage(deliverydate?.data?.data?.message);
              } else if (
                (deliverydate?.data).includes("Delivered by Sequel Logistics")
              ) {
                setDeliveryMessage("Delivered Sequel Logistics");
                let text = deliverydate?.data;
                const myArray = text.split(":");
                const myArray1 = myArray[5].split(/["\"$]/);
                const myArray2 = myArray1[1].split(" ");
                setDeliveryDate(myArray2[0]);
              } else {
                setDeliveryMessage("");
              }
            }
          })
        : ProductService.checkAvailability(pincode).then((deliverydate) => {
            if (deliverydate?.data?.status?.code === 1) {
              setDeliveryMessage(deliverydate?.data?.status?.message);
              setDeliveryDate("");
            } else {
              if (deliverydate?.data?.data?.message !== undefined) {
                setDeliveryDate(deliverydate?.data?.data?.estimated_delivery);
                setDeliveryMessage(deliverydate?.data?.data?.message);
              } else if (
                (deliverydate?.data).includes("Delivered by Sequel Logistics")
              ) {
                setDeliveryMessage("Delivered By Sequel Logistics");
                let text = deliverydate?.data;
                const myArray = text.split(":");
                const myArray1 = myArray[5].split(/["\"$]/);
                const myArray2 = myArray1[1].split(" ");
                setDeliveryDate(myArray2[0]);
              } else {
                setDeliveryMessage("");
              }
            }
          });
    }
  };

  useEffect(() => {
    setFormattedDate(deliverydate);
    //  console.log(deliverydate.split(" ")[0], "jhgdfsjhsdg");
  }, [deliverydate]);

  const openBackchain = () => {
    goldchaindetails(1);
    document.body.classList.add("modal-open");
    setShowBackchain(true);
  };
  const closeBackchain = () => {
    document.body.classList.remove("modal-open");
    setShowBackchain(false);
  };

  const openEnquiry = () => {
    document.body.classList.add("modal-open");
    setShowEnquiry(true);
  };
  const closeEnquiry = () => {
    document.body.classList.remove("modal-open");
    setShowEnquiry(false);
  };

  const openShipping = () => {
    document.body.classList.add("modal-open");
    setShowShipping(true);
  };

  const tollfree = () => {
    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashedData.email],
      ph: [hashedData.mobile_no, hashedData.alternate_number],
      ln: [hashedData.last_name],
      fn: [hashedData.first_name],
      ct: [hashedData.city, hashedData.shipping_city],
      st: [hashedData.state, hashedData.shipping_state],
      zp: [hashedData.pincode, hashedData.shipping_pincode],
    };

    const custom_data = {
      currency: "INR",
      value: singleproductList?.c_total.toString(),
      content_category: singleproductList?.pro_details?.description,
      content_ids: singleproductList?.SKU,
    };

    if (ipAddress && userAgent) {
      const updatedPixelDataUpdater = [
        {
          event_name: "Contact",
          event_time: unixTimestamp,
          action_source: "website",
          event_source_url: currentUrl,
          event_id: randomNumbersss.toString(),
          user_data: user_data,
          custom_data: custom_data,
        },
      ];
      // const updatedPixelDataUpdater = eventData;

      UserService.getUserData(updatedPixelDataUpdater)
        .then((response) => {
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };
  const closeShipping = () => {
    document.body.classList.remove("modal-open");
    setShowShipping(false);
  };

  const openCertified = () => {
    document.body.classList.add("modal-open");
    setShowCertified(true);
  };
  const closeCertified = () => {
    document.body.classList.remove("modal-open");
    setShowCertified(false);
  };
  const goldchaindetails = (val) => {
    setLace(val);
    setIsChain(1);
    setChainorlace(0);
  };
  const laceshow = (val) => {
    setLace(val);
    setChainorlace(val);
    setIsChain(1);
  };

  const addChainStatus = (prod_id, chain_status) => {
    return new Promise((resolve, reject) => {
      if (usr_id) {
        ProductService.addChainStatuss(prod_id, chain_status, chainorlace).then(
          (result) => {
            if (chain_status) {
              setChaincost(!chaincost);
            }
            resolve(true); // The operation succeeded, so resolve with true
          },
          (error) => {
            console.log(error, "Error");
            navigate("/login");
            reject(new Error("Session Expired please login"));
          }
        );
      } else {
        navigate("/login");
        reject(new Error("User is not logged in")); // Reject with an error indicating that the user is not logged in
      }
      closeBackchain();
    });
  };
  const showLargeImage = () => {
    return singleproductList?.pro_images?.image !== "";
  };

  const changeLargeImage = (index) => {
    setSelectedImageIndex(index);
  };

  const backchain_data = () => {
    history("/cart");
  };
  /**
   * send size
   * @param {*} e
   */
  const sendSize = (e) => {
    let a = JSON.parse(e.target.value);
    let parentSku = a?.parentSKU;
    let size = a?.size;
    itemSize = a?.size;

    ProductService.sizeCheck(parentSku, size)
      .then((response) => {
        getSku = response?.data?.data?.products?.[0]?.SKU;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //new code
  const sendItemPrice = (e) => {
    var prodId = e.target.value;
    setProdId(prodId);

    ProductService.getSizeValue(prodId)
      .then((response) => {
        setItemSizeDetails(response?.data?.data);
        setSelectedSizeValue(response.data.data.pro_details.size);
        setItemPrice(response?.data?.data?.c_gross_value);
        setMetalPrice(response?.data?.data?.c_metal_value);
        setMakingCharges(response?.data?.data?.c_making_amount);
        setDiamondPrice(response?.data?.data?.pro_details?.daimond);
        setGst(response?.data?.data?.c_gst_amount);
        setNetWeight(response?.data?.data?.net_weight);
        setQuantity(response?.data?.data?.pro_details?.pieces);
        setEstimateDelivery(response?.data?.data);
        setOutOfStock(response?.data?.data?.in_stock);
        setGender(
          itemSizeDetails &&
            itemSizeDetails.pro_details &&
            itemSizeDetails.pro_details.men === 1 &&
            itemSizeDetails.pro_details.men === 1
            ? "Men"
            : itemSizeDetails?.pro_details?.women === 1
            ? "women"
            : "kids"
        );
        setBackChainStatus(response?.data?.data);
        setWeightValue(response.data.data.pro_details.weight);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    history,
    useLocation,
    location,
    singleproductList,
    image_url,
    detail,
    detail2,
    detail3,
    detail4,
    chart,
    imgChange,
    alertActions,
    useState,
    LikelyImageData,
    cartPage,
    sample,
    showEnquiry,
    close_pop,
    openBackchain,
    closeBackchain,
    openEnquiry,
    closeEnquiry,
    openShipping,
    tollfree,
    closeShipping,
    openCertified,
    closeCertified,
    showShipping,
    backchain,
    showCertified,
    showBackchain,
    policyData,
    name,
    email,
    mobile,
    comments,
    sku,
    ctype,
    cbutton,
    changeEmailHandler,
    changeNameHandler,
    changeMobileNoHandler,
    changeCbuttonHandler,
    changeCommentsHandler,
    changeCtypeHandler,
    changeCurrentPage,
    submitted,
    handleSubmit,
    pincode,
    checkPincode,
    sub,
    homeREdirect,
    sendItemPrice,
    formattedDate,
    selectedImageIndex,
    showLargeImage,
    changeLargeImage,
    backchain_data,
    goldchaindetails,
    laceshow,
    laces,
    certifiedData,
    identity1,
    identity2,
    identity3,
    identity4,
    laceshow,
    laces,
    isChain,
    selectedSizeValue,
    addChainStatus,
    chainorlace,
    showProduct,
    openProduct,
    closeProduct,
    loading,
    sendSize,
    itemSize,
    deliveryMessage,
    sizeArray,
    singleProductName,
    metaData1,
    itemSizeDetails,
    itemPrice,
    backChainStatus,
    weightValue,
    gst,
    makingCharges,
    metalPrice,
    diamondPrice,
    netWeight,
    deliverydate,
    quantity,
    gender,
    wishListCount,
    cartCount,
    outOfStock,
  };
};

export default useProductDetails;
