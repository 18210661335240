import { useEffect, useState } from "react";
import { alertActions } from "../../../actions";
import { PrivacyService } from "../../../services";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import close_pop from "../../../assets/images/close-pop.png";

const useFeedBackHook = (id) => {
  const [question, setQuestion] = useState([]);
  const [rating, setRating] = useState();
  const [rating1, setRating1] = useState();
  const [rating2, setRating2] = useState();
  const [rating3, setRating3] = useState();
  const [rating4, setRating4] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [feedbackRes, setFeedbackRes] = useState({});
  const [staus, setStatus] = useState();
  const [showButtom, setShowButton] = useState(true);
  const [cancelButton, setCancelButton] = useState(false);
  const [dataToSend, setDataToSend] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const displayValue = 2;
  let order_id = id;
  useEffect(() => {
    PrivacyService.getFeedBackQuestion()
      .then((response) => {
        setQuestion(response.data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, []);
  useEffect(() => {
    if (feedbackRes.status?.code === 0) {
      setStatus(feedbackRes?.feedback?.status);
      setSubmitted(true);
      setShowButton(false);
      navigate("/order-details", { replace: true });
      //dispatch(alertActions.success(feedbackRes.status?.message));
    } else if (feedbackRes.status?.code === 1) {
      dispatch(alertActions.error("Please fill all the details"));
    } else if (feedbackRes.status?.code === 2) {
      setShowButton(false);
      dispatch(alertActions.error(feedbackRes.status?.message));
    }
  }, [feedbackRes]);
  const changeStar = (rating) => {
    if (showButtom) {
      setRating(rating);
    }
  };
  const changeStar1 = (rating1) => {
    if (showButtom) {
      setRating1(rating1);
    }
  };
  const changeStar2 = (rating2) => {
    if (showButtom) {
      setRating2(rating2);
    }
  };
  const changeStar3 = (rating3) => {
    if (showButtom) {
      setRating3(rating3);
    }
  };
  const changeStar4 = (e) => {
    if (showButtom) {
      setRating4(e.target.value);
    }
  };

  function handleCancel() {
    setCancelButton(true);
    navigate("/order-details", { replace: true });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    //setShowButton(false);
    setCancelButton(false);
    if (staus !== 1) {
      PrivacyService.updateFeedback(
        rating,
        rating1,
        rating2,
        rating3,
        rating4,
        order_id
      )
        .then((response) => {
          setFeedbackRes(response?.data);
          //  window.location.reload();
        })
        .catch((error) => {
          console.log("Errorreg", error);
          //   setLoading(false);
        });
    }
  }

  return {
    question,
    handleSubmit,
    rating,
    rating1,
    rating2,
    rating3,
    rating4,
    showButtom,
    close_pop,
    cancelButton,
    submitted,
    dataToSend,

    changeStar,
    changeStar1,
    changeStar2,
    changeStar3,
    changeStar4,
    handleCancel,
  };
};
export default useFeedBackHook;
