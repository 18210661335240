import * as axios from "../network/Network";

const config = require("../network/api.json");

class HeaderService {
  static header() {
    return axios.appRequest({
      method: axios.GET,
      url: config.general.header,
      data: "",
    });
  }

  static getDailyRate() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.general.dailyRate,
    });
    return Promise.resolve(result);
  }
  static getBlockDate() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.blockDate,
    });
    return Promise.resolve(result);
  }

  static videoRequest(
    name,
    email,
    mobile_number,
    language,
    date,
    time,
    category,
    sub_category,
    city,
    purpose_video,
    message
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.general.videoRequest,
      data: {
        name,
        email,
        mobile_number,
        language,
        date,
        time,
        category,
        sub_category,
        city,
        purpose_video,
        message,
      },
    });
    return Promise.resolve(result);
  }
}
export { HeaderService };
