import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import JewelleryAccordion from "./JewelleryAccordion";
import useCountHook from "../../../hooks/useCountHook";

const JewelleryCare = () => {
  const { wishListCount, cartCount } = useCountHook();

  const Care = () => {
    return (
      <div class="faq_container flt">
        <div class="faq_head flt">Jewellery Care</div>
        <div class="faq_cont flt" id="accordion">
          <JewelleryAccordion
            title="1. Gold jewellery care"
            content="<p>Following are some tips to be taken to handle gold jewellery with care:</p>
                        <ul>
                            <li>Gold jewellery should be removed before a shower. This is because soap can cause a film to form on gold jewellery, making it appear dull and dingy.</li>
                            <li>For home cleaning of gold jewellery, one can remove tarnish by using soap and water mixed with a few drops of ammonia followed by careful brushing with a soft bristle brush. After the brushing, simply rinse with lukewarm water and allow drying.</li>
                            <li>There are many brands available in the market which serve to clean the gold jewellery. Soft chamois cloth is also a good option. One can retrieve complete information from the Jeweller regarding gold cleaning.</li>
                            <li>Your Gold jewellery can be permanently damaged and discoloured especially at high temperatures. Also, care should be taken not to use gold jewellery while using chlorine bleach or while in a swimming pool or hot bath tub.</li>
                            <li>Grease from gold jewellery can be removed by dipping the jewellery into plain rubbing alcohol. However care should be taken while cleaning gold jewellery with coloured gemstone pieces and consultation with Jeweller will be of help.</li>
                        </ul>"
          />
          <JewelleryAccordion
            title="2. Diamond care"
            content=" <p>A diamond is the hardest form of carbon with incredible durability. However, a strong blow to an edge can still chip it. Hence it is advised not to wear diamond during strenous physical activities.</p>
                        <ul>
                            <li>Diamond is susceptible to Chlorine. Hence diamond should be kept away from bleach or other household chemicals to avoid discolouration and damage. Also it is advised not to wear diamond jewellery while entering into pool or hot bath tub.</li>
                            <li>Diamonds should be regularly cleaned using commercial jewellery cleaner, a mix of ammonia and water, or a mild detergent. Use a soft brush to wipe dust or dirt from under the setting.</li>
                            <li>Diamonds should be handled by its edges. Also it is better to avoid touching clean diamonds with fingers.</li>
                        </ul>"
          />
          <JewelleryAccordion
            title="3. Coloured gemstone care"
            content="<p>Listed below are some of the general care and cleaning rules that apply to all coloured gemstone jewellery.</p>
                        <ul>
                            <li>After use, the precious gemstone jewellery should be wiped thoroughly with a clean soft, slightly damp cloth. This is because the gemstone's luster will be enhanced if it is ensured that jewellery is clean before storage.
                            </li>
                            <li>Gemstone or Gemstone studded jewellery should be stored individually in soft pouches provided by the Jeweller.</li>
                            <li>Exposure to salt water or harsh chemicals, such as chlorine or detergents deteriorates the quality of the jewellery. These chemicals will eventually erode the finish and polish of gemstones.</li>
                            <li>Care should be taken to not wear jewellery after application of cosmetics, hair spray, perfume, etc. Perspiration may cause jewellery to become dull.</li>
                            <li>Also gemstone jewellery should not be subjected to sudden temperature changes.</li>
                            <li>Extra precautions should be taken while having active lifestyle. For example, Emeralds are brittle and should be avoided when doing household chores or any other activity where the stone could be hit or damaged.</li>
                            <li>Careful precautions should be taken while using ultrasonic cleaners. Some gemstones are fragile and can be damaged by ultrasonic cleaners. One can retrieve complete information from the Jeweller regarding coloured gemstone care.</li>
                        </ul>"
          />
          <JewelleryAccordion
            title="4. Platinum care"
            content="<ul>
                        <li>Platinum, unlike gold is more durable, and does not get easily tarnished and discoloured by chlorine and other chemicals. However, proper care will ensure the natural sheen of the metal. Following are some tips to be taken to handle the Platinum.</li>
                        <li>Platinum jewellery can be cleaned with the conventional ways of cleaning gold jewellery. However, a professional cleaning every six months is always advisable for platinum jewellery.</li>
                        <li>Platinum jewellery should be treated with care and stored separately. Care should be taken not to allow pieces to touch each other to avoid scratches.</li>
                        <li>Special care should be rendered if platinum is set with diamonds or other precious stones, as these materials can be more susceptible to damage.</li>
                    </ul>"
          />
          <JewelleryAccordion
            title="5. Cultured pearl care"
            content="<ul>
                        <li>Pearl Jewellery should always be used after the application of cosmetics. After usage the pearl jewellery should be wiped with soft damp cloth.</li>
                        <li>Pearl Jewellery can also be washed with mild soap and water. Chemicals or any other solvents should be avoided as these substances can damage the pearls.</li>
                        <li>Also care should be taken not to toss pearl jewellery carelessly into a purse, bag or jewel box.</li>
                        <li>A pearl's surface is soft and can be scratched by hard metal edges or by harder gemstones of other jewellery pieces. Hence care must be taken to store it separately.</li>
                    </ul>"
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header count={wishListCount} cart={cartCount} />
      {Care()}
      <Footer />
    </div>
  );
};
export default JewelleryCare;
