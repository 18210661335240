import { SAVE_HEADER_DATA } from "../actions/types";
export function HeaderReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_HEADER_DATA:
      return {
        ...state,
        menu: payload.menu,
      };

    default:
      return state;
  }
}
