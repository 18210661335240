import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DailyRateHooks = () => {
  const [showDailyRate, setShowDailyRate] = useState(true);
  const navigate = useNavigate();
  const detailsGold = () => {
    document.body.classList.remove("modal-open");
    navigate("/gold-rate-today");
  };
  return {
    detailsGold,
  };
};
export default DailyRateHooks;
