import useContact from "./useContact";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import sample from "../../../assets/images/default.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useCountHook from "../../../hooks/useCountHook";

import "./Contact.css";
const Contact = () => {
  const {
    history,
    dispatch,
    add,
    changeHandler,

    handleSubmit,
    submitted,
    data,
    state,
    district,
    city,
  } = useContact();
  const { wishListCount, cartCount } = useCountHook();
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN; // Image url

  let contactInfo = {
    info: `Contact and Phone number of Abharan Jewellers Official site`,
  };

  document.titlle = `Contact Us | Abharan Jewellers`;

  const contact_cont = () => {
    return (
      <div className="contact_cont flt">
        <Helmet>
          <meta name="description" content={contactInfo?.info} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div className="row">
          <div className="col-sm-5">
            <div className="contact_left flt">
              <div className="form-group flt">
                <label>Your Name*</label>
                <input
                  type="text"
                  name="name"
                  value={add && add.name}
                  onChange={changeHandler}
                  className={submitted && add && !add.name ? " is-invalid" : ""}
                />
                {submitted && add && !add.name && (
                  <div className="invalid-feedback">Name is required</div>
                )}
              </div>
              <div className="form-group flt">
                <label>Your Mobile Number*</label>
                <input
                  type="text"
                  name="mobile_no"
                  value={add && add.mobile_no}
                  onChange={changeHandler}
                  className={
                    submitted && add && !add.mobile_no ? " is-invalid" : ""
                  }
                />

                {submitted && add && !add.mobile_no && (
                  <div className="invalid-feedback">
                    Mobile number is required
                  </div>
                )}
              </div>
              <div className="form-group flt">
                <label>Your Email *</label>
                <input
                  type="text"
                  name="email"
                  value={add && add.email}
                  onChange={changeHandler}
                  className={
                    submitted && add && !add.email ? " is-invalid" : ""
                  }
                />

                {submitted && add && !add.email && (
                  <div className="invalid-feedback">Email is required</div>
                )}
              </div>
              <div className="form-group flt">
                <label>Your Message *</label>
                <input
                  type="text"
                  name="reason"
                  value={add && add.reason}
                  onChange={changeHandler}
                  className={
                    submitted && add && !add.reason ? " is-invalid" : ""
                  }
                />
                {submitted && add && !add.reason && (
                  <div className="invalid-feedback">Message is required</div>
                )}
              </div>
              <button className="contact_btn flt" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
          <div className="col-sm-7">
            <div className="contact_right flt">
              <iframe src="https://www.google.com/maps/d/embed?mid=1uKhk9Kz2FwIMHTkj15tYq9pC73oX2cJD"></iframe>
            </div>
          </div>
        </div>
        {/* <div className="row">
                    {data && data.data && data.data.branches != null ? data.data.branches.map((list) =>
                        <div className="col-sm-4" key={list._id}>
                            <div className="place_box flt">
                                <div className="place_box_head flt"><span>{list.branch_name}</span></div>
                                <div className="place_box_cont flt">
                                    <ul>
                                        <li>{list.address}</li>
                                        <li>{list.contact1}{list.contact2 && ', '}{list.contact2}</li>
                                        <li>{list.email}</li>
                                        <li> Business Hours (Mon - Sat): {list.from} – {list.to} (no lunch
                                            break)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div> */}
        <div className="contact_branch_main flt">
          <div className="contact_location flt">
            <div className="row">
              <div className="col-sm-12">
                <div className="contact_location_head flt">Find Our Stores</div>
              </div>
            </div>
            <div className="contact_location_ip flt">
              <div className="row">
                <div className="col-sm-6">
                  <select name="state" value={state} onChange={changeHandler}>
                    <option disable>Please select State</option>
                    <option>Karnataka</option>
                    <option>Goa</option>
                  </select>
                </div>
                <div className="col-sm-6">
                  <select name="city" onChange={changeHandler}>
                    <option disable>Please select Location</option>
                    {district && district.branches
                      ? district.branches.map((value) => (
                          <option value={value} key={value}>
                            {value}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              {city && city !== null
                ? city.map((list) => (
                    <div className="col-sm-6">
                      <div className="branch_box flt">
                        <div className="row">
                          <div className="col-sm-5">
                            <div className="branch_image flt">
                              {list?.image ? (
                                <LazyLoadImage src={image_url + list?.image} />
                              ) : (
                                <LazyLoadImage src={sample} />
                              )}
                            </div>
                          </div>

                          <div className="col-sm-7" key={list._id}>
                            {/* <div className="place_box flt"> */}
                            <div className="place_box_head flt">
                              <span>{list.branch_name}</span>
                            </div>
                            <div className="place_box_cont flt">
                              <ul>
                                <li>{list.address}</li>
                                <li>
                                  {list.contact1}
                                  {list.contact2 && ", "}
                                  {list.contact2}
                                </li>
                                <li>{list.email}</li>
                                <li>
                                  {" "}
                                  Business Hours (Mon - Sat): {list.from} –{" "}
                                  {list.to} (no lunch break)
                                </li>
                              </ul>
                            </div>
                            {/* </div> */}
                          </div>

                          {/* <div className="col-sm-6">
                                            <div className="branch_address flt">
                                                <div class="place_box_head flt"><span>Panaji</span></div>
                                                <div class="place_box_cont flt">
                                                    <ul>
                                                        <li> Lawande Sarmalkar Bhawan Near
                                                            MahalaxmiTemple,Dada Vaidya Road - 403001</li>
                                                        <li> 6366385080 </li>
                                                        <li> goa@abharan.com</li>
                                                        <li> Business Hours (Mon - Sat): 10:30 – 18:30 (no lunch
                                                            break)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> */}
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header count={wishListCount} cart={cartCount} />
      <div className="contact_container flt">
        <div className="breadcrumbs flt">
          <Link to="/">HOME</Link> / <span>Contact Us</span>
        </div>
        <div className="contact_main flt">
          <div className="contact_head flt">
            <h1 className="contact_head flt">Contact Us</h1>
          </div>
          {contact_cont()}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Contact;
