import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import { PrivacyService } from "../../../services/PrivacyService";
import { useState, useEffect } from "react";
import {  useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import useCountHook from "../../../hooks/useCountHook";

import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  const { wishListCount, cartCount } = useCountHook();
  const [data, setData] = useState();
  useEffect(() => {
    PrivacyService.getPrivacy()
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        //   setLoading(false);
      });
  }, []);
  document.title = `Privacy Policy | Abharan Jewellers`;

  let privacyPolicy = {
    info: `Privacy Policy details of Abharan Jewellers Official site`,
  };

  const Privacy = () => {
    return (
      <div class="faq_container flt">
        <Helmet>
          <meta name="description" content={privacyPolicy?.info} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div class="faq_head flt">Abharan Privacy Policies</div>
        <div class="policy_cont flt">
          <div class="policy_box flt">
            <div class="policy_desc flt">
              {data && data.data && data.data.privacy && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.data.privacy.description,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header count={wishListCount} cart={cartCount} />
      {Privacy()}
      <Footer />
    </div>
  );
};
export default PrivacyPolicy;
