import { ADD_BANNER_DATA } from "../actions/types";
const initialState = {
  banners: [],
  branches: [],
  category: [],
  daily_rates: [],
  sub_category: [],
  whats_new: [],
};
export function ABDataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_BANNER_DATA:
      return {
        ...state,
        banners: payload.banners,
        branches: payload.branches,
        category: payload.category,
        daily_rates: payload.daily_rates,
        sub_category: payload.sub_category,
        whats_new: payload.whatsnew,
      };

    default:
      return state;
  }
}
