import * as axios from "../network/Network";
const config = require("../network/api.json");

class ProductService {
  static getCart() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getCart,
    });
    return Promise.resolve(result);
  }
  //delete cart
  static deleteCart(data) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.deleteCart + `/${data}`,
    });
    return Promise.resolve(result);
  }

  //video block
  static block_vdo(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.block_vdo,
    });
    return Promise.resolve(result);
  }

  static ongoinOrder() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.ongoing,
    });
    return Promise.resolve(result);
  }

  //doc track
  static doctrack(DocketNo) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.doctrack + `/${DocketNo}`,
    });
    return Promise.resolve(result);
  }

  //oprder track auth
  static ordertrackauth(
    CustomerId,
    OrderNo,
    AgentID,
    ProductCode,
    ItemName,
    AWBNo,
    No_Of_Pieces,
    CustomerName,
    CustomerAdd1,
    CustomerAdd2,
    CustomerCity,
    CustomerState,
    CustomerPincode,
    CustomerTeleNo,
    CustomerMobileNo,
    CustomerTeleNo2,
    TotalAmt,
    PaymentMode,
    CollectableAmt,
    Weight,
    UOM,
    ServiceType
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.ordertrackauth + `/${OrderNo}`,
      data: {
        pin_code: CustomerPincode,
        CustomerId: CustomerId,
        OrderUploadData: [
          {
            OrderNo: OrderNo,
            AgentID: AgentID,
            ProductCode: ProductCode,
            ItemName: ItemName,
            AWBNo: AWBNo,
            No_Of_Pieces: No_Of_Pieces,
            CustomerName: CustomerName,
            CustomerAdd1: CustomerAdd1,
            CustomerAdd2: CustomerAdd2,
            CustomerCity: CustomerCity,
            CustomerState: CustomerState,
            CustomerPincode: CustomerPincode,
            CustomerTeleNo: CustomerTeleNo,
            CustomerMobileNo: CustomerMobileNo,
            CustomerTeleNo2: CustomerTeleNo2,
            TotalAmt: TotalAmt,
            PaymentMode: PaymentMode,
            CollectableAmt: CollectableAmt,
            Weight: Weight,
            UOM: UOM,
            ServiceType: ServiceType,
          },
        ],
      },
    });
    return Promise.resolve(result);
  }

  // To save all search result in API
  static storeSearchData(search_data) {
    var bodyFormData = new FormData();
    bodyFormData.append("searchlist", search_data);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.general.storeSearchData,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  //order auth1
  static ordertrackauth1(
    token,
    OrderNo,
    location,
    shipmentType,
    serviceType,
    fromStoreCode,
    consignee_name,
    address_line1,
    pinCode,
    auth_receiver_phone,
    auth_receiver_name,
    net_weight,
    gross_weight,
    net_value,
    no_of_packages
  ) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.ordertrackauth + `/${OrderNo}`,
      data: {
        pin_code: pinCode,
        token,
        location,
        shipmentType,
        serviceType,
        fromStoreCode,
        toAddress: {
          consignee_name,
          address_line1,
          pinCode,
          auth_receiver_name,
          auth_receiver_phone,
        },
        net_weight,
        gross_weight,
        net_value,
        no_of_packages,
      },
    });
    return Promise.resolve(result);
  }

  //add chain status
  static addChainStatuss(data, status, chainorlace) {
    var bodyFormData = new FormData();
    bodyFormData.append("product_id", data);
    bodyFormData.append("is_chain", status);
    bodyFormData.append("lace_status", chainorlace);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addChainStatuss,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  //product click count productClickCount
  static productClickCount(id) {
    var bodyFormData = new FormData();
    bodyFormData.append("prod_id", id);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.productClickCount,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  //track order  trackOrder
  static trackOrder(id) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.trackOrder + `/${id}`,
    });
    return Promise.resolve(result);
  }
  static getSingleOrder(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getsingleorder + `/${id}`,
    });
    return Promise.resolve(result);
  }
  static getSubCategory() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.general.subCategory,
    });
    return Promise.resolve(result);
  }
  static getBranchDetails() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.general.getBranchDetails,
    });
    return Promise.resolve(result);
  }
  //doccor value doccorOrder
  static doccorOrder(docket) {
    var bodyFormData = new FormData();
    bodyFormData.append("docket", docket);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.doccorOrder,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  // "maincatcount": "analytics/count_cat",
  static maincatcount(id) {
    var bodyFormData = new FormData();
    bodyFormData.append("maincat_id", id);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.maincatcount,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }
  // "subcatcount": "analytics/allsub"

  static subcatcount(id, id1) {
    var bodyFormData = new FormData();
    bodyFormData.append("subcat_id", id);
    bodyFormData.append("maincat_id", id1);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.subcatcount,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  //video request subcategfory

  static subcategoryGet(data) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getsubcategory + `/${data}`,
    });
    return Promise.resolve(result);
  }

  //get video request
  static getvdorequest() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getvdorequest,
    });
    return Promise.resolve(result);
  }
  //get video request past
  static getvdorequestpast() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getvdorequestpast,
    });
    return Promise.resolve(result);
  }
  //search filter with 2 parameter
  static getSingleData(data, current) {
    var bodyFormData = new FormData();
    bodyFormData.append("search", data);
    bodyFormData.append("page", current);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.getSingleData,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  //delete promo code
  static deletePromo(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("promocode", data);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.deletePromo,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  //update billing address only for first time user
  static updateBillingAddress(
    billing_address,
    locality,
    city,
    state,
    district,
    pincode,
    landmark,
    user_id
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("billing_address", billing_address);
    bodyFormData.append("locality", locality);
    bodyFormData.append("city", city);
    bodyFormData.append("state", state);
    bodyFormData.append("district", district);
    bodyFormData.append("pincode", pincode);
    bodyFormData.append("landmark", landmark);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.updateBilling + `/${user_id}`,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  //my-details
  static myDetails(
    first_name,
    lname,
    email,
    address,
    password,
    c_password,
    confirm_password,
    new_password,
    user_id,
    mobile_no,
    locality,
    city,
    state,
    district,
    pincode,
    landmark,
    shipping_address,
    shipping_locality,
    shipping_city,
    shipping_landmark,
    shipping_pincode,
    shipping_state,
    shipping_district,
    alternate_number
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("first_name", first_name);
    bodyFormData.append("last_name", lname);
    bodyFormData.append("email", email);
    bodyFormData.append("billing_address", address);
    //bodyFormData.append("address", address);
    bodyFormData.append("current_password", c_password ? c_password : "");
    bodyFormData.append("change_password", new_password ? new_password : "");
    bodyFormData.append("mobile_no", mobile_no ? mobile_no : "");
    bodyFormData.append("locality", locality ? locality : "");
    bodyFormData.append("city", city ? city : "");
    bodyFormData.append("state", state ? state : "");
    bodyFormData.append("district", district ? district : "");
    bodyFormData.append("pincode", pincode ? pincode : "");
    bodyFormData.append("landmark", landmark ? landmark : "");
    bodyFormData.append(
      "shipping_address",
      shipping_address ? shipping_address : ""
    );
    bodyFormData.append(
      "shipping_locality",
      shipping_locality ? shipping_locality : ""
    );
    bodyFormData.append("shipping_city", shipping_city ? shipping_city : "");
    bodyFormData.append(
      "shipping_landmark",
      shipping_landmark ? shipping_landmark : ""
    );
    bodyFormData.append(
      "shipping_pincode",
      shipping_pincode ? shipping_pincode : ""
    );
    bodyFormData.append("shipping_state", shipping_state ? shipping_state : "");
    bodyFormData.append(
      "shipping_district",
      shipping_district ? shipping_district : ""
    );
    bodyFormData.append(
      "alternate_number",
      alternate_number ? alternate_number : ""
    );
    return axios.appRequest({
      method: axios.POST,
      url: config.user.mydetails + `/${user_id}`,
      data: bodyFormData,
    });
  }

  //saving shipping address
  static myDetail(
    shipping_address,
    shipping_locality,
    shipping_city,
    shipping_landmark,
    shipping_pincode,
    shipping_state,
    shipping_district,
    user_id
  ) {
    var bodyFormData = new FormData();

    bodyFormData.append(
      "shipping_address",
      shipping_address ? shipping_address : ""
    );
    bodyFormData.append(
      "shipping_locality",
      shipping_locality ? shipping_locality : ""
    );
    bodyFormData.append("shipping_city", shipping_city ? shipping_city : "");
    bodyFormData.append(
      "shipping_landmark",
      shipping_landmark ? shipping_landmark : ""
    );
    bodyFormData.append(
      "shipping_pincode",
      shipping_pincode ? shipping_pincode : ""
    );
    bodyFormData.append("shipping_state", shipping_state ? shipping_state : "");
    bodyFormData.append(
      "shipping_district",
      shipping_district ? shipping_district : ""
    );
    //bodyFormData.append("user_id", user_id ? user_id : "");

    return axios.appRequest({
      method: axios.POST,
      url: config.user.saveShippingAddress + `/${user_id}`,
      data: bodyFormData,
    });
  }

  //block date api blockDate
  static blockDate() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.blockDate,
    });
    return Promise.resolve(result);
  }

  static addToCart(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("product_id", data);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addCart,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  static addToCarts(data, is_chain, chain_cost, weight) {
    var bodyFormData = new FormData();
    bodyFormData.append("product_id", data);
    bodyFormData.append("is_chain", is_chain);
    bodyFormData.append("chain_price", chain_cost);
    bodyFormData.append("weight", weight);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addCart,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  static addPromocode(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("promocode", data);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.promocode,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  static getProdDetails(data, current) {
    const result = axios.appRequest({
      method: axios.POST,
      url:
        config.user.getProdDetails + `?product_id=${data}&user_id=${current}`,
    });
    return Promise.resolve(result);
  }

  static getHeaderData(data, current, search) {
    const result = axios.appRequest({
      method: axios.POST,
      url:
        config.user.productHeaderData +
        `?search=${data}&page=${current}&search=${search}`,
    });
    return Promise.resolve(result);
  }
  static getSingleProduct(data) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.product_jewellary + `/${data}`,
    });
    return Promise.resolve(result);
  }
  //new code
  static getSizeValue(product_id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getSizeValue + `/${product_id}`,
    });
    return Promise.resolve(result);
  }

  static productEnquiry(fname, email, number, message, call_type, sku) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.product_enquiry,
      data: { fname, email, number, message, call_type, sku },
    });
    return Promise.resolve(result);
  }

  static checkAvailability(destination_pincode) {
    // alert(product_id);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.pincodeavailability,
      data: { destination_pincode },
    });
    return Promise.resolve(result);
  }

  static estimated_delivery_outstock(destination_pincode, product_id) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.estimated_delivery_outstock + `/${product_id}`,
      data: { destination_pincode },
    });
    return Promise.resolve(result);
  }

  static payment_success(order_id) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.payment_success + `/${order_id}`,
    });
    return Promise.resolve(result);
  }
  static payment_failure(order_id) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.payment_failure + `/${order_id}`,
    });
    return Promise.resolve(result);
  }
  static getState() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getState,
    });
    return Promise.resolve(result);
  }

  //clearproductid
  static clearproductid(product_id) {
    // alert("in service");
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.clearproductid,
      data: { product_id },
    });
    return Promise.resolve(result);
  }

  // //checkoutpincodecheck
  // static checkoutPincode(pincode) {
  //   // alert("in service");
  //   const result = axios.appRequest({
  //     method: axios.POST,
  //     url: config.user.clearproductid,
  //     data: { product_id },
  //   });
  //   return Promise.resolve(result);
  // }

  static getDistrict(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getDistrict + `/${id}`,
    });
    return Promise.resolve(result);
  }

  static getDistricts(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.district_delivery + `/${id}`,
    });
    return Promise.resolve(result);
  }

  static deleteAllCart(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.delete_all_cart + `/${id}`,
    });
    return Promise.resolve(result);
  }

  static checkout(
    name,
    mobile,
    alternate_mobile,
    shipping_pincode,
    shipping_address,
    shipping_state,
    delivery_charge,
    shipping_city,
    city,
    pincode,
    address,
    state,
    product_id,
    res_total,
    gst_amount,
    pan_no,
    image,
    last_name,
    distrct,
    shipping_district,
    shipping_locality,
    shipping_landmark,
    locality,
    landmark,
    estimated_delivery_date,
    Gold_22k_rate,
    Gold_18k_rate,
    Silver_rate
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("name", name);
    bodyFormData.append("mobile", mobile);
    bodyFormData.append("alternate_mobile", alternate_mobile);
    bodyFormData.append("shipping_pincode", shipping_pincode);
    bodyFormData.append("shipping_address", shipping_address);
    bodyFormData.append("shipping_state", shipping_state);
    bodyFormData.append("delivery_charge", delivery_charge);
    bodyFormData.append("shipping_city", shipping_city);
    bodyFormData.append("city", city);
    bodyFormData.append("pincode", pincode);
    bodyFormData.append("address", address);
    bodyFormData.append("state", state);
    bodyFormData.append("product_id", product_id);
    bodyFormData.append("res_total", res_total);
    bodyFormData.append("gst_amount", gst_amount);
    bodyFormData.append("pan_no", pan_no);
    bodyFormData.append("image", image);
    bodyFormData.append("last_name", last_name);
    bodyFormData.append("district", distrct);
    bodyFormData.append("shipping_district", shipping_district);
    bodyFormData.append("shipping_locality", shipping_locality);
    bodyFormData.append("shipping_landmark", shipping_landmark);
    bodyFormData.append("locality", locality);
    bodyFormData.append("landmark", landmark);
    bodyFormData.append("estimated_delivery_date", estimated_delivery_date);
    bodyFormData.append("Gold_22k_rate", Gold_22k_rate);
    bodyFormData.append("Gold_18k_rate", Gold_18k_rate);
    bodyFormData.append("Silver_rate", Silver_rate);

    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.checkout,
      data: bodyFormData,
      // data: {
      //   name,
      //   mobile,
      //   shipping_pincode,
      //   shipping_address,
      //   shipping_state,
      //   delivery_charge,
      //   shipping_city,
      //   city,
      //   pincode,
      //   address,
      //   state,
      //   product_id,
      //   res_total,
      //   gst_amount,
      //   pan_no,
      //   image,
      //   last_name,
      // },
    });
    return Promise.resolve(result);
  }

  //get geolocation
  static geolocationtrack(longitude, lattitude) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.getlocations,
      data: { longitude, lattitude },
    });
    return Promise.resolve(result);
  }

  static cancelOrder(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("track_status", 6);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.cancelOrder + `/${data}`,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  //return order
  static returnOrder(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("track_status", 10);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.returnOrder + `/${data}`,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

  static getData(
    data,
    current,
    gender,
    min_amount,
    max_amount,
    main,
    sub,
    signal
  ) {
    if (current === null || current === undefined) current = 1;

    // if (this.cancelToken) {
    //   // Cancel the previous request if it is still pending
    //   this.cancelToken.cancel("Operation canceled by the user.");
    // }
    // this.cancelToken = axios.CancelToken.source();

    var bodyFormData = new FormData();
    bodyFormData.append("search", data.length > 0 ? data : "");
    bodyFormData.append("min_amount", min_amount);
    bodyFormData.append("max_amount", max_amount);
    bodyFormData.append("main", main.length > 0 ? JSON.stringify(main) : "");
    bodyFormData.append("sub", sub.length > 0 ? JSON.stringify(sub) : "");
    bodyFormData.append(
      "filters",
      gender.length > 0 ? JSON.stringify(gender) : ""
    );
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.product_search + `?page=${current}`,
      data: bodyFormData,
      //signal: signal,
      //cancelToken: this.cancelToken.token, // Pass the cancel token to the request
    });
    return Promise.resolve(result);
  }

  /**
   * size service
   */
  static sizeCheck(parentSKU, size) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.size + `?parentSKU=${parentSKU}&size=${size}`,
      data: { parentSKU, size },
    });
    return Promise.resolve(result);
  }

  /**
   * service check
   * @returns
   */
  static servicecheck(pincode) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.servicecheck + `/${pincode}`,
    });
    return Promise.resolve(result);
  }

  static getUserDetails() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getUserDetails,
    });
    return Promise.resolve(result);
  }

  /**
   * getuserinfo
   */
  static getuserinfo() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getuserinfo,
    });
    return Promise.resolve(result);
  }
}
export { ProductService };
