import * as axios from "../network/Network";

const config = require("../network/api.json");

class HomeService {
  static getBanner() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.general.getBanner,
    });
    return Promise.resolve(result);
  }
  static getWhatsapp() {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.general.whatscount,
    });
    return Promise.resolve(result);
  }
  //remarket
  static remarket() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.remarket,
    });
    return Promise.resolve(result);
  }
}
export { HomeService };
