import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ProductService } from "../services";
import { OrderService } from "../services/OrderService";

const useCountHook = () => {
  const [wishListCount, setWishlistCount] = useState();
  const [cartCount, setCartCount] = useState();
  const user_id = useSelector((state) => state?.userData?.user?._id);

  const getWishListCount = () => {
    if (user_id) {
      OrderService.getWishlist(user_id)
        .then((order) => {
          setWishlistCount(order?.data?.data?.total);
          return true;
        })
        .catch((error) => {
          console.log("Errorreg", error);
          //   setLoading(false);
          return false;
        });
    }
    // return count;
  };
  const getCartCount = () => {
    if (user_id) {
      setTimeout(() => {
        ProductService.getCart().then((res) => {
          if (res?.data?.data?.cart_count) {
            setCartCount(res?.data?.data?.cart_count);
          } else {
            // setCart();
            // handleLogin();
          }
        });
      }, 50);
    }
  };
  const getCartCountDelete = () => {
    if (user_id) {
      setTimeout(() => {
        ProductService.getCart().then((res) => {
          if (!res?.data?.data?.cart_count) {
            setCartCount(res?.data?.data?.cart_count);
          } else {
            // setCart();
            // handleLogin();
          }
        });
      }, 50);
    }
  };

  useEffect(() => {
    getCartCount();
    getWishListCount();
  }, []);

  return {
    wishListCount,
    cartCount,
    getWishListCount,
    getCartCountDelete,
  };
};

export default useCountHook;
