import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import "./Polki.css";
import { Link } from "react-router-dom";
import polki1 from "../../../assets/images/polki1.png";
import polki2 from "../../../assets/images/polki2.png";
import polki3 from "../../../assets/images/polki3.png";
import polki4 from "../../../assets/images/polki4.png";
import polki5 from "../../../assets/images/polki5.png";
import polki_pdf from "../../../assets/images/polki.pdf";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

let polkiData = {
  info: `Shop Latest Polki Collection At Best Price. Best Polki Jewellery Designs Online In India. 100% Certified Hallmark`,
};

if (window.location.href.endsWith("/New-Polki")) {
  document.title = `New Polki Collection Brocherue | Abharan Jewellers`;
}

const PolkiPage = () => {
  const polki = () => {
    return (
      <div className="polki_container flt">
        <Helmet>
          <meta name="description" content={polkiData?.info} />
        </Helmet>

        <div className="container">
          {window.location.href.endsWith("/New-Polki") ? (
            <h1 className="polki_collection">New Polki Collection</h1>
          ) : null}

          <Link to="/product-details/U1002309" className="polki_box flt">
            <LazyLoadImage src={polki1} />
          </Link>
          <Link to="/product-details/U1002330" className="polki_box flt">
            <LazyLoadImage src={polki2} />
          </Link>
          <Link to="/product-details/U1002373" className="polki_box flt">
            <LazyLoadImage src={polki3} />
          </Link>
          <Link to="/product-details/U1003866" className="polki_box flt">
            <LazyLoadImage src={polki4} />
          </Link>
          <div className="polki_box flt">
            <LazyLoadImage src={polki5} />
          </div>
          <div className="polki_btn flt">
            <Link to="/product-list/Diamond%20Polki">Shop now</Link>
            <a href={polki_pdf} target="_blank">
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };
  const renderHeader = () => {
    return <Header />;
  };
  const renderFooter = () => {
    return <Footer />;
  };
  return (
    <div className="polki_main">
      {renderHeader()}
      {polki()}
      {renderFooter()}
    </div>
  );
};
export default PolkiPage;
