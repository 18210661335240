import { SAVE_PROMO_CODE_DATA } from "./types";
import { ProductService } from "../services/ProductService";

export const promoCard = (promocode) => (dispatch) => {
  return ProductService.addPromocode(promocode).then((data) => {
    if (data.data.status.code === 0) {
      dispatch({
        type: SAVE_PROMO_CODE_DATA,
        payload: {
          promcode: data.data.data,
        },
      });
    }
    return Promise.resolve(data);
  });
};