import cartimg from "../../../assets/images/empty-cart.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { alertActions, userLogout } from "../../../actions";
import { useDispatch } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';
const EmptyCart = (tokenExpired) => {
  let history = useNavigate();
  const dispatch = useDispatch();
  const continueShopping = () => {
    history("/product-list");
  };
  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("You are logged out. Please Login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);
  const cart = () => {
    return (
      <div class="profile_container flt">
        <div class="row">
          <div class="col-sm-12">
            <div class="empty_order flt">
              <div class="empty_order_image flt">
                <LazyLoadImage src={cartimg} alt="image" />
              </div>
              <div class="empty_order_head flt">Your cart is empty</div>
              <div class="emptycart_btn flt">
                <button onClick={continueShopping}>Continue shopping</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <div>{cart()}</div>;
};
export default EmptyCart;
