import google from "../../../assets/images/google-icon.png";
import home_banner2 from "../../../assets/images/home-banner2.jpg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userLogin, googleLogin } from "../../../actions/index";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "react-google-login";
import { useLocation } from "react-router";

const clientId =
  "415782717327-bchn8sfbgsca031mrsrq03utlbkdhiga.apps.googleusercontent.com";

// const clientId =
//   "363969136192-gkq8ac5vukcid5m1sfn2k50bl5uqmus4.apps.googleusercontent.com";
const useLoginHooks = () => {
  let history = useNavigate();
  const dispatch = useDispatch();
  const [mobile_no, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loggedin, setLoggedin] = useState(false);
  const [userData, setUserData] = useState({});
  const [googleUserData, setGoogleUserData] = useState({});
  const location = useLocation();
  const pathname = localStorage.getItem("pathname");
  const [loginError, setLoginError] = useState("");

  let loginDescription = {
    info: `Log In or Sign In on Abharan official site`,
  };

  document.title = `Abharan Jewellers | Log In/Sign In`;

  useEffect(() => {
    if (userData?.data?.status?.code === 0) {
      // setMobileNo("");
      // setPassword("");
      if (location?.state?.id) {
        setTimeout(() => {
          history("/feedback" + `/${location?.state?.id}`);
        }, 1500);
      } else {
        setTimeout(() => {
          history(`${pathname}`);
        }, 1500);
      }

      setSubmitted(false);
      setLoggedin(true);
      //dispatch(alertActions.success(userData?.data?.status?.message));
    } else {
      setLoggedin(false);
      setLoginError(userData?.data?.status?.message);
      //dispatch(alertActions.error(userData?.data?.status?.message));
    }
  }, [userData]);

  useEffect(() => {
    if (googleUserData?.data?.status?.code === 0) {
      // setMobileNo("");
      // setPassword("");
      if (location?.state?.id) {
        setTimeout(() => {
          history("/feedback" + `/${location?.state?.id}`);
        }, 1500);
      } else {
        setTimeout(() => {
          history(`${pathname}`);
        }, 1500);
      }
      setLoggedin(true);
      setSubmitted(false);
      //dispatch(alertActions.success(googleUserData?.data?.status?.message));
    } else {
      setLoggedin(false);
      setLoginError(googleUserData?.data?.status?.message);
      //dispatch(alertActions.error(googleUserData?.data?.status?.message));
    }
  }, [googleUserData]);

  const onSuccess = (res) => {
    dispatch(
      googleLogin(
        res.profileObj.givenName,
        res.profileObj.googleId,
        res.profileObj.email
      )
    )
      .then((response) => {
        localStorage.setItem("expiresIn", response?.data?.data?.expires_in);
        var today = new Date();

        localStorage.setItem("loginTime", today);

        setGoogleUserData(response);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  const onFailure = (res) => {
    if (res.error === "popup_closed_by_user") {
    }
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
  });

  function handleMobileNoChange(e) {
    setLoginError(undefined);
    setMobileNo(e.target.value);
  }
  function handlePasswordChange(e) {
    setLoginError(undefined);
    setPassword(e.target.value);
  }

  function handleKeyPress(e) {}

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    if (mobile_no && password) {
      if (mobile_no && !filter.test(mobile_no)) {
        //dispatch(alertActions.error("Enter valid Mobile Number"));
        setLoginError("Enter Valid Mobile Number!!");
      } else {
        dispatch(userLogin(mobile_no, password))
          .then((response) => {
            localStorage.setItem("expiresIn", response?.data?.data?.expires_in);
            var today = new Date();
            localStorage.setItem("loginTime", today);
            setUserData(response);
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  }

  return {
    google,
    home_banner2,
    mobile_no,
    password,
    submitted,
    handleMobileNoChange,
    handlePasswordChange,
    handleSubmit,
    handleKeyPress,
    signIn,
    loginError,
    loggedin,
    loginDescription,
  };
};
export default useLoginHooks;
