import { useState, useEffect } from "react";
import { ContactService } from "../../../services/ContactService";
import { alertActions } from "../../../actions/alert.actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProductService } from "../../../services/ProductService";
import { UserService } from "../../../services";
import crypto from "crypto";

const useContact = () => {
  let history = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [district, setDistrict] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [city, setCity] = useState();
  const [add, setAdd] = useState({
    name: "",
    mobile_no: "",
    email: "",
    reason: "",
  });

  const [data, setData] = useState();

  const menuData = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [hashedData, setHashedData] = useState({});
  const [randomNumbersss, setRandomNumber] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [eventData, setEventData] = useState("");

  document.title = `Contact Us | Abharan Jewellers`;
  useEffect(() => {
    ProductService.getBranchDetails()
      .then((data) => {
        setData(data.data);
        //  window.location.reload();
      })
      .catch((error) => {
        console.log("Errorreg", error);
        //   setLoading(false);
      });
    // });

    const currentTimestamp = Math.floor(Date.now() / 1000);
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);
  }, []);

  const generateRandomNumber = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth() + 1; // Month is 0-indexed, so add 1
    // const date = currentDate.getDate();
    // const hours = currentDate.getHours();
    // const minutes = currentDate.getMinutes();
    // const seconds = currentDate.getSeconds();
    // const milliseconds = currentDate.getMilliseconds();

    // // Combine all parts into a single number
    // const randomNumber = parseInt(
    //   `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}`,
    //   10
    // );

    // return randomNumber;
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  useEffect(() => {
    const hashObject = (obj) => {
      const hashedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const hash = crypto
            .createHash("sha256")
            .update(String(value))
            .digest("hex");
          hashedObj[key] = hash;
        }
      }
      return hashedObj;
    };
    const hashedSingleObject = hashObject(menuData);
    setHashedData(hashedSingleObject);

    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashedData.email],
      ph: [hashedData.mobile_no, hashedData.alternate_number],
      ln: [hashedData.last_name],
      fn: [hashedData.first_name],
      ct: [hashedData.city, hashedData.shipping_city],
      st: [hashedData.state, hashedData.shipping_state],
      zp: [hashedData.pincode, hashedData.shipping_pincode],
    };
    setEventData({
      event_name: "Lead",
      event_time: unixTimestamp,
      action_source: "website",
      event_source_url: currentUrl,
      event_id: randomNumbersss.toString(),
      user_data: user_data,
    });
  }, [menuData, ipAddress, userAgent, unixTimestamp, randomNumbersss]);

  useEffect(() => {
    if (updatedPixelData.length) {
      UserService.getUserData(updatedPixelData)
        .then((response) => {
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }, [updatedPixelData]);

  function changeHandler(e) {
    //validation for mobile number
    //state change
    if (e.target.name === "state") {
      ContactService.getAllStates(e.target.value)
        .then((value) => {
          // alert(JSON.stringify(value.data.data.branches[0]))
          setDistrict(value.data.data);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }

    //find city
    if (e.target.name === "city") {
      ContactService.getBranches(e.target.value)
        .then((value) => {
          setCity(value.data.data.branches);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }

    //validation for email
    const { name, value } = e.target;
    setAdd((add) => ({ ...add, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (add.name && add.mobile_no && add.email && add.reason) {
      if (add && add.email && !pattern.test(add.email)) {
        dispatch(alertActions.error("Enter Valid Email Address"));
      } else if (add && add.mobile_no && !filter.test(add.mobile_no)) {
        dispatch(alertActions.error("Enter Valid Mobile Number"));
      } else {
        ContactService.add(add)
          .then((data) => {
            if (data.data.status.code === 0) {
              setAdd({
                name: "",
                mobile_no: "",
                email: "",
                reason: "",
              });

              // history.push("/");
              setSubmitted(false);
              dispatch(alertActions.success(data.data.status.message));
              const currentTimestamp = Math.floor(Date.now() / 1000);
              setUnixTimestamp(currentTimestamp);

              const newRandomNumber = generateRandomNumber();
              setRandomNumber(newRandomNumber);

              setTimeout(() => {
                if (ipAddress && userAgent && unixTimestamp) {
                  setUpdatedPixelData((prevData) => [...prevData, eventData]);
                }
              }, 100);
            }
            //  window.location.reload();
          })
          .catch((error) => {
            console.log("Errorreg", error);
            //   setLoading(false);
          });
      }
    }
  }

  return {
    history,
    dispatch,
    add,
    changeHandler,
    handleSubmit,
    submitted,
    data,
    state,
    district,
    city,
  };
};
export default useContact;
