import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import useWishlistHooks from "./useWishlistHooks";
import loader from "../../../assets/images/loader.png";
import ProfileMenu from "../../_common/ProfileMenu/ProfileMenu";
import { commaValidation } from "../../../utility";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { alertActions, userLogout } from "../../../actions";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Wishlist = (tokenExpired) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    close_icon,
    imgChange,
    lists,
    displayProduct,
    sample,
    cartPage,
    count,
    empty_wishlist,
    loading,
    wishListCount,
    cartCount,
  } = useWishlistHooks();

  const image_url = process.env.REACT_APP_MEDIA_DOMAIN; // Image url
  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("You are logged out. Please Login"));
      }, 50);
      dispatch(userLogout());
      navigate("/login");
    }
  }, [tokenExpired.tokenExpired]);
  const emptypg = () => {
    return (
      <div className="cartpg flt">
        <LazyLoadImage src={loader} alt="abharan" />
      </div>
    );
  };
  const list = () => {
    return (
      <div className="profile_container flt">
        <div className="row">
          <ProfileMenu />
          <div className="col-sm-9">
            <Helmet>
              <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="whish_main flt">
              <div className="row">
                {lists && lists.length > 0 ? (
                  lists.map((order_list) => (
                    <div className="col-sm-3">
                      <div className="wish_box flt">
                        <button
                          className="wish_close"
                          onClick={() => imgChange(order_list.product_id)}
                        >
                          <LazyLoadImage alt="abharan" src={close_icon} />
                        </button>
                        <div
                          className="wish_image flt"
                          onClick={() =>
                            displayProduct(order_list ? order_list : "")
                          }
                        >
                          {order_list?.product?.pro_images?.[0]?.image ? (
                            <LazyLoadImage
                              src={`${image_url}${
                                order_list &&
                                order_list?.product?.pro_images?.[0]?.image
                                  ? order_list?.product?.pro_images?.[0]?.image
                                  : ""
                              }`}
                              alt="abharan"
                            />
                          ) : (
                            <LazyLoadImage
                              src={sample}
                              alt="abharan"
                              className="example"
                            />
                          )}
                        </div>
                        <div className="wish_txt flt">
                          <div className="wish_desc flt">
                            <span>
                              {order_list &&
                              order_list.product &&
                              order_list.product.name
                                ? order_list.product.name
                                : " 0 "}
                            </span>
                            <span>
                              {order_list &&
                              order_list.product &&
                              order_list.product.pro_details &&
                              order_list.product.pro_details.weight &&
                              order_list.product.pro_details.weight !== 0 &&
                              order_list.product.pro_details.weight !== "0"
                                ? order_list.product.pro_details.weight
                                : " NA "}
                              {order_list?.product?.pro_details?.weight &&
                              order_list?.product?.pro_details?.weight !== 0 &&
                              order_list?.product?.pro_details?.weight !== "0"
                                ? "GM"
                                : ""}
                            </span>
                          </div>
                          <div className="wish_rate flt">
                            <b>₹</b>
                            {commaValidation(
                              order_list &&
                                order_list.product &&
                                order_list.product.c_gross_value
                                ? order_list.product.c_gross_value
                                : " 0 "
                            )}
                          </div>
                          <div className="wish_btn flt">
                            <button
                              onClick={() =>
                                cartPage(order_list ? order_list : "")
                              }
                            >
                              MOVE TO CART
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div class="empty_order flt">
                    <div class="empty_order_image flt">
                      <LazyLoadImage src={empty_wishlist} alt="abharan" />
                    </div>
                    <div class="empty_order_head flt">
                      Your Wishlist is empty
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderHeader = () => {
    return <Header count={wishListCount} cart={cartCount} />;
  };
  const renderFooter = () => {
    return <Footer />;
  };
  return (
    <div>
      {renderHeader()}
      {loading === "notempty" && list()}
      {loading === "loading" && emptypg()}
      {renderFooter()}
    </div>
  );
};

export default Wishlist;
