import Slider from "react-slick";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CategoryContainer = (props) => {
  const categoryBox = useSelector((state) => state.abData.category);
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN; // Image url

  var catregorySlider = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div class="category_container flt">
      <div class="arrow_head flt">
        <span>Categories</span>
      </div>

      {/* href={"product-list/"+category_list.title} */}
      <div class="catregory_cont flt">
        <Slider {...catregorySlider}>
          {categoryBox
            ? categoryBox.map((category_list) => (
                <div class="category_box flt" key={category_list._id}>
                  <a
                    onClick={() =>
                      props.onTopMainCategories(category_list.title)
                    }
                  >
                    <div class="category_image flt">
                      <LazyLoadImage
                        src={image_url + category_list.image}
                        alt="image"
                        width="278px"
                        height="140px"
                      />
                    </div>
                    <div class="category_name flt">{category_list.title}</div>
                  </a>
                </div>
              ))
            : null}
        </Slider>
      </div>
    </div>
  );
};
export default CategoryContainer;
