import { useNavigate, useLocation } from "react-router-dom";
import { ProductService } from "../../../services/ProductService";
import { useEffect, useState } from "react";
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch, useSelector } from "react-redux";
import { OrderService } from "../../../services/OrderService";
import { userLogout } from "../../../actions";
import { promoCard } from "../../../actions/PromocodeAction";
import sample from "../../../assets/images/default.png";
import useCountHook from "../../../hooks/useCountHook";
import { UserService } from "../../../services";
import crypto from "crypto";
import TagManager from "react-gtm-module";

const UseCartHooks = () => {
  const user_id = useSelector((state) => state?.userData?.user?._id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usr_id = useSelector((state) => state.userData.user._id);
  let history = useNavigate();
  const location = useLocation();
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
  const [cart, setCart] = useState("");
  const [cartdata, setCartData] = useState();
  const [prodList, setProdList] = useState();
  const [promo, setPromo] = useState();
  const [promodata, setPromoData] = useState();
  const [promocode, setPromoCode] = useState(0);
  const [count, setCount] = useState();
  const [cartval, setCartVal] = useState(false);
  const [loading, setLoading] = useState("loading");
  const [prodValue, setProdValue] = useState();
  const { wishListCount, cartCount, getCartCountDelete } = useCountHook();

  const menuData = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [randomNumbersss, setRandomNumber] = useState("");
  const [hashedData, setHashedData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [userAgent, setUserAgent] = useState("");

  useEffect(() => {
    // value: cartdata?.product?.final_amt.toString(),
    // content_category: cartdata?.product?.pro_details?.description,
    // content_ids: cartdata?.product?.SKU,

    const currentTimestamp = Math.floor(Date.now() / 1000);
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);
  }, []);

  useEffect(() => {
    //wishListCount();
    //cartCount();
    //cartCountForDelete();
    if (user_id) {
      ProductService.getCart()
        .then((cartdata) => {
          if (cartdata?.data?.data?.cart) {
            setCartData(cartdata?.data?.data?.cart);
            setLoading("notempty");
          } else {
            setLoading("empty");
          }
          //  window.location.reload();
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }, [promocode, count, cart, cartval]);

  const generateRandomNumber = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth() + 1;
    // const date = currentDate.getDate();
    // const hours = currentDate.getHours();
    // const minutes = currentDate.getMinutes();
    // const seconds = currentDate.getSeconds();
    // const milliseconds = currentDate.getMilliseconds();

    // const randomNumber = parseInt(
    //   `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}`,
    //   10
    // );

    // return randomNumber;
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  useEffect(() => {
    const hashObject = (obj) => {
      const hashedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const hash = crypto
            .createHash("sha256")
            .update(String(value))
            .digest("hex");
          hashedObj[key] = hash;
        }
      }
      return hashedObj;
    };
    const hashedSingleObject = hashObject(menuData);
    setHashedData(hashedSingleObject);

    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashedData.email],
      ph: [hashedData.mobile_no, hashedData.alternate_number],
      ln: [hashedData.last_name],
      fn: [hashedData.first_name],
      ct: [hashedData.city, hashedData.shipping_city],
      st: [hashedData.state, hashedData.shipping_state],
      zp: [hashedData.pincode, hashedData.shipping_pincode],
    };

    const custom_data = {
      currency: "INR",
      value: cartdata?.product?.final_amt.toString(),
      content_category: cartdata?.product?.pro_details?.description,
      content_ids: cartdata?.product?.SKU,
    };

    const eventData = [
      {
        event_name: "AddToCart",
        event_time: unixTimestamp,
        action_source: "website",
        event_source_url: currentUrl,
        event_id: randomNumbersss.toString(),
        user_data: user_data,
        custom_data: custom_data,
      },
    ];

    // if (ipAddress && userAgent) {
    //   setUpdatedPixelData((prevData) => [...prevData, eventData]);
    // }

    if (cartdata !== undefined) {
      UserService.getUserData(eventData)
        .then((response) => {
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }

    if (cartdata !== undefined) {
      const productData = {
        id: cartdata?.product?.SKU,
        name: cartdata?.product?.name,
        price: cartdata?.product?.c_total.toString(),
        quantity: 1,
        category: cartdata?.product?.name,
        currency: "INR",
      };

      TagManager.dataLayer({
        dataLayer: {
          event: "addtocart",
          // ecommerce: {
          //   currencyCode: productData.currency,
          //   add: {
          //     products: [productData],
          //   },
          // },
          ecommerce: productData,
          pagePath: location.pathname,
        },
      });
      console.log(window.dataLayer, "DataLayer");
    }
  }, [cartdata]);

  // useEffect(() => {
  //   if (updatedPixelData.length) {
  //     UserService.getUserData(updatedPixelData)
  //       .then((response) => {
  //         //setGetOtpData(response);
  //       })
  //       .catch((error) => {
  //         console.log("Errorreg", error);
  //       });
  //   }
  // }, [updatedPixelData]);

  const handleLogout = () => {
    dispatch(userLogout());
    history("/");
    //dispatch(alertActions.success("Logout Success"));
  };

  const handleLogin = () => {
    history("/login");
  };

  const deleteCart = (prod) => {
    ProductService.deleteCart(prod._id)
      .then((prodList) => {
        setCartVal(true);
        setProdList(prodList);
        getCartCountDelete();
        //  window.location.reload();
        dispatch(alertActions.success(prodList.data.status.message));
        setCartData("");
        setLoading("empty");
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };
  const deleteCart1 = (prod) => {
    ProductService.deleteCart(prod._id)
      .then((prodList) => {
        setCartVal(true);
        setProdList(prodList);
        cartCountForDelete();
        //  window.location.reload();
        // dispatch(alertActions.success(prodList.data.status.message));
        setCartData("");
        setLoading("empty");
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  const deletePromo = (promo) => {
    ProductService.deletePromo(promo)
      .then((response) => {
        if (response.data.status.code === 0) {
          setPromoCode(1);
          setPromoData("");
        }
        //  window.location.reload();
      })
      .catch((error) => {
        console.log("Errorreg", error);
        //   setLoading(false);
      });
  };

  const cartCountForDelete = () => {
    if (user_id) {
      ProductService.getCart()
        .then((res) => {
          if (!res?.data?.data?.cart_count) {
            setCart(res?.data?.data?.cart_count);
          } else {
            // setCart();
          }
          return cart;

          //  window.location.reload();
        })
        .catch((error) => {
          console.log("Errorreg", error);
          //   setLoading(false);
        });
    }
  };

  const displayProduct = (display) => {
    const prodNameData = display?.product?.name.replace(/\s+/g, "-");
    const baseProductName = display?.product?.name.split(" ")[0].toLowerCase();
    navigate(
      `/product-details/${baseProductName}/${prodNameData.toLowerCase()}/${display?.product?.SKU.toLowerCase()}`,
      {
        state: { id: display?.product?.SKU, userid: usr_id },
      }
    );
    setProdValue(display);
  };

  const imgChange = (prod) => {
    deleteCart1(prod);
    if (prod.wish_status === 1) {
      history("/wishlist");
    } else {
      if (usr_id) {
        ProductService.getProdDetails(
          prod?.product?.pro_details?.product_id,
          usr_id
        ).then((prodList) => {
          setProdList(prodList);
          if (prodList.data.status.code === 0) {
            dispatch(alertActions.error(prodList.status.message));
            setTimeout(() => dispatch(alertActions.clear()), 3000);
            const newRandomNumber = generateRandomNumber();
            setRandomNumber(newRandomNumber);
            function getCookie(name) {
              const cookieValue = document.cookie.match(
                "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
              );
              return cookieValue ? cookieValue.pop() : "";
            }

            // Fetch the values of fbp and fbc cookies
            const fbpValue = getCookie("_fbp");
            const fbcValue = getCookie("_fbc");

            var currentUrl = window.location.href;

            const user_data = {
              client_ip_address: ipAddress,
              client_user_agent: userAgent,
              fbp: fbpValue,
              fbc: fbcValue,
              em: [hashedData.email],
              ph: [hashedData.mobile_no, hashedData.alternate_number],
              ln: [hashedData.last_name],
              fn: [hashedData.first_name],
              ct: [hashedData.city, hashedData.shipping_city],
              st: [hashedData.state, hashedData.shipping_state],
              zp: [hashedData.pincode, hashedData.shipping_pincode],
            };

            const custom_data = {
              currency: "INR",
              value: cartdata?.product?.c_total.toString(),
              content_category: cartdata?.product?.pro_details?.description,
              content_ids: cartdata?.product?.SKU,
            };
            if (cartdata?.product.wish_status !== 1) {
              if (ipAddress && userAgent) {
                const updatedPixelDataUpdater = [
                  {
                    event_name: "AddToWishlist",
                    event_time: unixTimestamp,
                    action_source: "website",
                    event_source_url: currentUrl,
                    event_id: randomNumbersss.toString(),
                    user_data: user_data,
                    custom_data: custom_data,
                  },
                ];

                if (cartdata?.product !== undefined) {
                  const productData = {
                    id: cartdata?.product?.SKU,
                    name: cartdata?.product?.name,
                    price: cartdata?.product?.c_total.toString(),
                    quantity: 1,
                    category: cartdata?.product?.pro_details?.description,
                    currency: "INR",
                  };

                  TagManager.dataLayer({
                    dataLayer: {
                      event: "addtowishlist",
                      // ecommerce: {
                      //   currencyCode: productData.currency,
                      //   add: {
                      //     products: [productData],
                      //   },
                      // },
                      ecommerce: productData,
                      pagePath: location.pathname,
                    },
                  });
                  console.log(window.dataLayer, "DataLayer");
                }

                UserService.getUserData(updatedPixelDataUpdater)
                  .then((response) => {
                    //setGetOtpData(response);
                  })
                  .catch((error) => {
                    console.log("Errorreg", error);
                  });
              }
            }
            history("/wishlist");
          } else {
            dispatch(alertActions.error(prodList.data.status.message));
            const newRandomNumber = generateRandomNumber();
            setRandomNumber(newRandomNumber);
            function getCookie(name) {
              const cookieValue = document.cookie.match(
                "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
              );
              return cookieValue ? cookieValue.pop() : "";
            }

            // Fetch the values of fbp and fbc cookies
            const fbpValue = getCookie("_fbp");
            const fbcValue = getCookie("_fbc");

            var currentUrl = window.location.href;

            const user_data = {
              client_ip_address: ipAddress,
              client_user_agent: userAgent,
              fbp: fbpValue,
              fbc: fbcValue,
              em: [hashedData.email],
              ph: [hashedData.mobile_no, hashedData.alternate_number],
              ln: [hashedData.last_name],
              fn: [hashedData.first_name],
              ct: [hashedData.city, hashedData.shipping_city],
              st: [hashedData.state, hashedData.shipping_state],
              zp: [hashedData.pincode, hashedData.shipping_pincode],
            };

            const custom_data = {
              currency: "INR",
              value: cartdata?.product?.c_total.toString(),
              content_category: cartdata?.product?.pro_details?.description,
              content_ids: cartdata?.product?.SKU,
            };
            if (cartdata?.product.wish_status !== 1) {
              if (ipAddress && userAgent) {
                const updatedPixelDataUpdater = [
                  {
                    event_name: "AddToWishlist",
                    event_time: unixTimestamp,
                    action_source: "website",
                    event_source_url: currentUrl,
                    event_id: randomNumbersss.toString(),
                    user_data: user_data,
                    custom_data: custom_data,
                  },
                ];

                if (cartdata?.product !== undefined) {
                  const productData = {
                    id: cartdata?.product?.SKU,
                    name: cartdata?.product?.name,
                    price: cartdata?.product?.c_total.toString(),
                    quantity: 1,
                    category: cartdata?.product?.pro_details?.description,
                    currency: "INR",
                  };

                  TagManager.dataLayer({
                    dataLayer: {
                      event: "addtowishlist",
                      // ecommerce: {
                      //   currencyCode: productData.currency,
                      //   add: {
                      //     products: [productData],
                      //   },
                      // },
                      ecommerce: productData,
                      pagePath: location.pathname,
                    },
                  });
                  console.log(window.dataLayer, "DataLayer");
                }

                UserService.getUserData(updatedPixelDataUpdater)
                  .then((response) => {
                    //setGetOtpData(response);
                  })
                  .catch((error) => {
                    console.log("Errorreg", error);
                  });
              }
            }
            history("/wishlist");
          }
        });
      }
    }
  };
  const handleChange = (e) => {
    setPromo(e.target.value);
  };

  const checkOutPage = (gtotal) => {
    navigate("/shipping-address", {
      state: { product: cartdata, amount: gtotal },
    });
  };
  const submitPromo = () => {
    dispatch(promoCard(promo))
      .then((promodata) => {
        setPromoData(promodata);
        if (promodata.data.status.code === 1) {
          dispatch(alertActions.error(promodata?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
        //   setLoading(false);
      });
  };

  return {
    cart,
    image_url,
    imgChange,
    checkOutPage,
    promo,
    handleChange,
    submitPromo,
    deleteCart,
    promodata,
    deletePromo,
    handleLogout,
    handleLogin,
    cartdata,
    loading,
    sample,
    displayProduct,
    prodList,
    wishListCount,
    cartCount,
  };
};
export default UseCartHooks;
