import { useLocation } from "react-router-dom";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import useShippingAddressHook from "./useShippingAddressHook";
import "./ShippingAddress.css";
import { useRazorpay } from "../RazorPay/useRazorPay";
import CustomCheckbox from "./CustomCheckbox";
import upload_icon from "../../../assets/images/upload-icon.png";
import { commaValidation } from "../../../utility";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { alertActions, userLogout } from "../../../actions";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useCountHook from "../../../hooks/useCountHook";

const ShippingAddress = (tokenExpired) => {
  const location = useLocation();
  let history = useNavigate();
  const dispatch = useDispatch();
  let { displayRazorpay } = useRazorpay();

  const {
    name,
    mobile,
    alternate_mobile,
    billing_address,
    billing_city,
    billing_district,
    billing_landmarkValue,
    billing_locality,
    billing_pincode,
    billing_state,
    address,
    locality,
    city,
    state,
    landmarkValue,
    pincode,
    formattedDate,
    pan_no,
    isCheckedAddress,
    changeNameHandler,
    changeMobileNoHandler,
    changeAlternateMobileNoHandler,
    changeAddressHandler,
    changeLocalityHandler,
    changeCityHandler,
    changeStateHandler,
    changeLandMarkHandler,
    changePincodeHandler,
    changePancardHandler,
    changeBillingAddressHandler,
    changeBillingCityHandler,
    changeBillingDistrictHandler,
    changeBillingLandMarkHandler,
    changeBillingLocalityHandler,
    changeBillingPincodeHandler,
    changeBillingStateHandler,
    changeCheckboxHandler,
    changeLastNameHandler,
    saveAddress,
    lname,
    check,
    submitted,
    handleSubmit,
    onSelectFile,
    image,
    selectedFile,
    storeState,
    billingStoreDistrict,
    charge,
    storeDistrict,
    district,
    changeDistrictHandler,
    handleClose,
    showButton,
    paymentMode,
    fileName,
    changePaymentModeHandler,
    changeAcceptCheckboxHandler,
    acceptCheck,
    btnshow,
    deliverable,
    deliverydate,
  } = useShippingAddressHook(location, displayRazorpay);
  const { wishListCount, cartCount } = useCountHook();

  let notDeliverable = null;
  if (deliverable === false) {
    notDeliverable = true;
  }

  let total_amount = 0;
  if (charge === 0 || charge === undefined) {
    var gstAmount = Number(
      (location?.state?.product?.product?.c_gst_amount).replace(",", "")
    );
    var res_total =
      Number((location?.state?.amount).replace(",", "")) -
      Number(
        (location?.state?.product?.product?.c_gst_amount).replace(",", "")
      );

    total_amount =
      res_total +
      gstAmount +
      Number(location?.state?.product?.product?.c_offer_value);
    total_amount = Number(total_amount).toFixed(2);
  } else {
    var gstAmount =
      Number(
        (location?.state?.product?.product?.c_gst_amount).replace(",", "")
      ) +
      Number(charge) * 0.03;
    var res_total =
      Number((location?.state?.amount).replace(",", "")) -
      Number(
        (location?.state?.product?.product?.c_gst_amount).replace(",", "")
      );

    total_amount =
      res_total +
      Number(charge) +
      gstAmount +
      Number(location?.state?.product?.product?.c_offer_value);
    total_amount = Number(total_amount).toFixed(2);
  }

  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("You are Logged out. Please Login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);
  const personalDetails = () => {
    return (
      <div className="shipping_container flt">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="shipping_cont flt">
          <div className="row shipping_fields">
            <div className="col-sm-8 shipping_left">
              <div className="shipping_head flt">Personal Details</div>
              <div className="row pr-5">
                <div className="col-sm-4">
                  <div className="form-group flt">
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      value={name}
                      onChange={changeNameHandler}
                      className={submitted && !name ? " is-invalid" : ""}
                    />
                    {submitted && !name && (
                      <div className="invalid-feedback">Name is required</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group flt">
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      name="lname"
                      value={lname}
                      onChange={changeLastNameHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group flt">
                    <label>Phone</label>
                    <input
                      type="number"
                      placeholder="Enter Phone Number"
                      name="mobile"
                      value={mobile}
                      onChange={changeMobileNoHandler}
                      className={submitted && !mobile ? " is-invalid" : ""}
                      onWheel={(e) => e.currentTarget.blur()}
                    />

                    {submitted && !mobile && (
                      <div className="invalid-feedback">
                        Phone Number is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group flt">
                    <label>Alternate Phone</label>
                    <input
                      type="number"
                      placeholder="Enter Alternate Phone Number"
                      name="alternateMobile"
                      value={alternate_mobile}
                      onChange={changeAlternateMobileNoHandler}
                      className={
                        submitted && !alternate_mobile ? " is-invalid" : ""
                      }
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                    {submitted && !alternate_mobile && (
                      <div className="invalid-feedback">
                        Alternate Number is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="shipping_head flt">Billing Address</div>
              <div className="row pr-5">
                <div className="col-sm-4">
                  <div className="form-group flt">
                    <label>Address</label>
                    <input
                      type="text"
                      placeholder="Enter Address"
                      name="billing_address"
                      value={billing_address}
                      onChange={changeBillingAddressHandler}
                      className={
                        submitted && !billing_address ? " is-invalid" : ""
                      }
                    />
                    {submitted && !billing_address && (
                      <div className="invalid-feedback">
                        Address is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group flt">
                    <label>Locality</label>
                    <input
                      type="text"
                      placeholder="Enter Locality"
                      name="billing_locality"
                      value={billing_locality}
                      onChange={changeBillingLocalityHandler}
                      className={
                        submitted && !billing_locality ? " is-invalid" : ""
                      }
                    />
                    {submitted && !billing_locality && (
                      <div className="invalid-feedback">
                        Locality is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group flt">
                    <label>City</label>
                    <input
                      type="text"
                      placeholder="Enter City"
                      name="billing_city"
                      value={billing_city}
                      onChange={changeBillingCityHandler}
                      className={
                        submitted && !billing_city ? " is-invalid" : ""
                      }
                    />
                    {submitted && !billing_city && (
                      <div className="invalid-feedback">City is required</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-4">
                  <label>Select State</label>
                  <div className="form-group flt">
                    <select
                      name="billing_state"
                      value={billing_state}
                      className={
                        submitted && !billing_state ? " is-invalid" : ""
                      }
                      onChange={changeBillingStateHandler}
                    >
                      <option>Select State</option>
                      {storeState &&
                        storeState.map((state) => (
                          <option key={state._id}>{state.state}</option>
                        ))}
                    </select>
                    {submitted && !billing_state && (
                      <div className="invalid-feedback">State is required</div>
                    )}
                  </div>
                </div>
                {billingStoreDistrict?.[0]?.district && (
                  <div className="col-sm-4">
                    <label>Select District</label>

                    <div className="form-group flt">
                      <select
                        name="billing_district"
                        value={billing_district}
                        onChange={changeBillingDistrictHandler}
                        className={
                          submitted && !billing_district ? " is-invalid" : ""
                        }
                      >
                        <option>Select District</option>
                        {billingStoreDistrict &&
                          billingStoreDistrict.map((district) => (
                            <option key={district._id}>
                              {district.district}
                            </option>
                          ))}
                      </select>
                      {submitted && !billing_district && (
                        <div className="invalid-feedback">
                          District is required
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-sm-4">
                  <div className="form-group flt">
                    <label>Landmark</label>
                    <input
                      type="text"
                      placeholder="Enter Landmark"
                      name="billing_landmarkValue"
                      value={billing_landmarkValue}
                      onChange={changeBillingLandMarkHandler}
                      className={
                        submitted && !billing_landmarkValue ? " is-invalid" : ""
                      }
                    />
                    {submitted && !billing_landmarkValue && (
                      <div className="invalid-feedback">
                        Landmark is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group flt">
                    <label>Pincode</label>
                    <input
                      type="number"
                      placeholder="Enter Pincode"
                      name="billing_pincode"
                      value={billing_pincode}
                      onChange={changeBillingPincodeHandler}
                      className={
                        submitted && !billing_pincode ? " is-invalid" : ""
                      }
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                    {submitted && !billing_pincode && (
                      <div className="invalid-feedback">
                        Pincode is required
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="password_check flt">
                <input
                  type="checkbox"
                  id="password"
                  name="check"
                  value={check}
                  onClick={changeCheckboxHandler}
                  checked={check}
                />
                <label for="password">Address is same as above</label>
              </div>

              <div className="shipping_head flt">Shipping Address</div>
              {check === false ? (
                <div className="row pr-5">
                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>Address</label>
                      <input
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        value={address}
                        onChange={changeAddressHandler}
                        className={submitted && !address ? " is-invalid" : ""}
                      />
                      {submitted && !address && (
                        <div className="invalid-feedback">
                          Address is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>Locality</label>
                      <input
                        type="text"
                        placeholder="Enter Locality"
                        name="locality"
                        value={locality}
                        onChange={changeLocalityHandler}
                        className={submitted && !locality ? " is-invalid" : ""}
                      />
                      {submitted && !locality && (
                        <div className="invalid-feedback">
                          Locality is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>City</label>
                      <input
                        type="text"
                        placeholder="Enter City"
                        name="city"
                        value={city}
                        onChange={changeCityHandler}
                        className={submitted && !city ? " is-invalid" : ""}
                      />
                      {submitted && !city && (
                        <div className="invalid-feedback">City is required</div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <label>Select State </label>
                    <div className="form-group flt">
                      <select
                        name="state"
                        value={state}
                        className={submitted && !state ? " is-invalid" : ""}
                        onChange={changeStateHandler}
                      >
                        <option>Select State</option>
                        {storeState &&
                          storeState.map((state) => (
                            <option key={state._id}>{state.state}</option>
                          ))}
                      </select>
                      {submitted && !state && (
                        <div className="invalid-feedback">
                          State is required
                        </div>
                      )}
                    </div>
                  </div>
                  {storeDistrict?.[0]?.district && (
                    <div className="col-sm-4">
                      <label>Select District </label>
                      <div className="form-group flt">
                        <select
                          name="district"
                          value={district}
                          onChange={changeDistrictHandler}
                          className={
                            submitted && !district ? " is-invalid" : ""
                          }
                        >
                          <option>Select District</option>
                          {storeDistrict &&
                            storeDistrict.map((district) => (
                              <option key={district._id}>
                                {district.district}
                              </option>
                            ))}
                        </select>
                        {submitted && !district && (
                          <div className="invalid-feedback">
                            District is required
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>Landmark</label>
                      <input
                        type="text"
                        placeholder="Enter Landmark"
                        name="landmarkValue"
                        value={landmarkValue}
                        onChange={changeLandMarkHandler}
                        className={
                          submitted && !landmarkValue ? " is-invalid" : ""
                        }
                      />
                      {submitted && !landmarkValue && (
                        <div className="invalid-feedback">
                          Landmark is required
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>Pincode</label>
                      <div className="xmark_input flt">
                        <input
                          type="number"
                          placeholder="Enter Pincode"
                          name="pincode"
                          value={pincode}
                          onChange={changePincodeHandler}
                          className={
                            (submitted && !pincode) ||
                            deliverable ||
                            notDeliverable
                              ? " is-invalid"
                              : ""
                          }
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                        {submitted && !pincode && (
                          <div className="invalid-feedback">
                            Pincode is required
                          </div>
                        )}
                        {deliverable && (
                          <div className="invalid-feedback">
                            Sorry! Pincode is not serviceable
                          </div>
                        )}
                        {notDeliverable && (
                          <div className="invalid-feedback valid-feedback">
                            Pincode is serviceable
                          </div>
                        )}

                        <div className="xmark">
                          {pincode.length === 6 && deliverable !== null ? (
                            <CustomCheckbox isChecked={deliverable} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>Delivery Charge</label>
                      <input
                        type="text"
                        placeholder="Enter Delivery Charge"
                        name="delivery_charge"
                        value={charge}
                        className="disable"
                      />
                    </div>
                  </div>
                  {Number(total_amount) > 200000 && (
                    <div className="col-sm-4">
                      <div className="form-group flt">
                        <label>Pan Card Number</label>
                        <input
                          type="text"
                          placeholder="Enter Pancard Number"
                          name="pan_no"
                          value={pan_no}
                          onChange={changePancardHandler}
                          className={submitted && !pan_no ? " is-invalid" : ""}
                        />
                        {submitted && Number(total_amount) > 200000 && (
                          <div className="invalid-feedback">
                            Pancard Number is required
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {Number(total_amount) > 200000 && (
                    <div className="col-sm-4">
                      <div className="form-group flt">
                        <label>Upload document front page</label>

                        <div className="upload-btn-wrapper flt">
                          <button>
                            {!selectedFile && (
                              <LazyLoadImage src={upload_icon} />
                            )}
                            {selectedFile && (
                              <LazyLoadImage
                                src={image}
                                width="100%"
                                height="270"
                              />
                            )}
                          </button>

                          <input
                            type="file"
                            onChange={onSelectFile}
                            name="myfile"
                            key={fileName}
                            accept="image/*"
                            className={submitted && !image ? " is-invalid" : ""}
                          />
                          {submitted && Number(total_amount) > 200000 && (
                            <div className="invalid-feedback">
                              Please Upload the Pancard Image
                            </div>
                          )}
                        </div>

                        {showButton && image && (
                          <div className="upload_file_box flt">
                            <div className="upload_file_name flt">
                              {fileName}
                            </div>
                            <div className="upload_file_desc flt">
                              <span>Successfully uploaded</span>
                              {showButton && image && (
                                <button
                                  onClick={handleClose}
                                  className="uploadfile_close"
                                >
                                  <i
                                    className="fa fa-times-circle"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-sm-4"></div>
                  <div className="col-sm-12">
                    {Number(total_amount) > 200000 && (
                      <div className="password_check flt">
                        <input
                          type="checkbox"
                          id="password1"
                          name="acceptCheck"
                          value={acceptCheck}
                          onClick={changeAcceptCheckboxHandler}
                          checked={acceptCheck}
                          className={
                            submitted && !acceptCheck ? " is-invalid" : ""
                          }
                        />

                        <label for="password1">
                          I here by confirm that the details provided by me are
                          true and correct
                        </label>
                        <div className="invalid-feedback ">
                          Select the terms and condition
                        </div>
                      </div>
                    )}
                  </div>

                  {Number(total_amount) > 200000 && (
                    <div className="col-sm-12">
                      <div className="shipping_note flt">
                        <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
                        FOR TRANSACTION SUMS ABOVE Rs. 2,00,000 (TWO LAKH
                        RUPEES), SUBMISSION OF PAN CARD PROOF IS MANDATORY
                      </div>
                    </div>
                  )}
                  <div className="col-sm-8">
                    <div className="shipping_address flt">
                      <input
                        type="checkbox"
                        id="save_address"
                        name="check"
                        value={isCheckedAddress}
                        checked={isCheckedAddress}
                        onChange={saveAddress}
                      />
                      <label for="save_address">
                        Save Address for future reference
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row pr-5">
                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>Address</label>
                      <input
                        type="text"
                        name="address"
                        disabled={true}
                        className="input_box_color"
                        value={address}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>Locality</label>
                      <label className="input_label flt">{locality}</label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>City</label>
                      <label className="input_label flt">{city}</label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <label>Select State</label>
                    <label className="input_label flt">{billing_state}</label>
                  </div>

                  {billing_district !== "" && (
                    <div className="col-sm-4">
                      <label>Select District </label>
                      <label className="input_label flt">
                        {billing_district}
                      </label>
                    </div>
                  )}

                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>Landmark</label>
                      <label className="input_label flt">{landmarkValue}</label>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>Pincode</label>
                      <div className="xmark_input flt">
                        <input
                          type="number"
                          name="pincode"
                          value={pincode}
                          onChange={changePincodeHandler}
                          disabled={true}
                          className={
                            (submitted && !pincode) ||
                            deliverable ||
                            notDeliverable
                              ? " is-invalid"
                              : ""
                          }
                          onWheel={(e) => e.currentTarget.blur()}
                        />

                        {deliverable && (
                          <div className="invalid-feedback">
                            Sorry! Pincode is not serviceable
                          </div>
                        )}
                        {notDeliverable && (
                          <div className="invalid-feedback valid-feedback">
                            Pincode is serviceable
                          </div>
                        )}

                        <div className="xmark">
                          {pincode.length === 6 && deliverable !== null ? (
                            <CustomCheckbox isChecked={deliverable} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group flt">
                      <label>Delivery Charge</label>
                      <input
                        type="text"
                        placeholder="Enter Delivery Charge"
                        name="delivery_charge"
                        value={charge}
                        className="disable"
                      />
                    </div>
                  </div>
                  {Number(total_amount) > 200000 && (
                    <div className="col-sm-4">
                      <div className="form-group flt">
                        <label>Pan Card Number</label>
                        <input
                          type="text"
                          placeholder="Enter Pancard Number"
                          name="pan_no"
                          value={pan_no}
                          onChange={changePancardHandler}
                          className={submitted && !pan_no ? " is-invalid" : ""}
                        />
                        {submitted && Number(total_amount) > 200000 && (
                          <div className="invalid-feedback">
                            Pancard Number is required
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {Number(total_amount) > 200000 && (
                    <div className="col-sm-4">
                      <div className="form-group flt">
                        <label>Upload document front pagesss</label>

                        <div className="upload-btn-wrapper flt">
                          <button>
                            {!selectedFile && (
                              <LazyLoadImage src={upload_icon} />
                            )}
                            {selectedFile && (
                              <LazyLoadImage
                                src={image}
                                width="100%"
                                height="270"
                              />
                            )}
                          </button>

                          <input
                            type="file"
                            onChange={onSelectFile}
                            name="myfile"
                            key={fileName}
                            accept="image/*"
                            className={submitted && !image ? " is-invalid" : ""}
                          />
                          {submitted && Number(total_amount) > 200000 && (
                            <div className="invalid-feedback">
                              Please Upload the Pancard Image
                            </div>
                          )}
                        </div>

                        {showButton && image && (
                          <div className="upload_file_box flt">
                            <div className="upload_file_name flt">
                              {fileName}
                            </div>
                            <div className="upload_file_desc flt">
                              <span>Successfully uploaded</span>
                              {showButton && image && (
                                <button
                                  onClick={handleClose}
                                  className="uploadfile_close"
                                >
                                  <i
                                    className="fa fa-times-circle"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-sm-4"></div>
                  <div className="col-sm-12">
                    {Number(total_amount) > 200000 && (
                      <div className="password_check flt">
                        <input
                          type="checkbox"
                          id="password1"
                          name="acceptCheck"
                          value={acceptCheck}
                          onClick={changeAcceptCheckboxHandler}
                          checked={acceptCheck}
                          className={
                            submitted && !acceptCheck ? " is-invalid" : ""
                          }
                        />

                        <label for="password1">
                          I here by confirm that the details provided by me are
                          true and correct
                        </label>
                        <div className="invalid-feedback ">
                          Select the terms and condition
                        </div>
                      </div>
                    )}
                  </div>

                  {Number(total_amount) > 200000 && (
                    <div className="col-sm-12">
                      <div className="shipping_note flt">
                        <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
                        FOR TRANSACTION SUMS ABOVE Rs. 2,00,000 (TWO LAKH
                        RUPEES), SUBMISSION OF PAN CARD PROOF IS MANDATORY
                      </div>
                    </div>
                  )}
                  <div className="col-sm-8">
                    <div className="shipping_address flt">
                      <input
                        type="checkbox"
                        id="save_address"
                        name="check"
                        value={isCheckedAddress}
                        checked={isCheckedAddress}
                        onChange={saveAddress}
                      />
                      <label for="save_address">
                        Save Address for future reference
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-4 shipping_right">
              <div className="row shipp_box">
                <div className="col-sm-12">
                  <ul className="shipping_list flt">
                    <li>
                      Total [{location?.state?.product?.quantity}Item]
                      <span>
                        <b> ₹ </b>
                        {commaValidation(res_total.toFixed(2))}
                      </span>
                    </li>
                    <li>
                      Discount
                      <span>
                        <b> ₹ </b>
                        {commaValidation(
                          Number(
                            location?.state?.product?.product?.c_offer_value
                          )
                        )}
                      </span>
                    </li>
                    <li>
                      Delivery charges
                      <span>
                        <b> ₹ </b>

                        {charge ? charge : 0}
                      </span>
                    </li>
                    <li>
                      GST(3%)
                      <span>
                        <b> ₹ </b>
                        {gstAmount.toFixed(2)}
                      </span>
                    </li>
                    <li>
                      Grand Total
                      <span>
                        <b> ₹ </b>
                        {commaValidation(total_amount)}
                      </span>
                    </li>
                  </ul>
                  <div className="est_date flt">
                    Estimated Date :{" "}
                    {deliverydate ? formattedDate.split(" ")[0] : ""}
                  </div>

                  <div className="shipping_mode flt">
                    <div className="shipping_mode_head flt">Payment Mode</div>

                    <div className="shipping_mode_cont flt">
                      <input
                        type="radio"
                        id="online"
                        name="paymentMode"
                        checked={paymentMode === "1" ? true : false}
                        value="1"
                        onChange={changePaymentModeHandler}
                      />
                      <label for="online">Online Payment</label>
                    </div>
                  </div>
                  <button
                    className={
                      btnshow === false
                        ? "shipping_btn flt"
                        : "shipping_btn shipping_btn1 flt"
                    }
                    onClick={handleSubmit}
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header count={wishListCount} cart={cartCount} />
      {personalDetails()}
      <Footer />
    </div>
  );
};
export default ShippingAddress;
