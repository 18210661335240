import PropTypes from "prop-types";
import { FaCheck, FaTimes } from "react-icons/fa";
const CustomCheckbox = ({ isChecked }) => {
    return (
        <div>
            {isChecked ? (
                <FaTimes color="red" size={24} />
            ) : (
                <FaCheck color="green" size={24} />
            )}
        </div>
    );
};

CustomCheckbox.propTypes = {
    isChecked: PropTypes.bool.isRequired,
};
export default CustomCheckbox;
