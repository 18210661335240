import * as axios from "../network/Network";
const config = require("../network/api.json");

class OrderService {
  static getData(data, current, search) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.orderHistory,
    });
    return Promise.resolve(result);
  }

  static getWishlist(user_id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getWishlist + `/${user_id}`,
    });
    return Promise.resolve(result);
  }

  static sendProblems(name, mobile_no, sku, product_name, message, order_id) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.getProblems,
      data: { name, mobile_no, sku, product_name, message, order_id },
    });
    return Promise.resolve(result);
  }
  static reUpload(id, pan_no, image) {
    var bodyFormData = new FormData();
    bodyFormData.append("pan_no", pan_no);
    bodyFormData.append("image", image);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.reUpload + `/${id}`,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }
  //ongoin_order
  // static ongoinOrder() {
  //   const result = axios.appRequest({
  //     method: axios.GET,
  //     url: config.user.ongoin_order,
  //   });
  //   return Promise.resolve(result);
  // }

  static getCancelRequest() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getCancelRequest,
    });
    return Promise.resolve(result);
  }

  static getReturnedRequest() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getReturnedRequest,
    });
    return Promise.resolve(result);
  }

  static downloadInvoice(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.download_invoice + `/${id}`,
    });
    return Promise.resolve(result);
  }
}
export { OrderService };
