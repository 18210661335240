import { ProductService } from "../../../services";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import crypto from "crypto";
import { UserService } from "../../../services";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

const useThankYouHook = () => {
  const [cart, setCart] = useState("");
  const id = useSelector((state) => state?.userData?.user?._id);
  const queryParams = new URLSearchParams(window.location.search);
  const product_id = queryParams.get("product_id");
  const order_id = queryParams.get("order_id");

  const menuData = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [randomNumbersss, setRandomNumber] = useState("");
  const [hashedData, setHashedData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [orderP, setOrderP] = useState("");
  const location = useLocation();

  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000); // Getting Unix timestamp in seconds
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);

    ProductService.ongoinOrder()
      .then((order) => {
        setOrderP(order?.data?.data?.orders[0]);

        // setOrder(order.data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        return false;
      });
  }, []);

  const generateRandomNumber = () => {
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  useEffect(() => {
    const hashObject = (obj) => {
      const hashedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const hash = crypto
            .createHash("sha256")
            .update(String(value))
            .digest("hex");
          hashedObj[key] = hash;
        }
      }
      return hashedObj;
    };
    const hashedSingleObject = hashObject(menuData);
    setHashedData(hashedSingleObject);

    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashedData.email],
      ph: [hashedData.mobile_no, hashedData.alternate_number],
      ln: [hashedData.last_name],
      fn: [hashedData.first_name],
      ct: [hashedData.city, hashedData.shipping_city],
      st: [hashedData.state, hashedData.shipping_state],
      zp: [hashedData.pincode, hashedData.shipping_pincode],
    };
    const customer_data = {
      product_id: queryParams.get("product_id"),
      order_id: queryParams.get("order_id"),
      content_category: orderP?.product?.name,
      content_ids: orderP?.product?.SKU,
      currency: "INR",
      value: orderP?.product?.c_total,
    };

    if (orderP !== "") {
      const eventData = [
        {
          event_name: "Purchase",
          event_time: unixTimestamp,
          action_source: "website",
          event_source_url: currentUrl,
          event_id: randomNumbersss.toString(),
          user_data: user_data,
          custom_data: customer_data,
        },
      ];

      UserService.getUserData(eventData)
        .then((response) => {
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });

      const productData = {
        id: orderP?.product?.SKU,
        name: orderP?.product?.name,
        price: orderP?.product?.c_total,
        quantity: 1,
        category: orderP?.product?.pro_details?.description,
        currency: "INR",
      };

      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          ecommerce: productData,
          pagePath: location.pathname,
        },
      });
      console.log(window.dataLayer, "DataLayer");
    }

    if (ipAddress && userAgent) {
      // setUpdatedPixelData((prevData) => ({
      //   data: prevData.data ? [...prevData.data, eventData] : [eventData],
      //   test_event_code: "TEST22969",
      // }));
      // setUpdatedPixelData((prevData) => [...prevData, eventData]);
    }
  }, [menuData, ipAddress, userAgent]);

  // useEffect(() => {
  //   if (updatedPixelData.length) {
  //     UserService.getUserData(updatedPixelData)
  //       .then((response) => {
  //         //setGetOtpData(response);
  //       })
  //       .catch((error) => {
  //         console.log("Errorreg", error);
  //       });
  //   }
  // }, [updatedPixelData]);

  useEffect(() => {
    ProductService.clearproductid(product_id)
      .then((response) => {
        // alert("HAIII");
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
    ProductService.deleteAllCart(id)
      .then((response) => {
        if (response.data.staus.code === 0) {
          setCart(0);
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, [cart]);
  return { cart, order_id };
};
export default useThankYouHook;
