import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";

const WhatsNew = (props) => {
  const whatsNewBox = useSelector((state) => state.abData.whats_new);
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN; // Image url

  var homePageDescription = {
    info: `Abharan Jewellers is a reputed jeweller founded in 1935 in Udupi. We are a Gold, Diamond & Silver Jewellers for the past 90 years and have earned the trust of thousands of customers by delivering quality and fair pricing.`,
  };
  return (
    <div class="what_container flt">
      <Helmet>
        <meta name="description" content={homePageDescription?.info} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div class="arrow_head flt">
        <span>Whats new</span>
      </div>
      <div class="what_cont flt">
        <div class="row">
          {whatsNewBox
            ? whatsNewBox.map((whats_list) => (
                <div class="col-sm-4" key={whats_list._id}>
                  <div class="what_box flt">
                    <div class="what_image flt">
                      <LazyLoadImage
                        src={image_url + whats_list.image}
                        alt="image"
                      />
                    </div>
                    <div class="what_txt">
                      {/* <div class="what_name flt">{whats_list.title}</div> */}
                      <div class="what_button flt">
                        {/* <button onClick={() => imgChange1(whats_list.title)}>
                          Shop Now
                        </button> */}

                        <button
                          onClick={() =>
                            props.onShopNowClicked(whats_list.title)
                          }
                        >
                          Shop Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};
export default WhatsNew;
