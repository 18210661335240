import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import useGetOtpHook from "./GetOtpHook";
import "./GetOtp.css";
import { useLocation } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
const GetOtp = () => {
  const location = useLocation();
  const {
    home_banner2,
    otp,
    new_password,
    re_password,
    submitted,
    getOtpError,
    loggedin,
    handleOtpChange,
    handleNewPasswordChange,
    handleRePasswordChange,
    handleSubmit,
  } = useGetOtpHook(location);
  const getOtp = () => {
    return (
      <div class="login_container flt">
        <div class="login_box">
          <div class="login_banner flt">
            <LazyLoadImage src={home_banner2} />
          </div>
          <div class="login_cont flt">
            <div class="login_head flt">Reset Password</div>
            <div class="form-group flt">
              <input
                type="number"
                placeholder="Enter Otp"
                name="otp"
                value={otp}
                onChange={handleOtpChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
                className={submitted && !otp ? " is-invalid" : ""}
                onWheel={(e) => e.currentTarget.blur()}
              />
              {submitted && !otp && (
                <div className="invalid-feedback">OTP is required</div>
              )}
            </div>
            <div class="form-group flt">
              <input
                type="password"
                placeholder="Enter Password"
                name="new_password"
                value={new_password}
                onChange={handleNewPasswordChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
                className={submitted && !new_password ? " is-invalid" : ""}
              />
              {submitted && !new_password && (
                <div className="invalid-feedback">Password is required</div>
              )}
            </div>
            <div class="form-group flt">
              <input
                type="password"
                placeholder="Enter Confirm Password"
                name="re_password"
                value={re_password}
                onChange={handleRePasswordChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
                className={submitted && !re_password ? " is-invalid" : ""}
              />
              {!re_password && (
                <div className="invalid-feedback">
                  Confirm password is required
                </div>
              )}
              <div class="get-otp-error">{getOtpError}</div>
            </div>

            {loggedin ? (
              <button class="login_btn flt" onClick={handleSubmit}>
                Update Password
              </button>
            ) : (
              <button class="login_btn flt" onClick={handleSubmit}>
                Password Updated Successfully!!
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header />
      {getOtp()}
      <Footer />
    </div>
  );
};
export default GetOtp;
