import {UPDATE_SEARCH,MIN_AMOUNT, MAX_AMOUNT, FILTER_DATA, MAIN_CAT, SUB_CAT, PAGE_NO, SEARCH_KEY, UPDATE_ALL_FILTER} from "../actions/types"
const initialState={
    filter:[],
    min_amount:0,
    max_amount:0,
    main_cat:[],
    sub_cat:[],
    page_no:1,
    search_key:""
}
export function ListReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SEARCH:
         return {
        ...state,
        search_key:payload.search_key
      };

      case MIN_AMOUNT:
         return {
        ...state,
        min_amount:payload.min_amount
        
      };
        case MAX_AMOUNT:
         return {
        ...state,
        max_amount:payload.max_amount
      };
         case FILTER_DATA:
         return {
        ...state,
        filter:payload.filter,

      };
        case MAIN_CAT:
         return {
        ...state,
        main_cat:payload.main_cat
      };
       case SUB_CAT:
         return {
        ...state,
        sub_cat:payload.sub_cat
      };
     case PAGE_NO:
         return {
        ...state,
        page_no:payload.page_no
      };
      case SEARCH_KEY:
        return{
            ...state,
            search_key:payload.search_key
        }
    case UPDATE_ALL_FILTER:
      
          let data =  {
        ...state,
        filter:payload.hasOwnProperty("filter")?payload.filter:state.filter,
            min_amount:payload.hasOwnProperty("min_amount")?payload.min_amount:state.min_amount,
            max_amount:payload.hasOwnProperty("max_amount")?payload.max_amount:state.max_amount,
            main_cat:payload.hasOwnProperty("main_cat")? payload.main_cat:state.main_cat,
            sub_cat:payload.hasOwnProperty("sub_cat")? payload.sub_cat :state.sub_cat,
            page_no:payload.hasOwnProperty("page_no")? payload.page_no :state.page_no,
            search_key:payload.hasOwnProperty("search_key")? payload.search_key :state.search_key       
  };
    return data

    default:
      return state;
  }
}
