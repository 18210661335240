import { useEffect, useMemo, useState } from "react";
import { ProductService } from "../../../services/ProductService";
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from "react-redux";
import { myDetail } from "../../../actions/UserAction";
import { useSelector } from "react-redux";
import { HeaderService } from "../../../services/HeaderService";
import { UserService } from "../../../services";
import crypto from "crypto";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

const useShippingAddressHook = (location, displayRazorpay) => {
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state?.userData?.user);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [alternate_mobile, setAlternateMobile] = useState("");
  const [address, setAddress] = useState("");
  const [locality, setLocality] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [state1, setState1] = useState("");
  const [state2, setState2] = useState("");
  const [landmarkValue, setLandmarkValue] = useState("");
  const [pincode, setPincode] = useState("");
  const [pan_no, setPancard] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [image, setImage] = useState();
  const [checkOutData, setCheckOutData] = useState({});
  const [storeState, setStoreState] = useState([]);
  const [charge, setCharge] = useState(0);
  const [id, setId] = useState("");
  const [storeDistrict, setStoreDistrict] = useState([]);
  const [billingStoreDistrict, setBillingStoreDistrict] = useState([]);
  const [billingStoreState, setBillingStoreState] = useState([]);
  const [district, setDistrict] = useState(
    userinfo?.district ? userinfo?.district : ""
  );
  const [district1, setDistrict1] = useState("");
  const [district2, setDistrict2] = useState("");
  const [deliverydate, setDeliveryDate] = useState();
  const [showButton, setShowButton] = useState(false);
  const [paymentMode, setPaymentMode] = useState("1");
  const [fileName, setFileName] = useState("");
  const [billing_address, setBillingAddress] = useState("");
  const [billing_city, setBillingCity] = useState("");
  const [billing_district, setBillingDistrict] = useState("");
  const [billing_district1, setBillingDistrict1] = useState("");
  const [billing_landmarkValue, setBillingLandmarkValue] = useState("");
  const [billing_locality, setBillingLocality] = useState("");
  const [billing_pincode, setBillingPincode] = useState("");
  const [billing_state, setBillingState] = useState("");
  const [billing_state1, setBillingState1] = useState("");
  const [check, setCheck] = useState(false);
  const [lname, setLname] = useState("");
  const [billingDistSelected, setBillingDistSelected] = useState(false);
  const [acceptCheck, setAcceptCheck] = useState(false);
  const [btnshow, setbtnShow] = useState(false);
  const [initialPlace, setInitialPlace] = useState(false);
  const [initialPlaceDist, setInitialPlaceDist] = useState(false);
  const [temp, setTemp] = useState(false);
  const [goldRate18, setGoldRate18] = useState("");
  const [goldRate22, setGoldRate22] = useState("");
  const [silverRate, setSilverRate] = useState("");
  const [cartdata, setCartData] = useState();
  const [formattedDate, setFormattedDate] = useState(
    new Date().toLocaleDateString("en-GB")
  );
  const [isCheckedAddress, setIsCheckedAddress] = useState(false);

  const menuData = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [randomNumbersss, setRandomNumber] = useState("");
  const [hashedData, setHashedData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [eventData, setEventData] = useState("");
  const locationss = useLocation();

  let [deliverable, setDeliverable] = useState(null);

  var product_id = location?.state?.product.product_id;
  const user_id = useSelector((state) => state?.userData?.user?._id);
  let total_amount = 0;
  if (charge === 0 || charge === undefined) {
    var gstAmount = Number(
      (location?.state?.product?.product?.c_gst_amount).replace(",", "")
    );
    var res_total =
      Number((location?.state?.amount).replace(",", "")) -
      Number(
        (location?.state?.product?.product?.c_gst_amount).replace(",", "")
      );

    total_amount =
      res_total +
      gstAmount +
      Number(location?.state?.product?.product?.c_offer_value);
    total_amount = Number(total_amount).toFixed(2);
  } else {
    var gstAmount =
      Number(
        (location?.state?.product?.product?.c_gst_amount).replace(",", "")
      ) +
      Number(charge) * 0.03;
    var res_total =
      Number((location?.state?.amount).replace(",", "")) -
      Number(
        (location?.state?.product?.product?.c_gst_amount).replace(",", "")
      );

    total_amount =
      res_total +
      Number(charge) +
      gstAmount +
      Number(location?.state?.product?.product?.c_offer_value);
    total_amount = Number(total_amount).toFixed(2);
  }

  useEffect(() => {
    HeaderService.getDailyRate()
      .then((response) => {
        setGoldRate18(response.data.data.gold18);
        setGoldRate22(response.data.data.gold22);
        setSilverRate(response.data.data.silver);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });

    ProductService.getCart()
      .then((cartdata) => {
        if (cartdata?.data?.data?.cart) {
          setCartData(cartdata?.data?.data?.cart);
        } else {
        }
        //  window.location.reload();
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });

    const currentTimestamp = Math.floor(Date.now() / 1000);
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);
  }, []);

  const generateRandomNumber = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth() + 1;
    // const date = currentDate.getDate();
    // const hours = currentDate.getHours();
    // const minutes = currentDate.getMinutes();
    // const seconds = currentDate.getSeconds();
    // const milliseconds = currentDate.getMilliseconds();

    // const randomNumber = parseInt(
    //   `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}`,
    //   10
    // );

    // return randomNumber;
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  useEffect(() => {
    const hashObject = (obj) => {
      const hashedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const hash = crypto
            .createHash("sha256")
            .update(String(value))
            .digest("hex");
          hashedObj[key] = hash;
        }
      }
      return hashedObj;
    };
    const hashedSingleObject = hashObject(menuData);
    setHashedData(hashedSingleObject);

    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashedData.email],
      ph: [hashedData.mobile_no, hashedData.alternate_number],
      ln: [hashedData.last_name],
      fn: [hashedData.first_name],
      ct: [hashedData.city, hashedData.shipping_city],
      st: [hashedData.state, hashedData.shipping_state],
      zp: [hashedData.pincode, hashedData.shipping_pincode],
    };

    const custom_data = {
      currency: "INR",
      value: cartdata?.product?.final_amt.toString(),
      content_category: cartdata?.product?.pro_details?.description,
      content_ids: cartdata?.product?.SKU,
    };

    setEventData({
      event_name: "InitiateCheckout",
      event_time: unixTimestamp,
      action_source: "website",
      event_source_url: currentUrl,
      event_id: randomNumbersss.toString(),
      user_data: user_data,
      custom_data: custom_data,
    });
  }, [menuData, ipAddress, userAgent, randomNumbersss]);

  useEffect(() => {
    if (updatedPixelData.length) {
      UserService.getUserData(updatedPixelData)
        .then((response) => {
          const productData = {
            id: cartdata?.product?.SKU,
            name: cartdata?.product?.pro_details?.description,
            price: cartdata?.product?.final_amt.toString(),
            quantity: 1,
            category: cartdata?.product?.pro_details?.description,
            currency: "INR",
          };

          TagManager.dataLayer({
            dataLayer: {
              event: "initiatecheckout",
              ecommerce: productData,
              pagePath: locationss.pathname,
            },
          });
          console.log(window.dataLayer, "DataLayer");
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }, [updatedPixelData]);

  const saveAddress = () => {
    setIsCheckedAddress(!isCheckedAddress);
    if (
      isCheckedAddress === false &&
      address != "" &&
      locality != "" &&
      city != "" &&
      landmarkValue != "" &&
      pincode != ""
    ) {
      dispatch(
        myDetail(
          address,
          locality,
          city,
          landmarkValue,
          pincode,
          check ? billing_state : state,
          check ? billing_district : district,
          user_id
        )
      )
        .then((value) => {
          if (value.data.status.code === 0) {
            dispatch();
          } else {
            dispatch(alertActions.error(value.data.status.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  useEffect(() => {
    if (!selectedFile) {
      setImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    ProductService.getState()
      .then((storeState) => {
        setStoreState(storeState.data.data);
        setBillingStoreState(storeState.data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });

    ProductService.getuserinfo().then((response) => {
      if (response?.data?.status?.code === 0) {
        setName(
          response?.data?.data?.[0]?.first_name
            ? response?.data?.data?.[0]?.first_name
            : ""
        );
        setLname(
          response?.data?.data?.[0]?.last_name
            ? response?.data?.data?.[0]?.last_name
            : ""
        );
        setMobile(
          response?.data?.data?.[0]?.mobile_no
            ? response?.data?.data?.[0]?.mobile_no
            : ""
        );
        setBillingCity(
          response?.data?.data?.[0]?.city ? response?.data?.data?.[0]?.city : ""
        );
        setBillingLocality(
          response?.data?.data?.[0]?.locality
            ? response?.data?.data?.[0]?.locality
            : ""
        );
        setBillingLandmarkValue(
          response?.data?.data?.[0]?.landmark
            ? response?.data?.data?.[0]?.landmark
            : ""
        );
        setAddress(
          response?.data?.data?.[0]?.shipping_address
            ? response?.data?.data?.[0]?.shipping_address
            : ""
        );
        setCity(
          response?.data?.data?.[0]?.shipping_city
            ? response?.data?.data?.[0]?.shipping_city
            : ""
        );
        setPincode(
          response?.data?.data?.[0]?.shipping_pincode
            ? response?.data?.data?.[0]?.shipping_pincode
            : ""
        );
        setLandmarkValue(
          response?.data?.data?.[0]?.shipping_landmark
            ? response?.data?.data?.[0]?.shipping_landmark
            : ""
        );
        setLocality(
          response?.data?.data?.[0]?.shipping_locality
            ? response?.data?.data?.[0]?.shipping_locality
            : ""
        );
        setBillingPincode(
          response?.data?.data?.[0]?.pincode
            ? response?.data?.data?.[0]?.pincode
            : ""
        );
        setBillingAddress(
          response?.data?.data?.[0]?.billing_address
            ? response?.data?.data?.[0]?.billing_address
            : ""
        );
        setBillingState(
          response?.data?.data?.[0]?.state
            ? response?.data?.data?.[0]?.state
            : ""
        );
        setBillingDistrict(
          response?.data?.data?.[0]?.district
            ? response?.data?.data?.[0]?.district
            : ""
        );
        setState(
          response?.data?.data?.[0]?.shipping_state
            ? response?.data?.data?.[0]?.shipping_state
            : ""
        );
        setDistrict(
          response?.data?.data?.[0]?.shipping_district
            ? response?.data?.data?.[0]?.shipping_district
            : ""
        );
        setAlternateMobile(
          response?.data?.data?.[0]?.alternate_number
            ? response?.data?.data?.[0]?.alternate_number
            : ""
        );
        setTemp(true);
      }
    });
  }, []);

  useEffect(() => {
    if (temp === true) {
      if (billing_state !== "") {
        setInitialPlace(true);
      }
      if (billing_district !== "") {
        setInitialPlaceDist(true);
      }
    }
  }, [temp]);

  useEffect(() => {
    billingStoreState.map((statess) => {
      if (billing_state === statess.state) {
        setBillingState(statess.state);
        const id = statess._id;
        setBillingState1(id);
        ProductService.getDistrict(id)
          .then((billingStoreDistrict) => {
            setBillingStoreDistrict(billingStoreDistrict?.data?.data);
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    });
    storeState.map((statess) => {
      if (state === statess.state) {
        const id = statess._id;
        setState1(id);
        ProductService.getDistrict(id)
          .then((storeDistrict) => {
            setStoreDistrict(storeDistrict?.data?.data);

            storeState.filter((value) => {
              if (
                statess?.state === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.gold_delivery_charge?.catId
              ) {
                if (
                  statess?.state === value?.state &&
                  location?.state?.product?.product?.s_category_id ===
                    value?.voucher_delivery_charge?.catId
                ) {
                  var charge = value?.voucher_delivery_charge?.charge;
                  setCharge(charge);
                  setId(value?._id);
                } else {
                  setId(value?._id);
                  var charge = value?.gold_delivery_charge?.charge;
                  setCharge(charge);
                }
              } else if (
                statess?.state === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.silver_delivery_charge?.catId
              ) {
                setId(value?._id);
                var charge = value?.silver_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                statess?.state === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.pearl_delivery_charge?.catId
              ) {
                setId(value?._id);
                var charge = value?.pearl_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                statess?.state === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.gems_delivery_charge?.catId
              ) {
                setId(value?._id);
                var charge = value?.gems_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                statess?.state === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.diamond_delivery_charge?.catId
              ) {
                if (
                  statess?.state === value?.state &&
                  location?.state?.product?.product?.s_category_id ===
                    value?.polky_delivery_charge?.catId
                ) {
                  setId(value?._id);
                  var charge = value?.polky_delivery_charge?.charge;
                  setCharge(charge);
                } else {
                  setId(value?._id);
                  var charge = value?.diamond_delivery_charge?.charge;
                  setCharge(charge);
                }
              }
            });
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    });
  }, [initialPlace, billingStoreState, storeState]);

  useEffect(() => {
    billingStoreDistrict.filter((value) => {
      if (billing_district == value.district) {
        setBillingDistrict1(value._id);
        ProductService.getDistricts(value._id).then((res) => {
          storeState.filter((value) => {});
        });
      }
    });
  }, [billingStoreDistrict]);

  useEffect(() => {
    storeDistrict.filter((value) => {
      if (district == value.district) {
        setDistrict1(value._id);
        ProductService.getDistricts(value._id).then((res) => {
          storeState.filter((value) => {
            if (
              location?.state?.product?.product?.m_category_id ===
              res?.data?.data?.gold_delivery_charge?.catId
            ) {
              if (
                location?.state?.product?.product?.s_category_id ===
                value?.voucher_delivery_charge?.catId
              ) {
                var charge = value?.voucher_delivery_charge?.charge;
                setCharge(charge);
              } else {
                var charge = res?.data?.data?.gold_delivery_charge?.charge;
                setCharge(charge);
              }
            } else if (
              location?.state?.product?.product?.m_category_id ===
              res?.data?.data?.silver_delivery_charge?.catId
            ) {
              var charge = res?.data?.data?.silver_delivery_charge?.charge;
              setCharge(charge);
            } else if (
              location?.state?.product?.product?.m_category_id ===
              res?.data?.data?.pearl_delivery_charge?.catId
            ) {
              var charge = res?.data?.data?.pearl_delivery_charge?.charge;
              setCharge(charge);
            } else if (
              location?.state?.product?.product?.m_category_id ===
              res?.data?.data?.gems_delivery_charge?.catId
            ) {
              var charge = res?.data?.data?.gems_delivery_charge?.charge;
              setCharge(charge);
            } else if (
              location?.state?.product?.product?.m_category_id ===
              res?.data?.data?.diamond_delivery_charge?.catId
            ) {
              if (
                location?.state?.product?.product?.s_category_id ===
                value?.polky_delivery_charge?.catId
              ) {
                var charge = value?.polky_delivery_charge?.charge;
                setCharge(charge);
              } else {
                var charge = res?.data?.data?.diamond_delivery_charge?.charge;
                setCharge(charge);
              }
            } else {
              setCharge(0);
            }
            setCharge(charge);
          });
        });
      }
    });
  }, [storeDistrict]);

  useEffect(() => {
    if (location?.state?.product?.product?.in_stock === 0) {
      ProductService.estimated_delivery_outstock(pincode, product_id)
        .then((deliverydate) => {
          if (deliverydate?.data?.status?.code === 1) {
            setDeliveryDate("");
            if (pincode.length === 6) {
              setDeliverable(true);
            } else {
              setDeliverable(null);
            }
          } else {
            if (pincode.length === 6) {
              setDeliverable(false);
            } else {
              setDeliverable(null);
            }
            if (deliverydate?.data?.data?.message !== undefined) {
              setDeliveryDate(deliverydate?.data?.data?.estimated_delivery);
            } else {
              let text = deliverydate?.data;
              const myArray = text.split(":");
              const myArray1 = myArray[5].split(/["\"$]/);
              // setDeliveryDate(myArray1[1].split(" ")[0]);
              setDeliveryDate(myArray1[1]);
            }
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    } else {
      ProductService.checkAvailability(pincode)
        .then((deliverydate) => {
          if (deliverydate?.data?.status?.code === 1) {
            if (pincode.length === 6) {
              setDeliverable(true);
            } else {
              setDeliverable(null);
            }

            setDeliveryDate("");
          } else {
            if (pincode.length === 6) {
              setDeliverable(false);
            } else {
              setDeliverable(null);
            }

            if (deliverydate?.data?.data?.message !== undefined) {
              setDeliveryDate(deliverydate?.data?.data?.estimated_delivery);
            } else if (
              (deliverydate?.data).includes("Delivered by Sequel Logistics")
            ) {
              let text = deliverydate?.data;
              const myArray = text.split(":");
              const myArray1 = myArray[5].split(/["\"$]/);
              setDeliveryDate(myArray1[1]);
            }
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }, [pincode, billing_pincode]);

  useEffect(() => {
    setFormattedDate(deliverydate);
  }, [deliverydate]);

  useEffect(() => {
    if (checkOutData?.data?.auto_capture === 1) {
      displayRazorpay(checkOutData, product_id);
      setSubmitted(false);
    } else if (checkOutData?.data?.success === false) {
      if (checkOutData?.data?.message?.image) {
        dispatch(alertActions.success(checkOutData?.data?.message?.image[0]));
      } else if (checkOutData?.data?.message?.pan_no) {
        dispatch(alertActions.success(checkOutData?.data?.message?.pan_no[0]));
      }
    }
  }, [checkOutData]);

  useEffect(() => {
    if (check === true && billingDistSelected === false) {
      setDistrict("");
    }
  }, [check]);

  function onSelectFile(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    if (
      e.target.files[0].name.includes("jpeg") ||
      e.target.files[0].name.includes("jpg") ||
      e.target.files[0].name.includes("png")
    ) {
      setFileName(e.target.files[0].name);
      setSelectedFile(e.target.files[0]);
      setShowButton(true);
    } else {
      dispatch(
        alertActions.error("Please upload images of JPG or PNG format!!")
      );
    }
  }
  function changeMobileNoHandler(e) {
    setMobile(e.target.value);
  }
  function changeNameHandler(e) {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setName(e.target.value);
    } else if (!e.target.value) {
      setName("");
    }
  }
  function changeLastNameHandler(e) {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setLname(e.target.value);
    } else if (!e.target.value) {
      setLname("");
    }
  }
  function changeAlternateMobileNoHandler(e) {
    setAlternateMobile(e.target.value);
  }
  function changeAddressHandler(e) {
    setAddress(e.target.value);
  }
  function changeBillingAddressHandler(e) {
    setBillingAddress(e.target.value);
  }
  function changeLocalityHandler(e) {
    setLocality(e.target.value);
  }
  function changeBillingLocalityHandler(e) {
    setBillingLocality(e.target.value);
  }
  function changeCityHandler(e) {
    setCity(e.target.value);
  }
  function changeBillingCityHandler(e) {
    setBillingCity(e.target.value);
  }
  function changeDistrictHandler(e) {
    storeDistrict.filter((value) => {
      if (e.target.value == value.district) {
        setDistrict1(value._id);
        ProductService.getDistricts(value._id).then((res) => {
          storeState.filter((value) => {
            if (
              location?.state?.product?.product?.m_category_id ===
              res?.data?.data?.gold_delivery_charge?.catId
            ) {
              if (
                location?.state?.product?.product?.s_category_id ===
                value?.voucher_delivery_charge?.catId
              ) {
                var charge = value?.voucher_delivery_charge?.charge;
                setCharge(charge);
              } else {
                var charge = res?.data?.data?.gold_delivery_charge?.charge;
                setCharge(charge);
              }
            } else if (
              location?.state?.product?.product?.m_category_id ===
              res?.data?.data?.silver_delivery_charge?.catId
            ) {
              var charge = res?.data?.data?.silver_delivery_charge?.charge;
              setCharge(charge);
            } else if (
              location?.state?.product?.product?.m_category_id ===
              res?.data?.data?.pearl_delivery_charge?.catId
            ) {
              var charge = res?.data?.data?.pearl_delivery_charge?.charge;
              setCharge(charge);
            } else if (
              location?.state?.product?.product?.m_category_id ===
              res?.data?.data?.gems_delivery_charge?.catId
            ) {
              var charge = res?.data?.data?.gems_delivery_charge?.charge;
              setCharge(charge);
            } else if (
              location?.state?.product?.product?.m_category_id ===
              res?.data?.data?.diamond_delivery_charge?.catId
            ) {
              if (
                location?.state?.product?.product?.s_category_id ===
                value?.polky_delivery_charge?.catId
              ) {
                var charge = value?.polky_delivery_charge?.charge;
                setCharge(charge);
              } else {
                var charge = res?.data?.data?.diamond_delivery_charge?.charge;
                setCharge(charge);
              }
            } else if (
              e.target.value === value.state &&
              res?.state?.product?.product?.m_category_id
            ) {
              setCharge(0);
            }
            setCharge(charge);
          });
        });
      }
    });
    setDistrict(e.target.value);
    setDistrict2(e.target.value);
  }

  function changeBillingDistrictHandler(e) {
    billingStoreDistrict.filter((value) => {
      if (e.target.value == value.district) {
        setBillingDistrict1(value._id);
        ProductService.getDistricts(value._id).then((res) => {
          storeState.filter((value) => {});
        });
      }
    });
    setInitialPlaceDist(false);
    setTemp(false);
    setBillingDistrict(e.target.value);
    setBillingDistSelected(true);
  }

  function changeStateHandler(e) {
    storeState.filter((value) => {
      if (e.target.value == value.state) {
        setId(value._id);
        setState1(value._id);
        ProductService.getDistrict(value._id)
          .then((storeDistrict) => {
            setStoreDistrict(storeDistrict?.data?.data);

            storeState.filter((value) => {
              if (
                e.target.value === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.gold_delivery_charge?.catId
              ) {
                if (
                  e.target.value === value?.state &&
                  location?.state?.product?.product?.s_category_id ===
                    value?.voucher_delivery_charge?.catId
                ) {
                  var charge = value?.voucher_delivery_charge?.charge;
                  setCharge(charge);
                  setId(value?._id);
                } else {
                  setId(value?._id);

                  var charge = value?.gold_delivery_charge?.charge;
                  setCharge(charge);
                }
              } else if (
                e.target.value === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.silver_delivery_charge?.catId
              ) {
                setId(value?._id);
                var charge = value?.silver_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                e.target.value === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.pearl_delivery_charge?.catId
              ) {
                setId(value?._id);
                var charge = value?.pearl_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                e.target.value === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.gems_delivery_charge?.catId
              ) {
                setId(value?._id);
                var charge = value?.gems_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                e.target.value === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.diamond_delivery_charge?.catId
              ) {
                if (
                  e.target.value === value?.state &&
                  location?.state?.product?.product?.s_category_id ===
                    value?.polky_delivery_charge?.catId
                ) {
                  setId(value?._id);
                  var charge = value?.polky_delivery_charge?.charge;
                  setCharge(charge);
                } else {
                  setId(value?._id);
                  var charge = value?.diamond_delivery_charge?.charge;
                  setCharge(charge);
                }
              }
            });
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    });
    setDistrict("");
    setDistrict2("");
    setState(e.target.value);
    setState2(e.target.value);
  }

  function changeBillingStateHandler(e) {
    setInitialPlace(false);
    setInitialPlaceDist(false);
    setTemp(false);
    setBillingDistSelected(false);
    setBillingDistrict("");

    storeState.filter((value) => {
      if (e.target.value == value.state) {
        setBillingState1(value._id);
        ProductService.getDistrict(value?._id)
          .then((billingStoreDistrict) => {
            setBillingStoreDistrict(billingStoreDistrict?.data?.data);
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    });
    setBillingState(e.target.value);
  }

  function changeLandMarkHandler(e) {
    setLandmarkValue(e.target.value);
  }
  function changeBillingLandMarkHandler(e) {
    setBillingLandmarkValue(e.target.value);
  }
  function changePincodeHandler(e) {
    if (e.target.value.length > 6) {
      dispatch(alertActions.error("Enter valid pincode number"));
    } else {
      setPincode(e.target.value);
    }
  }
  function changeBillingPincodeHandler(e) {
    if (e.target.value.length > 6) {
      dispatch(alertActions.error("Enter valid pincode number"));
    } else {
      setBillingPincode(e.target.value);
    }
  }

  function changePaymentModeHandler(e) {
    setPaymentMode(e.target.value);
  }
  function changePancardHandler(e) {
    setPancard(e.target.value);
  }
  function handleClose(e) {
    setSelectedFile("");
    setFileName("");
    setImage("");
    onSelectFile(e);
  }

  function changeCheckboxHandler(e) {
    setCheck(!check);
    if (!check) {
      var id = billing_district1;
      if (
        billing_state === "Karnataka" ||
        billing_state === "Goa" ||
        billing_state === "Telangana"
      ) {
        billingStoreDistrict.map((districtss) => {
          if (billing_district === districtss.district) {
            setBillingDistrict(districtss.district);
            const id = districtss._id;
            setBillingDistrict1(id);
            ProductService.getDistricts(id)
              .then((res) => {
                storeState.filter((value) => {
                  if (
                    location?.state?.product?.product?.m_category_id ===
                    res?.data?.data?.gold_delivery_charge?.catId
                  ) {
                    if (
                      location?.state?.product?.product?.s_category_id ===
                      value?.voucher_delivery_charge?.catId
                    ) {
                      var charge = value?.voucher_delivery_charge?.charge;
                      setCharge(charge);
                    } else {
                      var charge =
                        res?.data?.data?.gold_delivery_charge?.charge;
                      setCharge(charge);
                    }
                  } else if (
                    location?.state?.product?.product?.m_category_id ===
                    res?.data?.data?.silver_delivery_charge?.catId
                  ) {
                    var charge =
                      res?.data?.data?.silver_delivery_charge?.charge;
                    setCharge(charge);
                  } else if (
                    location?.state?.product?.product?.m_category_id ===
                    res?.data?.data?.pearl_delivery_charge?.catId
                  ) {
                    var charge = res?.data?.data?.pearl_delivery_charge?.charge;
                    setCharge(charge);
                  } else if (
                    location?.state?.product?.product?.m_category_id ===
                    res?.data?.data?.gems_delivery_charge?.catId
                  ) {
                    var charge = res?.data?.data?.gems_delivery_charge?.charge;
                    setCharge(charge);
                  } else if (
                    location?.state?.product?.product?.m_category_id ===
                    res?.data?.data?.diamond_delivery_charge?.catId
                  ) {
                    if (
                      location?.state?.product?.product?.s_category_id ===
                      value?.polky_delivery_charge?.catId
                    ) {
                      var charge = value?.polky_delivery_charge?.charge;
                      setCharge(charge);
                    } else {
                      var charge =
                        res?.data?.data?.diamond_delivery_charge?.charge;
                      setCharge(charge);
                    }
                  } else if (
                    e.target.value === value.state &&
                    res?.state?.product?.product?.m_category_id
                  ) {
                    setCharge(0);
                  }
                  setCharge(charge);
                });
              })
              .catch((error) => {
                console.log("Errorreg", error);
              });
          }
        });
        if (!billingStoreDistrict) {
          ProductService.getDistricts(id).then((res) => {
            storeState.filter((value) => {
              if (
                location?.state?.product?.product?.m_category_id ===
                res?.data?.data?.gold_delivery_charge?.catId
              ) {
                if (
                  location?.state?.product?.product?.s_category_id ===
                  value?.voucher_delivery_charge?.catId
                ) {
                  var charge = value?.voucher_delivery_charge?.charge;
                  setCharge(charge);
                } else {
                  var charge = res?.data?.data?.gold_delivery_charge?.charge;
                  setCharge(charge);
                }
              } else if (
                location?.state?.product?.product?.m_category_id ===
                res?.data?.data?.silver_delivery_charge?.catId
              ) {
                var charge = res?.data?.data?.silver_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                location?.state?.product?.product?.m_category_id ===
                res?.data?.data?.pearl_delivery_charge?.catId
              ) {
                var charge = res?.data?.data?.pearl_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                location?.state?.product?.product?.m_category_id ===
                res?.data?.data?.gems_delivery_charge?.catId
              ) {
                var charge = res?.data?.data?.gems_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                location?.state?.product?.product?.m_category_id ===
                res?.data?.data?.diamond_delivery_charge?.catId
              ) {
                if (
                  location?.state?.product?.product?.s_category_id ===
                  value?.polky_delivery_charge?.catId
                ) {
                  var charge = value?.polky_delivery_charge?.charge;
                  setCharge(charge);
                } else {
                  var charge = res?.data?.data?.diamond_delivery_charge?.charge;
                  setCharge(charge);
                }
              } else if (
                e.target.value === value.state &&
                res?.state?.product?.product?.m_category_id
              ) {
                setCharge(0);
              }
              setCharge(charge);
            });
          });
        }
      } else {
        var id = billing_state1;
        ProductService.getDistrict(id)
          .then((storeDistrict) => {
            setStoreDistrict(storeDistrict?.data?.data);

            storeState.filter((value) => {
              if (
                billing_state === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.gold_delivery_charge?.catId
              ) {
                if (
                  billing_state === value?.state &&
                  location?.state?.product?.product?.s_category_id ===
                    value?.voucher_delivery_charge?.catId
                ) {
                  var charge = value?.voucher_delivery_charge?.charge;
                  setCharge(charge);
                  setId(value?._id);
                } else {
                  setId(value?._id);

                  var charge = value?.gold_delivery_charge?.charge;
                  setCharge(charge);
                }
              } else if (
                billing_state === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.silver_delivery_charge?.catId
              ) {
                setId(value?._id);
                var charge = value?.silver_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                billing_state === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.pearl_delivery_charge?.catId
              ) {
                setId(value?._id);
                var charge = value?.pearl_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                billing_state === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.gems_delivery_charge?.catId
              ) {
                setId(value?._id);
                var charge = value?.gems_delivery_charge?.charge;
                setCharge(charge);
              } else if (
                billing_state === value?.state &&
                location?.state?.product?.product?.m_category_id ===
                  value?.diamond_delivery_charge?.catId
              ) {
                if (
                  billing_state === value?.state &&
                  location?.state?.product?.product?.s_category_id ===
                    value?.polky_delivery_charge?.catId
                ) {
                  setId(value?._id);
                  var charge = value?.polky_delivery_charge?.charge;
                  setCharge(charge);
                } else {
                  setId(value?._id);
                  var charge = value?.diamond_delivery_charge?.charge;
                  setCharge(charge);
                }
              }
            });
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }

    if (!check) {
      setAddress(billing_address);
      setLocality(billing_locality);
      setCity(billing_city);
      setState(billing_state);
      setDistrict(billing_district);
      setLandmarkValue(billing_landmarkValue);
      setPincode(billing_pincode);
    } else {
      setAddress("");
      setLocality("");
      setCity("");
      setState("");
      setDistrict("");
      setLandmarkValue("");
      setPincode("");
    }
  }
  function changeAcceptCheckboxHandler() {
    setAcceptCheck(!acceptCheck);
  }

  const hasValidBillingDistrict = useMemo(() => {
    if (
      (billing_state === "Karnataka" ||
        billing_state === "Goa" ||
        billing_state === "Telangana") &&
      billing_district === ""
    ) {
      return false;
    } else if (
      (billing_state === "Karnataka" ||
        billing_state === "Goa" ||
        billing_state === "Telangana") &&
      billing_district !== ""
    ) {
      return true;
    } else {
      return true;
    }
  }, [billing_district || billing_state]);

  const hasValidDistrict = useMemo(() => {
    if (
      (state2 === "Karnataka" ||
        state2 === "Goa" ||
        billing_state === "Telangana") &&
      district2 === ""
    ) {
      return false;
    } else if (
      (state2 === "Karnataka" ||
        state2 === "Goa" ||
        billing_state === "Telangana") &&
      district2 !== ""
    ) {
      return true;
    } else {
      return true;
    }
  }, [district2 || state2]);

  const isFormValid = useMemo(() =>
    name &&
    mobile &&
    alternate_mobile &&
    billing_pincode &&
    billing_address &&
    billing_locality &&
    billing_landmarkValue &&
    landmarkValue &&
    billing_state &&
    billing_city &&
    pincode &&
    locality &&
    address &&
    city &&
    state &&
    hasValidBillingDistrict &&
    check
      ? hasValidBillingDistrict
      : hasValidDistrict && !check
      ? hasValidDistrict
      : hasValidBillingDistrict[
          (name,
          mobile,
          alternate_mobile,
          billing_pincode,
          billing_address,
          billing_locality,
          billing_landmarkValue,
          landmarkValue,
          billing_state,
          billing_city,
          pincode,
          locality,
          address,
          city,
          state,
          hasValidBillingDistrict,
          check ? hasValidBillingDistrict : hasValidDistrict,
          !check ? hasValidDistrict : hasValidBillingDistrict)
        ]
  );

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    var filter = /^[0-9]{10}$/;
    var pattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (hasValidBillingDistrict === true && hasValidDistrict === true) {
      if (
        name &&
        mobile &&
        alternate_mobile &&
        billing_pincode &&
        billing_address &&
        billing_locality &&
        billing_landmarkValue &&
        landmarkValue &&
        billing_state &&
        billing_city &&
        pincode &&
        locality &&
        address &&
        city &&
        state
      ) {
        setbtnShow(true);
        setTimeout(() => {
          setbtnShow(false);
        }, 7000);
        if (mobile && !filter.test(mobile)) {
          dispatch(alertActions.error("Enter valid Mobile number"));
        } else if (billing_pincode && billing_pincode.length !== 6) {
          dispatch(alertActions.error("Enter valid Pincode number"));
        } else if (pincode && pincode.length !== 6) {
          dispatch(alertActions.error("Enter valid Pincode number"));
        } else if (!pan_no && Number(total_amount) > 200000) {
          dispatch(alertActions.error("Enter pancard number"));
        } else if (pan_no && !pattern.test(pan_no)) {
          dispatch(alertActions.error("Enter valid pancard number"));
        } else if (!image && Number(total_amount) > 200000) {
          dispatch(alertActions.error("Upload the image of pancard"));
        } else if (alternate_mobile && !filter.test(alternate_mobile)) {
          dispatch(alertActions.error("Enter valid alternate mobile number"));
        } else if (!acceptCheck && Number(total_amount) > 200000) {
          dispatch(alertActions.error("Select the terms and conditions"));
        } else {
          ProductService.servicecheck(pincode).then((response) => {
            if (check === true) {
              if (response?.data?.status?.data?.Officename) {
                dispatch(alertActions.success(response?.data?.status?.message));

                ProductService.checkout(
                  name,
                  mobile,
                  alternate_mobile,
                  pincode,
                  address,
                  billing_state1,
                  charge ? charge : 0,
                  city,
                  billing_city,
                  billing_pincode,
                  billing_address,
                  billing_state1,
                  product_id,
                  total_amount,
                  gstAmount,
                  pan_no,
                  selectedFile,
                  lname,
                  billing_district1,
                  billing_district1,
                  locality,
                  landmarkValue,
                  billing_locality,
                  billing_landmarkValue,
                  deliverydate.split(" ")[0],
                  goldRate22,
                  goldRate18,
                  silverRate
                )
                  .then((response) => {
                    if (response?.data?.status?.code === 1) {
                      dispatch(
                        alertActions.error(response?.data?.status?.message)
                      );
                    } else {
                      const newRandomNumber = generateRandomNumber();
                      setRandomNumber(newRandomNumber);
                      if (ipAddress && userAgent) {
                        setUpdatedPixelData((prevData) => [
                          ...prevData,
                          eventData,
                        ]);
                      }
                      setCheckOutData(response);
                    }
                  })
                  .catch((error) => {
                    console.log("Errorreg", error);
                  });

                ProductService.updateBillingAddress(
                  billing_address,
                  billing_locality,
                  billing_city,
                  billing_state,
                  billing_district,
                  billing_pincode,
                  billing_landmarkValue,
                  user_id
                );
              } else if (response?.data?.status?.data?.COD_OPTION) {
                ProductService.checkout(
                  name,
                  mobile,
                  alternate_mobile,
                  pincode,
                  address,
                  billing_state1,
                  charge ? charge : 0,
                  city,
                  billing_city,
                  billing_pincode,
                  billing_address,
                  billing_state1,
                  product_id,
                  total_amount,
                  gstAmount,
                  pan_no,
                  selectedFile,
                  lname,
                  billing_district1,
                  billing_district1,
                  locality,
                  landmarkValue,
                  billing_locality,
                  billing_landmarkValue,
                  deliverydate.split(" ")[0],
                  goldRate22,
                  goldRate18,
                  silverRate
                )
                  .then((response) => {
                    if (response?.data?.status?.code === 1) {
                      dispatch(
                        alertActions.error(response?.data?.status?.message)
                      );
                    } else {
                      const newRandomNumber = generateRandomNumber();
                      setRandomNumber(newRandomNumber);
                      if (ipAddress && userAgent) {
                        setUpdatedPixelData((prevData) => [
                          ...prevData,
                          eventData,
                        ]);
                      }
                      setCheckOutData(response);
                    }
                  })
                  .catch((error) => {
                    console.log("Errorreg", error);
                  });

                ProductService.updateBillingAddress(
                  billing_address,
                  billing_locality,
                  billing_city,
                  billing_state,
                  billing_district,
                  billing_pincode,
                  billing_landmarkValue,
                  user_id
                );
              } else {
                dispatch(alertActions.error(response?.data?.status?.message));
              }
            } else {
              if (response?.data?.status?.data?.Officename) {
                dispatch(alertActions.success(response?.data?.status?.message));

                ProductService.checkout(
                  name,
                  mobile,
                  alternate_mobile,
                  pincode,
                  address,
                  state1,
                  charge ? charge : 0,
                  city,
                  billing_city,
                  billing_pincode,
                  billing_address,
                  billing_state1,
                  product_id,
                  total_amount,
                  gstAmount,
                  pan_no,
                  selectedFile,
                  lname,
                  billing_district1,
                  district1,
                  locality,
                  landmarkValue,
                  billing_locality,
                  billing_landmarkValue,
                  deliverydate.split(" ")[0],
                  goldRate22,
                  goldRate18,
                  silverRate
                )
                  .then((response) => {
                    if (response?.data?.status?.code === 1) {
                      dispatch(
                        alertActions.error(response?.data?.status?.message)
                      );
                    } else {
                      const newRandomNumber = generateRandomNumber();
                      setRandomNumber(newRandomNumber);
                      if (ipAddress && userAgent) {
                        setUpdatedPixelData((prevData) => [
                          ...prevData,
                          eventData,
                        ]);
                      }
                      setCheckOutData(response);
                    }
                  })
                  .catch((error) => {
                    console.log("Errorreg", error);
                  });

                ProductService.updateBillingAddress(
                  billing_address,
                  billing_locality,
                  billing_city,
                  billing_state,
                  billing_district,
                  billing_pincode,
                  billing_landmarkValue,
                  user_id
                );
              } else if (response?.data?.status?.data?.COD_OPTION) {
                ProductService.checkout(
                  name,
                  mobile,
                  alternate_mobile,
                  pincode,
                  address,
                  state1,
                  charge ? charge : 0,
                  city,
                  billing_city,
                  billing_pincode,
                  billing_address,
                  billing_state1,
                  product_id,
                  total_amount,
                  gstAmount,
                  pan_no,
                  selectedFile,
                  lname,
                  billing_district1,
                  district1,
                  locality,
                  landmarkValue,
                  billing_locality,
                  billing_landmarkValue,
                  deliverydate.split(" ")[0],
                  goldRate22,
                  goldRate18,
                  silverRate
                )
                  .then((response) => {
                    if (response?.data?.status?.code === 1) {
                      dispatch(
                        alertActions.error(response?.data?.status?.message)
                      );
                    } else {
                      const newRandomNumber = generateRandomNumber();
                      setRandomNumber(newRandomNumber);
                      if (ipAddress && userAgent) {
                        setUpdatedPixelData((prevData) => [
                          ...prevData,
                          eventData,
                        ]);
                      }
                      setCheckOutData(response);
                      let text = response?.data?.callback_url;
                      const myArray = text.split("/");
                      const resOrderId = myArray[6];

                      ProductService.getSingleOrder(resOrderId).then(
                        (data) => {}
                      );
                    }
                  })
                  .catch((error) => {
                    console.log("Errorreg", error);
                  });

                ProductService.updateBillingAddress(
                  billing_address,
                  billing_locality,
                  billing_city,
                  billing_state,
                  billing_district,
                  billing_pincode,
                  billing_landmarkValue,
                  user_id
                );
              } else {
                dispatch(alertActions.error(response?.data?.status?.message));
              }
            }
          });
        }
      }
    }
  }

  return {
    name,
    mobile,
    alternate_mobile,
    billing_address,
    billing_city,
    billing_district,
    billing_landmarkValue,
    billing_locality,
    billing_pincode,
    billing_state,
    address,
    locality,
    city,
    state,
    landmarkValue,
    pincode,
    pan_no,
    changeNameHandler,
    changeMobileNoHandler,
    changeAlternateMobileNoHandler,
    changeAddressHandler,
    changeLocalityHandler,
    changeCityHandler,
    changeStateHandler,
    changeLandMarkHandler,
    changePincodeHandler,
    changePancardHandler,
    changeBillingAddressHandler,
    changeBillingCityHandler,
    changeBillingDistrictHandler,
    changeBillingLandMarkHandler,
    changeBillingLocalityHandler,
    changeBillingPincodeHandler,
    changeBillingStateHandler,
    submitted,
    handleSubmit,
    onSelectFile,
    image,
    selectedFile,
    checkOutData,
    storeState,
    charge,
    billingStoreState,
    storeDistrict,
    district,
    initialPlaceDist,
    temp,
    changeDistrictHandler,
    deliverydate,
    handleClose,
    saveAddress,
    showButton,
    paymentMode,
    changePaymentModeHandler,
    fileName,
    billingStoreDistrict,
    changeCheckboxHandler,
    isCheckedAddress,
    check,
    deliverydate,
    changeLastNameHandler,
    lname,
    state2,
    changeAcceptCheckboxHandler,
    acceptCheck,
    id,
    btnshow,
    deliverable,
    initialPlace,
    formattedDate,
  };
};
export default useShippingAddressHook;
