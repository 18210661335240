import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import ProfileMenu from "../../_common/ProfileMenu/ProfileMenu";
import { ProductService } from "../../../services/ProductService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import empty_video_shopping from "../../../assets/images/empty-video-shopping.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertActions, userLogout } from "../../../actions";
import "./VideoShopping.css";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useCountHook from "../../../hooks/useCountHook";

const VideoShopping = (tokenExpired) => {
  let history = useNavigate();
  const dispatch = useDispatch();
  const usr_id = useSelector((state) => state.userData.user._id);
  const [data, setData] = useState();
  const [displaydata, setDisplayData] = useState(false);
  const [userData, setUserData] = useState();
  const [activedata, setactive] = useState(1);
  const { wishListCount, cartCount } = useCountHook();
  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("You are logged out. Please Login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);
  const upcomingdata = (val) => {
    setactive(val);
    if (val === 2) {
      ProductService.getvdorequestpast().then((value) => {
        setData(value.data.data.video);
      });
    } else {
      ProductService.getvdorequest().then((value) => {
        setData(value.data.data.video);
      });
    }
  };
  useEffect(() => {
    ProductService.getvdorequest().then((value) => {
      setData(value.data.data.video);
    });
  }, []);

  const readless = () => {
    setUserData("");
    setDisplayData(false);
  };
  const readmore = (id) => {
    setUserData(id);
    setDisplayData(true);
  };
  const pastOrder = () => {
    return (
      <div className="order_main flt">
        <Helmet>
          <link rel="canonical" href={window.location.href} />
        </Helmet>

        <ul className="nav nav-pills" role="tablist">
          <li className="nav-item">
            <a
              className={activedata === 1 ? "nav-link active" : "nav-link "}
              data-toggle="pill"
              onClick={() => upcomingdata(1)}
            >
              Upcoming Calls
            </a>
          </li>
          <li className="nav-item">
            <a
              className={activedata === 2 ? "nav-link active" : "nav-link "}
              data-toggle="pill"
              onClick={() => upcomingdata(2)}
            >
              Past Calls
            </a>
          </li>
        </ul>
      </div>
    );
  };

  const shopping = () => {
    return (
      <div class="profile_container flt">
        <div class="row">
          <ProfileMenu />
          <div class="col-sm-9">
            {pastOrder()}
            <div className="row vdo_shopp">
              {data && data.length > 0 ? (
                data.map((val) => (
                  <div className="col-sm-6">
                    <ul class="video_shop flt">
                      {/* <li><span>Meeting Link</span><span><a href="#" target="_blank">meet.google.com/qww-hcuy-sss</a></span></li> */}
                      <li>
                        <span>
                          Name<b>:</b>
                        </span>
                        <span className="namedata">
                          {val?.name ? val.name : ""}
                        </span>
                        {val &&
                        val.confirm_status &&
                        val.confirm_status === 1 ? (
                          <span className="aprove">
                            <i
                              className="fa fa-check-circle "
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </li>
                      <li>
                        <span>
                          Link<b>:</b>
                        </span>
                        <span>
                          <a href={val?.url} target="_blank" rel="noreferrer">
                            {val?.url ? val.url : ""}
                          </a>
                        </span>
                      </li>
                      <li>
                        <span>
                          Email<b>:</b>
                        </span>
                        <span>{val?.email ? val.email : ""}</span>
                      </li>
                      <li>
                        <span>
                          Language<b>:</b>
                        </span>
                        <span>{val?.language ? val.language : ""}</span>
                      </li>
                      <li>
                        <span>
                          Phone Number<b>:</b>
                        </span>
                        <span>
                          {val?.mobile_number ? val.mobile_number : ""}
                        </span>
                      </li>
                      {userData !== val._id && (
                        <span className="readmore">
                          {" "}
                          <li
                            onClick={() => {
                              readmore(val?._id);
                            }}
                          >
                            <a className="read">View More...</a>
                          </li>
                        </span>
                      )}
                      {displaydata && userData === val._id && (
                        <div>
                          <li>
                            <span>
                              Selected Date<b>:</b>
                            </span>
                            <span>{val?.date ? val.date : ""}</span>
                          </li>

                          <li>
                            <span>
                              Selected Time<b>:</b>
                            </span>
                            <span>{val?.time ? val.time : ""}</span>
                          </li>

                          <li>
                            <span>
                              Category<b>:</b>
                            </span>
                            <span>
                              {val && val.category ? val.category : ""}
                            </span>
                          </li>

                          <li>
                            <span>
                              Sub Category<b>:</b>
                            </span>
                            <span>
                              {val?.sub_category ? val.sub_category : ""}
                            </span>
                          </li>
                          <li>
                            <span>
                              City/Town<b>:</b>
                            </span>
                            <span>{val?.city ? val.city : ""}</span>
                          </li>
                          <li>
                            <span>
                              Purpose of videocall<b>:</b>
                            </span>
                            <span>
                              {val?.purpose_video ? val.purpose_video : ""}
                            </span>
                          </li>
                          <li>
                            <span>
                              Message<b>:</b>
                            </span>
                            <span>{val?.message ? val.message : ""}</span>
                          </li>
                          {displaydata && userData === val._id && (
                            <span className="readmore">
                              <li
                                onClick={() => {
                                  readless(val._id);
                                }}
                              >
                                <a className="read">View Less...</a>
                              </li>
                            </span>
                          )}
                        </div>
                      )}
                    </ul>
                  </div>
                ))
              ) : (
                <div class="empty_order flt">
                  <div class="empty_order_image flt">
                    <LazyLoadImage src={empty_video_shopping} alt="image" />
                  </div>
                  <div class="empty_order_head flt">
                    Video shopping is not scheduled
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header count={wishListCount} cart={cartCount} />
      {/* {pastOrder()} */}
      {shopping()}
      <Footer />
    </div>
  );
};
export default VideoShopping;
