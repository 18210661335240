import useCartHooks from "./UseCartHooks";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import cartimg from "../../../assets/images/empty-cart.png";
import { useNavigate, Link } from "react-router-dom";
import loader from "../../../assets/images/loader.png";
import { useEffect } from "react";
import { commaValidation } from "../../../utility";
import { alertActions } from "../../../actions";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../actions/UserAction";
import "./Cart.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Cart = (tokenExpired) => {
  const dispatch = useDispatch();
  let history = useNavigate();
  const {
    cart,
    image_url,
    imgChange,
    checkOutPage,
    displayProduct,
    cartdata,
    loading,
    sample,
    promo,
    handleChange,
    submitPromo,
    deleteCart,
    promodata,
    wishListCount,
    cartCount,
    deletePromo,
  } = useCartHooks();
  const continueShopping = () => {
    history("/product-list");
  };

  //new code for no-index
  const location = useLocation();

  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("You are logged out. Please Login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);
  const gross =
    cartdata && cartdata.product && cartdata.product.c_gross_value
      ? cartdata.product.c_gross_value.replaceAll(",", "")
      : 0;
  let saved;

  if (
    cartdata?.chain_price === null ||
    cartdata?.chain_price === "0" ||
    cartdata?.chain_price === undefined
  ) {
    saved =
      cartdata?.product?.chain_c_gross_value &&
      cartdata?.product?.chain_c_gross_value !== "null"
        ? cartdata?.product?.chain_c_gross_value.replaceAll(",", "")
        : 0;
  } else {
    if (cartdata && cartdata.chain_price === null) {
      saved = 0;
    } else {
      saved =
        cartdata?.chain_price !== "null"
          ? cartdata?.chain_price.replaceAll(",", "")
          : 0;
    }
  }

  // alert(cartdata?.product?.pro_details?.lace_cost!=="null")
  const total = (parseFloat(gross) + parseFloat(saved)).toFixed(2);

  const offervalue =
    cartdata && cartdata.product && cartdata.product.c_offer_value
      ? cartdata.product.c_offer_value.replaceAll(",", "")
      : 0;

  const gtotal = (parseFloat(total) + parseFloat(offervalue)).toFixed(2);

  const imagedisp = () => {
    return (
      <div class="col-sm-12">
        <div class="empty_order flt">
          <div class="empty_order_image flt">
            <LazyLoadImage src={cartimg} alt="image" />
          </div>
          <div class="empty_order_head flt">Your cart is empty</div>
          <div class="emptycart_btn flt">
            <button onClick={continueShopping}>Continue Shopping</button>
          </div>
        </div>
      </div>
    );
  };

  const emptypg = () => {
    return (
      <div className="cartpg">
        <LazyLoadImage src={loader} />
      </div>
    );
  };

  const CartDetails = () => {
    return (
      <div class="cart_container flt">
        <div class="breadcrumbs flt">
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <Link to="/">HOME</Link> / <span>{cartdata?.product?.name}</span>
        </div>

        <div class="cart_main flt">
          <div class="cart_cont flt">
            <div class="row">
              <div class="col-sm-8 cart_col_left">
                <div class="cart_left flt">
                  <div class="cart_box flt">
                    <div class="row">
                      <div class="col-sm-4">
                        <div
                          class="cart_image flt"
                          onClick={() =>
                            displayProduct(cartdata ? cartdata : "")
                          }
                        >
                          {cartdata?.product?.pro_images?.[0]?.image ? (
                            <LazyLoadImage
                              src={`${image_url}${
                                cartdata &&
                                cartdata?.product?.pro_images?.[0]?.image
                              }`}
                              alt="image"
                            />
                          ) : (
                            <LazyLoadImage src={sample} class="example" />
                          )}
                        </div>
                      </div>
                      <div class="col-sm-8">
                        <div class="cart_txt flt">
                          <div className="cart_vert flt">
                            <div
                              onClick={() =>
                                displayProduct(cartdata ? cartdata : "")
                              }
                            >
                              <div className="cart_front flt">
                                <div className="cart_renow flt">
                                  <div class="cart_name flt">
                                    {cartdata &&
                                    cartdata.product &&
                                    cartdata.product.name
                                      ? cartdata.product.name
                                      : ""}
                                  </div>
                                  <div class="cart_weight flt">
                                    {cartdata &&
                                    cartdata.product &&
                                    cartdata.product.pro_details &&
                                    cartdata.product.pro_details.weight &&
                                    cartdata.product.pro_details.weight !== 0 &&
                                    cartdata.product.pro_details.weight !== "0"
                                      ? cartdata.product.pro_details.weight
                                      : ""}
                                    {cartdata?.product?.pro_details?.weight &&
                                    cartdata?.product?.pro_details?.weight !==
                                      0 &&
                                    cartdata?.product?.pro_details?.weight !==
                                      "0"
                                      ? "GM"
                                      : ""}
                                  </div>
                                </div>
                                <div class="cart_rate flt">
                                  <b>
                                    {" "}
                                    <span className="rs_font"> ₹ </span>
                                  </b>
                                  {commaValidation(
                                    cartdata &&
                                      cartdata.product &&
                                      cartdata.product.pro_details
                                        .include_backchain === "YES"
                                      ? cartdata.product.c_gross_value
                                      : cartdata.product.c_gross_value
                                  )}
                                </div>
                              </div>

                              {cartdata?.chain_price === null ||
                              cartdata?.product?.pro_details?.is_chain === 0 ? (
                                ""
                              ) : (
                                <div className="cart_name flt">
                                  Backchain Details :{" "}
                                </div>
                              )}
                              {cartdata?.product?.pro_details?.is_chain ===
                              0 ? (
                                ""
                              ) : (
                                <div className="cart_weight flt">
                                  Weight :&nbsp;
                                  {cartdata?.product?.pro_details
                                    ?.chain_weight &&
                                  cartdata?.chain_price !== "null"
                                    ? cartdata?.product?.pro_details
                                        ?.chain_weight
                                    : "0"}{" "}
                                  GM &nbsp;&nbsp; Price :
                                  <span className="rs_font"> ₹ </span>
                                  {cartdata?.product?.chain_c_gross_value &&
                                  cartdata?.chain_price !== "null"
                                    ? cartdata?.product?.chain_c_gross_value
                                    : "0"}{" "}
                                </div>
                              )}
                              {cartdata?.product?.pro_details?.size === null ? (
                                ""
                              ) : (
                                <span className="cart_name flt">
                                  Item Size :{" "}
                                </span>
                              )}
                              {cartdata?.product?.pro_details?.size === null ? (
                                ""
                              ) : (
                                <span className="cart_weight flt">
                                  Size: {cartdata?.product?.pro_details?.size}{" "}
                                </span>
                              )}
                            </div>

                            <div class="cart_weight flt"></div>
                          </div>
                          <div class="cart_btn flt">
                            <button
                              onClick={() =>
                                imgChange(cartdata ? cartdata : "")
                              }
                            >
                              Move to Wishlist
                            </button>
                            <button onClick={() => deleteCart(cartdata)}>
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 cart_col_right">
                <div class="card_right flt">
                  <div class="coupon_box flt">
                    <div class="promo_applied flt">
                      <span>
                        Promo Code:
                        {promodata &&
                        promodata.data &&
                        promodata.data.data &&
                        promodata.data.data.promocode &&
                        promodata.data.data.promocode.coupon_code
                          ? promodata.data.data.promocode.coupon_code
                          : ""}{" "}
                      </span>{" "}
                      <i
                        class="far fa-trash-alt"
                        aria-hidden="true"
                        onClick={() =>
                          deletePromo(
                            promodata?.data?.data?.promocode?.coupon_code
                          )
                        }
                      ></i>
                    </div>
                    <div class="cart_coupon flt">
                      <input
                        type="text"
                        placeholder="Enter coupon code"
                        name="promo"
                        value={promo}
                        onChange={handleChange}
                      />
                      <button onClick={submitPromo}>Apply</button>
                    </div>
                  </div>
                  <div class="check_head flt">Order Summary</div>
                  <ul class="cart_desc flt">
                    <li>
                      Total [
                      {cartdata && cartdata.quantity ? cartdata.quantity : ""}{" "}
                      item]
                      <span>
                        <b>
                          {" "}
                          <span className="rs_font"> ₹ </span>
                        </b>
                        {cartdata?.chain_price === "0" &&
                        cartdata?.product?.pro_details?.include_backchain ===
                          "NO"
                          ? commaValidation(cartdata?.product?.final_amt)
                          : commaValidation(total)}
                      </span>
                    </li>
                    <li>
                      Discount
                      <span>
                        <b>
                          {" "}
                          <span className="rs_font"> ₹ </span>
                        </b>
                        {commaValidation(
                          cartdata &&
                            cartdata.product &&
                            cartdata.product.c_offer_value
                            ? cartdata.product.c_offer_value
                            : "0"
                        )}
                      </span>
                    </li>
                    <li>
                      Total
                      <span>
                        <b>
                          {" "}
                          <span className="rs_font"> ₹ </span>
                        </b>
                        {cartdata?.chain_price === "0" &&
                        cartdata?.product?.pro_details?.include_backchain ===
                          "NO"
                          ? commaValidation(cartdata?.product?.final_amt)
                          : commaValidation(gtotal)}
                      </span>
                    </li>
                  </ul>
                  <button
                    class="checkout_btn flt"
                    onClick={() => checkOutPage(total)}
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return <Header count={wishListCount} cart={cartCount} />;
  };
  const renderFooter = () => {
    return <Footer />;
  };

  return (
    <div>
      {renderHeader()}
      {loading === "notempty" && cartdata && CartDetails()}
      {loading === "empty" && !cartdata && imagedisp()}
      {loading === "loading" && emptypg()}
      {renderFooter()}
    </div>
  );
};
export default Cart;
