import "./App.css";
import { Route, Navigate, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PolkiPage from "./component/pages/AbharanPolki/Polki";
import AboutUs from "./component/pages/AbharanAboutUs/AboutUs";
import Contact from "./component/pages/AbharanContactScreen/Contact";
import EmptyCart from "./component/pages/AbharanEmptyCart/EmptyCart";
import FeedBack from "./component/pages/AbharanFeedback/FeedBack";
import Faq from "./component/pages/AbharanFaq/Faq";
import ForgotPassword from "./component/pages/AbharanForgotScreen/ForgotPassword";
import GetOtp from "./component/pages/AbharanGetOtpScreen/GetOtp";
import Home from "./component/pages/AbharanHomeScreen/HomePage";
import JewelleryCare from "./component/pages/AbharanJewelleryCare/JewelleryCare";
import Login from "./component/pages/AbharanLoginScreen/Login";
import ProductList from "./component/pages/AbharanProductList/ProductList";
import MyDetails from "./component/pages/AbharanMyDetails/MyDetails";
import OrderDetails from "./component/pages/AbharanOrderDetails/OrderDetails";
import OrderDetailsEmpty from "./component/pages/AbharanOrderDetailsEmpty/OrderDetailsEmpty";
import Privacy from "./component/pages/AbharanPrivacyPolicy/PrivacyPolicy";
import ProductDetails from "./component/pages/AbharanProductDetails/ProductDetails";
import Registration from "./component/pages/AbharanRegistrationScreen/Registration";
import ReturnPolicy from "./component/pages/AbharanReturnPolicy/ReturnPolicy";
import ShippingAddress from "./component/pages/AbharanShippingAddress/ShippingAddress";
import ThankYou from "./component/pages/AbharanThankYou/ThankYou";
import VideoShopping from "./component/pages/AbharanVideoShopping/VideoShopping";
import WishList from "./component/pages/AbharanWishList/WishList";
import Cart from "./component/pages/AbharanCart/Cart";
import GoldRateToday from "./component/pages/AbharanGoldRateToday/GoldRateToday";
import { Provider } from "react-redux";
import store from "./store";
import { useEffect, useState } from "react";
import { header } from "./actions/index";
import { alertActions } from "./actions/alert.actions";
import CookieConsent from "react-cookie-consent";
import ScrollToTop from "./component/_common/ScrollTop/ScrollTop";
import { useNavigate } from "react-router-dom";
import { ProductService } from "./services";
import BuyBackPolicy from "./component/pages/AbharanBuyBackPolicy/BuyBackPolicy";
import ProductListNew from "./component/pages/AbharanProductListNew/ProductListNew";
import { UserService } from "./services";
import crypto from "crypto";

//App

const Main = () => {
  let history = useNavigate();
  const location = useLocation();
  const [values, setvalues] = useState("");
  const [change, setChange] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useSelector((state) => state?.alert);
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;

  const menuData = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [randomNumbersss, setRandomNumber] = useState("");
  const [hashedData, setHashedData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [eventData, setEventData] = useState("");
  const [userAgent, setUserAgent] = useState("");

  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000); // Getting Unix timestamp in seconds
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);
  }, []);

  const generateRandomNumber = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth() + 1; // Month is 0-indexed, so add 1
    // const date = currentDate.getDate();
    // const hours = currentDate.getHours();
    // const minutes = currentDate.getMinutes();
    // const seconds = currentDate.getSeconds();
    // const milliseconds = currentDate.getMilliseconds();

    // // Combine all parts into a single number
    // const randomNumber = parseInt(
    //   `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}`,
    //   10
    // );

    // return randomNumber;
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  useEffect(() => {
    const hashObject = (obj) => {
      const hashedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const hash = crypto
            .createHash("sha256")
            .update(String(value))
            .digest("hex");
          hashedObj[key] = hash;
        }
      }
      return hashedObj;
    };
    const hashedSingleObject = hashObject(menuData);
    setHashedData(hashedSingleObject);

    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashedData.email],
      ph: [hashedData.mobile_no, hashedData.alternate_number],
      ln: [hashedData.last_name],
      fn: [hashedData.first_name],
      ct: [hashedData.city, hashedData.shipping_city],
      st: [hashedData.state, hashedData.shipping_state],
      zp: [hashedData.pincode, hashedData.shipping_pincode],
    };

    setEventData({
      event_name: "FindLocation",
      event_time: unixTimestamp,
      action_source: "website",
      event_source_url: currentUrl,
      event_id: randomNumbersss.toString(),
      user_data: user_data,
    });
  }, [menuData, ipAddress, userAgent, change]);

  useEffect(() => {
    if (updatedPixelData.length) {
      UserService.getUserData(updatedPixelData)
        .then((response) => {
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }, [updatedPixelData]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setvalues(
        `Latitude:${position.coords.latitude}Longitude:${position.coords.longitude}`
      );
      localStorage.setItem("lattitude", position.coords.latitude);
      localStorage.setItem("longitude", position.coords.longitude);
      if (ipAddress && userAgent) {
        setUpdatedPixelData((prevData) => [...prevData, eventData]);
      }
      if (isUser) {
        ProductService.geolocationtrack(
          localStorage.getItem("longitude"),
          localStorage.getItem("lattitude")
        );
      }
    });
  }, [change]);

  //title

  //   useEffect(() => {
  //     document.title = `Buy {category_name} | Latest {category_name} Designs | Abharan
  //  - ${location.pathname}`;
  //   }, [location]);

  // alert(localStorage.getItem("pathname"))
  //time calculation
  var today = new Date();
  const presentTime = today;

  const expiresIn = localStorage.getItem("expiresIn");
  const loginTime = localStorage.getItem("loginTime");
  const diffTime = Date.parse(presentTime) - Date.parse(loginTime);
  //session expired
  let tokenExpired = false;
  if (Number(expiresIn) <= Number(diffTime)) {
    tokenExpired = true;
  }

  useEffect(() => {
    setTimeout(() => dispatch(alertActions.clear()), 3000);
  }, [alert.message]);

  const redirectprivacy = () => {
    setChange(!change);
    // history("/privacy");
  };

  return (
    <div>
      {alert.message && (
        <div className={`alert ${alert.type}`}>{alert.message}</div>
      )}
      <ScrollToTop />
      <CookieConsent
        className="pqr"
        location="bottom"
        buttonText="Close"
        cookieName="Abharan"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b" }}
        expires={150}
        // acceptOnScroll={true}
        hideOnAccept={true}
        // enableDeclineButton
        onAccept={() => redirectprivacy()}
        cookieValue={values}
      >
        We use cookies to optimise your visits. By continuing to browse our
        website, you are accepting our cookies and{" "}
        {
          <a className="clickPrivacy" href="/privacy">
            privacy policy.
          </a>
        }
      </CookieConsent>
      <Routes>
        <Route path="/New-Polki" element={<PolkiPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/cart" element={<Cart tokenExpired={tokenExpired} />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/product-list/:title" element={<ProductList />} /> */}
        <Route path="/product-list/:title" element={<ProductListNew />} />

        <Route path="/gold-rate-today" element={<GoldRateToday />} />
        <Route
          path="/feedback/:id"
          element={<FeedBack tokenExpired={tokenExpired} />}
        />
        <Route
          path="/order-details"
          element={<OrderDetails tokenExpired={tokenExpired} />}
        />
        <Route
          path="/thank-you"
          element={<ThankYou tokenExpired={tokenExpired} />}
        />
        <Route
          path="/empty-cart"
          element={<EmptyCart tokenExpired={tokenExpired} />}
        />
        <Route path="/Faq" element={<Faq />} />
        <Route path="/getOtp" element={<GetOtp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/jewellery-care" element={<JewelleryCare />} />

        <Route
          path="/my-details"
          element={<MyDetails tokenExpired={tokenExpired} />}
        />
        <Route
          path="/order-details-empty"
          element={<OrderDetailsEmpty tokenExpired={tokenExpired} />}
        />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/buy-back-policy" element={<BuyBackPolicy />} />

        <Route
          path="/product-details/:title/:prodname/:SKU"
          element={<ProductDetails />}
        />
        <Route
          path="/registration"
          element={isUser ? <Navigate to="/" /> : <Registration />}
        />

        <Route
          path="/login"
          element={isUser ? <Navigate to="/" /> : <Login />}
        />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route
          path="/wishlist"
          element={<WishList tokenExpired={tokenExpired} />}
        />
        <Route
          path="/video-shopping"
          element={<VideoShopping tokenExpired={tokenExpired} />}
        />
        <Route
          path="/shipping-address"
          element={<ShippingAddress tokenExpired={tokenExpired} />}
        />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

export default function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}
