import google from "../../../assets/images/google-icon.png";
import home_banner2 from "../../../assets/images/home-banner2.jpg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../../services";

const useGetOtpHook = (location) => {
  let history = useNavigate();
  const [otp, setOtp] = useState("");
  const [new_password, setNew_password] = useState("");
  const [mobile, setMobile] = useState(location?.state?.mobile);
  const [re_password, setRe_password] = useState("");
  const [loggedin, setLoggedin] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [getOtpData, setGetOtpData] = useState({});
  const [getOtpError, setGetOtpError] = useState("");

  useEffect(() => {
    if (getOtpData?.data?.status?.code === 0) {
      setTimeout(() => {
        history("/login");
      }, 1500);

      setSubmitted(false);
      setTimeout(() => {
        setLoggedin(false);
      }, 50);
    } else {
      setLoggedin(true);
      setGetOtpError(getOtpData?.data?.status?.message);
    }
  }, [getOtpData]);

  function handleOtpChange(e) {
    setGetOtpError(undefined);
    setOtp(e.target.value);
  }
  function handleRePasswordChange(e) {
    setGetOtpError(undefined);
    setRe_password(e.target.value);
  }
  function handleNewPasswordChange(e) {
    setGetOtpError(undefined);
    setNew_password(e.target.value);
  }
  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (otp && new_password && re_password) {
      if (otp && otp.length !== 5) {
        setGetOtpError("Invalid OTP");
      } else if (new_password !== re_password) {
        setGetOtpError("Both password must match");
      } else {
        UserService.getOtp(otp, new_password, mobile)
          .then((response) => {
            setGetOtpData(response);
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  }

  return {
    google,
    home_banner2,
    otp,
    new_password,
    re_password,
    submitted,
    getOtpError,
    loggedin,
    handleOtpChange,
    handleNewPasswordChange,
    handleRePasswordChange,
    handleSubmit,
  };
};
export default useGetOtpHook;
