import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, userLogin, userLogout } from "../../../actions";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../../services";
import crypto from "crypto";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

function useFooterHooks() {
  //to display login and registration button at the bottom of home page
  const [isButtonShow, setButtonShow] = useState(false);

  const menuData = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [randomNumbersss, setRandomNumber] = useState("");
  const [hashedData, setHashedData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: location.pathname,
      },
    });
    console.log(window.dataLayer, "DataLayer");
    if (window.location.pathname === "/") {
      //setting state value as true
      setButtonShow(true);
    } else {
      setButtonShow(false);
    }
    const currentTimestamp = Math.floor(Date.now() / 1000); // Getting Unix timestamp in seconds
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);
  }, []);

  const generateRandomNumber = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth() + 1; // Month is 0-indexed, so add 1
    // const date = currentDate.getDate();
    // const hours = currentDate.getHours();
    // const minutes = currentDate.getMinutes();
    // const seconds = currentDate.getSeconds();
    // const milliseconds = currentDate.getMilliseconds();

    // // Combine all parts into a single number
    // const randomNumber = parseInt(
    //   `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}`,
    //   10
    // );

    // return randomNumber;
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  useEffect(() => {
    const hashObject = (obj) => {
      const hashedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const hash = crypto
            .createHash("sha256")
            .update(String(value))
            .digest("hex");
          hashedObj[key] = hash;
        }
      }
      return hashedObj;
    };
    const hashedSingleObject = hashObject(menuData);
    setHashedData(hashedSingleObject);

    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashedData.email],
      ph: [hashedData.mobile_no, hashedData.alternate_number],
      ln: [hashedData.last_name],
      fn: [hashedData.first_name],
      ct: [hashedData.city, hashedData.shipping_city],
      st: [hashedData.state, hashedData.shipping_state],
      zp: [hashedData.pincode, hashedData.shipping_pincode],
    };

    const eventData = {
      event_name: "PageView",
      event_time: unixTimestamp,
      action_source: "website",
      event_source_url: currentUrl,
      event_id: randomNumbersss.toString(),
      user_data: user_data,
    };

    if (ipAddress && userAgent) {
      // setUpdatedPixelData((prevData) => ({
      //   data: prevData.data ? [...prevData.data, eventData] : [eventData],
      //   test_event_code: "TEST22969",
      // }));

      setUpdatedPixelData((prevData) => [...prevData, eventData]);
    }
  }, [menuData, ipAddress, userAgent]);

  useEffect(() => {
    if (updatedPixelData.length) {
      UserService.getUserData(updatedPixelData)
        .then((response) => {
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }, [updatedPixelData]);

  let history = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(userLogout());
    history("/");
    //dispatch(alertActions.success("Logout Success"));
  };
  const handleLOgin = () => {
    // dispatch(userLogin());
    history("/login");
    dispatch(alertActions.success("Please Login..."));
  };
  //return isButtonShow state
  return { isButtonShow, handleLogout, handleLOgin };
}
export default useFooterHooks;
