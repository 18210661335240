import { React } from "react";
import "./Footer.css";
import { Link, useNavigate } from "react-router-dom";
import facebook from "../../../assets/images/facebook.png";
import instagram from "../../../assets/images/instagram.png";
import youtube from "../../../assets/images/youtube.png";
import payment_supported from "../../../assets/images/payment-supported.png";
import whatsapp_icon from "../../../assets/images/whatsapp_icon.png";
import { HomeService } from "../../../services/HomeService";
import useFooterHooks from "./FooterFunction";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { UserService } from "../../../services";
import crypto from "crypto";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = (props) => {
  let getSku = window.location.href.split("/")[6];

  const { isButtonShow, handleLogout, handleLOgin } = useFooterHooks();
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;

  const menuData = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [randomNumbersss, setRandomNumber] = useState("");
  const [hashedData, setHashedData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [userAgent, setUserAgent] = useState("");

  let history = useNavigate();
  const returndata = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000); // Getting Unix timestamp in seconds
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);
  }, []);

  const WhatsappCount = () => {
    HomeService.getWhatsapp();
    const hashObject = (obj) => {
      const hashedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const hash = crypto
            .createHash("sha256")
            .update(String(value))
            .digest("hex");
          hashedObj[key] = hash;
        }
      }
      return hashedObj;
    };
    const hashedSingleObject = hashObject(menuData);

    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashedSingleObject.email],
      ph: [hashedSingleObject.mobile_no, hashedSingleObject.alternate_number],
      ln: [hashedSingleObject.last_name],
      fn: [hashedSingleObject.first_name],
      ct: [hashedSingleObject.city, hashedSingleObject.shipping_city],
      st: [hashedSingleObject.state, hashedSingleObject.shipping_state],
      zp: [hashedSingleObject.pincode, hashedSingleObject.shipping_pincode],
    };

    if (ipAddress && userAgent) {
      const updatedPixelDataUpdater = [
        {
          event_name: "Contact",
          event_time: unixTimestamp,
          action_source: "website",
          event_source_url: currentUrl,
          event_id: randomNumbersss.toString(),
          user_data: user_data,
        },
      ];
      // const updatedPixelDataUpdater = eventData;

      UserService.getUserData(updatedPixelDataUpdater)
        .then((response) => {
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  const [footerShow1, setFooterShow1] = useState(false);
  const [footerShow2, setFooterShow2] = useState(false);
  const [footerShow3, setFooterShow3] = useState(false);
  const [footerShow4, setFooterShow4] = useState(false);

  const pathname = window?.location?.pathname;

  //code for sub-cat <h1> name
  var str = props?.locationPath?.replace(/[-\r]/g, " ");
  let words = str?.split(" ");
  let result = "";

  for (let i = 0; i < words?.length; i++) {
    result += words[i][0].toUpperCase() + words[i].slice(1) + " ";
  }

  const generateRandomNumber = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth() + 1; // Month is 0-indexed, so add 1
    // const date = currentDate.getDate();
    // const hours = currentDate.getHours();
    // const minutes = currentDate.getMinutes();
    // const seconds = currentDate.getSeconds();
    // const milliseconds = currentDate.getMilliseconds();

    // // Combine all parts into a single number
    // const randomNumber = parseInt(
    //   `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}`,
    //   10
    // );

    // return randomNumber;
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  const footer_body = () => {
    return (
      <div>
        <div className="divider_main flt">
          <div className="divider flt">
            {
              <div className="seo_cont">
                <h1>
                  {props.locationPath === "gems-jewellery" ||
                  props.locationPath === "gems" ||
                  props.locationPath === "gold-jewellery" ||
                  props.locationPath === "gold" ||
                  props.locationPath === "pearl-jewellery" ||
                  props.locationPath === "pearl" ||
                  props.locationPath === "diamond-jewellery" ||
                  props.locationPath === "diamond" ||
                  props.locationPath === "silver-jewellery" ||
                  props.locationPath === "silver"
                    ? ""
                    : result}
                </h1>
              </div>
            }

            {window.location.href.split("/")[3] === "" ? (
              <div className="seo_cont flt">
                <div className="seo_cont flt">
                  <h1>ABHARAN JEWELLERS PRIVATE LIMITED</h1>
                  <p>
                    {" "}
                    Welcome to the Official ABHARAN JEWELLERS PRIVATE LIMITED
                    Online site, where we offer an extensive range of stunning
                    pieces that will leave you spellbound. Our collections are
                    crafted with love and care, making sure each piece is
                    unique, elegant, and classic. Our mission is to provide you
                    with the highest quality and certified jewellery that will
                    elevate your style and make you feel special.
                    <br />
                    <br />
                    Abharan Jewellery brand is a well-known, trustworthy and
                    respected brand in India. The company was founded in 1935 at
                    Udupi in Karnataka, and since then it has expanded to
                    multiple locations across India, including Mangaluru,
                    Kundapura, Goa, Shivamogga, Chikmagalur and Uttara Kannada
                    etc.
                    <br />
                    <br />
                    Abharan Jewellers is known for its exquisite designs and
                    high-quality BIS Hallmarked jewellery, including gold,
                    diamond, platinum, and silver pieces. The company offers a
                    wide range of jewellery collections, including traditional,
                    contemporary, and fusion designs to suit different tastes
                    and occasions.
                    <br />
                    <br />
                    Apart from jewellery, Abharan Jewellers also deals with
                    precious stones, silverware, and gift articles. The brand is
                    known for its excellent customer service and has won several
                    awards for its quality and innovative designs.
                  </p>

                  <h2>
                    <Link to="/product-list/gold-jewellery">
                      Gold Jewellery
                    </Link>
                  </h2>
                  <p>
                    {" "}
                    At Abharan, discover the wide range of gold jewellery. Our
                    collection features exquisite pieces crafted from 18k and
                    22k (916) BIS Hallmarked gold. From delicate chains to
                    intricate earrings and stunning rings, our jewellery is
                    designed to elevate any outfit.
                  </p>

                  <h2>
                    <Link to="/product-list/diamond-jewellery">
                      Diamond Jewellery
                    </Link>
                  </h2>
                  <p>
                    {" "}
                    Our exquisite collection of diamond jewellery is sure to
                    leave you spellbound. From classic solitaire rings to
                    delicate diamond necklaces and stunning diamond earrings, we
                    offer a wide range of designs to suit every taste and
                    occasion. Our diamonds are ethically sourced and expertly
                    crafted to ensure the highest quality and brilliance among
                    the 4C's. Abharan sells VVS-clarity EF-colour GIA IGI and
                    SGL certified diamonds.
                  </p>
                  <h2>
                    <Link to="/product-list/pearl-jewellery">
                      Pearl Jewellery
                    </Link>
                  </h2>
                  <p>
                    {" "}
                    Discover the elegance of pearl jewelry. From classic
                    Necklaces, Bangles to Braceletes and Chains, our collection
                    offers a range of styles to suit any occasion. Each pearl is
                    unique, with its own luster and shape, making every piece
                    truly one-of-a-kind.
                  </p>
                  <h2>
                    <Link to="/product-list/silver-jewellery">
                      Silver Jewellery
                    </Link>{" "}
                  </h2>
                  <p>
                    {" "}
                    Add a touch of elegance and sophistication to your look with
                    Abharan Jewellers silver collection. From simple and classic
                    designs to intricate and ornate styles, we have something
                    for every taste and preference. Range includes Silver ear
                    rings, neclaces, chains and leg chains.
                  </p>
                  <h2>
                    <Link to="/product-list/gems-jewellery">Gems</Link>{" "}
                  </h2>
                  <p>
                    {" "}
                    Discover the brilliance of our gemstone collection at
                    Abharan Jewellers. From vivid sapphires to lush emeralds and
                    blood-red rubies, our exquisite gems are hand-selected for
                    their quality and beauty. Adorn yourself or someone special
                    with a classic treasure that will be cherished for
                    generations to come. So why wait? Explore our stunning range
                    of jewellery and find your perfect match today. With
                    low-cost secure shipping and free returns you can shop with
                    confidence. Shop now and join our community of satisfied
                    customers.
                  </p>
                </div>
              </div>
            ) : window.location.href.split("/")[3] === "gold-rate-today" ? (
              <div className="seo_cont flt">
                <h1>Gold Rate Today</h1>
                <p>
                  Find latest Gold Rates Today in India. You can find gold rate
                  in Mangalore, gold rate in Udupi, gold rate in Mumbai, gold
                  rate in Bangalore, gold rate in Hyderabad here. We also
                  provide latest gold price in cities like Delhi, Pune, Jaipur,
                  Indore, Kochi etc.
                </p>
              </div>
            ) : (
              ""
            )}

            {props.locationPath === "gold-jewellery" ||
            props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <h1>Gold Jewellery</h1>
                <p>
                  {" "}
                  Gold jewellery is a classic and versatile investment for any
                  occasion, adding a touch of elegance and sophistication to any
                  outfit. At our online store, we offer a wide variety of gold
                  earrings, pendants and bangles to suit any taste and budget.
                  Abharan specialises in traditional, divine gold jewellery that
                  suit well for weddings and other cultural events and functions
                  and also stocks a wide range of contemporary designs and
                  antique gold jewellery too. Abharan has designs for the
                  Kannadiga bride, Bunt Bride, Konkani Bride and all other South
                  Indian brides.
                </p>
                <h2>
                  <Link to="/product-list/gold-ear-rings-jewellery">
                    Gold Earrings
                  </Link>
                </h2>
                <p>
                  Abharan Jewellers gold earrings designs range from classic
                  studs to more contemporary hoop and drop styles. Crafted from
                  high-quality 18k and 22k gold, our earrings feature exquisite
                  detailing and design. Whether you are looking for a pair of
                  dainty diamond studs or something more statement-making, we
                  have something for everyone. Abharan Jewellers Jhumka earrings
                  designs are very well known for being unique and elegant
                </p>

                <h2>
                  <Link to="/product-list/gold-pendant-jewellery">
                    Gold Pendants
                  </Link>
                </h2>
                <p>
                  Our gold pendants are the perfect way to add a touch of luxury
                  to any outfit. From elegant and delicate chains to bolder
                  statement pendants, we offer a range of styles to suit all
                  tastes.
                </p>

                <h2>
                  <Link to="/product-list/gold-bangles-jewellery">
                    Gold Bangles
                  </Link>
                </h2>
                <p>
                  {" "}
                  Abharan Jewellers gold bangle designs come with the best price
                  and designs. Whether you prefer a simple and classic design or
                  something more bold and ornate, our gold bangles will add a
                  touch of glamour to any outfit. Our bangles are also available
                  in different gold tones and are perfect for stacking or
                  wearing on their own.
                </p>
                <h2>
                  <Link to="/product-list/gold-chain-jewellery">
                    Gold Chains
                  </Link>{" "}
                </h2>
                <p>
                  {" "}
                  Elevate your style with a stunning gold chain. Our collection
                  offers a variety of designs in Gold chains for both men &
                  women. Crafted from high-quality gold, these chains are
                  durable, perfect for everyday wear or special occasions
                </p>
                <h2>
                  <Link to="/product-list/gold-necklace-jewellery">
                    Gold Necklace
                  </Link>
                </h2>
                <p>
                  Make a statement with a beautiful gold necklace. Our selection
                  features a range of styles, from delicate chains to bold
                  pendants and traditional south Indian necklaces. Each piece is
                  crafted intricately to ensure long-lasting quality and
                  elegance. We have elaborate designs like marriage bridal gold
                  necklace, gold choker necklace etc.
                </p>
                <h2>
                  <Link to="/product-list/gold-finger-ring-jewellery">
                    Gold rings
                  </Link>{" "}
                </h2>
                <p>
                  Discover a stunning collection of gold rings for men and
                  women. From classic bands to elaborate designs, our selection
                  features high-quality gold rings in a variety of designs and
                  sizes. With our extensive selection of gold jewellery, you are
                  sure to find the perfect piece to add to your collection. Shop
                  now.
                </p>
              </div>
            ) : props.locationPath === "gold-men-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Gold Men Jewellery. All of our jewellery including gold
                  ornaments for men is made with great precision and expert
                  craftsmanship. We also offer personalized jewellery options,
                  perfect for special occasions like weddings or birthdays.
                  Check our collection today and find your perfect piece! Shop
                  now and get the best prices.
                </p>
              </div>
            ) : props.locationPath === "gold-women-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Gold Women Jewellery offers a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. Each piece showcases beauty,
                  durability and craftmanship. Our categories include gold
                  ornaments for ladies, gold jewellery designs for bride etc.
                  Whether you're looking for a classic or a trendy statement
                  piece, our collection of Gold Women jewellery is the perfect
                  addition to any outfit. Shop now and discover the perfect
                  piece at the best price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-kids-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Gold Kids Jewellery. All of our jewellery, including gold kids
                  jewellery, is made with great precision and expert
                  craftsmanship. We also offer personalized jewellery options,
                  perfect for special occasions like weddings or birthdays.
                  Check our collection today and find your perfect piece! Shop
                  now and get the best prices.
                </p>
              </div>
            ) : props.locationPath === "gold-bangles-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Gold Bangles Jewellery offers a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. Each piece showcases beauty,
                  durability and craftmanship. Our categories include gold kada
                  for men and women, gold bangles for women, daily wear gold
                  bangles, antique gold bangles, bridal bangles set etc. Whether
                  you're looking for a classic or a trendy statement piece, our
                  collection of Gold Bangles jewellery is the perfect addition
                  to any outfit. Shop now and discover the perfect piece at the
                  best price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-ear-rings-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gold Earrings
                  Jewellery. Our gold earrings for women and men, gold stud
                  earrings, small gold earrings designs and our collection is
                  sure to add a touch of sparkle to any occasion. Explore our
                  range of exquisite jewellery and find the perfect piece for
                  your loved one or for yourself. Shop now for the best prices
                  only at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-haram-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Gold Haram Jewellery. All of our jewellery, including gold
                  haram designs, is made with great precision and expert
                  craftsmanship. We also offer personalized jewellery options,
                  perfect for special occasions like weddings or birthdays.
                  Check our collection today and find your perfect piece! Shop
                  now and get the best prices.
                </p>
              </div>
            ) : props.locationPath === "gold-necklace-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gold Necklace
                  Jewellery. Our gold necklace design, gold necklace set, gold
                  choker necklace designs and our collection is sure to add a
                  touch of sparkle to any occasion. Explore our range of
                  exquisite jewellery and find the perfect piece for your loved
                  one or for yourself. Shop now for the best prices only at
                  Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-necklaces" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gold Necklace
                  Jewellery. Our gold necklace design, gold necklace set, gold
                  choker necklace designs and our collection is sure to add a
                  touch of sparkle to any occasion. Explore our range of
                  exquisite jewellery and find the perfect piece for your loved
                  one or for yourself. Shop now for the best prices only at
                  Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-studs-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gold Studs Jewellery.
                  Our gold stud earrings, gold stud earrings for women and men
                  designs and our collection is sure to add a touch of sparkle
                  to any occasion. Explore our range of exquisite jewellery and
                  find the perfect piece for your loved one or for yourself.
                  Shop now for the best prices only at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-pendant-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Gold Pendant Jewellery offers a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. Each piece showcases beauty,
                  durability and craftmanship. Our categories include gold
                  locket, gold pendant for men and women, mangalsutra pendant,
                  chain pendant, gold pendant set, name pendant chain etc.
                  Whether you're looking for a classic or a trendy statement
                  piece, our collection of Gold Pendant jewellery is the perfect
                  addition to any outfit. Shop now and discover the perfect
                  piece at the best price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-finger-ring-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gold Finger Ring
                  Jewellery. Our gold finger ring designs, gold finger ring for
                  women and men, daily wear gold finger rings designs and our
                  collection is sure to add a touch of sparkle to any occasion.
                  Explore our range of exquisite jewellery and find the perfect
                  piece for your loved one or for yourself. Shop now for the
                  best prices only at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-bracelet-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Gold Bracelet Jewellery offers a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. Each piece showcases beauty,
                  durability and craftmanship. Our categories include gold
                  bracelet for men, gold bracelet for women, mangalsutra
                  bracelet, baby gold bracelet, gold chain bracelet etc. Whether
                  you're looking for a classic or a trendy statement piece, our
                  collection of Gold Bracelet jewellery is the perfect addition
                  to any outfit. Shop now and discover the perfect piece at the
                  best price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-armlet-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gold Armlet Jewellery.
                  Our armlet gold design designs and our collection is sure to
                  add a touch of sparkle to any occasion. Explore our range of
                  exquisite jewellery and find the perfect piece for your loved
                  one or for yourself. Shop now for the best prices only at
                  Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-waist-chain-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gold Waist Chain
                  Jewellery. Our gold waist chain for saree, gold hip chains
                  designs and our collection is sure to add a touch of sparkle
                  to any occasion. Explore our range of exquisite jewellery and
                  find the perfect piece for your loved one or for yourself.
                  Shop now for the best prices only at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-chain-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gold Chain Jewellery.
                  Our gold chain for men, gold chain for women, mangalsutra
                  chain designs and our collection is sure to add a touch of
                  sparkle to any occasion. Explore our range of exquisite
                  jewellery and find the perfect piece for your loved one or for
                  yourself. Shop now for the best prices only at Abharan
                  Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-coin-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Gold Coin Jewellery. All of our jewellery, including gold
                  coins, is made with great precision and expert craftsmanship.
                  We also offer personalized jewellery options, perfect for
                  special occasions like weddings or birthdays. Check our
                  collection today and find your perfect piece! Shop now and get
                  the best prices.
                </p>
              </div>
            ) : props.locationPath === "gold-bindi-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Gold Bindi Jewellery. All of our jewellery, including gold
                  bindi, is made with great precision and expert craftsmanship.
                  We also offer personalized jewellery options, perfect for
                  special occasions like weddings or birthdays. Check our
                  collection today and find your perfect piece! Shop now and get
                  the best prices.
                </p>
              </div>
            ) : props.locationPath === "gold-kanti-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Gold Kanti Jewellery. All of our jewellery, including gold
                  kanti necklace, is made with great precision and expert
                  craftsmanship. We also offer personalized jewellery options,
                  perfect for special occasions like weddings or birthdays.
                  Check our collection today and find your perfect piece! Shop
                  now and get the best prices.
                </p>
              </div>
            ) : props.locationPath === "gold-watches-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gold Watches
                  Jewellery. Our gold watches for men, gold watches for women
                  designs and our collection is sure to add a touch of sparkle
                  to any occasion. Explore our range of exquisite jewellery and
                  find the perfect piece for your loved one or for yourself.
                  Shop now for the best prices only at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gold-god-photo-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Gold God Photo Jewellery. All of our jewellery, including gold
                  god photo, is made with great precision and expert
                  craftsmanship. We also offer personalized jewellery options,
                  perfect for special occasions like weddings or birthdays.
                  Check our collection today and find your perfect piece! Shop
                  now and get the best prices.
                </p>
              </div>
            ) : props.locationPath === "gold-voucher-jewellery" ||
              props.locationPath === "gold" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Gold Vouchers Jewellery. All of our jewellery, including gold
                  voucher online, is made with great precision and expert
                  craftsmanship. We also offer personalized jewellery options,
                  perfect for special occasions like weddings or birthdays.
                  Check our collection today and find your perfect piece! Shop
                  now and get the best prices.
                </p>
              </div>
            ) : (
              ""
            )}

            {props.locationPath === "pearl-jewellery" ||
            props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <h1>Pearl Jewellery</h1>
                <p>
                  <Link to="/product-list/pearl-women-jewellery">
                    Pearl jewellery for Women{" "}
                  </Link>
                  adds elegance to any outfit, from classic pearl necklaces to
                  modern pearl drop earrings. They make a great gift for special
                  occasions.
                </p>
                <p>
                  {" "}
                  <Link to="/product-list/pearl-men-jewellery">
                    For men
                  </Link>,{" "}
                  <Link to="/product-list/pearl-jewellery">
                    Pearl jewellery
                  </Link>{" "}
                  is a unique way for men to accessorize, from subtle pearl
                  cufflinks to statement pearl rings. They can elevate any
                  outfit.
                </p>
                <p>
                  <Link to="/product-list/pearl-kids-jewellery">For kids</Link>,
                  Pearl jewellery can be a fun and unique accessory for kids,
                  from pearl bracelets to pearl hair accessories. They add a
                  touch of whimsy to any outfit.
                </p>

                <h2>
                  <Link to="/product-list/pearl-necklace-jewellery">
                    Pearl Necklace
                  </Link>
                </h2>
                <p>
                  A pearl necklace is a classic piece of jewellery that can add
                  a touch of sophistication to any outfit. They come in various
                  lengths and styles, from gold pearl necklaces to pearl choker
                  necklace to pearl necklace sets
                </p>

                <h2>
                  <Link to="/product-list/pearl-bracelet-jewellery">
                    Pearl Bracelets
                  </Link>
                </h2>
                <p>
                  {" "}
                  Pearl bracelets are delicate and feminine, adding a touch of
                  elegance to any wrist. They can be worn alone or stacked with
                  other bracelets for a trendy look.
                </p>

                <h2>
                  <Link to="/product-list/pearl-bangles-jewellery">
                    Pearl Bangles
                  </Link>{" "}
                </h2>
                <p>
                  Also popularly known as Moti Bangles, are a stylish way to
                  wear pearls on your wrist. They come in various sizes and
                  styles, from simple and elegant to more intricate designs.
                </p>

                <h2>
                  <Link to="/product-list/pearl-pendant-jewellery">
                    Pearl Pendant
                  </Link>
                </h2>
                <p>
                  A pearl pendant is a classic and versatile piece of jewellery
                  that can be worn with any outfit. It can be a single pearl or
                  a cluster of pearls set in a variety of metals and popular
                  among them are Gold pearl pendants and Silver pearl pendants
                </p>

                <h2>
                  <Link to="/product-list/pearl-chain-jewellery">
                    {" "}
                    Pearl Chains
                  </Link>
                </h2>
                <p>
                  Pearl chains are a beautiful way to add some elegance to your
                  outfit. They come in various lengths and can be worn alone or
                  layered with other necklaces for a trendy look
                </p>

                <h2>
                  <Link to="/product-list/pearl-studs-jewellery">
                    Pearl Earrnings and Pearl Studs
                  </Link>
                </h2>
                <p>
                  Classic and elegant pearl stud earrings can be worn every day
                  or for special occasions. They come in various sizes and
                  styles, from simple and classic to more intricate designs.
                </p>
              </div>
            ) : props.locationPath === "pearl-men-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  Among our collection of Pearl Men Jewellery we offer a wide
                  range of stunning pieces that are sure to make you shine and
                  is perfect for any occasion. We use only the highest quality
                  materials, including precious stones and metals, to ensure
                  that each piece is both beautiful and durable. Our categories
                  include pearl necklace for men, pearl chain for men, men's
                  pearl earring etc. . Whether you're looking for a classic or a
                  trendy statement piece, our collection of Pearl men jewellery
                  is the perfect addition to any outfit. Shop now and discover
                  the perfect piece at the best price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "pearl-women-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  We offer a wide range of beautifully crafted Pearl jewellery
                  for women and our collection is designed to cater to every
                  taste and style, from classics to trendy statement pieces. All
                  of our jewellery like pearl necklace women, pearl necklace for
                  bride, pearl jewellery for brides etc. are made with
                  high-quality materials and expert craftsmanship, ensuring each
                  piece is a treasure that will last a lifetime. We also offer
                  personalized jewellery options, perfect for special occasions
                  like weddings or birthdays. Browse our selection today and
                  find the perfect piece to add to your jewellery collection!
                  Shop now from Abharan.
                </p>
              </div>
            ) : props.locationPath === "pearl-kids-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Pearl Jewellery for
                  Kids, crafted to perfection by skilled artisans. From infant
                  pearl necklace to childrens pearl set designs, our collection
                  is sure to add a touch of sparkle to any occasion. Explore our
                  range of exquisite jewellery and find the perfect piece for
                  your loved one. Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "pearl-bangles-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's pearl bangles(moti
                  bangles). Handcrafted from the finest materials, each piece
                  exudes sophistication and grace. Perfect for any occasion, our
                  collection of pearl bangles in gold, traditional pearl
                  bangles, silver pearl bangles, antique pearl bangles , modern
                  pearl bangles etc. add a touch of glamour to any outfit. Shop
                  our collection today and experience the beauty of Abharan
                  jewellery.
                </p>
              </div>
            ) : props.locationPath === "pearl-haram-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Pearl Haram, crafted
                  to perfection by skilled artisans. From pearl haram designs in
                  gold to traditional pearl haram designs, our collection are
                  sure to add a touch of sparkle to any occasion. Explore our
                  range of exquisite jewellery and find the perfect piece for
                  your loved one or for yourself. Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "pearl-necklace-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Pearl necklace. Handcrafted
                  from the finest materials, each piece is unique and filled
                  with grace. Perfect for any occasion, our collection of pearl
                  necklace sets, pearl chokers, gold pearl necklace, long pearl
                  necklace etc. add a touch of sophistication to any outfit.
                  Shop our collection today and experience the beauty of Abharan
                  Pearl necklace jewellery.
                </p>
              </div>
            ) : props.locationPath === "pearl-studs-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Pearl studs, crafted
                  to perfection. From pearl stud earrings designs in gold or
                  diamond, our collection is sure to add a touch of sparkle to
                  any occasion. Explore our range of exquisite jewellery and
                  find the perfect piece for your loved one or for yourself.
                  Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "pearl-pendant-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Pearl Pendants(Moti
                  pendants). Handcrafted from the finest materials, each piece
                  exudes sophistication and grace. Perfect for any occasion, our
                  collection of pearl pendant necklace, pearl pendant set etc.,
                  add a touch of glamour to any outfit. Shop our collection
                  today and experience the beauty of Abharan jewellery.
                </p>
              </div>
            ) : props.locationPath === "pearl-bracelet-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Pearl bracelet (Moti
                  bracelet), crafted to perfection by our experienced artisans.
                  From pearl bracelet gold and silver designs, our collection is
                  sure to wow you. Explore our range of exquisite jewellery and
                  find the perfect piece for your loved one or for yourself.
                  Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "pearl-chain-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Pearl Chains. Perfect for
                  any occasion, our collection of pearl chains in gold for men
                  and women add a touch of glamour to any outfit. Pearl chains
                  with pendants also form a large part of our Pearl jewellery
                  collection. Shop our collection today and experience the
                  beauty of Abharan jewellery.
                </p>
              </div>
            ) : props.locationPath === "pearl-kanti-jewellery" ||
              props.locationPath === "pearl" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Pearl Kanti, crafted
                  to perfection by skilled artisans. Our collection is sure to
                  add a touch of sparkle to any occasion. Explore our range of
                  exquisite jewellery and find the perfect piece for your loved
                  one or for yourself. Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : null}

            {props.locationPath === "silver-jewellery" ||
            props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <h1>Silver Jewellery</h1>
                <p>
                  {" "}
                  Silver Jewellery Discover the exquisite world of silver
                  jewellery at our store. From minimalist designs to statement
                  pieces, we have it all. Shop now and add a touch of elegance
                  to your style.
                </p>

                <h1>
                  <Link to="/product-list/silver-leg-chain-jewellery">
                    Silver Leg Chains
                  </Link>{" "}
                </h1>
                <p>
                  Elevate your footwear game with our stunning collection of
                  silver leg chains. With a wide range, these chains will add a
                  dash of glamour to your every step.
                </p>
                <h2>
                  <Link to="/product-list/silver-bangles-jewellery">
                    Silver Bangles
                  </Link>
                </h2>
                <p>
                  Add a touch of sophistication to your outfit with our
                  collection of silver bangles. From delicate to chunky, our
                  range of bangles will complement your every look.
                </p>

                <h2>
                  <Link to="/product-list/silver-pendant-jewellery">
                    Silver Pendants
                  </Link>
                </h2>
                <p>
                  Make a statement with our collection of silver pendants.
                  Choose from an array of designs, including minimalist,
                  religious, and quirky styles. Perfect for gifting or
                  self-indulgence.
                </p>
                <h2>
                  <Link to="/product-list/silver-chain-jewellery">
                    Silver Chains
                  </Link>
                </h2>
                <p>
                  Classic and versatile, a silver chain is a must-have in every
                  jewellery collection. Our range of silver chains for men and
                  woen comes in different lengths and designs, perfect for
                  layering or wearing solo.
                </p>
              </div>
            ) : props.locationPath === "silver-men-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Silver Men Jewellery. All of our jewellery, including men's
                  silver jewelry, silver ornaments for gents, is made with great
                  precision and expert craftsmanship. We also offer personalized
                  jewellery options, perfect for special occasions like weddings
                  or birthdays. Check our collection today and find your perfect
                  piece! Shop now and get the best prices.
                </p>
              </div>
            ) : props.locationPath === "silver-women-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Silver Women Jewellery offers a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. Each piece showcases beauty,
                  durability and craftmanship. Our categories include silver
                  antique jewellery etc. Whether you're looking for a classic or
                  a trendy statement piece, our collection of Silver Women
                  jewellery is the perfect addition to any outfit. Shop now and
                  discover the perfect piece at the best price at Abharan
                  Jewellers.
                </p>
              </div>
            ) : props.locationPath === "silver-kids-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Silver Kids Jewellery. All of our jewellery, including silver
                  kids jewellery, is made with great precision and expert
                  craftsmanship. We also offer personalized jewellery options,
                  perfect for special occasions like weddings or birthdays.
                  Check our collection today and find your perfect piece! Shop
                  now and get the best prices.
                </p>
              </div>
            ) : props.locationPath === "silver-articles-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Silver Articles.
                  Handcrafted from the finest materials, each piece exudes
                  sophistication and grace. Perfect for any occasion, our
                  collection of silver gift items, silver pooja items add a
                  touch of sparkle to your outfit. Shop our collection and avail
                  the best prices today and thereby experience the beauty of
                  Abharan jewellery.
                </p>
              </div>
            ) : props.locationPath === "silver-bangles-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Silver Bangles Jewellery offers
                  a wide range of stunning pieces that are sure to make you
                  shine and is perfect for any occasion. Each piece showcases
                  beauty, durability and craftmanship. Our categories include
                  silver bangles for women, silver kada for women etc. Whether
                  you're looking for a classic or a trendy statement piece, our
                  collection of Silver Bangles jewellery is the perfect addition
                  to any outfit. Shop now and discover the perfect piece at the
                  best price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "silver-ear-rings-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Silver Earrings Jewellery offers
                  a wide range of stunning pieces that are sure to make you
                  shine and is perfect for any occasion. Each piece showcases
                  beauty, durability and craftmanship. Our categories include
                  silver earrings for women, silver stud earrings, silver drop
                  earrings, silver jhumka earrings etc. Whether you're looking
                  for a classic or a trendy statement piece, our collection of
                  Silver Earrings jewellery is the perfect addition to any
                  outfit. Shop now and discover the perfect piece at the best
                  price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "silver-necklace-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Silver Necklace
                  Jewellery. Our silver chain necklace, silver necklace for
                  women, silver necklace for men, silver necklace set, silver
                  pendant necklace designs and our collection is sure to add a
                  touch of sparkle to any occasion. Explore our range of
                  exquisite jewellery and find the perfect piece for your loved
                  one or for yourself. Shop now for the best prices only at
                  Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "silver-pendant-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Silver Pendants.
                  Handcrafted from the finest materials, each piece exudes
                  sophistication and grace. Perfect for any occasion, our
                  collection of silver pendant for men, silver locket, silver
                  pendant for women, silver name pendant add a touch of sparkle
                  to your outfit. Shop our collection and avail the best prices
                  today and thereby experience the beauty of Abharan jewellery.
                </p>
              </div>
            ) : props.locationPath === "silver-finger-ring-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Silver Finger Ring
                  Jewellery. Our silver finger ring designs and our collection
                  is sure to add a touch of sparkle to any occasion. Explore our
                  range of exquisite jewellery and find the perfect piece for
                  your loved one or for yourself. Shop now for the best prices
                  only at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "silver-bracelet-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Silver Bracelet Jewellery offers
                  a wide range of stunning pieces that are sure to make you
                  shine and is perfect for any occasion. Each piece showcases
                  beauty, durability and craftmanship. Our categories include
                  silver bracelet for men, silver bracelet for women, silver
                  chain bracelet etc. Whether you're looking for a classic or a
                  trendy statement piece, our collection of Silver Bracelet
                  jewellery is the perfect addition to any outfit. Shop now and
                  discover the perfect piece at the best price at Abharan
                  Jewellers."
                </p>
              </div>
            ) : props.locationPath === "silver-waist-chain-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Silver Waist Chains.
                  Handcrafted from the finest materials, each piece exudes
                  sophistication and grace. Perfect for any occasion, our
                  collection of silver waist chain for saree add a touch of
                  sparkle to your outfit. Shop our collection and avail the best
                  prices today and thereby experience the beauty of Abharan
                  jewellery.
                </p>
              </div>
            ) : props.locationPath === "silver-chain-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Silver Chain
                  Jewellery. Our silver chain for men, silver chain for women,
                  pure silver chain designs and our collection is sure to add a
                  touch of sparkle to any occasion. Explore our range of
                  exquisite jewellery and find the perfect piece for your loved
                  one or for yourself. Shop now for the best prices only at
                  Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "silver-rakhi-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Silver Rakhi. Handcrafted
                  from the finest materials, each piece exudes sophistication
                  and grace. Perfect for any occasion, our collection of silver
                  rakhi bracelet, pure silver rakhi add a touch of sparkle to
                  your outfit. Shop our collection and avail the best prices
                  today and thereby experience the beauty of Abharan jewellery.
                </p>
              </div>
            ) : props.locationPath === "silver-kanti-jewellery" ||
              props.locationPath === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Silver Kanti. Handcrafted
                  from the finest materials, each piece exudes sophistication
                  and grace. Perfect for any occasion, our collection of silver
                  kanti add a touch of sparkle to your outfit. Shop our
                  collection and avail the best prices today and thereby
                  experience the beauty of Abharan jewellery.
                </p>
              </div>
            ) : props.locationPath === "silver-leg-chain-jewellery" ||
              props.data1 === "silver" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Silver Legchain Jewellery offers
                  a wide range of stunning pieces that are sure to make you
                  shine and is perfect for any occasion. Each piece showcases
                  beauty, durability and craftmanship. Our categories include
                  silver legchain etc. Whether you're looking for a classic or a
                  trendy statement piece, our collection of Silver Legchain
                  jewellery is the perfect addition to any outfit. Shop now and
                  discover the perfect piece at the best price at Abharan
                  Jewellers.
                </p>
              </div>
            ) : null}
            {props.locationPath === "gems-jewellery" ||
            props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <h1>Gemstone Jewellery </h1>
                <p>
                  Our collection of gem stone jewellery is crafted with
                  precision and care. Add a touch of elegance to your everyday
                  look or find the perfect statement piece for a special
                  occasion.
                </p>
                <h2>
                  <Link to="/product-list/gems-bangles-jewellery">
                    Gemstone Bangles
                  </Link>
                </h2>
                <p>
                  Add a pop of color to your outfit with our exquisite gemstone
                  bangles. Handcrafted with care, our collection offers a
                  variety of stones to choose from.
                </p>
                <h2>
                  <Link to="/product-list/gems-necklace-jewellery">
                    Gemstone Necklaces
                  </Link>
                </h2>
                <p>
                  Elevate your style with our stunning gem stone set necklaces.
                  Whether you prefer delicate or statement pieces, our selection
                  has something for every occasion.
                </p>
                <h2>
                  <Link to="/product-list/gems-studs-jewellery">
                    Gemstone Studs
                  </Link>
                </h2>
                <p>
                  Perfect for everyday wear, our gemstone studs are a must-have
                  in your jewelry collection. Browse our range of colors and
                  shapes to find your perfect match.
                </p>
              </div>
            ) : props.locationPath === "gems-men-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Gems Men Jewellery. All of our jewellery, including mens
                  gemstone jewelry, mens rings gemstone , is made with great
                  precision and expert craftsmanship. We also offer personalized
                  jewellery options, perfect for special occasions like weddings
                  or birthdays. Check our collection today and find your perfect
                  piece! Shop now and get the best prices.
                </p>
              </div>
            ) : props.locationPath === "gems-women-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Gems Women Jewellery offers a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. Each piece showcases beauty,
                  durability and craftmanship. Our categories include womens
                  gemstone jewelry etc. Whether you're looking for a classic or
                  a trendy statement piece, our collection of Gems Women
                  jewellery is the perfect addition to any outfit. Shop now and
                  discover the perfect piece at the best price at Abharan
                  Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gems-kids-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan Jewellers offer a wide range of beautifully crafted
                  Gems Kids Jewellery. All of our jewellery, including gems kids
                  jewellery , is made with great precision and expert
                  craftsmanship. We also offer personalized jewellery options,
                  perfect for special occasions like weddings or birthdays.
                  Check our collection today and find your perfect piece! Shop
                  now and get the best prices.
                </p>
              </div>
            ) : props.locationPath === "gems-bangles-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Gems Bangles Jewellery offers a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. Each piece showcases beauty,
                  durability and craftmanship. Our categories include gemstone
                  bangle, bangle with gemstones etc. Whether you're looking for
                  a classic or a trendy statement piece, our collection of Gems
                  Bangles jewellery is the perfect addition to any outfit. Shop
                  now and discover the perfect piece at the best price at
                  Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gems-haram-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Gems Haram. Handcrafted
                  from the finest materials, each piece exudes sophistication
                  and grace. Perfect for any occasion, our collection of gems
                  haram add a touch of sparkle to your outfit. Shop our
                  collection and avail the best prices today and thereby
                  experience the beauty of Abharan jewellery.
                </p>
              </div>
            ) : props.locationPath === "gems-necklace-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gems Necklace
                  Jewellery. Our gemstone necklace designs and our collection is
                  sure to add a touch of sparkle to any occasion. Explore our
                  range of exquisite jewellery and find the perfect piece for
                  your loved one or for yourself. Shop now for the best prices
                  only at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gems-studs-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Gems Studs. Handcrafted
                  from the finest materials, each piece exudes sophistication
                  and grace. Perfect for any occasion, our collection of
                  gemstone stud add a touch of sparkle to your outfit. Shop our
                  collection and avail the best prices today and thereby
                  experience the beauty of Abharan jewellery.
                </p>
              </div>
            ) : props.locationPath === "gems-pendant-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Gems Pendants Jewellery offers a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. Each piece showcases beauty,
                  durability and craftmanship. Our categories include gemstone
                  pendant etc. Whether you're looking for a classic or a trendy
                  statement piece, our collection of Gems Pendants jewellery is
                  the perfect addition to any outfit. Shop now and discover the
                  perfect piece at the best price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gems-finger-ring-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gems Finger Rings
                  Jewellery. Our gems finger rings designs and our collection is
                  sure to add a touch of sparkle to any occasion. Explore our
                  range of exquisite jewellery and find the perfect piece for
                  your loved one or for yourself. Shop now for the best prices
                  only at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gems-bracelet-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  At Abharan, our collection of Gems Bracelet Jewellery offers a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. Each piece showcases beauty,
                  durability and craftmanship. Our categories include gemstone
                  bracelet etc. Whether you're looking for a classic or a trendy
                  statement piece, our collection of Gems Bracelet jewellery is
                  the perfect addition to any outfit. Shop now and discover the
                  perfect piece at the best price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gems-chain-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Gems Chain Jewellery.
                  Our gemstone chain designs and our collection is sure to add a
                  touch of sparkle to any occasion. Explore our range of
                  exquisite jewellery and find the perfect piece for your loved
                  one or for yourself. Shop now for the best prices only at
                  Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "gems-kanti-jewellery" ||
              props.locationPath === "gems" ? (
              <div className="seo_cont flt">
                <p>
                  Discover the elegance of Abharan's Gems Kanti. Handcrafted
                  from the finest materials, each piece exudes sophistication
                  and grace. Perfect for any occasion, our collection of gems
                  kanti add a touch of sparkle to your outfit. Shop our
                  collection and avail the best prices today and thereby
                  experience the beauty of Abharan jewellery.
                </p>
              </div>
            ) : null}
            {(props.locationPath === "diamond-jewellery" ||
              props.locationPath === "diamond") &&
            props.locationPath !== "diamond-polki" ? (
              <div className="seo_cont flt">
                <h1>Diamond Jewellery</h1>
                <p>
                  Shop our exquisite collection of diamond jewellery and find
                  the perfect piece to add a touch of luxury to any outfit. From
                  rings to necklaces, our diamonds shine bright. Diamond and
                  Gold are made for each other and so are you thus making it the
                  ideal gift for many occassions like anniversaries, birthdays
                  or weddings.
                </p>
                <p>
                  <Link to="/product-list/diamond-women-jewellery">
                    For Women
                  </Link>
                  ,
                  <Link to="/product-list/diamond-jewellery">
                    Diamond jewellery
                  </Link>{" "}
                  is a classic and elegant accessory that adds a touch of luxury
                  to any outfit. From delicate necklaces to statement earrings,
                  there are endless options for every occasion.
                </p>
                <p>
                  <Link to="/product-list/diamond-men-jewellery">
                    Diamond jewellery for men{" "}
                  </Link>
                  is often understated and classic, with simple designs that add
                  a touch of sophistication to any outfit. Rings, Pendants, and
                  watches are popular choices.
                </p>
                <p>
                  <Link to="/product-list/diamond-kids-jewellery">
                    For Kids
                  </Link>
                  ,
                  <Link to="/product-list/diamond-jewellery">
                    Diamond jewellery
                  </Link>{" "}
                  is typically simple and fun, with designs like butterfly or
                  flower-shaped earrings or necklaces. It can be a special gift
                  for a milestone occasion or just a fun accessory for dress-up.
                </p>
                <h3>Diamond Jewellery Categories</h3>
                <h2>
                  <Link to="/product-list/diamond-ear-rings-jewellery">
                    Diamond Earrings
                  </Link>
                </h2>
                <p>
                  Elevate your look with a pair of stunning diamond earrings
                  from our collection. Choose from classic studs, hoops, or drop
                  earrings for a touch of glamour.
                </p>
                <h2>
                  <Link to="/product-list/diamond-necklace-jewellery">
                    Diamond Necklace
                  </Link>
                </h2>
                <p>
                  Add a touch of elegance to your neckline with our diamond
                  necklaces. Our collection features a range of styles from
                  delicate and dainty to bold and statement-making.
                </p>
                <h2>
                  <Link to="/product-list/diamond-studs-jewellery">
                    Diamond Studs
                  </Link>
                </h2>
                <p>
                  {" "}
                  Are a classic and versatile addition to any jewellery
                  collection. Choose from a range of carat sizes and designs to
                  find your perfect pair.
                </p>
                <h2>
                  {" "}
                  <Link to="/product-list/diamond-pendant-jewellery">
                    Diamond Pendants
                  </Link>
                </h2>
                <p>
                  Diamond Pendants make a beautiful gift for a loved one or a
                  special treat for yourself. From solitaire diamonds to
                  intricate designs, we have something for everyone.
                </p>
                <h2>
                  <Link to="/product-list/diamond-finger-ring-jewellery">
                    Diamond Finger Ring
                  </Link>
                </h2>
                <p>
                  Our diamond rings are the perfect way to add a touch of
                  sparkle to any occasion. We have categories like engagement
                  rings for couples, mens engagement rings and engagement rings
                  for women. Whether you're looking for a classic solitaire or a
                  unique design, we have you covered. Our diamond ring designs
                  are elaborate in categories like mens diamond rings, diamond
                  rings for women.
                </p>
              </div>
            ) : props.locationPath === "diamond-men-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  Among our collection of Diamond Men Jewellery, we offer a wide
                  range of stunning pieces that are sure to make you shine and
                  is perfect for any occasion. We use only the highest quality
                  materials, including precious stones and metals, to ensure
                  that each piece is both beautiful and durable. Our categories
                  include diamond ear studs for men, diamond men's ear rings,
                  etc. Whether you're looking for a classic or a trendy
                  statement piece, our collection of Diamond Men jewellery is
                  the perfect addition to any outfit. Shop now and discover the
                  perfect piece at the best price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "diamond-women-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  We offer a wide range of beautifully crafted Diamond Women
                  Jewellery. All of our jewellery including diamond jewellery
                  for bride, diamond ear studs for women, is made with
                  high-quality materials and expert craftsmanship, ensuring each
                  piece is a treasure that will last a lifetime. We also offer
                  personalized jewellery options, perfect for special occasions
                  like weddings or birthdays. Browse our selection today and
                  find the perfect piece to add to your jewellery collection!
                  Shop now.
                </p>
              </div>
            ) : props.locationPath === "diamond-kids-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Diamond Kids
                  Jewellery, crafted to perfection by skilled artisans. Among
                  our diamond kids jewellery designs, our collection is sure to
                  add a touch of sparkle to any occasion. Explore our range of
                  exquisite jewellery and find the perfect piece for your loved
                  one or for yourself. Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "diamond-bangles-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  Among our collection of Diamond Women Jewellery, we offer a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. We use only the highest
                  quality materials, including precious stones and metals, to
                  ensure that each piece is both beautiful and durable. Our
                  categories include diamond jewellery for bride, diamond ear
                  studs for women, etc. Whether you're looking for a classic or
                  a trendy statement piece, our collection of Diamond Women
                  jewellery is the perfect addition to any outfit. Shop now and
                  discover the perfect piece at the best price at Abharan
                  Jewellers.
                </p>
              </div>
            ) : props.locationPath === "diamond-ear-rings-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Diamond Ear rings
                  Jewellery, crafted to perfection by skilled artisans. Among
                  our diamond stud earrings, diamond earrings for women and men,
                  diamond hoop earrings designs, our collection is sure to add a
                  touch of sparkle to any occasion. Explore our range of
                  exquisite jewellery and find the perfect piece for your loved
                  one or for yourself. Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "diamond-haram-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Diamond Haram
                  Jewellery, crafted to perfection by skilled artisans. Among
                  our diamond haram designs, our collection is sure to add a
                  touch of sparkle to any occasion. Explore our range of
                  exquisite jewellery and find the perfect piece for your loved
                  one or for yourself. Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "diamond-necklace-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  Among our collection of Diamond Necklace Jewellery, we offer a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. We use only the highest
                  quality materials, including precious stones and metals, to
                  ensure that each piece is both beautiful and durable. Our
                  categories include diamond choker, diamond necklace set, gold
                  diamond necklace, diamond chain etc. Whether you're looking
                  for a classic or a trendy statement piece, our collection of
                  Diamond Necklace jewellery is the perfect addition to any
                  outfit. Shop now and discover the perfect piece at the best
                  price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "diamond-necklaces" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  Among our collection of Diamond Necklace Jewellery, we offer a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. We use only the highest
                  quality materials, including precious stones and metals, to
                  ensure that each piece is both beautiful and durable. Our
                  categories include diamond choker, diamond necklace set, gold
                  diamond necklace, diamond chain etc. Whether you're looking
                  for a classic or a trendy statement piece, our collection of
                  Diamond Necklace jewellery is the perfect addition to any
                  outfit. Shop now and discover the perfect piece at the best
                  price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "diamond-studs-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  We offer a wide range of beautifully crafted Diamond Studs
                  Jewellery. All of our jewellery including diamond stud
                  earrings, diamond studs for women and men is made with
                  high-quality materials and expert craftsmanship, ensuring each
                  piece is a treasure that will last a lifetime. We also offer
                  personalized jewellery options, perfect for special occasions
                  like weddings or birthdays. Browse our selection today and
                  find the perfect piece to add to your jewellery collection!
                  Shop now.
                </p>
              </div>
            ) : props.locationPath === "diamond-pendant-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Diamond Pendant
                  Jewellery, crafted to perfection by skilled artisans. Among
                  our diamond pendant set, diamond locket, gold diamond pendant
                  designs, our collection is sure to add a touch of sparkle to
                  any occasion. Explore our range of exquisite jewellery and
                  find the perfect piece for your loved one or for yourself.
                  Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "diamond-finger-ring-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  We offer a wide range of beautifully crafted Diamond Finger
                  Ring Jewellery. All of our jewellery including diamond finger
                  ring is made with high-quality materials and expert
                  craftsmanship, ensuring each piece is a treasure that will
                  last a lifetime. We also offer personalized jewellery options,
                  perfect for special occasions like weddings or birthdays.
                  Browse our selection today and find the perfect piece to add
                  to your jewellery collection! Shop now.
                </p>
              </div>
            ) : props.locationPath === "diamond-bracelet-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  Among our collection of Diamond Bracelet Jewellery, we offer a
                  wide range of stunning pieces that are sure to make you shine
                  and is perfect for any occasion. We use only the highest
                  quality materials, including precious stones and metals, to
                  ensure that each piece is both beautiful and durable. Our
                  categories include diamond bracelet for women, men's diamond
                  bracelet, gold diamond bracelet etc. Whether you're looking
                  for a classic or a trendy statement piece, our collection of
                  Diamond Bracelet jewellery is the perfect addition to any
                  outfit. Shop now and discover the perfect piece at the best
                  price at Abharan Jewellers.
                </p>
              </div>
            ) : props.locationPath === "diamond-polki-jewellery" ||
              props.locationPath === "diamond" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Diamond Polki
                  Jewellery, crafted to perfection by skilled artisans. Among
                  our polki diamond necklace, polki diamond set, polki diamond
                  bracelet designs, our collection is sure to add a touch of
                  sparkle to any occasion. Explore our range of exquisite
                  jewellery and find the perfect piece for your loved one or for
                  yourself. Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : null}
            {props.locationPath === "diamond-polki" ? (
              <div className="seo_cont flt">
                <p>
                  Abharan offers a stunning collection of Diamond Polki
                  Jewellery, crafted to perfection by skilled artisans. Among
                  our polki diamond necklace, polki diamond set, polki diamond
                  bracelet designs, our collection is sure to add a touch of
                  sparkle to any occasion. Explore our range of exquisite
                  jewellery and find the perfect piece for your loved one or for
                  yourself. Shop now at Abharan Jewellers.
                </p>
              </div>
            ) : null}
          </div>
        </div>

        <div className="footer_container flt">
          <div className="footer_box">
            <div
              className="footer_head flt"
              onClick={() => setFooterShow1((prev) => !prev)}
            >
              ABOUT US{" "}
              <i
                className={
                  "fa fa-chevron-down" + (footerShow1 ? " chevron-rotate" : "")
                }
                aria-hidden="true"
              ></i>
            </div>
            <div
              className={
                "footer_cont flt" + (footerShow1 ? " footer_cont_show" : "")
              }
            >
              <ul>
                <li>
                  <Link to="/about">Who are we</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer_box">
            <div
              className="footer_head flt"
              onClick={() => setFooterShow2((prev) => !prev)}
            >
              INFORMATION{" "}
              <i
                className={
                  "fa fa-chevron-down" + (footerShow2 ? " chevron-rotate" : "")
                }
                aria-hidden="true"
              ></i>
            </div>
            <div
              className={
                "footer_cont flt" + (footerShow2 ? " footer_cont_show" : "")
              }
            >
              <ul>
                <li>
                  <Link to="/faq">Help & FAQs</Link>
                </li>
                <li>
                  <Link to="/jewellery-care">Jewellery Care</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_box">
            <div
              className="footer_head flt"
              onClick={() => setFooterShow3((prev) => !prev)}
            >
              ACCOUNT{" "}
              <i
                className={
                  "fa fa-chevron-down" + (footerShow3 ? " chevron-rotate" : "")
                }
                aria-hidden="true"
              ></i>
            </div>
            <div
              className={
                "footer_cont flt" + (footerShow3 ? " footer_cont_show" : "")
              }
            >
              <ul>
                {!isUser ? (
                  <li>
                    <a onClick={handleLOgin}>Cart Page</a>
                  </li>
                ) : (
                  <li>
                    <Link to="/cart">Cart Page</Link>
                  </li>
                )}
                {isUser ? (
                  <li>
                    <a onClick={handleLogout}>Sign Out</a>
                  </li>
                ) : (
                  <li>
                    <Link to="/registration">Sign In</Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="footer_box">
            <div
              className="footer_head flt"
              onClick={() => setFooterShow4((prev) => !prev)}
            >
              CUSTOMER{" "}
              <i
                className={
                  "fa fa-chevron-down" + (footerShow4 ? " chevron-rotate" : "")
                }
                aria-hidden="true"
              ></i>
            </div>
            <div
              className={
                "footer_cont flt" + (footerShow4 ? " footer_cont_show" : "")
              }
            >
              <ul>
                <li onClick={() => returndata()}>
                  <Link to="/return-policy">T & C / Returns / Shipping</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/buy-back-policy">Buy Back Policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_box">
            <div className="footer_head flt">FOLLOW US</div>
            <div className="footer_media flt">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/abharanudupi"
                    target="_blank"
                  >
                    <LazyLoadImage src={facebook} alt="image" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/abharanofficial?utm_medium=copy_link"
                    target="_blank"
                  >
                    <LazyLoadImage src={instagram} alt="image" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://youtube.com/channel/UCWh1dqWSnscluT1cH2_KhzQ"
                    target="_blank"
                  >
                    <LazyLoadImage src={youtube} alt="image" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="jewel_header flt" >
          <div className="jewel_head"> BUYBACK POLICY</div>
          <ul className="jewel_list">
            <li>Gold Jewellery</li>
            <li>Diamond Jewellery</li>
            <li>Gemstone Jewellery</li>
            <li>Silver Jewellery</li>
          </ul>
        </div> */}

        <div class="whatsapp_icon" onClick={WhatsappCount}>
          <a
            href="https://api.whatsapp.com/send?phone=918861206178"
            target="_blank"
          >
            <LazyLoadImage
              class="img-responsive"
              src={whatsapp_icon}
              height="50px"
              width="50px"
              alt="Abharan"
            />
          </a>
        </div>
        <div className="copyright_container flt">
          <div className="row">
            <div className="col-sm-8">
              <div className="copyright_left flt">
                <ul>
                  <li>
                    Copyright © 2022 ABHARAN JEWELLERS PRIVATE LIMITED,10-3-4,
                    CORPORATION BANK ROAD, UDUPI- 576101
                  </li>
                  <li>All rights reserved</li>

                  <li>
                    Designed and developed by{" "}
                    <a href="https://www.chipsyservices.com/" target="_blank">
                      Chipsy Services
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="copyright_right flt">
                <LazyLoadImage src={payment_supported} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const search = () => {
    return (
      /* <!-- Search Modal --> */

      <div className="modal fade" id="searchModal">
        {/* <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet> */}

        <div className="modal-dialog">
          <div className="modal-content">
            {/* <!-- Modal body --> */}
            <div className="modal-body">
              <span>
                <input type="text" placeholder="Search for items" />
              </span>
            </div>

            {/* <!-- Modal footer --> */}
            <div className="modal-footer">
              <button className="search_btn">Search</button>
              <button className="close_btn" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const enquiry = () => {
    return (
      /* <!-- Enquiry Modal --> */
      <div className="modal fade" id="enquiryModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" data-dismiss="modal">
                <LazyLoadImage src="assets/images/close-pop.png" alt="image" />
              </button>
              <div className="enquiry_head flt">Enquiry Details</div>
              <div className="enquiry_cont flt">
                <div className="form-group flt">
                  <input type="text" placeholder="Name" />
                </div>
                <div className="form-group flt">
                  <input type="text" placeholder="Email ID" />
                </div>
                <div className="form-group flt">
                  <input type="text" placeholder="Phone Number" />
                </div>
                <div className="form-group flt">
                  <input type="text" placeholder="Comments" />
                </div>
                <div className="mode_box flt">
                  <div className="mode_tag flt">
                    What is your mode of communication ?
                  </div>
                  <ul className="mode_inp flt">
                    <li>
                      <input
                        type="radio"
                        name="radio-group"
                        id="video"
                        checked
                      />
                      <label for="video">Video call</label>
                    </li>
                    <li>
                      <input type="radio" name="radio-group" id="phone" />
                      <label for="phone">Phone call</label>
                    </li>
                    <li>
                      <input type="radio" name="radio-group" id="whatsapp" />
                      <label for="whatsapp">Whatsapp</label>
                    </li>
                  </ul>
                </div>
                <div className="mode_check flt">
                  <input type="checkbox" id="agree" checked />
                  <label for="agree">
                    <span>
                      I agree to get a call from one of the representatives from
                      Abharan to discuss more about jewellery and its purchase
                    </span>
                  </label>
                </div>
                <div className="mode_btn flt">
                  <button data-dismiss="modal">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const backchain = () => {
    return (
      /* <!-- Backchain Modal --> */
      <div className="modal fade" id="backChain">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <div className="backchain_box">
                <div className="backchain_title">
                  Do you want to include Back Chain ?
                </div>
                <div className="backchain_btn">
                  <button>Gold Backchain</button>
                  <button>Lace</button>
                </div>
                <div className="backchain_tag">Weight : 05g Cost : 2500rs</div>
                <div className="backchain_image">
                  <LazyLoadImage src="assets/images/backchain.png" />
                </div>
                <div className="backchain_click">
                  <button
                    onclick="window.location.href='cart.php';"
                    data-dismiss="modal"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const shipping = () => {
    return (
      /* <!-- Shipping Modal --> */
      <div className="modal fade" id="shippingModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="faq_head flt">
                <h1 className="faq_head flt">T&C/Returns/Shipping</h1>
              </div>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="policy_cont flt">
                <div className="policy_box flt">
                  <div className="policy_head flt">Shipping Policy</div>
                  <div className="policy_desc flt">
                    We are offering free shipping on all jewellery including
                    coins and silver jewellery in districts where we are
                    located. In other locations, delivery charges will be
                    applicable on all orders as indicated when location data is
                    entered on the checkout page. The charges in most cases are
                    rupees 500 for Gold products and 200 for Silver products. We
                    use secure insured shipping. Once ordered, please contact
                    <u>customercare@abharan.com</u> to check order status.
                  </div>
                </div>

                <div className="policy_box flt">
                  <div className="policy_head flt">Returns And Exchanges</div>
                  <div className="policy_desc flt">
                    Your complete shopping satisfaction is our No.1 Priority. If
                    an item you ordered from abharan.com does not meet your
                    expectations, please contact us within seven (7) days of
                    purchase to obtain a return authorization and instructions.
                    The product must be returned in original condition in the
                    original packaging. Upon arrival and inspection, product
                    refunds will be promptly credited to the original purchase
                    account. Certain items may have additional specific return
                    conditions published on the respective product web page.
                  </div>
                </div>

                <div className="policy_box flt">
                  <div className="policy_head flt">
                    Warranty And Defect Claims
                  </div>
                  <div className="policy_desc flt">
                    Abharan takes complete responsibility for return and
                    replacement shipping costs if any product is determined to
                    be defective. Please contact us within the warranty period
                    to obtain instructions.
                  </div>
                </div>

                <div className="policy_box flt">
                  <div className="policy_head flt">Cost Differs</div>
                  <div className="policy_desc flt">
                    If the ordered item is not in stock at the time of delivery,
                    the cost and weight may differ
                  </div>
                </div>
                <div className="policy_note flt">
                  Note : Order once placed on website is not final. All details
                  will be reverified. Final Order confirmation will be done by
                  our executive on call or WhatsApp within 48 hours
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const certified = () => {
    return (
      /* <!-- Certified jewellery Modal --> */
      <div className="modal fade" id="certifiedModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="faq_head flt">Certified Jewellery</div>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="policy_desc flt">
                All our jewellery is certified by reputed institutions. Our
                Diamonds are certified by IGI (
                <b>International Gemological Institute</b>) /SGL(
                <b>Solitaire</b> Gemological <b>Laboratories Ltd</b>.) All gold
                jewellery is BIS 916 or 750 Hallmarked.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const dailyrate = () => {
    return (
      /* <!-- Daily Rate Modal --> */
      <div className="modal fade" id="dailyRate">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr className="gold_clr">
                      <td>Metal Type</td>
                      <td>Per Gram</td>
                    </tr>

                    <tr>
                      <td>Gold 22 KT</td>
                      <td id="gold22">
                        <b>Rs. 4735</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Gold 18 KT</td>
                      <td id="gold18">
                        <b>Rs. 3874</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Silver</td>
                      <td id="silver">
                        <b>Rs. 69.4</b>
                      </td>
                    </tr>
                    <tr>
                      {/* <!-- <td colspan="2"><b>*Goa rates will be 0.5% more than rates shown here.</b></td> --> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const videorequest = () => {
    return (
      /* <!-- Video Request Modal --> */
      <div className="modal fade video_pop" id="videoModal">
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <!-- Modal Header --> */}
            <div className="row">
              <div className="col-sm-12">
                <h4 className="modal-title">
                  <span>Request for a video Call</span>
                  <br />
                  Hop On A Video Call & See Your Fave Designs Live
                </h4>
              </div>
            </div>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>

            {/* <!-- Modal body --> */}
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group flt">
                    <input type="text" placeholder="Name" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group flt">
                    <input type="text" placeholder="Email" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group flt">
                    <input type="text" placeholder="Number" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group flt">
                    <select>
                      <option>Select Date</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group flt">
                    <select>
                      <option>Select Language</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group flt">
                    <select>
                      <option>Select Time</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group flt">
                    <select>
                      <option>Choose Category</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group flt">
                    <select>
                      <option>Sub Category ( optional )</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group flt">
                    <input type="text" placeholder="City/Town" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group flt">
                    <select>
                      <option>Select Purpose</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group flt">
                    <input type="text" placeholder="Message" />
                  </div>
                </div>
              </div>
              <div className="video_pop_btn">
                <button data-dismiss="modal">Request</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flt">
      {footer_body()}
      {search()}
      {enquiry()}
      {backchain()}
      {shipping()}
      {certified()}
      {dailyrate()}
      {videorequest()}
    </div>
  );
};

export default Footer;
