import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import "./ReturnPolicy.css";
import { PrivacyService } from "../../../services/PrivacyService";
import { useState, useEffect } from "react";
import {  useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import useCountHook from "../../../hooks/useCountHook";

const RerturnPolicy = () => {
  const usr_id = useSelector((state) => state?.userData?.user?._id);
  const [data, setData] = useState();
  const { wishListCount, cartCount } = useCountHook();

  useEffect(() => {
    PrivacyService.getPrivacy()
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        //   setLoading(false);
      });
  }, []);
  let returnMetadata = {
    info: `Return Policy details of Abharan Jewellers Official site`,
  };

  document.title = `Return Policy | Abharan Jewellers`;
  const Policy = () => {
    return (
      <div class="faq_container flt">
        <Helmet>
          <meta name="description" content={returnMetadata?.info} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div class="faq_head flt">
          <h1 class="faq_head flt">T & C / Returns / Shipping</h1>
        </div>
        <div class="policy_cont flt">
          <div class="policy_box flt">
            <div class="policy_desc flt">
              {data && data.data && data.data.shipping && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.data.shipping.description,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header count={wishListCount} cart={cartCount} />
      {Policy()}
      <Footer />
    </div>
  );
};
export default RerturnPolicy;
