import Footer from "../../_common/Footer/Footer";
import Header from "../../_common/Header/Header";
import useFeedBackHook from "./FeedBackHook";
import StarRatings from "react-star-ratings";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertActions, userLogout } from "../../../actions";

const FeedBack = (tokenExpired) => {
  const { id } = useParams();
  const {
    question,
    handleSubmit,
    rating,
    rating1,
    rating2,
    rating3,
    rating4,
    showButtom,
    changeStar,
    changeStar1,
    changeStar2,
    changeStar3,
    changeStar4,
    handleCancel,
  } = useFeedBackHook(id);
  const user_id = useSelector((state) => state?.userData?.user?._id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user_id || tokenExpired.tokenExpired || !id) {
      setTimeout(() => {
        dispatch(alertActions.error("Please login"));
      }, 50);
      dispatch(userLogout());
      navigate("/login", { state: { id: id } });
    }
  }, [tokenExpired.tokenExpired]);

  return (
    <div className="flt">
      <Header />
      <div className="feedback_main flt">
        {/* <button type="button" class="close" >
          <img src={close_pop} alt="image" />
        </button> */}
        <div className="feedback_box flt">
          <form className="flt">
            <div className="form-group flt">
              <label>{question[0]?.question}</label>
              <div className="star_ans flt">
                <StarRatings
                  rating={rating}
                  starRatedColor="gold"
                  starHoverColor="yellow"
                  changeRating={changeStar}
                  numberOfStars={5}
                  name="rating"
                  starDimension="25px"
                  starSpacing="12px"
                />
              </div>
            </div>
            <div className="form-group flt">
              <label>{question[1]?.question}</label>
              <div className="star_ans flt">
                <StarRatings
                  rating={rating1}
                  starRatedColor="gold"
                  starHoverColor="yellow"
                  changeRating={changeStar1}
                  numberOfStars={5}
                  name="rating1"
                  starDimension="25px"
                  starSpacing="12px"
                />
              </div>
            </div>
            <div className="form-group flt">
              <label>{question[2]?.question}</label>
              <div className="star_ans flt">
                <StarRatings
                  rating={rating2}
                  starRatedColor="gold"
                  starHoverColor="yellow"
                  changeRating={changeStar2}
                  numberOfStars={5}
                  name="rating2"
                  starDimension="25px"
                  starSpacing="12px"
                />
              </div>
            </div>
            <div className="form-group flt">
              <label>{question[3]?.question}</label>
              <div className="star_ans flt">
                <StarRatings
                  rating={rating3}
                  starRatedColor="gold"
                  starHoverColor="yellow"
                  changeRating={changeStar3}
                  numberOfStars={5}
                  name="rating3"
                  starDimension="25px"
                  starSpacing="12px"
                />
              </div>
            </div>
            <div className="form-group flt">
              <label>
                <label>{question[4]?.question}</label>
              </label>
              <textarea
                type="text"
                rows={5}
                onChange={changeStar4}
                value={rating4}
              ></textarea>
            </div>
            {showButtom && <button onClick={handleSubmit}>Submit</button>}
            &nbsp;
            <button onClick={handleCancel}>Cancel</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default FeedBack;
