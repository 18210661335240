import useMyDetails from "./useMyDetails";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import ProfileMenu from "../../_common/ProfileMenu/ProfileMenu";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertActions, userLogout } from "../../../actions";
import { useEffect } from "react";
import useCountHook from "../../../hooks/useCountHook";

const MyDetails = (tokenExpired) => {
  let history = useNavigate();
  const dispatch = useDispatch();
  const {
    prof1,
    prof2,
    prof3,
    prof4,
    name,
    email,
    address,
    mobile,
    password,
    storeStateShipping,
    c_password,
    new_password,
    confirm_password,
    check,
    editDetails,
    HandleChangeclick,
    changeDistrictHandlerShipping,
    submitted,
    alternateMobile,
    shippingDistrict,
    billingAddress,
    billingCity,
    billingLandmark,
    billingLocality,
    billingPincode,
    shippingAddress,
    shippingLocality,
    shippingLandmark,
    shippingPincode,
    shippingCity,
    billingState,
    storeDistrictShipping,
    lname,
    valid,
    active,
    locality,
    city,
    storeState,
    state,
    storeDistrict,
    district,
    pincode,
    shippingState,
    landmark,
    HandleChange,
    changeStateHandler,
    changeDistrictHandler,
    changePincodeHandler,
    changePincodeShippingHandler,
    changeStateHandlerShipping,
  } = useMyDetails();
  const { wishListCount, cartCount } = useCountHook();
  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("You are logged out. Please Login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);
  const MyDetails = () => {
    return (
      <div class="profile_container flt">
        <div class="row">
          <ProfileMenu />

          <div class="col-sm-9">
            <div class="profile_right flt">
              <div class="profile_right_head flt">Edit Account Information</div>
              <div class="profile_right_cont flt">
                <form class="flt">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>First Name</label>
                        <input
                          name="name"
                          type="text"
                          value={name}
                          placeholder="Enter First Name"
                          onChange={HandleChange}
                          className={
                            submitted && !name ? " is-invalid" : "login_lbl"
                          }
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Last Name</label>
                        <input
                          name="lname"
                          type="text"
                          placeholder="Enter Last Name"
                          value={lname}
                          onChange={HandleChange}
                          className={
                            submitted && !name ? " is-invalid" : "login_lbl"
                          }
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group  flt">
                        <label>Email</label>
                        <input
                          name="email"
                          type="text"
                          value={email}
                          onChange={HandleChange}
                          className={
                            submitted && !email
                              ? " is-invalid opac"
                              : "login_lbl opac"
                          }
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Mobile</label>
                        <input
                          name="mobile"
                          type="text"
                          value={mobile}
                          onChange={HandleChange}
                          className={
                            submitted && !mobile
                              ? " is-invalid opac"
                              : "login_lbl opac"
                          }
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Alternate Mobile</label>
                        <input
                          name="alternameMobile"
                          type="number"
                          placeholder="Enter Alternate Mobile Number"
                          value={alternateMobile}
                          onChange={HandleChange}
                          onWheel={(e) => e.currentTarget.blur()}
                          className={
                            submitted && !mobile ? " is-invalid" : "login_lbl"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Billing Address</label>
                        <input
                          name="billing_address"
                          value={billingAddress}
                          type="text"
                          placeholder="Enter Billing Address"
                          onChange={HandleChange}
                          className={
                            submitted && !billingAddress
                              ? " is-invalid"
                              : "login_lbl"
                          }
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Locality</label>
                        <input
                          name="locality"
                          value={billingLocality}
                          type="text"
                          placeholder="Enter Locality"
                          onChange={HandleChange}
                          className={
                            submitted && !billingLocality
                              ? " is-invalid"
                              : "login_lbl"
                          }
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Landmark</label>
                        <input
                          name="landmark"
                          value={billingLandmark}
                          type="text"
                          placeholder="Enter Landmark"
                          onChange={HandleChange}
                          className={
                            submitted && !billingLandmark
                              ? " is-invalid"
                              : "login_lbl"
                          }
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>City</label>
                        <input
                          name="city"
                          value={billingCity}
                          type="text"
                          placeholder="Enter City"
                          onChange={HandleChange}
                          className={
                            submitted && !billingCity
                              ? " is-invalid"
                              : "login_lbl"
                          }
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Select State</label>
                        <div className="form-group flt">
                          <select
                            name="state"
                            value={state}
                            className={submitted && !state ? " is-invalid" : ""}
                            onChange={changeStateHandler}
                          >
                            <option>Select State</option>
                            {storeState &&
                              storeState.map((state) => (
                                <option key={state._id}>{state.state}</option>
                              ))}
                          </select>
                          {/* {submitted && !state && (
                            <div className="invalid-feedback">State is required</div>
                          )} */}
                        </div>
                      </div>
                    </div>
                    {storeDistrict?.[0]?.district && (
                      <div className="col-sm-6">
                        <label>Select District</label>
                        <div className="form-group flt">
                          <select
                            name="billing_district"
                            value={district}
                            onChange={changeDistrictHandler}
                            className={
                              submitted && !district ? " is-invalid" : ""
                            }
                          >
                            <option>Select District</option>
                            {storeDistrict &&
                              storeDistrict.map((district) => (
                                <option key={district._id}>
                                  {district.district}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    )}
               
                    <div className="col-sm-6">
                      <div className="form-group flt">
                        <label>Pincode</label>
                        <input
                          type="number"
                          placeholder="Enter Pincode"
                          name="billing_pincode"
                          value={billingPincode}
                          //maxLength={6}
                          onChange={changePincodeHandler}
                          className={
                            submitted && !billingPincode ? " is-invalid" : ""
                          }
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Shipping Address</label>
                        <input
                          name="shipping_address"
                          value={shippingAddress}
                          type="text"
                          placeholder="Enter Shipping Address"
                          onChange={HandleChange}
                          className={
                            submitted && !shippingAddress
                              ? " is-invalid"
                              : "login_lbl"
                          }
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Locality</label>
                        <input
                          name="shipping_locality"
                          value={shippingLocality}
                          type="text"
                          placeholder="Enter Locality"
                          onChange={HandleChange}
                          className={
                            submitted && !shippingLocality
                              ? " is-invalid"
                              : "login_lbl"
                          }
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Landmark</label>
                        <input
                          name="shipping_landmark"
                          value={shippingLandmark}
                          placeholder="Enter Landmark"
                          type="text"
                          onChange={HandleChange}
                          className={
                            submitted && !shippingLandmark
                              ? " is-invalid"
                              : "login_lbl"
                          }
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>City</label>
                        <input
                          name="shipping_city"
                          value={shippingCity}
                          placeholder="Enter City"
                          type="text"
                          onChange={HandleChange}
                          className={
                            submitted && !shippingCity
                              ? " is-invalid"
                              : "login_lbl"
                          }
                        />
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group flt">
                        <label>Select State</label>
                        <div className="form-group flt">
                          <select
                            name="shipping_state"
                            value={shippingState}
                            className={
                              submitted && !shippingState ? " is-invalid" : ""
                            }
                            onChange={changeStateHandlerShipping}
                          >
                            <option>Select State</option>
                            {storeStateShipping &&
                              storeStateShipping.map((state) => (
                                <option key={state._id}>{state.state}</option>
                              ))}
                          </select>
                          {/* {submitted && !state && (
                            <div className="invalid-feedback">State is required</div>
                          )} */}
                        </div>
                      </div>
                    </div>
                    {storeDistrictShipping?.[0]?.district && (
                      <div className="col-sm-6">
                        <label>Select District</label>
                        <div className="form-group flt">
                          <select
                            name="shipping_district"
                            value={shippingDistrict}
                            onChange={changeDistrictHandlerShipping}
                            className={
                              submitted && !shippingDistrict
                                ? " is-invalid"
                                : ""
                            }
                          >
                            <option>Select District</option>
                            {storeDistrictShipping &&
                              storeDistrictShipping.map((district) => (
                                <option key={district._id}>
                                  {district.district}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    )}

                    <div className="col-sm-6">
                      <div className="form-group flt">
                        <label>Pincode </label>
                        <input
                          type="number"
                          placeholder="Enter Pincode"
                          name="shipping_pincode"
                          value={shippingPincode}
                          onChange={changePincodeShippingHandler}
                          className={
                            submitted && !shippingPincode ? " is-invalid" : ""
                          }
                          maxLength={6}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </div>
                    </div>

                    {active && (
                      <div class="col-sm-12">
                        <div class="password_check flt">
                          <input
                            type="checkbox"
                            id="password"
                            name="check"
                            onClick={HandleChangeclick}
                          />
                          <label for="password">Change Password</label>
                        </div>
                      </div>
                    )}

                    {check === true && active && (
                      <div class="col-sm-6">
                        <div class="form-group flt">
                          <label>Current Password</label>
                          <input
                            name="c_password"
                            value={c_password}
                            type="password"
                            placeholder="Enter current password"
                            onChange={HandleChange}
                          />
                        </div>
                        <div class="form-group flt">
                          <label>New Password</label>
                          <input
                            name="new_password"
                            value={new_password}
                            placeholder="Enter new password"
                            type="password"
                            onChange={HandleChange}
                          />
                        </div>
                        {valid === false && (
                          <div className="invalid-feedback">
                            Both password must match
                          </div>
                        )}
                        <div class="form-group flt">
                          <label>Confirm new Password</label>
                          <input
                            name="confirm_password"
                            value={confirm_password}
                            type="password"
                            placeholder="Re-enter new password"
                            onChange={HandleChange}
                          />
                        </div>
                        {valid === false && (
                          <div className="invalid-feedback">
                            Both password must match
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <div class="profile_right_btn flt">
                <button onClick={editDetails}>Save & Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header count={wishListCount} cart={cartCount} />
      {MyDetails()}
      <Footer />
    </div>
  );
};
export default MyDetails;
