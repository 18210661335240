import { useCallback } from "react";
import defaultImg from "../../../assets/images/default.png";
import { ProductService } from "../../../services/ProductService";
import { alertActions } from "../../../actions/alert.actions";
import "./ProductListNew.css";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sample from "../../../assets/images/default.png";
import { UserService } from "../../../services";
import crypto from "crypto";
import TagManager from "react-gtm-module";

import {
  MAX_AMOUNT,
  MIN_AMOUNT,
  PAGE_NO,
  SUB_CAT,
  UPDATE_ALL_FILTER,
} from "../../../actions/types";
import useCountHook from "../../../hooks/useCountHook";

const image_url = process.env.REACT_APP_MEDIA_DOMAIN; // Image url
const useProductListNewHook = (location) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usr_id = useSelector((state) => state?.userData?.user?._id);
  const user_id = useSelector((state) => state?.userData?.user?._id);
  const filter = useSelector((state) => state?.listData?.filter);
  const min_amount = useSelector((state) => state?.listData?.min_amount);
  const max_amount = useSelector((state) => state?.listData?.max_amount);
  const main_cat = useSelector((state) => state?.listData?.main_cat);
  const sub_cat = useSelector((state) => state?.listData?.sub_cat);
  const page_no = useSelector((state) => state?.listData?.page_no);
  var search_key = useSelector((state) => state?.listData?.search_key);
  const [pageCount, setPageCount] = useState(0);
  const [product, setProductList] = useState(null);
  const [loading, setLoading] = useState("loading");
  const [mobileFilter, showMobileFilter] = useState(false);
  const [search, setSearch] = useState(search_key);
  let [minPrice, setMinPrice] = useState(min_amount);
  let [maxPrice, setMaxPrice] = useState(max_amount);
  const [subCategory, setSubCategory] = useState(sub_cat);
  const [count, setCount] = useState();
  const [cart, setCart] = useState();
  const [prodValue, setProdValue] = useState();
  const [prodList, setProdList] = useState();
  const [timeOutId, updateTimeOutId] = useState();
  const [searchValue, setSearchValue] = useState();
  const [minAmountValue, setMinAmountValue] = useState();
  const [maxAmountValue, setMaxAmountValue] = useState();
  const [countCall, setCountCall] = useState(0);
  const [sub_category, setSub_category] = useState([]);
  const locationPath = window.location.href.split("/")[4];
  const [singleproductList, setsingleProductList] = useState(null);
  const { wishListCount, cartCount, getWishListCount } = useCountHook();

  const menuData = useSelector((state) => state.userData.user);
  const [updatedPixelData, setUpdatedPixelData] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(null);
  const [randomNumbersss, setRandomNumber] = useState("");
  const [hashedData, setHashedData] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [eventData, setEventData] = useState("");

  let history = useNavigate();
  var newsearch1;
  var newsearch2;
  var newsearch3 = locationPath.replace(/[' '\r]/g, "");

  if (/^[A-Za-z0-9]*$/.test(locationPath)) {
    newsearch1 = locationPath;
  } else {
    newsearch2 = locationPath.replace(/[-\r]/g, " ");
    newsearch1 = newsearch2.replace(/[%20\r]/g, "");
    if (newsearch1.includes("Diamond Polki")) {
      newsearch1 = "Polki";
    }
  }

  var filterName;
  filterName =
    newsearch1.includes("women") ||
    newsearch1.includes("men") ||
    newsearch1.includes("kids");
  var newsearchs = newsearch1.replace(/  /g, "");
  var newsearch = newsearchs.replace(/jewellery/g, "");

  //new changes
  let strArray = locationPath.split("-"); // Split the string into an array based on the hyphen separator
  let ProductName = strArray[0];

  //title
  const urlParams = new URLSearchParams(window.location.search);

  // Set the page title
  const words = locationPath.split("-"); // split the string by hyphen
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  ); // capitalize the first letter of each word
  const productName = capitalizedWords.join(" "); // join the words back into a single string with a space separator
  document.title = `Buy ${productName} | Latest ${productName} Designs | Abharan Jewellers`;

  let metaData = {
    info: `Shop Latest ${productName}  At Best Price. Best ${productName} Designs Online In India. 100% Certified Hallmark`,
  };

  //new changes
  const collectionREdirect = () => {
    history("/product-list/gold");
  };
  const homeREdirect = () => {
    history("/");
  };

  const openFilter = () => {
    showMobileFilter(true);
    // document.body.classList.add("modal-shadow");
  };

  const closeFilter = () => {
    showMobileFilter(false);
    document.body.classList.remove("modal-shadow");
  };

  const isInGenderCategory = (title) => {
    return filter?.includes(title);
  };

  //filtering the gender
  const handleChangeGender = (event) => {
    if (event.target.name === "gender") {
      const updatedList = [...filter];

      if (event.target.checked) {
        updatedList.push(event.target.value);
      } else {
        updatedList.splice(updatedList.indexOf(event.target.value), 1);
      }
      dispatch({
        type: UPDATE_ALL_FILTER,
        payload: {
          filter: updatedList,
        },
      });
    }
  };

  //filtering Main_Category
  const handleChangeMainCat = (event) => {
    if (event.target.name === "main") {
      var updatedList = [...main_cat];
      if (event.target.checked) {
        updatedList = [...main_cat, event.target.value];
      } else {
        updatedList.splice(main_cat.indexOf(event.target.value), 1);
      }
      dispatch({
        type: UPDATE_ALL_FILTER,
        payload: {
          main_cat: updatedList,
        },
      });
    }
  };

  //filtering Sub category
  const handleChangeSubCat = (event) => {
    if (event.target.name === "sub") {
      var updatedList = [...sub_cat];

      if (event.target.checked) {
        updatedList = [...sub_cat, event.target.value];
      } else {
        updatedList.splice(sub_cat.indexOf(event.target.value), 1);
      }
      dispatch({
        type: UPDATE_ALL_FILTER,
        payload: {
          sub_cat: updatedList,
        },
      });
    }
  };

  const handleChangePrice = (event) => {
    setLoading("loading");
    // min_amount
    if (event.target.name === "min_amount") {
      setMinPrice(event.target.value);
      minPrice = event.target.value;

      /** timeoutHadler added to implement Debouncing feature **/
      clearTimeout(timeOutId);
      const timeout = setTimeout(() => {
        setMinAmountValue(minPrice);
      }, 700);

      updateTimeOutId(timeout);

      dispatch({
        type: MIN_AMOUNT,
        payload: {
          min_amount: minPrice,
        },
      });
    }

    // max_amount
    if (event.target.name === "max_amount") {
      setMaxPrice(event.target.value);
      maxPrice = event.target.value;

      /** timeoutHadler added to implement Debouncing feature **/
      clearTimeout(timeOutId);
      const timeout = setTimeout(() => {
        setMaxAmountValue(maxPrice);
      }, 700);
      updateTimeOutId(timeout);

      dispatch({
        type: MAX_AMOUNT,
        payload: {
          max_amount: maxPrice,
        },
      });
    }
  };

  useEffect(() => {
    setLoading("loading");
    ProductService.getData(
      search,
      page_no,
      filter ? filter : "",
      minPrice ? minPrice : 1,
      maxPrice ? maxPrice : "",
      main_cat ? main_cat : "",
      sub_cat ? sub_cat : ""
    )
      .then((productList) => {
        setLoading("notempty");
        setProductList(productList?.data?.data?.products?.data);
        setPageCount(productList?.data?.data?.products?.last_page);
        if (productList?.data?.data?.products?.last_page != 1) {
          if (
            productList?.data?.data?.products?.last_page <=
            productList?.data?.data?.products?.current_page
          ) {
            // setCurrent(productList?.data?.data?.products?.last_page)
            dispatch({
              type: PAGE_NO,
              payload: {
                page_no: productList?.data?.data?.products?.last_page,
              },
            });
          }
        } else if (productList?.data?.data?.products?.last_page === 1) {
          dispatch({
            type: PAGE_NO,
            payload: {
              page_no: productList?.data?.data?.products?.last_page,
            },
          });
        }
      })
      .catch((error) => {
        if (
          error === "Cannot read properties of undefined (reading 'status')"
        ) {
          setLoading("loading");
        } else {
          //setLoading("empty");
          setLoading("loading");
        }
      });
    //  }
  }, [
    filter,
    minAmountValue,
    maxAmountValue,
    main_cat,
    sub_cat,
    page_no,
    searchValue,
  ]);

  // const cartCount = () => {
  //   if (user_id) {
  //     ProductService.getCart().then((res) => {
  //       if (res?.data?.data?.cart_count) {
  //         setCart(res?.data?.data?.cart_count);
  //       } else {
  //         // setCart();
  //         // handleLogin();
  //       }
  //     });
  //   }
  // };

  // useEffect(() => {
  //   cartCount();
  //   wishListCount();
  // }, []);

  const imgChange = (prod) => {
    setsingleProductList(prod);
    if (usr_id) {
      product.forEach(function (obj) {
        if (obj.wish_status === undefined) obj.wish_status = 0;
      });

      product.forEach(function (obj) {
        if (prod._id === obj._id) {
          if (obj.wish_status === 1) {
            obj.wish_status = 0;
          } else {
            obj.wish_status = 1;
            // if (singleproductList !== undefined) {
            //   const productData = {
            //     id: singleproductList?.SKU,
            //     name: singleproductList?.name,
            //     price: singleproductList?.c_total.toString(),
            //     quantity: 1,
            //     category: singleproductList?.pro_details?.description,
            //     currency: "INR",
            //   };

            //   TagManager.dataLayer({
            //     dataLayer: {
            //       event: "addToWishlist",
            //       ecommerce: productData,
            //       pagePath: location.pathname,
            //     },
            //   });
            //   console.log(window.dataLayer, "DataLayer");
            // }
            const hashObject = (obj) => {
              const hashedObj = {};
              for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                  const value = obj[key];
                  const hash = crypto
                    .createHash("sha256")
                    .update(String(value))
                    .digest("hex");
                  hashedObj[key] = hash;
                }
              }
              return hashedObj;
            };
            const hashedSingleObject = hashObject(menuData);
            setHashedData(hashedSingleObject);

            function getCookie(name) {
              const cookieValue = document.cookie.match(
                "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
              );
              return cookieValue ? cookieValue.pop() : "";
            }

            // Fetch the values of fbp and fbc cookies
            const fbpValue = getCookie("_fbp");
            const fbcValue = getCookie("_fbc");

            var currentUrl = window.location.href;

            const user_data = {
              client_ip_address: ipAddress,
              client_user_agent: userAgent,
              fbp: fbpValue,
              fbc: fbcValue,
              em: [hashedData.email],
              ph: [hashedData.mobile_no, hashedData.alternate_number],
              ln: [hashedData.last_name],
              fn: [hashedData.first_name],
              ct: [hashedData.city, hashedData.shipping_city],
              st: [hashedData.state, hashedData.shipping_state],
              zp: [hashedData.pincode, hashedData.shipping_pincode],
            };

            if (singleproductList && singleproductList.wish_status == 1) {
              setTimeout(() => {
                const custom_data = {
                  currency: "INR",
                  value: singleproductList?.c_total.toString(),
                  content_category: singleproductList?.pro_details?.description,
                  content_ids: singleproductList?.SKU,
                };

                const eventData = {
                  event_name: "AddToWishlist",
                  event_time: unixTimestamp,
                  action_source: "website",
                  event_source_url: currentUrl,
                  event_id: randomNumbersss.toString(),
                  user_data: user_data,
                  custom_data: custom_data,
                };

                UserService.getUserData(eventData)
                  .then((response) => {
                    //setGetOtpData(response);
                  })
                  .catch((error) => {
                    console.log("Errorreg", error);
                  });

                const newRandomNumber = generateRandomNumber();
                setRandomNumber(newRandomNumber);

                if (singleproductList !== undefined) {
                  const productData = {
                    id: singleproductList?.SKU,
                    name: singleproductList?.name,
                    price: singleproductList?.c_total.toString(),
                    quantity: 1,
                    category: singleproductList?.pro_details?.description,
                    currency: "INR",
                  };

                  TagManager.dataLayer({
                    dataLayer: {
                      event: "addtowishlist",
                      ecommerce: productData,
                      pagePath: location.pathname,
                    },
                  });
                  console.log(window.dataLayer, "DataLayer");
                }

                if (ipAddress && userAgent && singleproductList) {
                  setUpdatedPixelData((prevData) => [...prevData, eventData]);
                }
              }, 300);
            }
          }
        }
      });

      ProductService.getProdDetails(prod.pro_details.product_id, usr_id)
        .then((prodList) => {
          setProdList(prodList);

          getWishListCount();
          if (prodList.status.code === 4) {
            dispatch(alertActions.error(prodList.status.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);

          dispatch(alertActions.error("Please login....!"));

          history("/login");
          //   setLoading(false);
        });
    } else {
      history("/login");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page_no]);

  const isInMainCategory = useCallback(
    (title) => {
      return main_cat?.includes(title);
    },
    [main_cat]
  );

  useEffect(() => {
    ProductService.getSubCategory().then((sub_category) => {
      setSub_category(sub_category.data.data.sub_category);
    });
    const currentTimestamp = Math.floor(Date.now() / 1000); // Getting Unix timestamp in seconds
    setUnixTimestamp(currentTimestamp);

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));

    setUserAgent(navigator.userAgent);

    const newRandomNumber = generateRandomNumber();
    setRandomNumber(newRandomNumber);
  }, []);

  useEffect(() => {
    setSearch(search_key);
  }, [search_key]);

  const isInSubCategory = useCallback(
    (title) => {
      return sub_cat?.includes(title);
    },
    [sub_cat]
  );

  function changeHandler(e) {
    setLoading("loading");
    setSearch(e.target.value);

    /**TimeOut used to achieve debouncing */
    clearTimeout(timeOutId);
    const timeout = setTimeout(() => {
      setSearchValue(search);
      search_key = search;
    }, 700);
    updateTimeOutId(timeout);

    dispatch({
      type: UPDATE_ALL_FILTER,
      payload: {
        search_key: search_key,
      },
    });
  }

  // code for adding filtered data
  useEffect(() => {
    dispatch({
      type: SUB_CAT,
      payload: {
        sub_cat: sub_cat,
      },
    });
    if (sub_cat?.length === 1) {
      setSubCategory(true);
    }
  }, [sub_cat]);

  const generateRandomNumber = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = currentDate.getMonth() + 1; // Month is 0-indexed, so add 1
    // const date = currentDate.getDate();
    // const hours = currentDate.getHours();
    // const minutes = currentDate.getMinutes();
    // const seconds = currentDate.getSeconds();
    // const milliseconds = currentDate.getMilliseconds();

    // // Combine all parts into a single number
    // const randomNumber = parseInt(
    //   `${year}${month}${date}${hours}${minutes}${seconds}${milliseconds}`,
    //   10
    // );

    // return randomNumber;
    var possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    var randomNumber = "";
    for (var i = 0; i < 10; i++) {
      var randomIndex = Math.floor(Math.random() * possibleCharacters.length);
      randomNumber += possibleCharacters.charAt(randomIndex);
    }
    return randomNumber;
  };

  useEffect(() => {
    const hashObject = (obj) => {
      const hashedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const hash = crypto
            .createHash("sha256")
            .update(String(value))
            .digest("hex");
          hashedObj[key] = hash;
        }
      }
      return hashedObj;
    };
    const hashedSingleObject = hashObject(menuData);
    setHashedData(hashedSingleObject);

    function getCookie(name) {
      const cookieValue = document.cookie.match(
        "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
      );
      return cookieValue ? cookieValue.pop() : "";
    }

    // Fetch the values of fbp and fbc cookies
    const fbpValue = getCookie("_fbp");
    const fbcValue = getCookie("_fbc");

    var currentUrl = window.location.href;

    const user_data = {
      client_ip_address: ipAddress,
      client_user_agent: userAgent,
      fbp: fbpValue,
      fbc: fbcValue,
      em: [hashedData.email],
      ph: [hashedData.mobile_no, hashedData.alternate_number],
      ln: [hashedData.last_name],
      fn: [hashedData.first_name],
      ct: [hashedData.city, hashedData.shipping_city],
      st: [hashedData.state, hashedData.shipping_state],
      zp: [hashedData.pincode, hashedData.shipping_pincode],
    };

    if (singleproductList && singleproductList.wish_status == 1) {
      const custom_data = {
        currency: "INR",
        value: singleproductList?.c_total.toString(),
        content_category: singleproductList?.pro_details?.description,
        content_ids: singleproductList?.SKU,
      };

      const eventData = {
        event_name: "AddToWishlist",
        event_time: unixTimestamp,
        action_source: "website",
        event_source_url: currentUrl,
        event_id: randomNumbersss.toString(),
        user_data: user_data,
        custom_data: custom_data,
      };

      UserService.getUserData(eventData)
        .then((response) => {
          //setGetOtpData(response);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });

      const newRandomNumber = generateRandomNumber();
      setRandomNumber(newRandomNumber);

      if (singleproductList !== undefined) {
        const productData = {
          id: singleproductList?.SKU,
          name: singleproductList?.name,
          price: singleproductList?.c_total.toString(),
          quantity: 1,
          category: singleproductList?.pro_details?.description,
          currency: "INR",
        };

        TagManager.dataLayer({
          dataLayer: {
            event: "addtowishlist",
            // ecommerce: {
            //   currencyCode: productData.currency,
            //   add: {
            //     products: [productData],
            //   },
            // },
            ecommerce: productData,
            pagePath: location.pathname,
          },
        });
        console.log(window.dataLayer, "DataLayer");
      }

      // if (ipAddress && userAgent && singleproductList) {
      //   setUpdatedPixelData((prevData) => [...prevData, eventData]);
      // }
    }
  }, [menuData, ipAddress, userAgent, singleproductList]);

  // useEffect(() => {
  //   if (updatedPixelData.length) {
  //     UserService.getUserData(updatedPixelData)
  //       .then((response) => {
  //         //setGetOtpData(response);
  //       })
  //       .catch((error) => {
  //         console.log("Errorreg", error);
  //       });
  //   }
  // }, [updatedPixelData]);

  const handleClearSingleSubCat = (index) => {
    if (sub_cat?.length !== 0) {
      const newSub = [...sub_cat];
      newSub.splice(index, 1);
      dispatch({
        type: SUB_CAT,
        payload: {
          sub_cat: newSub,
        },
      });
    } else {
      dispatch({
        type: SUB_CAT,
        payload: {
          sub_cat: [],
        },
      });
    }
  };

  const handleClearSubCat = () => {
    dispatch({
      type: SUB_CAT,
      payload: {
        sub_cat: [],
      },
    });
  };

  const changeCurrentPage = (page_no) => {
    // setCurrent(page_no.selected +1)
    dispatch({
      type: PAGE_NO,
      payload: {
        page_no: page_no.selected + 1,
      },
    });
  };

  const displayProduct = (display) => {
    if (location.pathname !== "/login") {
      localStorage.setItem("pathname", window.location.pathname);
    }
    ProductService.productClickCount(display?.pro_details?.product_id);
    const sku = display.SKU;
    const productName = display?.main_cat?.title
      .toLowerCase()
      .replace(/\s+/g, "-");
    const prodNameData = display?.name.replace(/\s+/g, "-");
    const url = `/product-details/${productName}/${prodNameData.toLowerCase()}/${sku.toLowerCase()}`;
    navigate(url, {
      state: { id: sku, userid: usr_id, name: location?.state?.name },
    });
    setProdValue(display);
  };

  return {
    defaultImg,
    sample,
    homeREdirect,
    collectionREdirect,
    locationPath,
    filter,
    min_amount,
    max_amount,
    main_cat,
    sub_cat,
    search_key,
    product,
    pageCount,
    page_no,
    search,
    loading,
    image_url,
    count,
    cart,

    sub_category,
    minPrice,
    maxPrice,
    subCategory,
    metaData,
    mobileFilter,
    handleChangePrice,
    handleChangeSubCat,
    handleChangeMainCat,
    openFilter,
    handleChangeGender,
    closeFilter,
    isInGenderCategory,
    isInMainCategory,
    isInSubCategory,
    changeHandler,
    handleClearSingleSubCat,
    handleClearSubCat,
    imgChange,
    changeCurrentPage,
    displayProduct,
    wishListCount,
    cartCount,
  };
};

export default useProductListNewHook;
