import { SAVE_USER_DATA, USER_LOGOUT, EDIT_USER_DATA } from "./types";
import { UserService } from "../services/UserService";
import { ProductService } from "../services";

export const userLogin = (mobile_no, password) => (dispatch) => {
  return UserService.login(mobile_no, password).then((data) => {
    if (data.data.status.code === 0) {
      dispatch({
        type: SAVE_USER_DATA,
        payload: {
          token: data.data.data.access_token,
          user: data.data.user,
          type: data.data.data.token_type,
        },
      });
    }
    return Promise.resolve(data);
  });
};
export const registration =
  (name, mobile_no, email, password) => (dispatch) => {
    return UserService.registration(name, mobile_no, email, password).then(
      (data) => {
        if (data.data.status.code === 0) {
          dispatch({
            type: SAVE_USER_DATA,
            payload: {
              token: data.data.data.access_token,
              user: data.data.user,
              type: data.data.data.token_type,
            },
          });
        }
        return Promise.resolve(data);
      }
    );
  };
export const myDetails =
  (
    name,
    lname,
    email,
    address,
    password,
    c_password,
    confirm_password,
    new_password,
    user_id,
    mobile,
    locality,
    city,
    state,
    district,
    pincode,
    landmark,
    shipping_address,
    shipping_locality,
    shipping_city,
    shipping_landmark,
    shipping_pincode,
    shipping_state,
    shipping_district,
    alternate_number
  ) =>
  (dispatch) => {
    return ProductService.myDetails(
      name,
      lname,
      email,
      address,
      password,
      c_password,
      confirm_password,
      new_password,
      user_id,
      mobile,
      locality,
      city,
      state,
      district,
      pincode,
      landmark,
      shipping_address,
      shipping_locality,
      shipping_city,
      shipping_landmark,
      shipping_pincode,
      shipping_state,
      shipping_district,
      alternate_number
    ).then((data) => {
      if (data.data.status.code === 0) {
        dispatch({
          type: EDIT_USER_DATA,
          payload: {
            // token: data.data.data.access_token,
            user: data.data.data.user_details,
            // type: data.data.data.token_type,
          },
        });
      }
      return Promise.resolve(data);
    });
  };

export const myDetail =
  (
    shipping_address,
    shipping_locality,
    shipping_city,
    shipping_landmark,
    shipping_pincode,
    shipping_state,
    shipping_district,
    user_id
  ) =>
  (dispatch) => {
    return ProductService.myDetail(
      shipping_address,
      shipping_locality,
      shipping_city,
      shipping_landmark,
      shipping_pincode,
      shipping_state,
      shipping_district,
      user_id
    ).then((data) => {
      if (data.data.status.code === 0) {
        dispatch({
          type: EDIT_USER_DATA,
          payload: {
            user: data.data.data.user_details,
          },
        });
      }
      return Promise.resolve(data);
    });
  };

export const googleLogin = (first_name, socialId, email) => (dispatch) => {
  // console.log("google", first_name);
  return UserService.googleLogin(first_name, socialId, email).then((data) => {
    // console.log("data", data);
    if (data.data.status.code === 0) {
      dispatch({
        type: SAVE_USER_DATA,
        payload: {
          token: data.data.data.access_token,
          user: data.data.user,
          type: data.data.data.token_type,
        },
      });
    }
    return Promise.resolve(data);
  });
};

export function userLogout() {
  return function (dispatch) {
    const action = {
      type: USER_LOGOUT,
    };
    // localStorage.clear();
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("loginTime");

    dispatch(action);
  };
}
